import React, { useState } from 'react';
import { ReactComponent as SVGArrowLeft } from "../../../Icons/Actions/Arrow-Left.svg";
import "./clientSelectorModal.css";

const ClientSelector = ({ clients, selectedClients, onClientSelect, showModal }) => {
    const [auxSelectedClients, setAuxSelectedClients] = useState([...selectedClients])

    const toggleClientSelection = (client) => {
        if (auxSelectedClients.includes(client)) {
            setAuxSelectedClients(auxSelectedClients.filter(c => c !== client));
        } else {
            setAuxSelectedClients([...auxSelectedClients, client]);
        }
    };

    const handleConfirm = () => {
        onClientSelect(auxSelectedClients);
        showModal();
    };


    return (
        <>
            <div className='d-flex align-items-center gap-2'>
                <button
                    className="back-button"
                    onClick={() => {
                        showModal()
                    }}
                >
                    <SVGArrowLeft />
                </button>
                <h5 className="m-0 museo-sans-500 s20">Seleccionar clientes</h5>
            </div>

            <div>
                <div className="client-list mb-4">
                    {clients?.map((client, index) => (
                        <div key={index} className="d-flex align-items-center border-bottom check-input">
                            <input
                                type="checkbox"
                                className="form-check-input m-0 me-3"
                                checked={auxSelectedClients.includes(client)}
                                onChange={() => toggleClientSelection(client)}
                            />
                            <label className="form-check-label museo-sans-500 s14">{client.firstName} {client.lastName}</label>
                        </div>
                    ))}
                </div>
                <div className='d-flex justify-content-center'>
                    <button
                        className="filter-button-modal filter-button-primary museo-sans-700 s14"
                        onClick={handleConfirm}
                    >
                        Seleccionar
                    </button>
                </div>

            </div>
        </>
    );
};

export default ClientSelector;