import React, { useState } from "react";
import "./forWindows.css";
import logoGalicia from "../../../../../../Imgs/logo_galicia.png";
const ForWindows = () => {
  return (
    <div style={{width:'396px'}}>
      <p className="museo-sans-600 container-img   s20">
        Pago por bocas de recaudación
      </p>
      <div className="container-overflow" style={{padding:'0px'}}>
        <div className="container-img">
          {" "}
          <img style={{width:'150px',height:'43px'}} src={logoGalicia} alt="logo-galicia" />
        </div>

        <p className="museo-sans-600 container-img  margin-top-payment s16" >
          CIC Pago en efectivo por ventalilla
        </p>
        <p className="museo-sans-600   s14" style={{ color: "#908F9A" }}>
          No tenés que presentar ninguna Boleta.
        </p>
        <div style={{ padding: "0px 8px" }}>
          <p className="museo-sans-400   s14" style={{ color: "#908F9A" }}>
            • Presentate en la sucursal y dirigite a la Terminal de Autoservicio
            Inteligente (TASI - sin sobre).
          </p>
          <p className="museo-sans-400   s14" style={{ color: "#908F9A" }}>
            • Seleccioná la operación Cobranza Integrada.
          </p>
          <p className="museo-sans-400   s14" style={{ color: "#908F9A" }}>
            • Colocá el número de convenio - 638
          </p>
          <p className="museo-sans-400   s14" style={{ color: "#908F9A" }}>
            • Identificate con tu clave de Consultor/a y seleccioná el medio de
            pago (Efectivo o Débito en cuenta).
          </p>
        </div>
        <p className="museo-sans-600 container-img margin-top-payment margin-button-payment s16" >
          CIC Pago en efectivo por terminal de autoservicio
        </p>
        <p className="museo-sans-600   s14" style={{ color: "#908F9A" }}>
          No tenés que presentar ninguna Boleta.
        </p>
        <div style={{ padding: "0px 8px" }}>
        <p className="museo-sans-400   s14" style={{ color: "#908F9A" }}>
          • Presentate en la sucursal y dirigite a la Terminal de Autoservicio
          Inteligente (TASI - sin sobre).
        </p>
        <p className="museo-sans-400   s14" style={{ color: "#908F9A" }}>
          • Seleccioná la operación Cobranza Integrada.
        </p>
        <p className="museo-sans-400   s14" style={{ color: "#908F9A" }}>
          • Colocá el número de convenio - 638{" "}
        </p>
        <p className="museo-sans-400   s14" style={{ color: "#908F9A" }}>
          {" "}
          • Identificate con tu clave de Consultor/a y seleccioná el medio de
          pago (Efectivo o Débito en cuenta).
        </p>
        </div>
      </div>
    </div>
  );
};

export default ForWindows;
