import React from 'react';
import "./OrdersCard.css"
import { FormattedMessage } from 'react-intl';
import { ReactComponent as SVGEInfo } from "../../Icons/Notification/MoneyMedal.svg";
import { ReactComponent as SVGCheck } from "../../Icons/Notification/Check-Circle.svg";
import OrdersNotification from '../components/OrdersNotification';
import { useSelector } from 'react-redux';
import OrdersKitDetailModal from './OrdersKitDetailModal';

const OrdersSelectKit = ({ kit, handleKit, handleKitDetail, kitSelected, kitDetail }) => {
    const isLoading = useSelector((state) => state.orderData.loading)
    return (
        <>
            {isLoading ? (
                <div
                    style={{ height: "200px" }}
                    className="rounded-3 brightGrayBg lightui1-shimmer my-3"
                ></div>
            ) :
                (<div className={`${kitSelected === kit.kitId ? "kit-selection-container-selected" : ""} kit-selection-container`}>
                    {kit.mostChosen && (
                        <div className="most-chosen-badge s12 museo-sans-700">
                            ¡EL MÁS ELEGIDO!
                        </div>
                    )}
                    <div className='d-flex flex-column'>
                        {kit.freeKitAvailable && <div className='mb-3'>
                            <OrdersNotification
                                icon={<SVGEInfo />}
                                text={kit.info}
                                type="info"
                            />
                        </div>}

                        <div className='kit-selection d-flex gap-4'>
                            <img className="kit-selection-img" src={kit.imageURL} alt={kit.name}>
                            </img>
                            <div>
                                <p className='museo-sans-700 s16'>{kit.name}<span className='ms-3 museo-sans-500 s12 grayColor'>{kit.includedProducts.length} PRODUCTOS</span></p>
                                <p className='kit-selection-description museo-sans-500 grayColor mt-3'>{kit.description}</p>
                                <button className='mt-4 museo-sans-700 s14 skyColor bronceBg button-border-none' data-bs-toggle="modal"
                                    data-bs-target="#kitDetailModal"
                                    onClick={() => handleKitDetail(kit)}>
                                    <FormattedMessage
                                        id="business.kit.details"
                                        defaultMessage="Ver detalles"
                                    />
                                </button>
                            </div>
                            <div className='d-flex flex-column justify-content-between align-items-end'>
                                <div className='dm-sans-500'>
                                    <span className='kit-regular-price s16'>{kit.regularPrice}</span>{' '}
                                    <span className='kit-discount-price s20'>{kit.discountPrice}</span>
                                </div>

                                <button onClick={() => handleKit(kit)} className={`${kitSelected === kit.kitId ? 'kit-selection-detail--selected' : ''} kit-selection-detail museo-sans-500 s14`}>
                                    {kitSelected === kit.kitId ?
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <FormattedMessage
                                                id="business.kit.selected"
                                                defaultMessage="Seleccionado"
                                            />
                                            <SVGCheck className='ms-1' />
                                        </div>
                                        :
                                        <FormattedMessage
                                            id="business.kit.selection"
                                            defaultMessage="Seleccionar"
                                        />
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                    <OrdersKitDetailModal kit={kitDetail} kitSelected={kitSelected} handleKit={handleKit} />
                </div>
                )}
        </>
    );
}

export default OrdersSelectKit;
