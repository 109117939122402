import React, { useEffect, useState } from 'react';
import ShippingAddress from '../components/ShippingAddress';
import PaymentMethod from '../components/PaymentMethod';
import '../components/styles.css';
import { useSelector, useDispatch } from 'react-redux';
import { OrderCartSummary } from '../../../Orders/Presentation/pages/OrdersCart';
import { saveNewTotalAmount } from '../../../Orders/Framework/redux/OrdersActions';
import { ReactComponent as SVGWarning } from "../../Icons/Warning.svg";
import { formatNumber } from '../../common'
import ModalCashPayment from '../components/modal/modalCashPayment/ModalCashPayment';
import ModalDifferentCashPayments from '../components/modal/modalDifferentCashPayments/ModalDifferentCashPayments';

const Payments = () => {
    const dispatch = useDispatch();
    const orderSummary = useSelector(
        (state) => state.orderData.orderSummary
    );
    const [newTotalAmount, setNewTotalAmount] = useState("")
    const [showModalCahsPayment, setShowModalCahsPayment] = useState(false);
    const [showModalDifferentCashPayments, setShowModalDifferentCashPayments] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState('');

    useEffect(() => {
        if (newTotalAmount != '')
            dispatch(saveNewTotalAmount("$ " + formatNumber(newTotalAmount)))
    }, [newTotalAmount]);


    const onclickPayment = () => {
        selectedPayment != 'mercado-pago' && iscashPayment()
    }
    const iscashPayment = () => {
        setShowModalCahsPayment(true);
    }
    const toModalDifferentCashPayments = () => {
        setShowModalCahsPayment(false)
        setShowModalDifferentCashPayments(true)
    }
    const closedDifferentPaymentModal = () => {
        setShowModalDifferentCashPayments(false);
    }
    const closedcashPaymentModal = () => {
        setShowModalCahsPayment(false)
    }

    return (
        <div className="d-flex my-5 gap-4">
            <div style={{ width: "496px" }}>
                <ShippingAddress />
                <PaymentMethod setNewTotalAmount={(value) => setNewTotalAmount(value)} setSelectedPayment={setSelectedPayment} selectedPayment={selectedPayment} />
            </div>
            <div style={{ width: "616px", height: "432px", margin: "0 44px 44px" }}>
                <p className='mb-4 museo-sans-700 s24'>Detalle del pedido</p>

                {newTotalAmount !== '' && <div style={{
                    background: "#FEFBEC"
                }}
                    className="kit-notification-container notification-border p-3 d-flex align-items-center mt-3">
                    <div style={{ marginRight: "0px" }}>
                        <SVGWarning />
                    </div>
                    <p className='museo-sans-500 s14 blackColor'>
                        Para que se facture tu pedido debes también pagar el monto adeudado en el saldo de tu cuenta.
                    </p>
                </div>}

                <div className='museo-sans-500'>
                    <OrderCartSummary
                        cartSummary={orderSummary.cartSummary}
                        isSummary={true}
                    />
                </div>
                <button
                    style={{
                        width: "288px",
                        height: "40px",
                        borderRadius: "8px",
                        marginTop: "44px"
                    }}
                    onClick={() => onclickPayment()}
                    className='museo-sans-700 s14 skyBg text-white border-0' >
                    Realizar el pago
                </button>
            </div>
            <ModalCashPayment isOpen={showModalCahsPayment} close={closedcashPaymentModal} toModalDifferentCashPayments={toModalDifferentCashPayments} />
            <ModalDifferentCashPayments close={closedDifferentPaymentModal} isOpen={showModalDifferentCashPayments} />
        </div>
    );
}

export default Payments;
