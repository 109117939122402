import React, { useState, useEffect } from "react";
import "./listadoOrders.css";
import edbIcon from "../../../imgs/EDB.png";
import gropuIcon from "../../../imgs/Group.png";
import profileIcon from "../../../imgs/Profile.png";
import vectorEventIcon from "../../../imgs/Vectorevent.png";
import warningIcon from "../../../imgs/Warning.png";
import MailboxIcon from "../../../imgs/Mailbox.png";
import { status } from "./common/common-listadoOrders";
import filterIcon from "../../../imgs/Filter.png";
import trashIcon from "../../../imgs/Trash.png";
import checkIcon from "../../../imgs/check.png";
import checkAllIcon from "../../../imgs/checkall.png";
import notProductIcon from "../../../../common/img/consultationCatalogue/Icon (1).png";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ListadoOrdersFilterModal from "./listadoOrdersFilterModal";
import { useNavigate } from "react-router";
import { isOrder, ordersSelect } from "../../../Framework/redux/OrdersActions";
import { OrdersKitSkeleton } from "../OrdersKitSkeleton/OrdersKitSkeleton";
import { ModalProducts } from "../modals/modalProducts/modalProducts";
import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories";
export default function ListOrders() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOrders } = location.state || {};
  const orderData = useSelector((state) => state.orderData);
  const [selectedCount, setSelectedCount] = useState(0);
  const [titleTable, setTitleTable] = useState("");
  const [list, setList] = useState([]);
  const [isActiveSelect, setIsActiveSelect] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [orderSelect, setOrderSelect] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [filterModal, setFilterModal] = useState({
    isOpen: false,
    filtersApplied: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userModal, setUserModal] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const title = isOrders ? "ORDEN" : "PEDIDO";
    if(isFilter && !isOrders) return
    isOrders
      ? setTotalCount(orderData.orders.length)
      : setTotalCount(orderData.requests.length);
    setTitleTable(title);
    if (isOrders) {
      setListNew(orderData.orders, false, false);
    } else {
      setList(orderData.requests);
    }
    if(list.length > 0){
      setIsLoading(false)
    }
  }, [isOrders, orderData,list]);

  const handleSelectAllChange = (e) => {
    setIsActiveSelect(e.target.checked);
    if (e.target.checked) {
      setSelectedCount(totalCount);
      setListNew(list, e.target.checked, false);
      setOrderSelect(list);
    } else {
      setSelectedCount(0);
      setListNew(list, e.target.checked, false);
      setOrderSelect([]);
    }
  };

  const handleUnSelect = () => {
    setIsActiveSelect(false);
    setSelectedCount(0);
    setListNew(list, false, false);
    setOrderSelect([]);
  };

  const setListNew = (array, value, valueDelete) => {
    let newList = [];
    array.map((resp) => {
      newList.push({
        ...resp,
        isCheck: value,
        isDelete: resp.isDelete ? resp.isDelete : valueDelete,
      });
    });

    setList(newList);
  };

  const getIcon = (tipo) => {
    let icon = "";
    if (tipo.toLowerCase() === "personal") {
      icon = profileIcon;
    } else if (
      tipo.toLowerCase() === "client" ||
      tipo.toLowerCase() === "cliente"
    ) {
      icon = gropuIcon;
    } else if (tipo.toLowerCase() === "edb") {
      icon = edbIcon;
    }
    return icon;
  };

  const getDate = (date) => {
    return date.split("T")[0];
  };
  const getIconState = (statu) => {
    return  statu != "FACTURADO" ? warningIcon:MailboxIcon;
  };
  const getColorState = (statu) => {
    if (isOrders) {
      return  statu == "SIN PAGAR"? "#DCB632": "#16BE7D";
    } else {
     return  statu != "FACTURADO" ? "#DCB632": "#16BE7D";
    }
  };
  const goToSummaryOrders = async () => {
    let count = 0;
    let products = []
    const countryCode = localStorage.getItem("country");
    const clientId = localStorage.getItem("clientId");
    const promises = orderSelect.map(async (resp) => {
      const response = await Repository.OrderingRepository.getOrderProducts(
        clientId,
        countryCode,
        resp.id
      );
      products = products.concat(response)
      response.forEach((item) => {
        count += item.totalItems;
      });
    });
    await Promise.all(promises);
    console.log(count);
    dispatch(ordersSelect({ orderSelect, count, products }));
    navigate("/dashboard/orders/summary-requests");
  };
  const checkElement = (index) => {
    const order = list[index];

    const updatedList = [...list];
    updatedList[index].isCheck = !updatedList[index].isCheck;
    if (updatedList[index].isCheck) {
      setOrderSelect([...orderSelect, order]);
    } else {
      const listFilter = orderSelect.filter((result) => result.id != order.id);
      setOrderSelect(listFilter);
    }
    setList(updatedList);
    setIsActiveSelect(list.some((element) => element.isCheck === true));
    !updatedList[index].isCheck
      ? setSelectedCount(selectedCount - 1)
      : setSelectedCount(selectedCount + 1);
  };
  const deleteOrders = () => {
    let auxList = [];
    list.map((elemt) => {
      let elementAux = {};
      if (elemt.isCheck) {
        elementAux = { ...elemt, isDelete: true };
      } else {
        elementAux = { ...elemt };
      }
      auxList.push(elementAux);
    });
    const total = auxList.filter((resp) => !resp.isDelete).length;
    setTotalCount(total);
    setSelectedCount(0);
    setIsActiveSelect(false);
    setList(auxList);
  };

  const handleFilter = () => {
    setFilterModal({
      isOpen: !filterModal.isOpen,
      filtersApplied: filterModal.filtersApplied,
    });
  };

  const changeFilterModalApplied = (value) => {
    setIsFilter(true)
    setFilterModal({ isOpen: filterModal.isOpen, filtersApplied: value });
  };
  const closeModal = () => setIsModalOpen(false);
  const goModalProducts = async (id, type, date, img) => {
    if (isOrders) {
      const countryCode = localStorage.getItem("country");
      const clientId = localStorage.getItem("clientId");
      const response = await Repository.OrderingRepository.getOrderProducts(
        clientId,
        countryCode,
        id
      );
      setUserModal({
        id: id,
        type: type,
        date: date,
        img: img,
        products: response,
      });
      setIsModalOpen(true);
    }
  };
  return (
    <>
      {isLoading  ? (
        <OrdersKitSkeleton></OrdersKitSkeleton>
      ) : (
        <>
          <div className="container-list museo-sans-500">
            {!isOrders && (
              <div
                style={{ display: "flex", justifyContent: "end" }}
                className="container-filter"
              >
                <button
                  className="filter-button"
                  data-bs-toggle="modal"
                  data-bs-target="#filterOrdersModal"
                  onClick={handleFilter}
                >
                  <img src={filterIcon} alt="filter" className="filter-icon" />
                  {filterModal.filtersApplied ? (
                    <div
                      className="rounded-circle position-absolute"
                      style={{
                        width: "8px",
                        height: "8px",
                        backgroundColor: "#F06E69",
                        top: "8px",
                        left: "71px",
                      }}
                    ></div>
                  ) : null}
                  Filtrar
                </button>
              </div>
            )}

            <div style={{ display: "flex" }}>
              {isOrders ?
              <div
              className="titleTable ordenName museo-sans-500 s12"
              style={{ width: "317px"  }}
            >
              {titleTable}
            </div>
              :
              <div
              className="titleTable requestName museo-sans-500 s12"
             
            >
              {titleTable}
            </div>
              }
              
              
              <div className="titleTable title-table-center museo-sans-500 s12">
                ESTADO
              </div>
              <div className="titleTable title-table-center museo-sans-500 s12">
                FECHA
              </div>
              <div className="titleTable title-table-center museo-sans-500 s12">
                TIPO
              </div>
              <div className="titleTable title-table-end total museo-sans-500 s12">
                TOTAL
              </div>
            </div>
            {isOrders && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "36px",
                  marginBottom: "36px",
                  justifyContent: "space-between",
                  height: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    height: "20px",
                  }}
                >
                  {" "}
                  <input
                    type="checkbox"
                    id={`checkbox`}
                    style={{
                      display: "none",
                    }}
                    onChange={handleSelectAllChange}
                    checked={selectedCount != 0 && selectedCount === totalCount}
                  />
                  <label
                    htmlFor={`checkbox`}
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "20px",
                      height: "20px",
                      border: "2px solid #5AAFF1",
                      borderRadius: "4px",
                      cursor: "pointer",
                      backgroundColor:
                        selectedCount != 0 && selectedCount === totalCount
                          ? "#5AAFF1"
                          : "#fff",
                      color:
                        selectedCount != 0 && selectedCount === totalCount
                          ? "#fff"
                          : "transparent",
                      marginRight: "6px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {selectedCount != 0 && selectedCount === totalCount && (
                      <img
                        src={checkAllIcon}
                        alt="checkAll"
                        className="check-all-icon"
                      />
                    )}
                  </label>
                  <label
                    className="select-container museo-sans-500"
                    htmlFor="selectAll"
                  >
                    Seleccionar todas
                  </label>
                  <span
                    className="span-total museo-sans-600"
                    style={{
                      fontSize: "14px",
                      color: "rgba(144, 143, 154, 1)",
                    }}
                  >
                    {selectedCount} de {totalCount}
                  </span>
                </div>
                {isActiveSelect && (
                  <div
                    className="title-orders museo-sans-500"
                    style={{
                      fontSize: "12px",
                      color: "#908F9A",
                      marginTop: "24px",
                    }}
                  >
                    <span
                      className="museo-sans-700"
                      style={{ marginLeft: "8px", marginRight: "8px" }}
                      onClick={() => goToSummaryOrders()}
                    >
                      Enviar órdenes
                    </span>
                    |
                    <span
                      className="museo-sans-700"
                      style={{ marginLeft: "8px", marginRight: "8px" }}
                      onClick={() => handleUnSelect()}
                    >
                      Deseleccionar
                    </span>
                    |
                    <span
                      className="museo-sans-700"
                      style={{
                        color: "#F06E69",
                        marginLeft: "8px",
                        marginRight: "8px",
                      }}
                      onClick={() => deleteOrders()}
                    >
                      Eliminar órdenes
                      <img src={trashIcon} alt="trash" className="trash-icon" />
                    </span>
                  </div>
                )}
              </div>
            )}
            {list.length === 0 || list.every((element) => element.isDelete) ? (
              <>
                <div className="container-error" style={{ marginTop: "100px" }}>
                  <img
                    src={notProductIcon}
                    alt="notProductIcon"
                    className="notProduct-icon"
                  />
                  <p>No se encuentran resultados</p>
                </div>
              </>
            ) : (
              <>
                {list.map(
                  (order, index) =>
                    !order.isDelete && (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginTop: "26px",
                          alignItems: "center",
                          borderBottom:
                            index == list.length - 1 ? "" : "1px solid #DDDDDD",
                          paddingBottom: index == list.length - 1 ? "" : "16px",
                        }}
                      >
                        {isOrders && (
                          <>
                            <input
                              type="checkbox"
                              id={`checkbox-${index}`}
                              style={{
                                display: "none",
                              }}
                              checked={order.isCheck}
                              onChange={() => checkElement(index)}
                            />
                            <label
                              className="check"
                              htmlFor={`checkbox-${index}`}
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "2px solid #5AAFF1",
                                borderRadius: "4px",
                                cursor: "pointer",
                                backgroundColor: order.isCheck
                                  ? "#5AAFF1"
                                  : "#fff",
                                color: order.isCheck ? "#fff" : "transparent",
                                marginRight: "10px",
                                fontSize: "16px",
                                fontWeight: "bold",
                              }}
                            >
                              {order.isCheck && (
                                <img
                                  src={checkIcon}
                                  alt="check"
                                  className="check-icon"
                                />
                              )}
                            </label>
                          </>
                        )}
                        {
                          isOrders ? 
                          <div className="titleTable ordenName museo-sans-500">
                          <span className="museo-sans-700 nameSpan">
                          {order.client}{" "}
                          </span>{" "}
                          ID: {order.id}
                        </div>
                          :
                          <div className="titleTable requestName museo-sans-500">
                          <span className="museo-sans-700 nameSpan">
                             {order.clientName}
                          </span>{" "}
                          ID: {order.id}
                        </div>
                        }
                        <div
                          className="titleTable title-table-center status museo-sans-500"
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            width: !isOrders && "131px",
                            marginLeft: !isOrders && "33px",
                            color: getColorState(order.status),
                          }}
                        >
                          {!isOrders && (
                            <img
                              src={getIconState(order.status)}
                              alt="status"
                              className="status-icon"
                              style={{ marginRight: "5px" }}
                            />
                          )}
                          <p>{!isOrders && order.status}</p>
                        </div>
                        <div
                          className="titleTable title-table-center museo-sans-500"
                          style={{
                            marginLeft: !isOrders && "60px",
                          }}
                        >
                          {isOrders
                            ? order.startDate
                            : getDate(order.startDate)}
                        </div>
                        <div
                          className="titleTable title-table-center museo-sans-500"
                          style={{ fontSize: "12px" }}
                        >
                          <img
                            src={getIcon(order.type)}
                            alt="profile"
                            className="profile-icon"
                            style={{ marginRight: "5px" }}
                          />
                          {order.type.toUpperCase()}
                        </div>
                        <div className="titleTable title-table-end total dm-sans-500 width-total">
                          {order.amount}
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            goModalProducts(
                              order.id,
                              order.type.toUpperCase(),
                              order.startDate,
                              getIcon(order.type)
                            )
                          }
                        >
                          <img
                            src={vectorEventIcon}
                            alt="event"
                            className="vectorEvent-icon"
                          />
                        </div>
                      </div>
                    )
                )}
              </>
            )}
          </div>

          <ListadoOrdersFilterModal
            filterModal={filterModal}
            changeFilterModalApplied={changeFilterModalApplied}
            setResponseRequest={setList}
            setIsFilter={setIsFilter}
          />
          <ModalProducts
            isOpen={isModalOpen}
            onClose={closeModal}
            data={userModal}
          />
        </>
      )}
    </>
  );
}
