import React, { useState, useEffect } from 'react';
import { ReactComponent as SVGSearch } from "../../Icons/Search.svg";
import { useDispatch } from 'react-redux';
import './SelectLocationModal.css';
import { getTerritoryByLevel } from '../../Framework/redux/PaymentsSideEffect';

const SelectLocationModal = ({ setSelectedLocation, selectedLocation, setShowModal, showModal }) => {
    const [searchLocation, setSearchLocation] = useState('');
    const [locations, setLocations] = useState([]);
    const [auxLocations, setAuxLocations] = useState([])
    const [tempSelected, setTempSelected] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        loadByLevels(2, "QlVFTk9TIEFJUkVT") //Buenos Aires
    }, []);

    useEffect(() => {
        setSearchLocation('')
        setAuxLocations(locations)
    }, [showModal]);

    useEffect(() => {
        setTempSelected(selectedLocation)
    }, [selectedLocation]);

    const loadByLevels = async (level, id = null) => {
        const result = await dispatch(
            getTerritoryByLevel({ level: level, value: id })
        );
        const { locations } = result.payload;
        setLocations(locations)
        setAuxLocations(locations);
    };

    const handleSelection = () => {
        setSelectedLocation(tempSelected)
        setTempSelected("");
        setShowModal(false);
    }

    const handleSearchLocation = (val) => {
        let locationsFiltered = []
        setSearchLocation(val)
        if (val === '') {
            setAuxLocations(locations)
            locationsFiltered = locations
        } else {
            locationsFiltered = locations.filter(({ value }) => value.includes(val.toUpperCase()))
            setAuxLocations(locationsFiltered)
        }

        if (tempSelected && !locationsFiltered.find(loc =>
            loc.value === tempSelected)) {
            setTempSelected('');
        }
    }

    const changeSelection = (e) => {
        setTempSelected(e.target.value)
    }

    const handleClose = () => {
        setShowModal(false);
        setTempSelected(selectedLocation)
    };
    const formatText = (text) => {
        return text.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    };
    return (
        <div
            className={`modal fade ${showModal ? 'show' : ''}`}
            id="selectLocation"
            aria-hidden={!showModal}
            tabIndex="-1"
            style={{ display: showModal ? 'block' : 'none' }}
            onClick={() => handleClose()}
        >
            <div
                className="modal-dialog modal-dialog-centered"
                style={{ maxWidth: "496px" }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="modal-content modal-radius">
                    <div className="modal-body" style={{ padding: "32px", width: '496px', height: '602px' }}>
                        <div className='mb-3'>
                            <div className='d-flex mb-4 gap-2 '>
                                <h1 className=' museo-sans-700 m-0 s20'>Seleccionar localidad</h1>
                            </div>
                        </div>

                        <div className='mb-4'>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control museo-sans-500 s14"
                                    style={{ boxShadow: "none", outline: "none", borderRight: "none", borderColor: "#dee2e6" }}
                                    placeholder="Ingresa una localidad"
                                    value={searchLocation}
                                    onChange={(e) => handleSearchLocation(e.target.value)}
                                />
                                <span className="input-group-text bg-transparent pointer">
                                    <SVGSearch />
                                </span>
                            </div>
                        </div>

                        <div style={{ maxHeight: "364px" }}
                            className="container-scroll-location d-flex flex-column my-4">
                            {auxLocations?.map((location, index) => (
                                <div key={location.id}>
                                    <div style={{ marginBottom: "8px" }} className="d-flex gap-2 align-items-center ms-3">
                                        <input
                                            className="custom-radio"
                                            type="radio"
                                            name="location"
                                            id={location.id}
                                            value={location.value}
                                            checked={tempSelected === location.value}
                                            onChange={changeSelection}
                                        />
                                        <label className="form-check-label museo-sans-500 s14" htmlFor={location.id}>
                                            {formatText(location.value)}
                                        </label>
                                    </div>
                                    {index < auxLocations.length - 1 && <hr />}
                                </div>
                            ))}
                        </div>

                        <div className='d-flex justify-content-center' style={{ marginTop: '32px' }}>
                            <button
                                disabled={tempSelected === ''}
                                onClick={() => handleSelection()}
                                data-bs-dismiss="modal" aria-label="Close"
                                type="button"
                                className={`${tempSelected === '' && "cursor-default opacity-50"} payment-btn skyBg whiteColor skyBorder museo-sans-700 s14 `}
                            >
                                Seleccionar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectLocationModal;
