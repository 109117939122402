import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import StatusAccountTableMyAccount from "../components/StatusAccountTableMyAccount.jsx";
import StatusAccountTableMyDescendant from "../components/StatusAccountTableMyDescendant";
import {
  fetchAccountStatusDataBalance,
  fetchAccountStatusDataTableMyAccount,
} from "../../Framework/redux/StatusAccountSideEffect";
import { emptyMyAccountTableAction } from "../../Framework/redux/StatusAccountAction";
import processDateData from "../../../common/Share/processDateData";
import moment from "moment-timezone";

export default function StatusAccountTable() {
  const dispatch = useDispatch();
  const [tableToggler, setTableToggler] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [loader, setLoader] = useState(true);
  const statusAccountData = useSelector((state) => state.statusAccountData);
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const calendarDataNeeded = useSelector(
    (state) => state.calendarData.currentPeriodInfo
  );

  const setterToggler = (boolean) => {
    setToggler(boolean);
  };

  const setterLoader = (boolean) => {
    setLoader(boolean);
  };

  const currentData = moment().format("YYYY-MM-DD");
  const currentDataMyAccount = moment().format("DD-MM-YYYY");


  useEffect(() => {
    onStartUp();
  }, [calendarDataNeeded?.periodStartDate]);

  const onStartUp = async () => {
    dispatch(emptyMyAccountTableAction());
    await dispatch(fetchAccountStatusDataBalance({}));
    await dispatch(
      fetchAccountStatusDataTableMyAccount({
        accountType: null,
        filterType: null,
        iniDate: null,
        endDate: null,
        iniPeriod: null,
        endPeriod: null,
        offSet: 0,
        defaultIniDate: processDateData(calendarDataNeeded?.periodStartDate || currentData),
        defaultEndDate: processDateData(currentData),
      })
    );

    setLoader(false);
  };

  const toggleDescendent = () => {
    setTableToggler(true);
  };

  const toggleMyAccountStatus = () => {
    setTableToggler(false);
  };

  return (
    <>
      <div className="pt-5 ">
        <div className="container--padding--card-holder container--padding ">
          <TableTogglerButtons
            tableToggler={tableToggler}
            toggleMyAccountStatus={toggleMyAccountStatus}
            toggleDescendent={toggleDescendent}
          />
        </div>
        {calendarDataNeeded && (
          <>
            {!tableToggler ? (
                <StatusAccountTableMyAccount
                  fatherSetLoader={setterLoader}
                  fatherLoader={loader}
                  toggler={toggler}
                  setToggler={setterToggler}
                  data={statusAccountData}
                  cultureInfo={cultureInfo}
                  calendarData={calendarDataNeeded}
                  defaultInit={processDateData(
                    calendarDataNeeded.periodStartDate
                  )}
                  defaultEnd={currentDataMyAccount}
                />
            ) : (
                <StatusAccountTableMyDescendant
                  fatherSetLoader={setterLoader}
                  fatherLoader={loader}
                  toggler={toggler}
                  setToggler={setterToggler}
                  data={statusAccountData}
                  cultureInfo={cultureInfo}
                  calendarData={calendarDataNeeded}
                  defaultInit={processDateData(
                    calendarDataNeeded.periodStartDate
                  )}
                  defaultEnd={currentData}
                />
            )}
          </>
        )}
      </div>
    </>
  );
}

export function TableTogglerButtons({
  tableToggler,
  toggleMyAccountStatus,
  toggleDescendent,
}) {
  return (
    
      <div className=" d-flex flex-row ">
        <button
          onClick={() => toggleMyAccountStatus()}
          className={` p-1 ps-4 pe-4 s14 flap-button museo-sans-500 s14 pointer ${
            !tableToggler
              ? " bronceBg blackColor"
              : " lightGreyBg grayColor"
          }`}
          style={{ width: "auto" }}
        >
          <FormattedMessage id="status.account.my.status.currency" />
        </button>
        <button
          onClick={() => toggleDescendent()}
          className={` p-1 ps-4 pe-4 s14 flap-button museo-sans-500 s14 pointer ${
            tableToggler
              ? " bronceBg blackColor"
              : " lightGreyBg grayColor"
          }`}
          style={{ width: "auto" }}
        >
          <FormattedMessage id="status.account.my.desc.status.currency" />
        </button>
      </div>
  );
}
