import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchBalance = createAsyncThunk(
    "fetchBalance",
    async ({ }, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await Repository.PeriodErningsRepository.getBalance(clientId, location);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getTerritoryByLevel = createAsyncThunk(
    'getTerritoryByLevel',
    async (data, { rejectWithValue }) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await Repository.PaymentRepository.getTerritoryByLevel(data, clientId, location);
            const object = { locations: response.locations, level: data?.level || 1 }
            return object;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)