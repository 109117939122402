import React, { useState, useEffect } from 'react';

const ChangeCategoryModal = ({ categories, setSelectedCategory, selectedCategory, setShowModal, showModal }) => {
    const [tempSelected, setTempSelected] = useState({});

    useEffect(() => {
        setTempSelected(selectedCategory)
    }, [selectedCategory]);

    const handleSelection = () => {
        setSelectedCategory(tempSelected)
        setTempSelected("");
        setShowModal(false);
    }

    const changeSelection = (category) => {
        setTempSelected(category)
    }

    const handleClose = () => {
        setShowModal(false);
        setTempSelected(selectedCategory)
    };

    return (
        <div
            id="changeCategory"
            className={`modal fade ${showModal ? 'show' : ''}`}
            aria-hidden={!showModal}
            tabIndex="-1"
            style={{ display: showModal ? 'block' : 'none' }}
            onClick={() => handleClose()}
        >
            <div
                className="modal-dialog modal-dialog-centered"
                style={{ maxWidth: "496px" }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="modal-content modal-radius">
                    <div className="modal-body" style={{ padding: "32px", height: '550px', width: '496px' }}>
                        <div className='mb-3'>
                            <div className='d-flex  gap-2 ' style={{ marginBottom: "34px" }}>
                                <h1 className=' museo-sans-700 m-0 s20'>Selecciona una Categoría</h1>
                            </div>
                        </div>

                        <div style={{ maxHeight: "364px" }}
                            className="scrollLocation d-flex flex-column my-4">
                            {categories?.map((category, index) => (
                                <div key={category.id} style={{ marginRight: '8px' }}>
                                    <div className="d-flex gap-2 align-items-center">
                                        <input
                                            className="custom-radio"
                                            type="radio"
                                            name="category"
                                            value={category.description}
                                            checked={tempSelected.description === category.description}
                                            onChange={() => changeSelection(category)}
                                        />
                                        <label className="form-check-label museo-sans-500 s14" htmlFor={category.description}>
                                            {category.description}
                                        </label>
                                    </div>
                                    {index < categories.length - 1 && <hr style={{ marginBlock: "20px" }} />}
                                </div>
                            ))}
                        </div>

                        <div className='d-flex justify-content-center' style={{ marginTop: '32px' }}>
                            <button
                                disabled={tempSelected === ''}
                                onClick={() => handleSelection()}
                                data-bs-dismiss="modal" aria-label="Close"
                                type="button"
                                className={`${tempSelected === '' && "cursor-default opacity-50"} payment-btn skyBg whiteColor skyBorder museo-sans-700 s14 `}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangeCategoryModal;
