import firstStepsAdjustDate from "./moments/firstStepsAdjustDate";
import { ReactComponent as SVGDigitalClock } from "../../../common/svgs/ic-clock-md.svg";
import { ReactComponent as SVGDigitalRedClock } from "../../../common/svgs/ic-clock-red-md.svg";
import { ReactComponent as SVGDigitalBlackClock } from "../../../common/svgs/ic-clock-black-md.svg";
import { FormattedMessage } from "react-intl";

export default function FirstStepsMeasureOutdated({ data }) {

  const getHour = (str) => {
    if (!str) {
      return;
    };
    str = str.split("T")[1];
    str = str.split("-");
    str = str[0];
  return str
  };

  const measureDateLimit = (data) => {
    let result = firstStepsAdjustDate({
      reached: data?.stepDetail?.reachedDate,
      completed: data?.stepDetail?.completedDate,
      limit: data?.stepDetail?.limitDate,
      command: null,
    });

    let isItToday = firstStepsAdjustDate({
      reached: data?.stepDetail?.reachedDate,
      completed: data?.stepDetail?.completedDate,
      limit: data?.stepDetail?.limitDate,
      command: "isItToday",
    });

    let reachingLimitInDays = firstStepsAdjustDate({
      reached: data?.stepDetail?.reachedDate,
      completed: data?.stepDetail?.completedDate,
      limit: data?.stepDetail?.limitDate,
      command: "fetchLimitDayOnDays",
    });

    let getHourResult = getHour(data?.stepDetail?.limitDate);

    let dayOfTheWeek = firstStepsAdjustDate({
      reached: data?.stepDetail?.reachedDate,
      completed: data?.stepDetail?.completedDate,
      limit: data?.stepDetail?.limitDate,
      command: "fetchDayOfWeekLimit",
    });

    if (reachingLimitInDays >= 7) {
      return (
        <>
          <div
            style={{ height: "14px", top: -4 }}
            className=" position-relative svg-force-size"
          >
            <SVGDigitalClock />
          </div>
          <p className="d-flex goldBoldColor ">
            <FormattedMessage id="first.steps.message.expires.until.two" />
            {reachingLimitInDays}
            <FormattedMessage id="first.steps.message.close.days" />
            <FormattedMessage id="first.steps.message.to" />
            {getHourResult}
            <FormattedMessage id="first.steps.message.hrs" />
          </p>
        </>
      );
    }
    if (result > 24 && (reachingLimitInDays < 7 && reachingLimitInDays >= 2 )) {
      return (
        <>
          <div
            style={{ height: "14px", top: -4 }}
            className=" position-relative svg-force-size"
          >
            <SVGDigitalClock />
          </div>
          <p className="d-flex goldBoldColor ">
            <FormattedMessage id="first.steps.message.expires.until" />
            {dayOfTheWeek}
            <FormattedMessage id="first.steps.message.to" />
            {getHourResult}
            <FormattedMessage id="first.steps.message.hrs" />
          </p>
        </>
      );
    }
    if (!isItToday && reachingLimitInDays < 2 && result > 24) {
      return (
        <>
          <div
            style={{ height: "14px", top: -4 }}
            className=" position-relative svg-force-size"
          >
            <SVGDigitalRedClock />
          </div>
          <p className="d-flex lightRedColor ">
            <FormattedMessage id="first.steps.message.expires.tomorrow" />
            {getHourResult}
            <FormattedMessage id="first.steps.message.hrs" />
          </p>
        </>
      );
    }
    if (result > 0 && isItToday) {
      return (
        <>
          <div
            style={{ height: "14px", top: -4 }}
            className=" position-relative svg-force-size"
          >
            <SVGDigitalRedClock />
          </div>
          <p className="d-flex lightRedColor ">
            <FormattedMessage id="first.steps.message.expires" />
            {getHourResult}
            <FormattedMessage id="first.steps.message.hrs" />
          </p>
        </>
      );
    }
    if (result < 0 && result > -24) {
      return (
        <>
          <div
            style={{ height: "14px", top: -4 }}
            className=" position-relative svg-force-size"
          >
            <SVGDigitalBlackClock />
          </div>
          <p className="d-flex ">
            <FormattedMessage id="first.steps.message.close.today" />
          </p>
        </>
      );
    }
    if (result > -24) {
      return (
        <>
          <div
            style={{ height: "14px", top: -4 }}
            className=" position-relative svg-force-size"
          >
            <SVGDigitalBlackClock />
          </div>
          <p className="d-flex ">
            <FormattedMessage id="first.steps.message.close.the.day" />
            {dayOfTheWeek} <FormattedMessage id="pasts" />
          </p>
        </>
      );
    }
    if (reachingLimitInDays < -7) {
      return (
        <>
          <div
            style={{ height: "14px", top: -4 }}
            className=" position-relative svg-force-size"
          >
            <SVGDigitalBlackClock />
          </div>
          <p className="d-flex ">
            <FormattedMessage id="first.steps.message.close.until" />
            {reachingLimitInDays * -1}
            <FormattedMessage id="first.steps.message.close.days" />
          </p>
        </>
      );
    }
    
    return (
      <>
        <div
          style={{ height: "14px", top: -4 }}
          className=" position-relative svg-force-size"
        >
          <SVGDigitalBlackClock />
        </div>
        <p className="d-flex ">
        <p className="d-flex "><FormattedMessage id="first.steps.message.close.the.day" />{dayOfTheWeek} <FormattedMessage id="pasts" /></p>
        </p>
      </>
    );
  };

  return measureDateLimit(data);
}
