import React, { useEffect, useState } from 'react';
import { ReactComponent as SVGClose } from "../../../Orders/Icons/Actions/Close.svg";
import ShareComponentInput from '../../../common/Share/ShareComponentInput';
import ShareInputWithSelectableValue from '../../../common/Share/ShareInputWithSelectableValue';
import { useDispatch } from 'react-redux';
import { deactivateError } from '../../../EDBs/Framework/redux/EDBFormActions';
import { getTerritoryByLevel } from '../../../ClientList/Framework/redux/ClientListSideEffect';
import ShareLocalityControllerOrders from '../../../common/Share/ShareLocalityControllerOrders';

function ShippingAddressModal({ setAddress }) {
    const dispatch = useDispatch();

    const [locationsByLevel, setLocationsByLevel] = useState({
        levelOne: [],
        levelTwo: [],
        levelThree: [],
        levelFour: [],
    });

    const [fatherURL, setFatherURL] = useState({
        levelOne: "",
        levelTwo: "",
        levelThree: "",
        levelFour: "",
    });

    const [formData, setFormData] = useState({
        street: '',
        floor: ''
    });

    const [errorToggler, setErrorToggler] = useState({
        street: false,
    });

    const onChangeFatherURL = (level, newUrl) => {
        const auxFatherUrl = { ...fatherURL };
        dispatch(deactivateError());

        if (level === 2) {
            setFatherURL({ ...auxFatherUrl, levelOne: newUrl });
        }
        if (level == 3) {
            setFatherURL({ ...auxFatherUrl, levelTwo: newUrl });
        }
        if (level == 4) {
            setFatherURL({ ...auxFatherUrl, levelThree: newUrl });
        }

        return newUrl;
    };

    const onInputChangeFromSelect = (level, prevData) => {
        onChangeSelectedFather(level);
    };

    const onChangeSelectedFather = async (level, data) => {
        if (level === 4) return "";

        const newLocations = {
            levelOne: locationsByLevel.levelOne,
            levelTwo: level >= 2 ? locationsByLevel.levelTwo : [],
            levelThree: level >= 3 ? locationsByLevel.levelThree : [],
            levelFour: level >= 4 ? locationsByLevel.levelFour : [],
        };

        setLocationsByLevel(newLocations);

        if (data?.value) {
            await dispatch(
                getTerritoryByLevel({
                    level: level + 1,
                    value: onChangeFatherURL(level + 1, data.value),
                })
            );

        }
    };

    const onTogglerFormSelect = () => {

    }

    const handleCancel = () => {
    }

    const saveChanges = () => {
    }

    return (
        <div
            className="modal fade"
            id="changeAddress"
            aria-hidden="true"
            tabIndex="-1"
        >
            <div
                className="modal-dialog modal-dialog-centered"
                style={{ maxWidth: "1224px" }}
            >
                <div className="modal-content modal-radius">
                    <div className="modal-body p-4">
                        <div className='mb-5'>
                            <div className='d-flex justify-content-between align-items-center mb-4'>
                                <h1 className='m-0 s24 museo-sans-700 '>Modificar Domicilio</h1>
                                <div className="pointer" data-bs-dismiss="modal" aria-label="Close">
                                    <SVGClose />
                                </div>
                            </div>


                            <div className='d-flex gap-4 mb-4'>
                                <ShareComponentInput
                                    errorToggler={errorToggler.street}
                                    value={formData.street || ""}
                                    label="Dirección"
                                    name="street"
                                    placeholder="Virrey del Pino 5423"
                                 
                                /*  onChange={onChangeAddressInput} */
                                />

                                <ShareComponentInput
                                    value={formData.floor || ""}
                                    label="Piso/Departamento/Referencia (opcional)"
                                    name="floor"
                                    placeholder="Piso 2 Depto A"
                                /*  onChange={onChangeAddressInput} */
                                />
                            </div>

                            <div className='d-flex gap-4 mb-4'>
                                <div className='w-50'>
                                    <label className='mb-2  museo-sans-500 s14'>Provincia</label>
                                    <ShareInputWithSelectableValue
                                        width={"100%"}
                                        isError={false}
                                        list={[]}
                                        IsDisabled={false}
                                        /* onChangeValue={onChangeAddressSelect} */
                                        placeholder="Seleccionar una provincia"
                                        inheritValue={""}
                                        name="province"
                                        togglerSelect={false}
                                        setTogglerSelect={onTogglerFormSelect}
                                        level={1}
                                        onLostSavedValue={onInputChangeFromSelect}
                                    />
                                </div>

                                <div className='w-50'>
                                    <label className='mb-2  museo-sans-500 s14'>Localidad</label>
                                    <ShareInputWithSelectableValue
                                        width={"100%"}
                                        isError={false}
                                        list={[]}
                                        IsDisabled={false}
                                        /* onChangeValue={onChangeAddressSelect} */
                                        placeholder="Seleccionar una localidad"
                                        inheritValue={""}
                                        name="locality"
                                        togglerSelect={false}
                                        setTogglerSelect={onTogglerFormSelect}
                                        level={2}
                                        onLostSavedValue={onInputChangeFromSelect}
                                    />
                                </div>
                            </div>

                            <div className='d-flex gap-4 mb-4'>
                                <div className='w-50'>
                                    <label className='mb-2 museo-sans-500 s14'>Código Postal</label>
                                    <ShareInputWithSelectableValue
                                        width={"100%"}
                                        isError={false}
                                        list={[]}
                                        IsDisabled={false}
                                        /* onChangeValue={onChangeAddressSelect} */
                                        placeholder="Seleccionar código postal"
                                        inheritValue={""}
                                        name="postalCode"
                                        togglerSelect={false}
                                        setTogglerSelect={onTogglerFormSelect}
                                        level={3}
                                        onLostSavedValue={onInputChangeFromSelect}
                                    />
                                </div>
                                <div className='w-50'></div>

                            </div>


                        </div>
                        <div className='d-flex flex-column align-items-center'>
                            <button
                                type="button"
                                className="payment-btn skyBg whiteColor skyBorder museo-sans-700 s14 mb-3"
                                onClick={saveChanges}
                            >
                                Guardar cambios
                            </button>
                            <div
                                data-bs-dismiss="modal"
                                className=" pointer lightRedColor museo-sans-500 s14"
                                onClick={handleCancel}
                            >
                                Cancelar
                            </div>
                            <div className='invisible' id='onSubmitSuccess' data-bs-dismiss="modal">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ShippingAddressModal;