import React from 'react';
import "./OrdersCard.css";

const OrdersNotification = ({ icon, text, type = "warning" }) => {
    const colors = {
        "warning": "warningBg blackColor",
        "error": "errorBg darkRedColor",
        "info": "donutHeaderBg blueColor "
    }
    return (
        <div className={`kit-notification-container ${colors[type]}  d-flex px-3 py-2 museo-sans-500 s12 align-items-center`}>
            {icon}
            <p>{text}</p>
        </div>
    );
}

export default OrdersNotification;
