import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import OrdersModalShare from "../components/modals/OrdersModalShare";
import OrderCartKitHolder from "../components/OrdersCartKitHolder";
import OrdersCartOfertsFeature from "../components/OrdersCartOfertsFeature";
import OrdersCartProduct from "../components/OrdersCartProduct";
import OrderCartModalOfertsList from "../components/modals/OrderCartModalOfertsList";
import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories";
import OrderCartImageContainer from "../components/OrdersCartImageContainer";
import { addToCart, removeFromCart, setOrder } from "../../Framework/redux/OrdersActions";
import { clearCart } from "../../Framework/redux/OrdersActions";
import { ModalClipboardContext } from "../../../common/routes/routes";

export default function OrdersCart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartContainer = useSelector(
    (state) => state.orderData.cart.cartContainer
  );
  const cartKit = useSelector((state) => state.orderData.cart.cartKit);
  const cartCounter = useSelector((state) => state.orderData.cart.cartCounter);
  const [hasChanged, setHasChanged] = useState(false);
  const [forcedChange, setForcedChange] = useState(false);
  const [cartBody, setCartBody] = useState({ cart: null, isLoading: true });

  const { setModalTexts } = useContext(ModalClipboardContext);

  const onUpdateCommand = async () => {
    setHasChanged(true);
  };

  const ifValueAPrice = (amount) => {
    return amount;
    // return formatAmount(
    //   amount,
    //   cultureInfo?.languageCode,
    //   cultureInfo?.defaultCurrencyCode
    // );
  };

  const onCallService = async (cartBody) => {
    const arrayProducts = Object.values(cartContainer);
    let cartLocalStorage = localStorage.getItem("cart");
    let cartLocalJSON = JSON.parse(cartLocalStorage);

    if (arrayProducts?.length < 1 && (!cartLocalJSON?.cartKit && !cartLocalJSON?.cartCounter)) {
      setCartBody({
        cart: {},
        isLoading: false,
      });
      return;
    }

    let arrayLocalJSON = Object.values(cartLocalJSON?.cartContainer);

    const requestCart = {
      id: cartLocalJSON?.id || null,
      orderType: "personal",
      contactId: null,
      edbData: null,
      kitProducts: [cartLocalJSON?.cartKit] || [], // Verlo despues :) 
      orderProducts: arrayProducts?.length > 0 ? arrayProducts : arrayLocalJSON,
    };

    let countryCode = localStorage.getItem("country");
    let clientId = localStorage.getItem("clientId");

    let response = await Repository.CartRepository.updateCart(
      clientId,
      countryCode,
      cartBody?.cart ?? requestCart
    );

    const orderProductsFull = response?.orderProducts.filter(
      (element) => element.quantity > 0
    );

    const updatedResponse = {
      ...response,
      orderProducts: orderProductsFull,
    };

    let addIdToLocalStorage = {
      ...cartLocalJSON,
      id: response.id,
    };

    localStorage.setItem("cart", JSON.stringify(addIdToLocalStorage));

    setCartBody({
      cart: updatedResponse,
      isLoading: false,
    });

    return "OK";
  };

  const onDeleteCart = async () => {
    localStorage.removeItem("cart");
    dispatch(clearCart());
    await Repository.CartRepository.deleteCart(
      localStorage.getItem("clientId"),
      localStorage.getItem("country"),
      cartBody?.cart?.id
    );
    navigate(-1);
  };

  const aChangeHasFalsed = async (cartBody) => {
    setCartBody({ cart: cartBody.cart, isLoading: true });
    let result = await onCallService(cartBody);
    setForcedChange(true);
    setHasChanged(false);
  };

  const onStartUp = () => {
    onCallService();
  };

  const onPromotionsChange = (product, operation) => {
    let quantity = product.quantity;
    let id = product.productId;
    let max = product.maxAvailable;
    if (operation === "add") {
      if (quantity >= max) {
        const modalData = {
          title: (
            <FormattedMessage id="orders.cart.pop.up.max.available.error.title" />
          ),
          text: (
            <FormattedMessage
              id="orders.cart.pop.up.max.available.error.p"
              values={{ x: product.maxAvailable }}
            />
          ),
          height: "auto",
          status: "error",
          show: false,
          bottom: 0,
        };
        setModalTexts(modalData);
      }
      quantity = Math.min(quantity + 1, max < 1 ? 999 : max);
    } else {
      quantity--;
    }

    const newCartBody = {
      cart: {
        ...cartBody.cart,
        promotionsProducts: cartBody.cart.promotionsProducts.map((product) => {
          if (product.productId == id) {
            return {
              ...product,
              quantity: quantity,
            };
          }
          return product;
        }),
      },
      isLoading: false,
    };

    setCartBody(newCartBody);
  };

  const onListPromotionUpdate = (products) => {
    setCartBody({
      cart: {
        ...cartBody.cart,
        promotionsProducts: products,
      },
      isLoading: false,
    });
  };

  const onListProductsUpdate = (product, operation) => {
    const prevCart = { ...cartBody.cart };
    const newCart = {
      ...prevCart,
      orderProducts: prevCart.orderProducts
        .map((element) => {
          if (element.productId === product.productId) {
            if (operation === "add") {
              product.quantity += 1;
              dispatch(addToCart(element));
            } else if (operation === "reduce") {
              product.quantity -= 1;
              dispatch(removeFromCart(element));
            }

            if (
              product.maxAvailable > 0 &&
              product.quantity > product.maxAvailable
            ) {
              product.quantity = product.maxAvailable;
            }

            return product;
          }
          return element;
        })
        .filter(Boolean),
    };

    setCartBody({
      cart: newCart,
      isLoading: false,
    });
    setForcedChange(false);
    setHasChanged(true);
  };

  const onSendSingleOrder = async () => {
    let countryCode = localStorage.getItem("country");
    let clientId = localStorage.getItem("clientId");

    const response = await Repository.OrderingRepository.createOrder(
      clientId,
      countryCode,
      cartBody?.cart
    );
    dispatch(setOrder(response))
    localStorage.setItem("orderSummary", JSON.stringify(response));
    navigate("/dashboard/orders/firststeps/1");
  };

  const JSXButtonsAndFunctionsForModals = {
    sendOrder: {
      modalId: "modal-send-order",
      modalTitle: <FormattedMessage id="orders.cart.modal.one.title" />,
      modalDescription: (
        <FormattedMessage id="orders.cart.modal.one.description" />
      ),
      modalStatus: "warning",
      modalButtons: [
        {
          text: <FormattedMessage id="orders.cart.modal.one.button.one" />,
          style: "skyBorder",
          onClick: () => {
            onSendSingleOrder();
          },
        },
        {
          text: <FormattedMessage id="orders.cart.modal.one.button.two" />,
          style: "skyBorder",
          onClick: () => {
            console.log("Sumar a un conjunto de órdenes");
          },
        },
        {
          text: <FormattedMessage id="buttons.cancel" />,
          style: "redColor",
          onClick: () => {
            console.log("Sumar a un conjunto de órdenes");
          },
        },
      ],
    },
    endAndSaveOrder: {
      modalId: "modal-end-and-save-order",
      modalTitle: <FormattedMessage id="orders.cart.modal.two.title" />,
      modalDescription: (
        <FormattedMessage id="orders.cart.modal.two.description" />
      ),
      modalStatus: "confirm",
      modalButtons: [
        {
          text: <FormattedMessage id="orders.cart.modal.two.button.one" />,
          style: "skyBg",
          onClick: () => {
            console.log("Enviar a Just");
          },
        },
        {
          text: <FormattedMessage id="orders.cart.modal.two.button.two" />,
          style: "skyBorder",
          onClick: () => {
            console.log("Sumar a un conjunto de órdenes");
          },
        },
      ],
    },
    endOrder: {
      modalId: "modal-end-order",
      modalTitle: <FormattedMessage id="orders.cart.modal.three.title" />,
      modalDescription: (
        <FormattedMessage id="orders.cart.modal.three.description" />
      ),
      modalStatus: "error",
      modalButtons: [
        {
          text: <FormattedMessage id="orders.cart.modal.three.button.one" />,
          style: "skyBorder",
          onClick: () => {
            console.log("Enviar a Just");
          },
        },
        {
          text: <FormattedMessage id="orders.cart.modal.three.button.two" />,
          style: "redColor",
          onClick: () => {
            onDeleteCart();
          },
        },
      ],
    },
  };


  useEffect(() => {
    onStartUp();
  }, []);



  return (
    <>
      <ShareModalStructure maxWidth={"496px"} id={"modal-send-order"}>
        <OrdersModalShare
          modalData={JSXButtonsAndFunctionsForModals["sendOrder"]}
          status={"warning"}
        />
      </ShareModalStructure>
      <ShareModalStructure maxWidth={"496px"} id={"modal-end-and-save-order"}>
        <OrdersModalShare
          modalData={JSXButtonsAndFunctionsForModals["endAndSaveOrder"]}
          status={"confirm"}
        />
      </ShareModalStructure>
      <ShareModalStructure maxWidth={"496px"} id={"modal-end-order"}>
        <OrdersModalShare
          modalData={JSXButtonsAndFunctionsForModals["endOrder"]}
          status={"error"}
        />
      </ShareModalStructure>
      {cartBody?.cart?.promotionsProducts && (
        <ShareModalStructure maxWidth={"496px"} id={"modal-cart-oferts-list"}>
          <OrderCartModalOfertsList
            ifValueAPrice={ifValueAPrice}
            products={cartBody?.cart?.promotionsProducts}
            onListPromotionUpdate={onListPromotionUpdate}
          />
        </ShareModalStructure>
      )}

      <>
        {(cartBody?.isLoading && !cartBody?.cart) ? (
          <div
            style={{ marginTop: "48px", marginBottom: "52px" }}
            className="d-flex flex-column museo-sans-400 "
          >
            <p className=" museo-sans-600 s24 mb-4">
              <FormattedMessage id="orders.cart.title" />
            </p>
            <div
              style={{ height: "600px" }}
              className="w-100 bg-white  box-shadow rounded-3"
            >
              <div className="w-100 h-100  position-relative  ">
                <div className=" lightui1-shimmer w-100 h-100 position-absolute rounded-4 "></div>
                <div
                  style={{ padding: "40px" }}
                  className=" d-flex justify-content-center align-items-center w-100 h-100"
                >
                  <p
                    style={{ top: "54%" }}
                    className=" blackColor position-absolute museo-sans-500 s14"
                  >
                    Actualizando promociones
                  </p>
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {!hasChanged && cartCounter == 0 ? (
              <OrderCartEmpty navigate={navigate} />
            ) : (
              <div
                style={{ marginTop: "48px", marginBottom: "52px" }}
                className={` position-relative d-flex flex-column museo-sans-400 `}
              >
                <p className=" museo-sans-600 s24 mb-4">
                  <FormattedMessage id="orders.cart.title" />
                </p>
                {
                  cartBody?.isLoading &&
                  <OrderCartUpdatingMessage />
                }
                <div
                  style={{ padding: "40px", boxShadow: "3px 3px 15px 0px rgba(136, 137, 219, 0.10)" }}
                  className={` ${cartBody?.isLoading ? " opacity-25 " : ""} w-100 bg-white rounded-3 `}
                >
                  <div className="mb-4">
                    <div className="d-flex justify-content-between w-100">
                      <p className=" s24 museo-sans-600">
                        <FormattedMessage id="orders.cart.order.personal.title" />{" "}
                        {cartBody?.cart?.id}
                      </p>
                      {
                        !hasChanged &&
                        <div className=" pointer d-flex gap-2 align-items-center">
                          <div
                            style={{ top: "-2px" }}
                            className=" position-relative"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 10C0 15.514 4.48598 20 10 20C15.514 20 20 15.514 20 10C20 4.48598 15.514 0 10 0C4.48598 0 0 4.48598 0 10ZM9.18555 10.7421V14.1741C9.18515 14.2727 9.20382 14.3704 9.24121 14.4616C9.2786 14.5528 9.33385 14.6358 9.40332 14.7058C9.47279 14.7757 9.55552 14.8312 9.64648 14.8692C9.73744 14.9072 9.83502 14.9269 9.93359 14.9272C10.0323 14.9273 10.1295 14.9079 10.2207 14.8702C10.3119 14.8325 10.3951 14.7772 10.4648 14.7075C10.5346 14.6377 10.5902 14.5549 10.6279 14.4637C10.6656 14.3725 10.6847 14.2748 10.6846 14.1761V10.7421H14.1182C14.2168 10.7423 14.3151 10.7229 14.4062 10.6853C14.4974 10.6476 14.5797 10.5922 14.6494 10.5224C14.7192 10.4527 14.7748 10.3698 14.8125 10.2786C14.8502 10.1875 14.8693 10.0898 14.8691 9.99116C14.869 9.89272 14.8503 9.79519 14.8125 9.7043C14.7747 9.61341 14.7192 9.53097 14.6494 9.4615C14.5797 9.39203 14.4963 9.33697 14.4053 9.29951C14.3142 9.26205 14.2166 9.24285 14.1182 9.24312H10.6846V5.80611C10.684 5.60737 10.6047 5.41706 10.4639 5.27681C10.3231 5.13656 10.1323 5.05791 9.93359 5.05818C9.73529 5.05844 9.54549 5.13732 9.40527 5.27754C9.26505 5.41776 9.18581 5.6078 9.18555 5.80611V9.24312H5.75098C5.55224 9.24285 5.36151 9.3215 5.2207 9.46174C5.07989 9.60199 5.00053 9.79242 5 9.99116C5 10.0898 5.0189 10.1874 5.05664 10.2785C5.09438 10.3696 5.14999 10.4524 5.21973 10.5222C5.28946 10.5919 5.37178 10.6473 5.46289 10.685C5.55401 10.7228 5.65235 10.7421 5.75098 10.7421H9.18555Z"
                                fill="#5AAFF1"
                              />
                            </svg>
                          </div>

                          <p
                            onClick={() =>
                              navigate("/dashboard/orders/catalogo-consulta")
                            }
                            className="skyColor museo-sans-600 s14"
                          >
                            <FormattedMessage id="orders.cart.order.add.products" />
                          </p>
                        </div>
                      }
                    </div>
                  </div>
                  {cartBody?.cart?.orderProducts?.length > 0 &&
                    cartBody?.cart?.orderProducts.map((product, index) => {
                      return (
                        <OrdersCartProduct
                          aChangeHasOccured={onUpdateCommand}
                          ifValueAPrice={ifValueAPrice}
                          key={index}
                          product={product}
                          isFirst={index == 0}
                          hasChanged={hasChanged}
                          forcedChange={forcedChange}
                          onAdd={onListProductsUpdate}
                          onReduce={onListProductsUpdate}
                        />
                      );
                    })}
                  {!hasChanged && (
                    <>
                      {cartBody?.cart?.spontaneousProducts?.length > 0 && (
                        <OrderCartFreeProduct
                          products={cartBody?.cart?.spontaneousProducts}
                        />
                      )}
                      <OrderCartKitHolder
                        ifValueAPrice={ifValueAPrice}
                        cartKit={cartKit}
                      />

                      <OrdersCartOfertsFeature
                        ifValueAPrice={ifValueAPrice}
                        products={cartBody?.cart?.promotionsProducts}
                        onPromotionsChange={onPromotionsChange}
                      />
                      <OrderCartSummary
                        cartSummary={cartBody?.cart?.cartSummary}
                      />
                    </>
                  )}
                </div>
                <OrderCartButtonContainer
                  hasChanged={hasChanged}
                  cartBody={cartBody}
                  aChangeHasFalsed={aChangeHasFalsed}
                  navigate={navigate}
                  isLoading={cartBody?.isLoading}
                />
              </div>
            )}
          </>
        )}
      </>
    </>
  );

}


export function OrderCartUpdatingMessage() {
  return (
    <div style={{ zIndex: 5 }} className="  w-100 h-100 position-absolute" >

      <div
        className="w-100 h-100 box-shadow rounded-3"
      >
        <div className="w-100 h-100  position-relative  ">
          <div
            style={{ padding: "40px" }}
            className=" d-flex justify-content-center align-items-center w-100 h-100"
          >
            <p
              style={{ top: "54%" }}
              className=" position-absolute museo-sans-500 s14"
            >
              Actualizando promociones
            </p>
            <div style={{ top: "48%" }} className="position-absolute spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function OrderCartFreeProduct(params) {
  return (
    <div className="d-flex flex-column mt-3 mb-3">
      <div className="mb-3" >
        <p className=" museo-sans-700 s20 ">
          <FormattedMessage id="orders.cart.free.product.title" />
        </p>
      </div>
      <div style={{ gap: "20px" }} className="d-flex flex-column " >
        {params?.products?.map((product, index) => {
          return (
            <div className="d-flex">
              <div className=" position-relative">
                <OrderCartImageContainer
                  imageUrl={product?.img}
                  hasAmount={product?.quantity}
                />
              </div>
              <div
                style={{ margin: "0px 0px 0px 12px", top: "6px" }}
                className=" position-relative d-flex justify-content-between w-100"
              >
                <div className="d-flex flex-column">
                  <p className=" museo-sans-600 s16">{product?.description}</p>
                  <p className=" grayColor s14 museo-sans-400">
                    {product?.productId}
                  </p>
                </div>
                <div className=" d-flex justify-content-end s16 dm-sans-700">
                  <p>{product?.totalPrice}</p>
                </div>
              </div>
            </div>
          );
        })}

      </div>
    </div>
  );
}

export function OrderCartButtonContainer({
  hasChanged,
  aChangeHasFalsed,
  cartBody,
  navigate,
  isSummary = false,
  isLoading
}) {
  const goToPayment = () => {
    navigate("/dashboard/orders/payment");
  };

  return (
    <div className=" s14" >
      {isSummary ? (
        <div
          style={{ marginTop: "48px" }}
          className="d-flex flex-column gap-3 museo-sans-600"
        >
          <div
            className=" pointer  d-flex justify-content-center align-items-center btnLoginWidth skyBg text-white rounded-3"
            onClick={goToPayment}
          >
            Continuar a pagar
          </div>
          <div
            data-bs-toggle="modal"
            data-bs-target="#modal-end-order"
            className=" pointer  lightRedColor "
          >
            Cancelar
          </div>
        </div>
      ) : (
        <div
          style={{ marginTop: "48px" }}
          className="d-flex flex-column gap-3 museo-sans-600"
        >
          {hasChanged ? (
            <div
              onClick={() => aChangeHasFalsed(cartBody)}
              className={` ${isLoading ? " brightGrayBg  " : "pointer skyBg"} d-flex justify-content-center align-items-center btnLoginWidth text-white rounded-3 `}
            >
              <FormattedMessage id="orders.cart.button.update.list" />
            </div>
          ) : (
            <>
              <div
                data-bs-toggle="modal"
                data-bs-target="#modal-send-order"
                className=" pointer   d-flex justify-content-center align-items-center btnLoginWidth skyBg text-white rounded-3"
              >
                <FormattedMessage id="orders.cart.button.send.order" />
              </div>
              <div
                className=" pointer  d-flex justify-content-center align-items-center btnLoginWidth skyColor skyBorder border-1 rounded-3 "
                onClick={() =>
                  navigate(`/dashboard/orders/listado-kit`, {
                    state: { isOrders: true },
                  })
                }
              >
                <FormattedMessage id="orders.cart.button.send.order.two" />
              </div>
              <div
                data-bs-toggle="modal"
                data-bs-target="#modal-end-and-save-order"
                className=" pointer skyColor"
              >
                <FormattedMessage id="orders.cart.button.exit.and.save" />
              </div>
              <div
                data-bs-toggle="modal"
                data-bs-target="#modal-end-order"
                className=" pointer  lightRedColor "
              >
                <FormattedMessage id="orders.cart.button.exit.and.not.save" />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export function OrderCartEmpty({ navigate }) {
  const onClickButton = () => {
    navigate("/dashboard/orders/catalogo-consulta");
  };

  return (
    <div className="w-100 h-100 d-flex museo-sans-400 justify-content-center text-center align-items-center flex-column  ">
      <div
        style={{ width: "255px", marginTop: "15%" }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <div
          style={{ background: "#ECF0FA", width: "104px", height: "104px" }}
          className=" rounded-circle d-flex justify-content-center align-items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
          >
            <path
              d="M47.4552 36.384L51.2851 19.3228C51.3502 19.0335 51.3498 18.7332 51.2842 18.4441C51.2185 18.155 51.0892 17.8847 50.906 17.6533C50.7227 17.4218 50.4902 17.2353 50.2258 17.1076C49.9613 16.9799 49.6718 16.9143 49.3789 16.9157H17.5658L16.8221 13.2411C16.7326 12.7967 16.4942 12.3972 16.1472 12.1103C15.8002 11.8234 15.366 11.6666 14.918 11.6666H6.6125C6.35712 11.6663 6.10418 11.717 5.86815 11.8158C5.63213 11.9145 5.41764 12.0594 5.23695 12.2421C5.05627 12.4249 4.91293 12.6419 4.81513 12.8807C4.71733 13.1196 4.66699 13.3756 4.66699 13.6342V14.9459C4.66699 15.2045 4.71733 15.4605 4.81513 15.6994C4.91293 15.9383 5.05627 16.1553 5.23695 16.338C5.41764 16.5207 5.63213 16.6656 5.86815 16.7644C6.10418 16.8631 6.35712 16.9138 6.6125 16.9135H12.2833L17.9715 45.089C17.147 45.5678 16.4919 46.2968 16.0986 47.1729C15.7053 48.049 15.5937 49.028 15.7797 49.9718C15.9656 50.9156 16.4396 51.7764 17.1347 52.4327C17.8299 53.089 18.7111 53.5077 19.6539 53.6296C20.5968 53.7515 21.5536 53.5705 22.3894 53.1122C23.2251 52.6538 23.8976 51.9412 24.3118 51.0751C24.726 50.2089 24.8611 49.2329 24.6979 48.2848C24.5347 47.3367 24.0815 46.4645 23.4024 45.7913H40.3885C39.7001 46.4745 39.2446 47.362 39.088 48.325C38.9313 49.288 39.0817 50.2765 39.5174 51.1471C39.953 52.0178 40.6512 52.7253 41.5108 53.1671C42.3703 53.6088 43.3465 53.7619 44.2978 53.6041C45.2491 53.4463 46.126 52.9858 46.8014 52.2894C47.4767 51.593 47.9154 50.6969 48.0539 49.731C48.1923 48.7651 48.0233 47.7797 47.5713 46.9177C47.1193 46.0556 46.4079 45.3617 45.5402 44.9367L45.9764 42.9492C46.0409 42.661 46.0404 42.3619 45.9752 42.0739C45.91 41.7859 45.7816 41.5165 45.5996 41.2855C45.4176 41.0546 45.1866 40.8681 44.9237 40.7398C44.6609 40.6115 44.3729 40.5447 44.0811 40.5444H22.3336L21.8036 37.9187H45.5576C45.9991 37.9191 46.4275 37.7674 46.7724 37.4884C47.1172 37.2095 47.3581 36.82 47.4552 36.384Z"
              fill="#456ECE"
            />
          </svg>
        </div>
        <p className=" museo-sans-600 s24 mt-3">
          <FormattedMessage id="orders.cart.empty.title" />
        </p>
        <p className=" s16 grayColor mt-2">
          <FormattedMessage id="orders.cart.empty.p" />
        </p>
      </div>
      <div
        onClick={onClickButton}
        style={{ marginTop: "32px" }}
        className="s14 btnLoginWidth skyBg text-white rounded-3 d-flex justify-content-center align-items-center pointer "
      >
        <FormattedMessage id="orders.cart.empty.button" />
      </div>
    </div>
  );
}

export function OrderCartSummary({ cartSummary, isSummary = false }) {
  let shippingCost = "$ 0.00";

  return (
    <div
      style={{ marginTop: "32px", gap: "24px" }}
      className="d-flex flex-column s14"
    >
      <div className="d-flex w-100 justify-content-between mb-2" style={{ lineHeight: "20px" }}>
        <p>
          <FormattedMessage id="orders.cart.products" />
        </p>
        <p className=" dm-sans-700 s14">{cartSummary?.productsQuantity}</p>
      </div>
      <hr style={{ marginTop: "10px" }} className="m-0" />
      <div className="d-flex flex-column">
        {isSummary && (
          <div className="d-flex w-100 justify-content-between py-2" style={{ lineHeight: "20px" }}>
            <p>Costo de Envío</p>
            <p className=" dm-sans-500 s14">{shippingCost}</p>
          </div>
        )}
        <div className="d-flex w-100 justify-content-between py-2" style={{ lineHeight: "20px" }}>
          <p>
            <FormattedMessage id="orders.cart.brute.total" />
          </p>
          <p className=" dm-sans-500 s14">{cartSummary?.GrossTotal}</p>
        </div>
        <div className="d-flex w-100 justify-content-between py-2" style={{ lineHeight: "20px" }}>
          <p className=" s14 museo-sans-400">I.V.A. 21.00%</p>
          <p className=" dm-sans-500 s14">{cartSummary?.IVA}</p>
        </div>
      </div>
      <hr className="m-0" />
      <div className="d-flex flex-column gap-1 s16">
        <div className="d-flex w-100 justify-content-between">
          <p className=" museo-sans-600 s20">
            <FormattedMessage id="personal.sale.details.list.five" />
          </p>
          <p className=" dm-sans-700 s20">{cartSummary?.totalAmount}</p>
        </div>
        <div className="d-flex w-100 justify-content-between blueColor">
          <p className=" museo-sans-400">
            <FormattedMessage id="personal.sale.details.sale.total.pvp" />
          </p>
          <p className=" dm-sans-500 s16">{cartSummary?.totalPVPAmount}</p>
        </div>
      </div>
    </div>
  );
}


