import { useRef, useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { ReactComponent as UploadIconSmallSVG } from "../../../svgs/upload-icon-sm.svg";
import { ReactComponent as CameraIconSmallSVG } from "../../../svgs/camera-icon-sm.svg";
import { ModalClipboardContext } from "../../../routes/routes";
import { updateProfilePicture } from "../../../redux/commonSideEffects";
import { fetchUserProfile } from "../../../../Login/Framework/redux/loginSideEffects";
import {
  fetchVirtualEcommerce,
  updateStoreImage,
} from "../../../../Ecommerce/Framework/redux/EcommerceSideEffect";
import LoginCamera from "../../../../Login/Presentation/components/LoginCamera";
import Loader from "../../../Share/Loader";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import ReturnButton from "../../../Share/ReturnButton";
import "../pages/Configuration.css";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";

export default function UserDataChangeProfilePicture({ travelFunction }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setModalTexts } = useContext(ModalClipboardContext);
  const ecommerceData = useSelector((state) => state.ecommerceData);
  const biggerThan992 = useMediaPredicate("(min-width: 992px)");

  const profilePictureRef = useRef();
  const secondImageInputRef = useRef();

  const [showCamera, setShowCamera] = useState(false);
  const [capture, setCapture] = useState(null);
  const [uploadedImage, setUploadedImage] = useState({
    myFile: "",
  });
  const [currentImage, setCurrentImage] = useState("");
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [componentKey, setComponentKey] = useState(Math.random());

  const profileImage = useSelector((state) => state.userInfo.userInfo.image);

  // const currentImage = ecommerce ? profileImage : ecommerceData.ecommerceData.data?.photo

  const handleCapture = (screenshot) => {
    setCapture(screenshot);
    if (screenshot !== null) {
      selectUploadPhoto();
      setComponentKey(Math.random());
    }
  };

  function selectUploadPhoto() {
    setShowCamera(false);
    setIsButtonDisabled(true);
  }

  function selectCamera() {
    setShowCamera(true);
    setIsButtonDisabled(false);
  }

  async function handleUpload() {
    setUpdatingProfile(true);
    let username = localStorage.getItem("clientId");
    const PP = profilePictureRef.current;
    const base64Image = PP.getImageAsDataUrl();
    const resizedBase64 = await resizeBase64Image(base64Image, 600, 600);
    const opennedFromEcommerce = localStorage.getItem("new-url-photo");

    if (opennedFromEcommerce == "true") {
      localStorage.removeItem("new-url-photo");
      const result = await dispatch(updateStoreImage(resizedBase64));
      if (result?.error?.message) {
        return;
      }
      await dispatch(fetchVirtualEcommerce());
      await dispatch(fetchUserProfile());
      const modalData = {
        title: "Foto de perfil guardada",
        text: "Has subido una foto de perfil con éxito. Ahora puedes acceder a configurar tu Tienda Virtual en esta pantalla.",
        status: "confirm",
        show: false,
        bottom: 86,
      };
      closeModalAndShowPopUp(modalData);
      runBeforeClosingModals();
      return;
    }

    await dispatch(updateProfilePicture(resizedBase64));
    await dispatch(fetchUserProfile(username));
    runBeforeClosingModals();

    const profileEcommerce = localStorage.getItem("profile-ecommerce");
    if (profileEcommerce == "true") {
      localStorage.removeItem("profile-ecommerce");
      navigate("/dashboard/ecommerce");
      setTimeout(() => {
        const modalData = {
          title: "Foto de perfil guardada",
          text: "Has subido una foto de perfil con éxito. Ahora puedes acceder a configurar tu Tienda Virtual en esta pantalla.",
          status: "confirm",
          show: false,
          bottom: 86,
        };
        closeModalAndShowPopUp(modalData);
        const closeModal = document.getElementById("closeModal");
        closeModal?.click();
      }, 500);
    }
  }

  const closeModalAndShowPopUp = (modalData) => {
    setModalTexts(modalData);
    const closeByClick = document.getElementById(
      "force-close-up-on-ecommcerce-profile-picture"
    );
    closeByClick?.click();
    const closeModal = document.getElementById("closeModal");
    closeModal?.click();
  };

  async function resizeBase64Image(base64Image, width, height) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = base64Image;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        const resizedBase64 = canvas.toDataURL("image/jpeg", 0.8);
        resolve(resizedBase64);
      };
    });
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const runBeforeClosingModals = () => {
    setUpdatingProfile(false);
    travelFunction(1.1);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    setUploadedImage({ ...uploadedImage, myFile: base64 });
    setCapture(null);
    setComponentKey(Math.random());
  };

  useEffect(() => {
    let ecommerce = localStorage.getItem("new-url-photo");
    setCurrentImage(null);
    if (ecommerce) {
      setCurrentImage(ecommerceData.ecommerceData.data?.photo);
    }
    if (profileImage && !ecommerce) {
      setCurrentImage(profileImage);
    }
    if (!profileImage && !ecommerce) {
      setCurrentImage(".");
    }
  }, []);

  return (
    <div className="d-flex flex-column h-100">
      <ReturnButton travelFunction={travelFunction} travelFunctionValue={1.1} />
      <div>
        <p className="museo-sans-700 s20 mb-2">
          <FormattedMessage id="settings.user.data.profile.picture.title" />
        </p>
        <p className="museo-sans-500 s16 config-picture-change-text">
          <FormattedMessage id="settings.user.data.profile.picture.description" />
        </p>
      </div>
      <div
        id="force-close-up-on-ecommcerce-profile-picture"
        data-bs-dismiss="modal"
        aria-label="Close"
        style={{ width: "18px", height: "18px", right: "4px" }}
        className=" invisible position-absolute svg-force-size pointer"
      >
        X
      </div>
      {currentImage && (
        <div
          className={`${
            biggerThan992 ? "w-50" : "w-100"
          } pe-3 pt-3 h-50 profile-picture-updater-padding-none profile-picture-updater-svg-fill upload-profile-picture-disable-trash-can upload-profile-picture-white-circle upload-profile-picture-grey-background upload-profile-picture-input-width upload-profile-picture-background-width`}
        >
          {!showCamera ? (
            <ProfilePicture
              frameSize={195}
              cropSize={195}
              key={componentKey}
              minImageSize={150}
              maxImageSize={1400}
              image={
                capture
                  ? capture
                  : uploadedImage.myFile
                  ? uploadedImage.myFile
                  : currentImage
              }
              ref={profilePictureRef}
              useHelper={true}
              debug={true}
              frameFormat={"circle"}
              messages={{
                DEFAULT: (
                  <FormattedMessage
                    id="profile.editor.default"
                    defaultMessage="Click here to upload your image."
                  />
                ),
                DRAGOVER: (
                  <FormattedMessage
                    id="profile.editor.dragover"
                    defaultMessage="Drop the file."
                  />
                ),
                INVALID_FILE_TYPE: (
                  <FormattedMessage
                    id="profile.editor.type"
                    defaultMessage="Image type file allowed only."
                  />
                ),
                INVALID_IMAGE_SIZE: (
                  <FormattedMessage
                    id="profile.editor.image"
                    defaultMessage="The file size must be above 350px and below or equal to 600px."
                  />
                ),
              }}
            />
          ) : (
            <LoginCamera
              capture={capture}
              handleCapture={handleCapture}
              handleShowCamera={setShowCamera}
              styles={{
                videoContainerWidth: "w-75",
                screenshotContainerWidth: "w-75",
                inConfig: true,
              }}
            />
          )}
        </div>
      )}
      <div
        className="d-flex justify-content-between mt-auto"
        style={{ marginBottom: "16px" }}
      >
        {isButtonDisabled ? (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 skyColor skyBorder button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding bronceBg svg-margin-left"
            onClick={() => secondImageInputRef.current.click()}
          >
            <input
              className="d-none"
              ref={secondImageInputRef}
              type="file"
              label="Image"
              name="myFile"
              accept=".jpg"
              onChange={(e) => handleFileUpload(e)}
            />
            <FormattedMessage
              id="buttons.upload.photo"
              defaultMessage="Upload photo"
            />
            <UploadIconSmallSVG />
          </button>
        ) : (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 skyColor skyBorder button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding bronceBg svg-margin-left"
            onClick={() => selectUploadPhoto()}
          >
            <FormattedMessage
              id="buttons.select.photo"
              defaultMessage="Select photo"
            />
            <UploadIconSmallSVG />
          </button>
        )}
        {isButtonDisabled ? (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 skyColor skyBorder button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding bronceBg svg-margin-left"
            onClick={() => selectCamera()}
          >
            <FormattedMessage
              id="buttons.take.photo.again"
              defaultMessage="Take photo"
            />
            <CameraIconSmallSVG />
          </button>
        ) : (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 button-bootstrap-radius button-padding bronceBg svg-margin-left button-disabled-border hover-pointer-none"
            disabled
          >
            <FormattedMessage
              id="buttons.take.photo.again"
              defaultMessage="Take photo"
            />
            <CameraIconSmallSVG />
          </button>
        )}
        {showCamera ? (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 brightGrayBg whiteColor button-border-none button-bootstrap-radius button-padding hover-pointer-none"
            disabled
          >
            <Loader loading={updatingProfile} />
            <FormattedMessage
              id="buttons.save.changes"
              defaultMessage="Save changes"
            />
          </button>
        ) : (
          <button
            className="settings-picture-buttons-width align-self-center museo-sans-700 s14 skyBg whiteColor skyBorder button-hover-light button-border-none button-bootstrap-radius button-padding"
            onClick={() => handleUpload()}
          >
            <Loader loading={updatingProfile} />
            <FormattedMessage
              id="buttons.save.changes"
              defaultMessage="Save changes"
            />
          </button>
        )}
      </div>
    </div>
  );
}
