import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const getEventDates  = createAsyncThunk(
    'getEventDates',
    async (data , {rejectWithValue}) => {
        try {
            const location = localStorage.getItem("country");
            const clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/events/${clientId}/dates`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getEventsForSpecificDate  = createAsyncThunk(
    'getEventsForSpecificDate',
    async (date , {rejectWithValue, dispatch}) => {
        try {
            const location = localStorage.getItem("country");
            const clientId = localStorage.getItem("clientId");
            const events = (await customNetServAxios().get(`${location}/events/${clientId}?date=${date}`)).data;
            return events
           
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const calendarCurrentPeriod  = createAsyncThunk(
    'calendarCurrentPeriod',
    async (date , {rejectWithValue, dispatch}) => {
        try {
            const location = localStorage.getItem("country");
            const currentPeriod = (await customNetServAxios().get(`${location}/calendar/period-data`)).data;
            return currentPeriod
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const currentYearPeriods  = createAsyncThunk(
    'currentYearPeriods',
    async (year , {rejectWithValue, dispatch}) => {
        try {
            const location = localStorage.getItem("country");
            const currentYear = new Date().getFullYear();
            const yearPeriods = (await customNetServAxios().get(`${location}/calendar/year-data/${currentYear}`)).data;
            return yearPeriods
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const pastYearPeriods  = createAsyncThunk(
    'pastYearPeriods',
    async (year , {rejectWithValue, dispatch}) => {
        try {
            const location = localStorage.getItem("country");
            const pastYear = (new Date().getFullYear() - 1)
            const pastYearPeriods = (await customNetServAxios().get(`${location}/calendar/year-data/${pastYear}`)).data;
            return pastYearPeriods
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const nextYearPeriods  = createAsyncThunk(
    'nextYearPeriods',
    async (year , {rejectWithValue, dispatch}) => {
        try {
            const location = localStorage.getItem("country");
            const nextYear = (new Date().getFullYear() + 1)
            const nextYearPeriods = (await customNetServAxios().get(`${location}/calendar/year-data/${nextYear}`)).data;
            return nextYearPeriods
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const thisYearPeriodData = createAsyncThunk(
    'thisYearPeriodData',
    async (yearPeriod, {rejectWithValue}) => {
        try {
            const location = localStorage.getItem("country");
            const clientId = localStorage.getItem("clientId");

            const prevPerid = adjustPeriod(yearPeriod - 1);
            const afterPerid = adjustPeriod(yearPeriod + 1);

            const result = (await customNetServAxios().get(`${location}/events/${clientId}/periodic/preview?yearPeriod=${yearPeriod}`)).data;
            const resultPrev = (await customNetServAxios().get(`${location}/events/${clientId}/periodic/preview?yearPeriod=${prevPerid}`)).data;
            const resultAfter = (await customNetServAxios().get(`${location}/events/${clientId}/periodic/preview?yearPeriod=${afterPerid}`)).data;

            const totalDates = { dates: [...result.dates, ...resultPrev.dates, ...resultAfter.dates] };

            return totalDates;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const adjustPeriod = (period) => {
    let periodStr = period.toString().split('');

    if (periodStr[3] === '0' && periodStr[2] === '0') {
        periodStr = periodStr[1] === '0'
            ? `${periodStr[0] - 1}912`
            : `${periodStr[0]}${periodStr[1] - 1}12`;
    } else if (periodStr[3] === '3' && periodStr[2] === '1') {
        periodStr = periodStr[1] === '9'
            ? `${+periodStr[0] + 1}001`
            : `${periodStr[0]}${+periodStr[1] + 1}01`;
    } else {
        periodStr = periodStr.join('');
    }

    return periodStr;
};

export { getEventDates, getEventsForSpecificDate, calendarCurrentPeriod, currentYearPeriods, pastYearPeriods, nextYearPeriods, thisYearPeriodData }