import { useState, useEffect, useRef } from "react";
import "./products.css";
import ProductCard from "../cardProduct/cardProduct";
import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories/index";
import notProductIcon from "../../../../common/img/consultationCatalogue/Icon (1).png";
import { FormattedMessage } from "react-intl";
import ProductCardSkeleton from "../skeletoncardproduct/skeletoncardproduct";

export default function ProductsCategories({ titleCategory, searchText, ordertype }) {
  const [products, setProducts] = useState([]);
  const extraRef = useRef(null);
  const observer = useRef(null);
  let offSetRef = useRef(0);
  const [loader, setLoader] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [returnedEmpty, setReturnEmpty] = useState(false);
  const [showCategory, setShowCategory] = useState(true);
  const limit = 50;

  useEffect(() => {
    if (titleCategory.id && searchText.length === 0) {
      setShowCategory(true)
      setProducts([]);
      setLoadingProducts(true)
      offSetRef.current = 0;
      setReturnEmpty(false);
      getProducts();
    }
  }, [titleCategory, searchText]);

  useEffect(() => {
    if (searchText.length > 2 && titleCategory.id != 0) {
      setProducts([]);
      setShowCategory(false);
      setLoadingProducts(true)
      offSetRef.current = 0;
      setReturnEmpty(false);
      getProductsSearch(searchText);
    }
  }, [searchText]);

  useEffect(() => {
    if (extraRef.current) {
      observer.current = new IntersectionObserver((entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !loader && !returnedEmpty) {
          callbackExtras();
        }
      });
      observer.current.observe(extraRef.current);
      return () => observer.current?.disconnect();
    }
  }, [extraRef.current, loader, returnedEmpty]);

  const getProducts = async () => {
    const params = {
      limit: limit,
      offset: 0,
      categoryId: titleCategory.id,
      search: null,
      orderType: ordertype ? ordertype : 'personal',
    };
    await getProductsService(params, 0);
    setLoadingProducts(false)
  }

  const getProductsSearch = async () => {
    const params = {
      limit: limit,
      offset: 0,
      categoryId: null,
      search: searchText,
      orderType: ordertype ? ordertype : 'personal',
    };
    await getProductsService(params, 0)
    setLoadingProducts(false)
  }

  const callbackExtras = async () => {
    const params = {
      limit: limit,
      offset: offSetRef.current,
      categoryId: searchText === "" ? titleCategory.id : null,
      search: searchText || null,
      orderType: ordertype ? ordertype : 'personal',
    };
    await getProductsService(params, offSetRef.current);
  };

  const getProductsService = async (params, offset) => {
    setLoader(true);
    try {
      const clientId = localStorage.getItem("clientId");
      const countryCode = localStorage.getItem("country");
      const response = await Repository.ProductCatalogRepository.getProducts(clientId, countryCode, params);

      if (response.products.length === 0) {
        setReturnEmpty(true);
      } else {
        offset === 0 ? setProducts(response.products)
          : setProducts((prevProducts) => [...prevProducts, ...response.products]);
        if (response.products.length < params.limit)
          setReturnEmpty(true);
        offSetRef.current += params.limit;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  }

  return (
    <>
      {!loadingProducts ? (
        <div className="containerProduct">
          <div className="title"> {showCategory ? titleCategory?.description : ""} </div>
          <div className={` ${products.length > 0 ? "row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4" : "d-flex justify-content-center"} `}>
            <>
              {products.length > 0 ? (
                products.map((product, index) => (
                  <div className="col" key={product?.id || index}>
                    <ProductCard
                      product={product}
                    />
                  </div>
                ))
              ) :
                (
                  <div className="container-error mt-5">
                    <img
                      src={notProductIcon}
                      alt="notProductIcon"
                      className="notProduct-icon"
                    />
                    <p>
                      No se encuentran resultados <br /> para tu filtrado.
                    </p>
                  </div>
                )}
            </>
          </div>

          <div ref={extraRef} className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "80px", marginTop: "20px" }}
          >
            {!returnedEmpty && (
              <>
                <div className="spinner-border blueColor" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                {loader && <p className="museo-sans-500 s14 mt-2">
                  <FormattedMessage id='personal.sales.loading.more.requests' />
                </p>}
              </>)}
          </div>
        </div >
      ) : (
        <div className="containerProduct">
          <div className="d-flex my-4 gap-4">
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
            <ProductCardSkeleton />
          </div>
        </div>
      )}
    </>
  );
}
