import ImgMercadoPago from "./Imgs/mercado-pago-method.png";
import ImgCash from "./Imgs/cash-method.png";
import ImgAccount from "./Imgs/account-method.png";
import ImgJustCoins from "./Imgs/just-coins-method.png";

export const normalizedStr = (strNumber) => {
    let cleanedStr = strNumber?.replace(/[^0-9,.-]/g, '');
    const normalizedStr = cleanedStr?.replace(/\./g, "").replace(",", ".");
    const number = parseFloat(normalizedStr);

    return isNaN(number) ? 0 : number;
}

export const formatNumber = (value = "", isJustCoin = false) => {
    let numericValue = value.replace(/[^\d,-]/g, '');

    let [integer, decimal] = numericValue.split(',');

    // Agrega puntos de miles
    if (integer) {
        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
    numericValue = isJustCoin ? integer : decimal !== undefined ? `${integer},${decimal.slice(0, 2)}` : integer;

    return numericValue
};

export const payments = {
    "mercado-pago": {
        img: ImgMercadoPago,
        title: "Mercado Pago"
    },
    "cash": {
        img: ImgCash,
        title: "Pago en efectivo"
    },
    "balance": {
        img: ImgAccount,
        title: "Saldo en cuenta"
    },
    "just-coins": {
        img: ImgJustCoins,
        title: "Just Coins"
    }
}