import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import { EcommerceRegisterCompleted } from "../components/EcommerceRegisterForm";
import { ReactComponent as SVGGreen } from "../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as SVGError } from "../../../EDBs/Presentation/img/redcross.svg";
import { FormattedMessage } from "react-intl";

export default function EcommerceFormProfileHolder(params) {
  const profileData = useSelector((state) => state.userInfo.userInfo);
  const history = useNavigate();
  const clientId = localStorage.getItem("clientId");

  const onStatusConfig = () => {
    if (params?.ecommerceData?.ecommerceData?.data?.configCause) {
      let cause = params?.ecommerceData?.ecommerceData?.data?.configCause;
      if (cause == "NAME") {
        return true;
      }
    }
    return false;
  };

  const onModifyName = () => {
    history("/dashboard/ecommerce/mod");
  };

  return (
    <div style={{ width: "600px" }} className="d-flex flex-column gap-4">
      <div className="d-flex flex-column gap-2">
        <div
          style={{ width: "84px", height: "72px" }}
          data-bs-toggle="modal"
          data-bs-target="#ecommerce-profile-pop-up"
          className=" pointer position-relative mb-2"
        >
          <ImageCircleHandler
            width={"72px"}
            height={"72px"}
            url={params?.ecommerceData?.ecommerceData?.data?.photo}
            firstName={profileData?.firstName}
            lastName={profileData?.lastName}
            top={0}
            styles={
              {
                border: params?.ecommerceData?.ecommerceData?.data?.configCause == "PHOTO" ? "3px solid var(--Status-Alert-500, #F06E69)" : ""
              }
            }
          />
          <div
            data-bs-toggle="modal"
            data-bs-target="#ecommerce-profile-pop-up"
            style={{ top: "40px", left: "45px" }}
            className=" position-absolute"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
            >
              <path
                d="M21 38.5C30.665 38.5 38.5 30.665 38.5 21C38.5 11.335 30.665 3.5 21 3.5C11.335 3.5 3.5 11.335 3.5 21C3.5 30.665 11.335 38.5 21 38.5Z"
                fill="#5AAFF1"
              />
              <path
                d="M30.0195 16.1368V26.1327C30.0195 26.5746 29.844 26.9984 29.5315 27.3108C29.2191 27.6232 28.7954 27.7987 28.3535 27.7987H13.916C13.4741 27.7987 13.0504 27.6232 12.738 27.3108C12.4255 26.9984 12.25 26.5746 12.25 26.1327V16.1368C12.25 15.6949 12.4255 15.2711 12.738 14.9587C13.0504 14.6463 13.4741 14.4708 13.916 14.4708H16.975L17.402 13.3298C17.5207 13.0133 17.733 12.7404 18.0106 12.5475C18.2882 12.3546 18.618 12.2509 18.956 12.25H23.3118C23.6504 12.2501 23.9809 12.3536 24.2592 12.5465C24.5375 12.7395 24.7503 13.0127 24.8693 13.3298L25.2997 14.4708H28.35C28.5691 14.4703 28.7861 14.513 28.9886 14.5966C29.1912 14.6801 29.3752 14.8027 29.5303 14.9575C29.6854 15.1122 29.8084 15.296 29.8923 15.4984C29.9763 15.7007 30.0195 15.9177 30.0195 16.1368Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.1539 19.1782C20.144 19.1782 19.3252 19.997 19.3252 21.007C19.3252 22.017 20.144 22.8357 21.1539 22.8357C22.1639 22.8357 22.9827 22.017 22.9827 21.007C22.9827 19.997 22.1639 19.1782 21.1539 19.1782ZM16.7002 21.007C16.7002 18.5472 18.6942 16.5532 21.1539 16.5532C23.6137 16.5532 25.6077 18.5472 25.6077 21.007C25.6077 23.4667 23.6137 25.4607 21.1539 25.4607C18.6942 25.4607 16.7002 23.4667 16.7002 21.007Z"
                fill="#5AAFF1"
              />
            </svg>
          </div>
        </div>
        <div className="d-flex flex-column gap-1">
          <p className=" museo-sans-600 s24">
            {profileData?.firstName + " " + profileData?.lastName}
          </p>
          <p style={{ color: "#A2A3A7" }} className=" museo-sans-400 s12">
            <FormattedMessage id="ecommerce.form.consult.id.text" values={{id: clientId}} />
          </p>
        </div>
        {params?.ecommerceData?.ecommerceData?.data?.configCause == "PHOTO" && (
          <>
            <div className="d-flex gap-2 s12 museo-sans-400">
              <SVGError />
              <p className=" lightRedColor">
                <FormattedMessage id="ecommerce.form.error.message.photo" />
              </p>
            </div>
          </>
        )}
      </div>
      
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-1">
          <p className=" museo-sans-600">
            <FormattedMessage id="ecommerce.register.title.name" />
          </p>
          <div
            data-bs-toggle="modal"
            data-bs-target="#register-modal-info"
            style={{ width: "16px", height: "16px", top: "-2px" }}
            className=" position-relative svg-force-size pointer"
          >
            <HelpIconNoTarget />
          </div>
        </div>
        <EcommerceRegisterCompleted
          onStatusConfig={onStatusConfig()}
          inputValue={{
            value: params?.ecommerceData?.ecommerceData?.data?.storeName,
          }}
        />
        {params?.ecommerceData?.ecommerceData?.data?.configCause == "NAME" && (
          <>
            <div className="d-flex gap-2 s12 mb-4 museo-sans-400">
              <SVGError />
              <p className=" lightRedColor">
              <FormattedMessage id="ecommerce.form.error.message.name" />
              </p>
            </div>
            <div
              onClick={onModifyName}
              style={{ height: "40px" }}
              className="d-flex btnLoginWidth justify-content-center align-items-center museo-sans-600 skyBorder border-1 rounded-3 skyColor pointer s14"
            >
              <FormattedMessage id="ecommerce.form.mod.store.name" />
            </div>
          </>
        )}
      </div>
      {params?.formData?.body?.accountMP?.value && (
        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-1">
            <p className=" s14 museo-sans-600">
              <FormattedMessage id="ecommerce.register.form.path.two.title" />
            </p>
            <div
              data-bs-toggle="modal"
              data-bs-target="#form-associate"
              style={{ width: "16px", height: "16px", top: "-2px" }}
              className=" position-relative pointer svg-force-size"
            >
              <HelpIconNoTarget />
            </div>
          </div>

          <div className="d-flex gap-1 s20 museo-sans-400 grayColor ">
            <p>ID {params?.formData?.body?.accountMP?.value}</p>
            <div
              style={{ width: "16px", height: "16px", top: "-2px" }}
              className=" position-relative svg-force-size"
            >
              <SVGGreen />
            </div>
          </div>
        </div>
      )}
      <hr className="m-0" />
    </div>
  );
}
