import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import edbIcon from "../../../imgs/EDB.png";
import gropuIcon from "../../../imgs/Group.png";
import profileIcon from "../../../imgs/Profile.png";
import ArrowRightIcon from "../../../imgs/Arrow-Right.png";
import ModalProducts from '../modals/modalProducts/modalProducts';
import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories";
import "./summaryOrdersList.css";
export default function SummaryOrdersList() {
  const orderData = useSelector((state) => state.orderData);
  const [isModalOpen, setIsModalOpen] = useState(false);
    const [userModal, setUserModal] = useState({});
  const openModal = () => {
    setIsModalOpen(true);
  }
  const closeModal = () => setIsModalOpen(false);
  const goModalProducts = async (id,type,date,img) => {
        const countryCode = localStorage.getItem("country");
        const clientId = localStorage.getItem("clientId");
        const response = await Repository.OrderingRepository.getOrderProducts(clientId,countryCode,id);
        setUserModal({
          id:id,
          type:type,
          date:date,
          img:img,
          products:response
        })
        setIsModalOpen(true);
    };
  const data = useSelector(
    (state) => state.orderData.cart.cartContainer
  );
  const getIcon = (tipo) => {
    let icon = "";
    if (tipo.toLowerCase() === "personal") {
      icon = profileIcon;
    } else if (
      tipo.toLowerCase() === "client" ||
      tipo.toLowerCase() === "cliente"
    ) {
      icon = gropuIcon;
    } else if (tipo.toLowerCase() === "edb") {
      icon = edbIcon;
    }
    return icon;
  };

  return (
    <>
      <div>
        {orderData.ordersSelect.map((request, index) => (
          <div key={index} >
            {index != 0 && (
              <div
                style={{
                  height: "1px",
                  width: "100%",
                  border: "1px solid #DDDDDD",
                }}
              ></div>
            )}
            <div className="museo-sans-400 s12" style={{ color: "#908F9A", marginTop: '20px' }}>
              <span style={{ marginRight: "4px" }}>
                <img src={getIcon(request.type)} alt="" />
              </span>
              {request.type.toUpperCase()}
            </div>
            <div className="containerList">
              <div className="museo-sans-600 s14">{request.clientName}</div>
              <div className="dm-sans-500 s14" >
                {request.amount}
                <span className="museo-sans-500" style={{ color: "#5AAFF1", marginLeft: "12px",cursor:'pointer' }}  onClick={() => goModalProducts(request.id,request.type.toUpperCase(),request.startDate,getIcon(request.type))}>
                  productos <img src={ArrowRightIcon} alt="" />
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ModalProducts isOpen={isModalOpen} onClose={closeModal} data={userModal} />
    </>
  );
}
