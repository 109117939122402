import React from 'react';
import './ModalCashPayment.css';
import iconCheckBlue from '../../../../Imgs/check-blue.png';
import closeIcon from "../../../../Imgs/Vector.png";
const ModalCashPayment = ({ isOpen, toModalDifferentCashPayments,close }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-contentProducts " style={{height:'496px',display:'flex',flexDirection:'column',alignItems:'center',paddingTop:'16px'}}>
      <div className='container-close'style={{paddingBottom:'0px'}}>
          <img onClick={()=>close()} src={closeIcon} alt="closeIcon" />
        </div>
        <div className='container-check-blue' style={{width:'408px',height:'260px'}} >
            <img src={iconCheckBlue} alt="iconCheckBlue" />
            <p className='museo-sans-600 color-payment s24'>¡Pedido enviado con éxito!</p>
            <p className='museo-sans-400 text-payment  s16'>Tu pedido ha sido enviado exitosamente pero aún queda pendiente recibir el pago en los puntos de pago autorizados. Verás actualizado el estado en cuanto realices el pago.</p>
        </div>
        <div className="modal-footer">
          <button className='container-button' style={{marginBottom:'16px',marginTop:'32px',height:'40px'}} onClick={toModalDifferentCashPayments}>Ver opciones de pago en efectivo</button>
          <button className='container-button-seconds' style={{marginBottom:'16px',height:'40px'}} >Ver mis pedidos</button>
          <p className='museo-sans-600 color-button  s14'>Ir al Inicio</p>
        </div>
      </div>
    </div>
  );
};

export default ModalCashPayment;
