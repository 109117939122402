import { useState, useEffect, useRef } from "react";
import "./categoriesSearch.css";
import searchIcon from "../../../../common/img/consultationCatalogue/fontAwsomesearch.png";
import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories/index";
import { calalogInit } from "./common/common-categoriesSearch";
import ChangeCategoryModal from "./modal/ChangeCategoryModal";
import { ReactComponent as SVGChevronRight } from "../../../Icons/Chevron-Right.svg";

export default function CategoriesSearch({
  setSelectedCategory,
  selectedCategory,
  setSearchText,
  searchText,
  ordertype
}) {

  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([
  ]);
  const [isSticky, setIsSticky] = useState(true);

  useEffect(() => {
    let clientId = localStorage.getItem("clientId");
    let countryCode = localStorage.getItem("country");
    Repository.ProductCatalogRepository.getCategories(clientId, countryCode, ordertype ? ordertype : 'personal').then((response) => {
      setCategories(response.categories)
      const personal = calalogInit.find(elemt => elemt.countryCod === countryCode).personal;
      const selecCategoryInit = response.categories.find(elemt => elemt.description == personal);
      setSelectedCategory(selecCategoryInit);
    }).catch((error) => {
    }).finally(() => {
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    const handleWheel = (event) => {
      if (showModal) return;
      if (event.deltaY > 0) {
        // Se detecta scroll hacia abajo
        setIsSticky(false);
      } else if (event.deltaY < 0) {
        // Se detecta scroll hacia arriba
        setIsSticky(true);
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: true });
    return () => window.removeEventListener("wheel", handleWheel);
  }, [showModal]);

  const handleSelection = (category) => {
    setSelectedCategory(category)
    setShowModal(false)
  }

  const openModal = () => {
    setShowModal(true)
  }

  return (
    <>
      <div className={isSticky ? "visible" : "hidden"}>
        {isLoading ? (
          <div className="container-loadding">
          </div>
        ) : (
          <div className="containerCategoriesSearch">
            <div className="containerSearch museo-sans-400">
              <input
                type="text"
                placeholder="Ej: Placeholder"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="search-input museo-sans-400"
              />
              <img src={searchIcon} alt="Lupa" className="search-icon" />
            </div>

            <div>
              <span className="grayColor museo-sans-500 s14">Categoría</span>  <SVGChevronRight style={{ marginBottom: "3px" }} />  <span className="museo-sans-700 s14 me-4">{selectedCategory?.description} </span>
              <span className='skyColor s14 pointer museo-sans-700' data-bs-toggle="modal"
                data-bs-target="#changeCategory" onClick={() => {
                  openModal()
                }}>Cambiar</span>
            </div>

          </div>
        )}
      </div>
      <ChangeCategoryModal categories={categories} setSelectedCategory={handleSelection} selectedCategory={selectedCategory} setShowModal={setShowModal} showModal={showModal} />
    </>
  );
}
