import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";

export default function EcommerceRegisterModalInfo({ }) {
  return (
    <div style={{ padding: "44px" }}>
      <div className="d-flex align-items-center gap-2 museo-sans-600 s24 ">
        <div
         
        style={{ top: "-6px" }} className=" position-relative">
          <HelpIconNoTarget />
        </div>
        <p>
          <FormattedMessage id="ecommerce.register.title.name" />
        </p>
      </div>
      <div style={{gap: "20px"}} className=" d-flex flex-column mt-2 pt-1 s14 museo-sans-400">
        <p>
          <FormattedMessage id="ecommerce.register.modal.info.p.one" />
        </p>
        <p>
        <FormattedMessage id="ecommerce.register.modal.info.p.two" />
        </p>
        <p className=" museo-sans-600">
        <FormattedMessage id="ecommerce.register.modal.info.p.three" />
        </p>
      </div>
      <div
        style={{ marginTop: "44px" }}
        className="w-100 d-flex justify-content-center"
      >
        <button
          data-bs-dismiss="modal"
          aria-label="Close"
          className=" border-0 rounded-3 btnLoginWidth museo-sans-600 s14 skyBg text-white "
        >
          <FormattedMessage id="buttons.understood" />
        </button>
      </div>
    </div>
  );
}
