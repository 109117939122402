import React from 'react';
import './ordersPromotion.css';

const OrdersPromotion = ({ data }) => {
  if (!data) return null
  const formatText = (text) => {
    return text.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  };
  return (
    <div style={{
      paddingTop: '16px', boxShadow: 'rgba(219, 219, 244, 0.5) 0px 2px 6px 0px'
      , backgroundColor: '#F9F9FC'
    }}
      className='p-3 rounded-3 gap-3' >
      <div >
        <div className="modal-header museo-sans-600 s20" style={{ marginBottom: '16px' }}>
          <span>
            {Object?.values(data)?.length > 0 && (
              <div
                style={{
                  background: "#D18DEF",
                  width: "24px",
                  height: "24px",
                  marginLeft: '226px',
                  marginTop: '4px'
                }}
                className=" s12 dm-sans-500 position-absolute text-white d-flex justify-content-center align-items-center rounded-circle"
              >
                x{Object?.values(data)?.length}
              </div>
            )}
          </span>
          <p>Ofertas y Promociones</p>
        </div>
        <div className="modal-body">
          <div className="museo-sans-400 s14 ">
            <div >
              {Object?.values(data)?.map((producto, index) => (
                <div key={index} className="productoPromotion" style={index < Object?.values(data)?.length - 1 ? {
                  borderBottom: '1px solid #DDDDDD',
                  marginBottom: '16px',
                  paddingBottom: '16px'
                } : undefined}>
                  <span>
                    {producto.quantity > 0 && (
                      <div
                        style={{
                          background: "#D18DEF",
                          width: "24px",
                          height: "24px",
                          marginLeft: '31px',
                          marginTop: '-31px'
                        }}
                        className=" s12 dm-sans-500 position-absolute text-white d-flex justify-content-center align-items-center rounded-circle"
                      >
                        x{producto.quantity}
                      </div>
                    )}
                  </span>
                  <img src={producto.img} alt={producto.description} />
                  <div className="producto-infoPromotion" >
                    <p className=' museo-sans-600 s16'>
                      {formatText(producto.description)} <br />
                      <small className=' museo-sans-400 s14 color'>{producto.productId} <span className='museo-sans-500 s14 color'>PVP: {producto.pvpPrice}</span></small>
                    </p>
                    <p className='museo-sans-700 s20' style={{ marginTop: '11px !important' }}>{producto.price}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersPromotion;