import DescendantsSublistRow from "./DescendantsSublistRow";
import Loader from "../../../common/Share/Loader";
import { useEffect, useState } from "react";
import customNetServAxios from "../../../common/services/NetSrv";

export default function DescendantsSublist({ gen, id, level }) {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState();

  let lineIndex = 0;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");

      let answer = undefined;
      answer = (await customNetServAxios().get(
        `${location}/descendants-tree/${clientId}/first-generation-group/root/${id}?offset=0&limit=20&search=`
      )).data;

      parcheOneZeroThree(answer?.firstGenerationGroup?.length, answer?.firstGenerationGroup);

      setLoading(false);
    }
  };

  const parcheOneZeroThree = async (lengthNumber, first) => {

    let auxLengthNumber = lengthNumber;
    let offSet = auxLengthNumber;
    let answer = undefined;
    let location = localStorage.getItem("country");
    let clientId = localStorage.getItem("clientId");
    let auxiliarList = first;

    while (auxLengthNumber == 20) {
      answer = (await customNetServAxios().get(
        `${location}/descendants-tree/${clientId}/first-generation-group/root/${id}?offset=${offSet}&limit=20&search=`
      )).data;

      let secondKey = Object.keys(answer)[1];

      if (answer[secondKey].length != 0) {
        answer[secondKey].map((elm) => {
          auxiliarList.push(elm);
        });
      }

      offSet = offSet + 20;

      auxLengthNumber = answer[secondKey].length;
    }

    setList(auxiliarList);
  };

  return (
    <div
      style={{ paddingLeft: "1px" }}
      className=" d-flex position-relative flex-column w-100 "
    >
      {list ? (
        <>
          {list?.map((elem) => {
            lineIndex = lineIndex + 1;
            return (
              <DescendantsSublistRow
                index={lineIndex}
                end={list?.length}
                gen={gen}
                user={elem}
              />
            );
          })}
        </>
      ) : (
        <div style={{ height: "100px" }} className=" position-relative w-100">
          <Loader loading={loading} />
        </div>
      )}
    </div>
  );
}

/*
    
                    list?.firstGenerationGroup ? 
                        list?.firstGenerationGroup?.map((elem) => {
                        lineIndex = lineIndex + 1;
                        return <DescendantsSublistRow index={lineIndex} end={list?.firstGenerationGroup.length} gen={gen} user={elem} />
                    })

    */

/**
                   * {!returnedEmpty && (
                  <>
                    <div ref={extraSubRef} className="mt-4" style={{ height: "30px" }}>
                      
                      {isVisible ? (
                        loading ? (
                          <div
                            style={{ top: "-30px" }}
                            className=" position-relative d-flex flex-column justify-content-center align-items-center"
                          >
                            <div className="spinner-border blueColor" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                            <p className="museo-sans-500 s14 mt-2">
                              <FormattedMessage
                                id="group.incorporations.descendant.loading.more"
                                defaultMessage="Cargando más consultoras"
                              />
                            </p>
                          </div>
                        ) : (
                          <></>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
                   */
