import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import OrdersCartProduct from "../OrdersCartProduct";
import ShareModalStructureCloseIcon from "../../../../common/Share/ShareModalStructureCloseIcon";

export default function OrderCartModalOfertsList(params) {
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [count, setCount] = useState(0);

  const onChangeArrayValue = (value, operation) => {
    const prevObjects = [...selectedObjects];

    if (operation == "add") {
      setSelectedObjects(
        prevObjects.map((element) => {
          if (element.productId == value.productId) {
            return { ...value, quantity: element?.maxAvailable < 1 ? element.quantity + 1 : (element.quantity + 1) > element?.maxAvailable ? element.quantity : element.quantity + 1 };
          }
          return element;
        })
      );
      return;

    }
    if (operation == "reduce") {
      setSelectedObjects(
        prevObjects.map((element) => {
          if (element.productId == value.productId) {
            return { ...value, quantity: element.quantity + -1 };
          }
          return element;
        })
      );
      return;
    }
  };

  const countHowManyProductsHaveQuantity = (products) => {
    let count = 0;
    products.map((product) => {
      if (product.quantity > 0) {
        count++;
      }
    });
    setCount(count);
  };

  const onSubmit = () => {
    params?.onListPromotionUpdate(selectedObjects);
  };

  useEffect(() => {
    setSelectedObjects(params?.products);
  }, [params?.products]);

  useEffect(() => {
    countHowManyProductsHaveQuantity(selectedObjects);
  }, [selectedObjects]);


  return (
    <div style={{ padding: "32px 20px 32px 20px" }}>
      <ShareModalStructureCloseIcon top={"16px"} />
      <div className=" ">
        <p className=" s20 museo-sans-700 mb-4">
          <FormattedMessage id="orders.cart.oferts.modal.title" />
        </p>
        <div
          style={{ height: "552px" }}
          className="d-flex flex-column hidden-y-scroll"
        >
          {
            params?.products && selectedObjects.map((product, index) => {
              return (
                <OrdersCartProduct
                  ifValueAPrice={params?.ifValueAPrice}
                  key={index}
                  product={product}
                  onAdd={onChangeArrayValue}
                  onReduce={onChangeArrayValue}
                  secondStyle={true}
                  removeHR={ index == 0}
                />
              );
            })
          }
          <hr className=" m-0 mt-3" />
        </div>
      </div>
      <div
        style={{ marginTop: "32px" }}
        className=" d-flex justify-content-center align-items-center "
      >
        <div
          data-bs-dismiss="modal"
          aria-label="Close"
          style={{ height: "40px" }}
          onClick={onSubmit} 
          className=" d-flex justify-content-center align-items-center museo-sans-500 s14 skyBg text-white btnLoginWidth rounded-3 pointer"
        >
          <FormattedMessage id="orders.cart.oferts.modal.add" values={{x: count}} />
        </div>
      </div>
    </div>
  );
}
