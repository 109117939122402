import ShareModalStructure from "../../../../common/Share/ShareModalStructure";
import ShareModalWarningMessage from "../../../../common/Share/ShareModalWarningMessage";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGCamera } from "../../icons/ic-camera.svg";
import { ReactComponent as SVGImages } from "../../icons/ic-images.svg";

export default function EcommerceDashboardModalProfileStartNav() {

  const onClickActivateConfiguration = () => {
    const clickMe = document.getElementById("configuration-button-start");
    localStorage.setItem("profile-ecommerce", "true");
    clickMe.click();
    setTimeout(() => {
      const clickMeStepTwo = document.getElementById("configuration-button-start-profile-change");
      clickMeStepTwo?.click();
    }, 100);
  };

  return (
    <>
      <ShareModalStructure
        maxWidth={"496px"}
        id={"ecommerce-profile-picture-first-step"}
      >
        <ShareModalWarningMessage
          onButtonConfirmedClick={() => {
            const clickMe = document.getElementById(
              "ecommerce-profile-picture-second-step-activator"
            );
            clickMe.click();
          }}
          buttonIsCanceled={true}
        />
      </ShareModalStructure>
      <ShareModalStructure
        maxWidth={"496px"}
        borderRadius={"12px"}
        id={"ecommerce-profile-picture-second-step"}
      >
        <EcommerceModalProfilePicture onClick={onClickActivateConfiguration} />
      </ShareModalStructure>
      <div
        data-bs-toggle="modal"
        data-bs-target="#ecommerce-profile-picture-first-step"
        id="ecommerce-profile-picture-first-step-activator"
      ></div>
      <div
        data-bs-toggle="modal"
        data-bs-target="#ecommerce-profile-picture-second-step"
        id="ecommerce-profile-picture-second-step-activator"
      ></div>
    </>
  );
}

export function EcommerceModalProfilePicture(params) {
  return (
    <div className="d-flex flex-column align-items-center text-center museo-sans-600 s16 ">
      <div className="d-flex justify-content-end align-items-center w-100">
        <div
          data-bs-dismiss="modal"
          aria-label="Close"
          style={{ width: "18px", height: "18px", right: "4px" }}
          className=" position-relative svg-force-size pointer"
        >
          <SVGClose />
        </div>
      </div>
      <div style={{ paddingBottom: "32px" }} className="w-100 s20 ">
        <p>Foto de perfil</p>
      </div>
      <div className="w-100 skyColor">
        <hr className="m-0" />
        <div
          onClick={params.onClick}
          style={{ padding: "24px" }}
          className="d-flex justify-content-center text-center align-items-center gap-1 pointer  "
        >
          <div style={{ top: "-2px" }} className=" svg-force-size position-relative">
            <SVGCamera />
          </div>
          <p>Tomar foto</p>
        </div>
        <hr className="m-0" />
        <div
          onClick={params.onClick}
          style={{ padding: "24px" }}
          className="d-flex justify-content-center text-center align-items-center gap-1 pointer "
        >
          <div style={{ top: "-2px" }} className=" svg-force-size position-relative">
            <SVGImages />
          </div>
          <p>Elegir foto de la galeria</p>
        </div>
      </div>
    </div>
  );
}
