import OrdersCard from './OrdersCard';
import { ReactComponent as SVGPeopleGroup } from "../../Icons/People/People-Group.svg";
import { ReactComponent as SVGPeople } from "../../Icons/People/People.svg";
import { ReactComponent as SVGEDB } from "../../../common/svgs/clients/edb.svg";

const OrdersCardContainer = (params) => {

    const cardsData = [
        { icon: <SVGPeopleGroup />, title: "Crear Orden Cliente individual", isDisabled: true },
        { icon: <SVGEDB />, title: "Crear Orden Cliente EDB", isDisabled: true },
        { icon: <SVGPeople />, title: "Crear Orden Personal", isDisabled: false, type: "personal" },
    ];

    return (
        <div className="mt-5 width-container-card">
           <div className="row g-4 justify-content-between">
                {cardsData.map((card, index) => (
                    <div className="col-md-4" key={index}>
                        <OrdersCard icon={card.icon} title={card.title} onClick={() => params?.onCardClick(card)} isDisabled={card.isDisabled} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OrdersCardContainer;
