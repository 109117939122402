import React from 'react';
import "./OrdersCard.css";
import { useSelector } from 'react-redux';

const OrdersCard = ({ title, onClick, icon, isDisabled }) => {
    const isLoading = useSelector((state) => state.orderData.loading);
    return (
        <>
            {isLoading ? (
                <div
                    style={{ height: "112px" }}
                    className="rounded-3 brightGrayBg lightui1-shimmer"
                ></div>
            ) : (
                <div
                    className={`custom-card ${isDisabled ? "disabled-card" : ""}`}
                    onClick={!isDisabled ? onClick : undefined}
                >
                    {icon}
                    <h5 className="custom-title">{title}</h5>
                </div>
            )}
        </>
    );
};

export default OrdersCard;
