import { ReactComponent as SVGArrowUp } from "../../common/svgs/ic-chevron-up-sm.svg";
import { ReactComponent as SVGArrowDown } from "../../common/svgs/ic-chevron-down-sm.svg";
import { ReactComponent as SVGCopy } from "../../common/svgs/ic-copy-md.svg";
import { ReactComponent as SVGExternalLink } from "../../common/svgs/ic-external-link-sm.svg";
import ModalClipboardDetails from "./ModalClipboardDetails";

export default function DetailsHasShipment({
  elm,
  show,
  close,
  personalSale,
  hideOnPrint,
  onDetailsToggler,
  togglerDetail
}) {

  const changeToggler = () => {
    onDetailsToggler(!togglerDetail)
  };

  const doThis = async () => {
    try {
      await navigator.clipboard.writeText(
        personalSale
          ? elm?.trackingInfo?.trackingNumber
          : elm?.transactionSummary.trackingInfo?.trackingNumber
      );
      close(true);
    } catch (error) {}
  };

  const goToDhl = () => {
    window.open(
      personalSale
        ? elm?.trackingInfo?.packageTrackingURL
        : elm?.transactionSummary?.trackingInfo?.packageTrackingURL
    );
  };

  const checkIfEmpty = (isPersonalSale) => {
    if (isPersonalSale) {
      return elm?.trackingInfo ? true : false;
    } else {
      return elm?.transactionSummary.trackingInfo ? true : false;
    }
  };

  return (
    <>
      {checkIfEmpty(personalSale) && (
        <>
          <ModalClipboardDetails show={show} close={close} />
          <div
            style={{
              marginTop: "24px",
            }}
            className="d-flex flex-column"
          >
            <div className="d-flex flex-row justify-content-between align-items-center">
              <p className="museo-sans-700 s16">Envío</p>
              {!hideOnPrint && (
                <button
                  onClick={changeToggler}
                  className="skyColor border-0 bg-transparent museo-sans-700 s14"
                >
                  Ver detalle{" "}
                  {!togglerDetail ? <SVGArrowDown /> : <SVGArrowUp />}{" "}
                </button>
              )}
            </div>
            {togglerDetail && (
              <>
                <div
                  className="d-flex flex-row"
                  style={{ marginTop: "17px", marginBottom: "24px" }}
                >
                  <p
                    className="museo-sans-500 s14"
                    style={{ color: "#908F9A" }}
                  >
                    Envío a cargo de{" "}
                  </p>{" "}
                  <a
                    className="museo-sans-700 s14"
                    style={{ color: "#5AAFF1" }}
                  >
                    &nbsp;
                    {personalSale
                      ? elm?.trackingInfo?.courierName
                      : elm?.transactionSummary.trackingInfo?.courierName}
                  </a>
                </div>
                <div className="d-flex flex-row">
                  <div
                    className="d-flex flex-column"
                    style={{ height: "62px", gap: "8px" }}
                  >
                    <div>
                      <p className="museo-sans-700 s12">
                        Código de seguimiento
                      </p>
                    </div>
                    <div className="d-flex flex-row " style={{ gap: "32px" }}>
                      <button
                        onClick={doThis}
                        style={{
                          height: "40px",
                          width: "392px",
                          color: "#171A22",
                        }}
                        className="bg-transparent skyBorder museo-sans-500 s14 rounded-3 d-flex flex-row justify-content-between align-items-center"
                      >
                        {" "}
                        {personalSale
                          ? elm?.trackingInfo?.trackingNumber
                          : elm?.transactionSummary.trackingInfo
                              ?.trackingNumber}{" "}
                        {!hideOnPrint && <SVGCopy />}
                      </button>

                      {!hideOnPrint && (
                        <button
                          onClick={goToDhl}
                          className="d-flex justify-content-center align-items-center skyColor bg-transparent skyBorder museo-sans-700 s14 rounded-3"
                          style={{
                            width: "184px",
                            height: "40px ",
                            gap: "7px",
                          }}
                        >
                          Seguir envío
                          <SVGExternalLink />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {!personalSale && <hr></hr>}
          </div>
        </>
      )}
    </>
  );
}
