import React, { useState } from 'react';
import "./MouthComponent.css";
import logos from "../../../../../../Imgs/logos.png";
const MouthComponent = () => {

  
  return (
    <div>
      <p className="museo-sans-600 container-img   s20">
        Pago por bocas de recaudación
      </p>
      <p className="museo-sans-400 container-img margin-top-payment   s14"style={{ color: "#908F9A", padding:'0px 44px',textAlign:'center' }}>
      ¡Sólo tenés que buscar tu código de pago electrónico
       en la web! Estas son las sucursales permitidas para pagar:
      </p>
      <div className='container-logos'>
      <img  src={logos} alt="logos" />
      </div>
    </div>
  );
};

export default MouthComponent;