import React, { useEffect, useState } from 'react';
import OrdersNotification from '../../../Orders/Presentation/components/OrdersNotification';
import { ReactComponent as SVGError } from "../../../Orders/Icons/Notification/Delete-One.svg";
import { ReactComponent as SVGMoney } from "../../Icons/MoneyMedal.svg";
import PaymentMethodModal from './PaymentMethosModal';
import { useSelector, useDispatch } from 'react-redux';
import ModalClipboard from '../../../common/Share/ModalClipboard';
import JustCoinsModal from './JustCoinsModal';
import { normalizedStr, formatNumber, payments } from '../../common';
import { fetchBalance } from '../../Framework/redux/PaymentsSideEffect';

const PaymentMethod = ({ setNewTotalAmount, selectedPayment, setSelectedPayment }) => {
    const [selectedMethods, setSelectedMethods] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showJustCoinsModal, setShowJustCoinsModal] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [copyToggler, setCopyToggler] = useState(false);
    const [selectedJustCoins, setSelectedJustCoins] = useState("")
    const orderSummary = useSelector(
        (state) => state.orderData.orderSummary.cartSummary
    );

    const maxPercentage = 0.9;
    const [totalBalance, setTotalBalance] = useState("");
    const [availableCoins, setAvailableCoins] = useState("");
    const [hasBalance, setHasBalance] = useState(false);
    const [hasNegativeBalance, setHasNegativeBalance] = useState(false);
    const [hasJustCoins, setHasJustCoins] = useState(false);
    const firstPayment = false;
    const [maxAmount, setMaxMount] = useState("")
    const [partialAmount, setPartialAmount] = useState(orderSummary?.totalAmount)
    const [isBalanceEnough, setIsBalanceEnough] = useState(false)
    const dispatch = useDispatch();


    useEffect(() => {
        onStartUp();
    }, []);

    useEffect(() => {

        const isBalanceEnough = normalizedStr(totalBalance) >= normalizedStr(orderSummary?.totalAmount);
        const isJustCoinsGreaterThanTotal = normalizedStr(availableCoins) >= normalizedStr(orderSummary?.totalAmount);
        setIsBalanceEnough(isBalanceEnough);
        if (firstPayment) {
            setSelectedMethods(hasBalance ? ["balance"] : []);
            return;
        }

        if (hasBalance || hasNegativeBalance) {
            hasBalance && setShowMessage(true);
            if (hasJustCoins && isJustCoinsGreaterThanTotal) {
                setMaxMount((normalizedStr(orderSummary?.totalAmount) * maxPercentage).toLocaleString());
            }
            if (isBalanceEnough) {
                setSelectedMethods(hasJustCoins ? ["balance", "just-coins"] : ["balance"]);
            } else {
                setSelectedMethods(hasJustCoins ? ["balance", "mercado-pago", "just-coins"] : ["balance", "mercado-pago"]);
                setSelectedPayment("mercado-pago")
            }
        }/*  else if (hasNegativeBalance) {
            setSelectedMethods(["balance"]);
            setSelectedPayment("")
        } */ else {
            if (hasJustCoins && isJustCoinsGreaterThanTotal) {
                setMaxMount((normalizedStr(orderSummary?.totalAmount) * maxPercentage).toLocaleString());
            }
            setSelectedMethods(hasJustCoins ? ["mercado-pago", "just-coins"] : ["mercado-pago"]);
            setSelectedPayment("mercado-pago")
        }
    }, [hasBalance, totalBalance, hasNegativeBalance, /* availableCoins,  */hasJustCoins, partialAmount]);

    const onStartUp = async () => {
        const { payload } = await dispatch(fetchBalance({}));
        setTotalBalance(formatNumber(payload.currentAccount.toLocaleString()))
        setHasBalance(payload.currentAccount > 0)
        setHasNegativeBalance(payload.currentAccount < 0)
        setAvailableCoins(formatNumber(payload.justCoins.toLocaleString()))
        setMaxMount(formatNumber(payload.justCoins.toLocaleString()))
        setHasJustCoins(payload.justCoins)
    }

    useEffect(() => {
        setPartialAmount(getBalance())
    }, [selectedJustCoins]);

    useEffect(() => {
        setPartialAmount(getPartialAmount())
    }, [isBalanceEnough, hasNegativeBalance]);

    const onOpenCopyModal = () => {
        setCopyToggler(true);
    };

    const onCloseCopyModal = () => {
        setCopyToggler(false);
    };

    const handleSelection = (value) => {
        setSelectedPayment(value)
        if (hasNegativeBalance && !orderSummary?.newTotalAmount) {
            let newTotalAmount = normalizedStr(orderSummary?.totalAmount) + -(normalizedStr(totalBalance))
            let newTotalAmountStr = formatNumber(newTotalAmount.toLocaleString())
            setNewTotalAmount(newTotalAmountStr)
        } else if ((!isBalanceEnough || hasNegativeBalance) && hasJustCoins) {
            setSelectedMethods(["balance", value, "just-coins"])
        } else if ((!isBalanceEnough || hasNegativeBalance) && !hasJustCoins) {
            setSelectedMethods(["balance", value])
        }
        else {
            setSelectedMethods([value])
        }


    }

    const getBalance = () => {
        let numericTotalAmount = normalizedStr(orderSummary?.totalAmount)
        let numericTotalBalance = normalizedStr(totalBalance)
        let numericSelectedJustCoins = normalizedStr(selectedJustCoins)

        return (numericTotalAmount - numericSelectedJustCoins - numericTotalBalance).toLocaleString()
    }

    const getPartialAmount = () => {
        let numericTotalAmount = normalizedStr(orderSummary?.totalAmount)
        let numericTotalBalance = normalizedStr(totalBalance)
        if (hasJustCoins && selectedJustCoins !== "") {
            let numericSelectedJustCoins = normalizedStr(selectedJustCoins)
            return (numericTotalAmount - numericTotalBalance - numericSelectedJustCoins).toLocaleString();
        } else {
            return (numericTotalAmount - numericTotalBalance).toLocaleString();
        }
    }

    const getPartialBalance = () => {
        let numericTotalBalance = normalizedStr(orderSummary?.totalAmount)
        let numericSelectedJustCoins = normalizedStr(selectedJustCoins)

        return (numericTotalBalance - numericSelectedJustCoins).toLocaleString();
    }

    const showMethodSelected = (method) => {
        return (
            <>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center gap-2'>
                        <img src={payments[method]?.img} width={48} height={32} alt={payments[method]?.title} />
                        <div>
                            <p className='museo-sans-500 blackColor s14' style={{ lineHeight: "20px" }}>{payments[method]?.title}</p>
                            {method === "balance" && <p className='dm-sans-500 grayColor s12'>{`${hasNegativeBalance ? `Saldo en deuda` : `$ ${formatNumber(totalBalance)} disponible`}`}</p>}
                            {method === "just-coins" && <p className='dm-sans-500 grayColor s12'>
                                <p>
                                    {`${selectedJustCoins ? `${formatNumber(maxAmount)} disponible` : `Tienes ${formatNumber(maxAmount)} - Utilízalas para completar el pago`}`}
                                </p>

                            </p>}
                        </div>
                    </div>
                    <div className='dm-sans-500 blackColor s14'>
                        {method === "just-coins" ?
                            selectedJustCoins ?
                                <div className='d-flex' style={{ gap: "12px" }}>
                                    <p className='skyColor pointer museo-sans-700'
                                        data-bs-toggle="modal"
                                        data-bs-target="#paymentJustCoins"
                                        onClick={() => setShowJustCoinsModal(true)}>
                                        Modificar monto
                                    </p>
                                    <p>{formatNumber(selectedJustCoins)}</p>
                                </div>

                                :
                                <p className='skyColor pointer museo-sans-500'
                                    data-bs-toggle="modal"
                                    data-bs-target="#paymentJustCoins"
                                    onClick={() => setShowJustCoinsModal(true)}>
                                    Utilizar
                                </p>
                            :
                            method === "balance" ?
                                <p className={hasNegativeBalance ? "lightRedColor" : ''}>
                                    {`${hasNegativeBalance ? `$ ${formatNumber(totalBalance)}` : `$ ${formatNumber(isBalanceEnough ? selectedJustCoins !== '' ? getPartialBalance() : orderSummary?.totalAmount : totalBalance)}`}`}
                                </p>
                                :
                                <p>$ {formatNumber(isBalanceEnough && !hasNegativeBalance ? orderSummary?.totalAmount : getPartialAmount())}</p>
                        }
                    </div>
                </div>
            </>
        )
    }

    const handleSelectedJustCoins = (value) => {
        setSelectedJustCoins(value !== '0' ? value : '')
    }
    return (
        <>
            <div>
                <div className='d-flex align-items-center mb-4 museo-sans-700 gap-3'>
                    <p className='s24'>Forma de pago</p>
                    {selectedMethods.length !== 0 && !isBalanceEnough && <p className='skyColor s14 pointer' data-bs-toggle="modal"
                        data-bs-target="#paymentMethod">Modificar</p>}
                </div>
                {selectedMethods.length ?
                    <div className='container-card'>
                        {selectedMethods.map((method, index) => (
                            <div key={index}>
                                {showMethodSelected(method)}
                                {index < selectedMethods.length - 1 && <hr />}
                                {showMessage && index === selectedMethods.length - 1 && /* selectedJustCoins === '' && */
                                    <div className="kit-notification-container donutHeaderBg p-3 d-flex align-items-center justify-content-between mt-4">
                                        <div style={{ marginRight: "0px" }}>
                                            <SVGMoney />
                                        </div>
                                        <p className='museo-sans-500 s14 blueColor'>
                                            Ya que tienes Saldo en cuenta disponible, este se selecciona automáticamente como medio de pago.
                                        </p>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                    :
                    <div className='container-card container-payment-method'>
                        <button
                            data-bs-toggle="modal"
                            data-bs-target="#paymentMethod"
                            onClick={() => setShowModal(true)}
                            style={{ height: "40px" }} className="d-flex mb-4 w-100 s14 justify-content-center align-items-center museo-sans-700 skyColor bg-transparent skyBorder rounded">
                            Elegir medio de pago
                        </button>

                        <OrdersNotification
                            icon={<SVGError />}
                            text="Debes seleccionar un medio de pago"
                            type="error" />
                    </div>
                }

            </div>

            <ModalClipboard show={copyToggler} close={onCloseCopyModal} title={"Codigo de barras copiado"} />

            <PaymentMethodModal selectedPayment={selectedPayment} setSelectedPayment={handleSelection} open={onOpenCopyModal} showModal={showModal} setShowModal={setShowModal} isBalanceEnough={isBalanceEnough} partialAmount={partialAmount} selectedMethods={selectedMethods} totalBalance={totalBalance} selectedJustCoins={selectedJustCoins} hasJustCoins={hasJustCoins} />

            <JustCoinsModal showModal={showJustCoinsModal} setShowModal={setShowJustCoinsModal} availableCoins={availableCoins} maxAmount={maxAmount} setSelectedJustCoins={handleSelectedJustCoins} isBalanceEnough={isBalanceEnough} />
        </>
    );
}
export default PaymentMethod;
