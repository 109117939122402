import { FormattedMessage } from "react-intl";
import { useState } from "react";
import OrdersCartProduct from "./OrdersCartProduct";
import ShareModalIndex from "../../../common/Share/ShareModalIndex";

export default function OrdersCartOfertsFeature(params) {
  const [toggler, setToggler] = useState(true);

  const onToggle = () => {
    setToggler(!toggler);
  };

  const returnFilteredProducts = () => {
    if (!params?.products) {
      return [];
    }
    const filteredProducts = params?.products;
    filteredProducts.sort((a, b) => b.quantity - a.quantity);
    return filteredProducts;
  };

  const returnProductsWithQuantity = (showLength) => {
    const products = returnFilteredProducts();
    const productsWithQuantity = products.filter(
      (product) => product.quantity > 0
    );
    console.log(productsWithQuantity);
    if (showLength) {
      return productsWithQuantity?.length ?? 0;
    }
    return productsWithQuantity?.length > 4
      ? productsWithQuantity
      : returnFilteredProducts().slice(0, 4);
  };

  return (
    <>
      {returnFilteredProducts().length > 0 && (
        <div
          style={{ boxShadow: "0px 2px 6px 0px rgba(219, 219, 244, 0.50)" }}
          className="d-flex flex-column rounded-3 lightWhiteBg gap-3 p-3 mt-4"
        >
          <p className=" position-relative s20 museo-sans-600">
            <FormattedMessage id="orders.cart.oferts.title.and.promo" />
            {returnProductsWithQuantity(true) > 0 && (
              <div
                style={{
                  background: "#D18DEF",
                  top: "4px",
                  left: "225px",
                  width: "24px",
                  height: "24px",
                }}
                className=" s12 dm-sans-500 position-absolute text-white d-flex justify-content-center align-items-center rounded-circle"
              >
                x{returnProductsWithQuantity(true)}
              </div>
            )}
          </p>
          <div className="d-flex justify-content-between">
            <p className=" skyColor museo-sans-600 s14">
              <FormattedMessage
                id="orders.cart.oferts.list"
                values={{ x: returnFilteredProducts()?.length }}
              />
            </p>
            <div
              style={{
                transform: !toggler ? "rotate(180deg)" : "rotate(0deg)",
              }}
              onClick={onToggle}
              className="pointer position-relative"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M7.99935 6.35001L3.09935 11.25C2.93268 11.4167 2.73546 11.4972 2.50768 11.4917C2.2799 11.4861 2.08268 11.4 1.91602 11.2333C1.74935 11.0667 1.66602 10.8695 1.66602 10.6417C1.66602 10.4139 1.74935 10.2167 1.91602 10.05L7.04935 4.93334C7.18268 4.80001 7.33268 4.70001 7.49935 4.63334C7.66602 4.56667 7.83268 4.53334 7.99935 4.53334C8.16602 4.53334 8.33268 4.56667 8.49935 4.63334C8.66602 4.70001 8.81602 4.80001 8.94935 4.93334L14.0827 10.0667C14.2493 10.2333 14.3299 10.4278 14.3244 10.65C14.3188 10.8722 14.2327 11.0667 14.066 11.2333C13.8993 11.4 13.7021 11.4833 13.4743 11.4833C13.2466 11.4833 13.0493 11.4 12.8827 11.2333L7.99935 6.35001Z"
                  fill="#5AAFF1"
                />
              </svg>
            </div>
          </div>
          {toggler && (
            <>
              {returnProductsWithQuantity().map((product, index) => {
                return (
                  <OrdersCartProduct
                    ifValueAPrice={params?.ifValueAPrice}
                    key={index}
                    product={product}
                    onAdd={params?.onPromotionsChange}
                    onReduce={params?.onPromotionsChange}
                    secondStyle={true}
                    removeHR={index == 0}
                  />
                );
              })}

              {returnFilteredProducts().length > 4 && (
                <ShareModalIndex id={"modal-cart-oferts-list"}>
                    <div className="d-flex gap-1">
                    <p className=" skyColor s14 museo-sans-600">
                      <FormattedMessage id="orders.cart.oferts.view.all" />
                      
                    </p>
                    <div style={{top: "-3px"}} className=" position-relative" >

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M12.1163 8.6667H1.99967C1.81079 8.6667 1.65245 8.60281 1.52467 8.47503C1.3969 8.34725 1.33301 8.18892 1.33301 8.00003C1.33301 7.81114 1.3969 7.65281 1.52467 7.52503C1.65245 7.39725 1.81079 7.33337 1.99967 7.33337H12.1163L9.53301 4.73337C9.41079 4.61114 9.3469 4.45837 9.34134 4.27503C9.33578 4.0917 9.39967 3.93337 9.53301 3.80003C9.65523 3.67781 9.81079 3.6167 9.99967 3.6167C10.1886 3.6167 10.3441 3.67781 10.4663 3.80003L14.1997 7.53337C14.2663 7.60003 14.3136 7.67226 14.3413 7.75003C14.3691 7.82781 14.383 7.91114 14.383 8.00003C14.383 8.08892 14.3691 8.17226 14.3413 8.25003C14.3136 8.32781 14.2663 8.40003 14.1997 8.4667L10.4663 12.2C10.3441 12.3223 10.1913 12.3834 10.008 12.3834C9.82467 12.3834 9.66634 12.3223 9.53301 12.2C9.39967 12.0667 9.33301 11.9084 9.33301 11.725C9.33301 11.5417 9.39967 11.3834 9.53301 11.25L12.1163 8.6667Z"
                          fill="#5AAFF1"
                        />
                      </svg>
                    </div>
                </div>
                  </ShareModalIndex>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
