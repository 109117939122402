import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MercadoPagoCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    
  }, [location]);

  return <div>Verificando cuenta...</div>;
};

export default MercadoPagoCallback;
