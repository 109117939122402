import { createReducer } from "@reduxjs/toolkit";
import { fetchVirtualEcommerce, fetchVirtualEcommerceTopics, updateVirtualEcommerce } from "./EcommerceSideEffect";

const initialState = { 
    ecommerceData: {
        data: null,
        isLoading: false,
        isError: false,
    },
    ecommerceDataTopics: {
        data: null,
        isLoading: false,
        isError: false,
    },
}

const ecommerceReducer = createReducer(initialState, (builder) => {
    builder
    .addCase(fetchVirtualEcommerce.pending, (state, action) => {
        state.ecommerceData.isLoading = true;
        state.ecommerceData.isError = false;
    })
    .addCase(fetchVirtualEcommerce.fulfilled, (state, action) => {
        state.ecommerceData.data = action.payload;
        state.ecommerceData.isLoading = false;
    })
    .addCase(fetchVirtualEcommerce.rejected, (state, action) => {
        state.ecommerceData.isLoading = false;
        state.ecommerceData.isError = true;
    })

    .addCase(fetchVirtualEcommerceTopics.pending, (state, action) => {
        state.ecommerceDataTopics.isLoading = true;
        state.ecommerceDataTopics.isError = false;
    })
    .addCase(fetchVirtualEcommerceTopics.fulfilled, (state, action) => {
        state.ecommerceDataTopics.data = action.payload;
        state.ecommerceDataTopics.isLoading = false;
    })
    .addCase(fetchVirtualEcommerceTopics.rejected, (state, action) => {
        state.ecommerceDataTopics.isLoading = false;
        state.ecommerceDataTopics.isError = true;
    })
})

export default ecommerceReducer;