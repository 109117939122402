import React, { useState, useEffect } from "react";
import "./ordersKit.css";
import vectorIcon from "../../../imgs/Vector.png";
import edbIcon from "../../../imgs/EDB.png";
import gropuIcon from "../../../imgs/Group.png";
import profileIcon from "../../../imgs/Profile.png";
import vectorEventIcon from "../../../imgs/Chevron-Right.png";
import notOrdensIcon from "../../../imgs/notOrdens.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isOrder } from "../../../Framework/redux/OrdersActions";
import { ModalProducts } from "../modals/modalProducts/modalProducts";
import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories";
export default function OrdersKit({
  title,
  subTitle,
  titleNot,
  subtitleNot,
  list,
  isOrders = true,
  titleTable,
}) {
  const navigate = useNavigate();
  const orderData = useSelector((state) => state.orderData);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userModal, setUserModal] = useState({});
  const getIcon = (tipo) => {
    let icon = "";
    if (tipo.toLowerCase() === "personal") {
      icon = profileIcon;
    } else if (
      tipo.toLowerCase() === "client" ||
      tipo.toLowerCase() === "cliente"
    ) {
      icon = gropuIcon;
    } else if (tipo.toLowerCase() === "edb") {
      icon = edbIcon;
    }
    return icon;
  };
  const navigationTo = () => {
    dispatch(isOrder(isOrders));
    navigate(`/dashboard/orders/listado-kit`, { state: { isOrders } });
  };
  const getDate = (date) => {
    return date.split("T")[0];
  };
  const getStatus = (status) => {
    return status.split("EL")[0];
  };

  const closeModal = () => setIsModalOpen(false);
  const goModalProducts = async (id,type,date,img) => {
    if(isOrders){
      const countryCode = localStorage.getItem("country");
      const clientId = localStorage.getItem("clientId");
      const response = await Repository.OrderingRepository.getOrderProducts(clientId,countryCode,id);
      console.log(response)
      setUserModal({
        id:id,
        type:type,
        date:date,
        img:img,
        products:response
      })
      setIsModalOpen(true);
    }
  };
  return (
    <>
      <div className="title-orders museo-sans-500 d-flex align-items-center">
        {title}
        <img src={vectorIcon} alt="vector" className="vector-icon" />
        {list.length > 0 && (
          <span onClick={() => navigationTo()}>{subTitle}</span>
        )}
      </div>
      <div className="container-orderkit">
        <div style={{ display: "flex" }}>
          <div className="titleTable orden museo-sans-500 s12">
            {titleTable}
          </div>
          <div className="titleTable title-table-center museo-sans-500 s12">
            ESTADO
          </div>
          <div className="titleTable title-table-center museo-sans-500 s12">
            FECHA
          </div>
          <div className="titleTable title-table-center museo-sans-500 s12">
            TIPO
          </div>
          <div className="titleTable title-table-end museo-sans-500 s12">
            TOTAL
          </div>
        </div>
        {list.length > 0 ? (
          <>
            {list.map((order, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  marginTop: "26px",
                  alignItems: "baseline",
                  borderBottom:
                    index == list.length - 1 ? "" : "1px solid #DDDDDD",
                  paddingBottom: index == list.length - 1 ? "" : "16px",
                }}
              >
                {isOrders ? (
                  <div className="titleTable orden dm-sans-medium s12">
                    <span className="museo-sans-700 s14">{order.client} </span>{" "}
                    ID: {order.id}
                  </div>
                ) : (
                  <div className="titleTable orden museo-sans-700 s14 ">
                    <span>Pedido ID: {order.id}</span>
                  </div>
                )}

                <div
                  className="titleTable title-table-center museo-sans-500"
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    /*color:
                        order.estado == "SIN PAGAR"
                        ? "#DCB632"
                        : "#16BE7D",*/
                  }}
                >
                  {!isOrders && getStatus(order.status)}
                </div>
                <div className="titleTable title-table-center museo-sans-500">
                  {isOrders ? order.startDate : getDate(order.startDate)}
                </div>
                <div
                  className="titleTable title-table-center museo-sans-500"
                  style={{ fontSize: "12px" }}
                >
                  <img
                    src={getIcon(order.type)}
                    alt="profile"
                    className="profile-icon"
                    style={{ marginRight: "5px" }}
                  />
                  {order.type.toUpperCase()}
                </div>
                <div className="titleTable title-table-end total dm-sans-medium s14">
                  {order.amount}
                </div>
                <div style={{cursor:'pointer'}} onClick={() => goModalProducts(order.id,order.type.toUpperCase(),order.startDate,getIcon(order.type))}>
                  <img
                    src={vectorEventIcon}
                    alt="event"
                    className="vectorEvent-icon"
                  />
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="container-not-ordens ">
            <img
              src={notOrdensIcon}
              alt="not-ordens"
              className="not-ordens-icon"
            />
            <h5 className="museo-sans-700">{titleNot}</h5>
            <p className="museo-sans-500">{subtitleNot}</p>
          </div>
        )}
      </div>
      <ModalProducts isOpen={isModalOpen} onClose={closeModal} data={userModal} />
    </>
  );
}
