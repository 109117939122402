import { useSelector } from "react-redux";
import { FormattedMessage } from "../../../../../node_modules/react-intl/index";
import { useNavigate } from "../../../../../node_modules/react-router-dom/dist/index";
import { ReactComponent as WarningSVG } from "../../Presentation/icons/WarningRed.svg";

export default function EcommerceError() {
  const navigate = useNavigate();

  const ecommerceData = useSelector(
    (state) => state.ecommerceData?.ecommerceData
  );

  return (
    <div style={{marginTop: "150px"}} className=" position-relative d-flex flex-column text-center align-items-center justify-content-center w-100 h-100">
      <div
        style={{ width: "295px" }}
        className="d-flex flex-column gap-4 justify-content-center align-items-center px-3 mx-4"
      >
        <div className=" position-relative d-flex justify-content-center align-items-center" >
          <div style={{top: "4px"}} className=" position-relative " >

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="104"
            height="104"
            viewBox="0 0 104 104"
            fill="none"
          >
            <circle cx="52" cy="52" r="52" fill="#FDF0F0" />
          </svg>
          </div>
          <div className=" position-absolute svg-force-size">
            <WarningSVG />
          </div>
        </div>
        <p style={{width: "350px"}} className=" museo-sans-600 s24">
        {ecommerceData?.data?.response?.data?.message || "Anything goes"}
        </p>
        <div onClick={() => navigate("/dashboard")} className=" pointer d-flex justify-content-center align-items-center rounded-3 btnLoginWidth skyBg text-white s14 museo-sans-600">
          <FormattedMessage id="buttons.back" />
        </div>
      </div>
    </div>
  );
}
