import Vimeo from "@u-wave/react-vimeo";
import { useState } from "react";
import { useMediaPredicate } from "react-media-hook";

import { ReactComponent as SVGx } from "../svgs/ic-x-overlay.svg";
import { ReactComponent as SVGFilledRightChev } from "../svgs/ic-slide-right.svg";
import { ReactComponent as SVGFilledLeftChev } from "../svgs/ic-slide-left.svg";

export default function ShareVimeoHolster(params) {

  const [toggler, setToggler] = useState(false);

  const onToggler = (operation = "close") => {
    if (operation == "close") {
      setToggler(false);
      return;
    }
    if (operation == "open") {
      setToggler(true);
      return;
    }
  };

  return (
    <>
    <VimeoController url={params.url} onClose={() => onToggler("close")} toggleToOpen={toggler} />
    <div
      onClick={() => onToggler("open")}
      style={{ boxShadow: "0px 2px 6px 0px rgba(219, 219, 244, 0.50)" }}
      className="d-flex overflow-hidden rounded-4 s14 lightVioletBorder pointer "
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="64"
          viewBox="0 0 40 64"
          fill="none"
        >
          <rect width="40" height="64" fill="#DDDDDD" />
          <path d="M16 27L23.765 31.992L16 36.984V27Z" fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.5208 26.1223C15.8419 25.947 16.2331 25.961 16.5408 26.1588L24.3058 31.1508C24.592 31.3348 24.765 31.6517 24.765 31.992C24.765 32.3323 24.592 32.6492 24.3058 32.8332L16.5408 37.8252C16.2331 38.023 15.8419 38.037 15.5208 37.8617C15.1997 37.6864 15 37.3498 15 36.984V27C15 26.6342 15.1997 26.2976 15.5208 26.1223ZM17 28.8317V35.1523L21.9158 31.992L17 28.8317Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="d-flex w-100 px-2 museo-sans-700 align-items-center" >
        <p className=" skyColor " >{params.description}</p>
      </div>
    </div>
    
    </>
  );
}

export function VimeoController({url, onClose, toggleToOpen}) {
  const biggerThan1921 = useMediaPredicate("(min-width: 1800px)");

  return (
    toggleToOpen && (
      <div
        style={{ width: "100%", height: "100%", top: "0", left: "0" }}
        className=""
        id="vimeo--displayer"
      >
        <div
          style={{ zIndex: "998" }}
          className="bg-black d-block position-absolute opacity-75 w-100 h-100 pointer "
          onClick={onClose}
        ></div>
        <VimeoRedCloseZone biggerThan1921={biggerThan1921} onClose={onClose} />
        <div
          onClick={onClose}
          style={
            biggerThan1921
              ? {
                  zIndex: "999991",
                  top: "42px",
                  right: "42px",
                  width: "42px",
                  height: "42px",
                }
              : {
                  zIndex: "999991",
                  top: "10px",
                  right: "10px",
                  width: "32px",
                  height: "32px",
                }
          }
          className="position-absolute pointer whiteColor pointer svg-force-size "
        >
          <SVGx />
        </div>
        <VimeoHolder reduxStoreVimeo={url} biggerThan1921={biggerThan1921} />
      </div>
    )
  );
}

export function VimeoHolder({ reduxStoreVimeo, biggerThan1921 }) {

  return (
    <div
      id="vimeo--holder"
      className=" shadow position-relative vh-100 d-flex justify-content-center mt-2"
      style={{ zIndex: "999", alignItems: "center" }}
    >
      <div
        style={
          biggerThan1921
            ? { width: "538px", height: "896px" }
            : { width: "338px", height: "696px" }
        }
        className=" position-relative"
      >
        <div
          style={
            biggerThan1921
              ? {
                  width: "508px",
                  height: "961px",
                  left: "15px",
                  justifyItems: "center",
                }
              : { width: "338px", height: "696px" }
          }
          className=" position-absolute "
        >
          <div
            style={
              biggerThan1921
                ? { zIndex: "900", top: "15px" }
                : { zIndex: "900", top: "60px" }
            }
            className=" d-flex justify-content-center  w-100 position-absolute"
          >
            <Vimeo
              speed={true}
              showTitle={true}
              showPortrait={false}
              controls={true}
              autoplay={false}
              showByline={true}
              video={`${reduxStoreVimeo}`}
            />
          </div>
        </div>
      </div>
      
    </div>
  );
}

export function VimeoRedCloseZone({ onClose, biggerThan1921 }) {

  return (
    <>
      <div
        onClick={onClose}
        style={
          biggerThan1921
            ? { width: "33%", zIndex: "99999" }
            : { width: "26%", zIndex: "99999" }
        }
        className=" position-absolute  h-100 "
      ></div>
      <div
        onClick={onClose}
        style={
          biggerThan1921
            ? { width: "33%", zIndex: "99999", right: "0px" }
            : { width: "26%", zIndex: "99999", right: "0px" }
        }
        className=" position-absolute  h-100 "
      ></div>
    </>
  );
}
