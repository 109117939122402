export default function OrderCartImageContainer(params) {
  return (
    <div style={{ padding: "9px 6px 0px 0px" }} className=" position-relative">
      <img src={params?.imageUrl} className=" rounded-3" style={{ width: "58px", height: "58px" }}></img>
      {params?.hasAmount && (
        <div
          style={{
            background: "#D18DEF",
            top: "0px",
            right: "0px",
            width: "24px",
            height: "24px",
          }}
          className=" s12 dm-sans-500 position-absolute text-white d-flex justify-content-center align-items-center rounded-circle"
        >
          x{params?.hasAmount}
        </div>
      )}
    </div>
  );
}
