import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import { ShareRadio } from "../../../../common/Share/ShareRadio";

export default function EcommerceFormModalSelect(params) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const scrollbarRef = useRef(null);

  const onSelectedIndex = (index) => {
    setSelectedIndex(index);
  };

  const onSubmitSelected = () => {
    params.onSelect(params.label, selectedIndex);
  };

  return (
    <div className="p-3 m-3">
      <p className=" s20 museo-sans-600"><FormattedMessage id="ecommerce.form.phrase.title" /></p>
      <div ref={scrollbarRef} className="mt-4 mb-3 hidden-y-scroll " style={{height: "364px"}} >
        {params?.list?.map((item, index) => {
          const objectRadios = [
            {
              labelId: `${item.title}`,
              disabled: false,
              active: selectedIndex?.id == item.id,
              onClick: () => onSelectedIndex(item),
            },
          ];

          return (
            <div className="w-100">
              <div className="d-flex gap-2 s14 w-100 museo-sans-400">
                <ShareRadio topAdjustment={"-4px"} radioSize={"20px"} gap={"8px"} arrayRadios={objectRadios} />
              </div>
              {(index != params.list.length -1 ) && <hr style={{margin: "12px 0px"}} className="" />}
            </div>
          );
        })}
      </div>
      <ModalWhiteShadow left={"6%"} bottom={"60px"} width={"85%"} scrollbarRef={scrollbarRef} />
      <div style={{zIndex: 20}} className=" position-relative w-100 pt-3 d-flex justify-content-center align-items-center">
        <div
        onClick={onSubmitSelected}
          data-bs-dismiss="modal"
          aria-label="Close"
          className=" pointer d-flex justify-content-center align-items-center btnLoginWidth skyBg text-white border-0 rounded-3 museo-sans-600 s14"
        >
          <FormattedMessage id="buttons.select" />
        </div>
      </div>
    </div>
  );
}
