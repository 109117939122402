import React, { useState, useEffect } from "react";
import { ReactComponent as SVGClose } from "../../../Icons/Actions/Close.svg";
import { ReactComponent as SVGCalendar } from "../../../Icons/Actions/Calendar.svg";
import { ReactComponent as ArrowRight } from "../../../../common/svgs/ic-arrow-right-md.svg";
import "./listadoOrdersFilterModal.css";
import { useSelector, useDispatch } from "react-redux";
import ClientSelector from './clientSelectorModal';
import CustomSelect from '../../../../common/Share/CustomSelect';
import { setFilter } from '../../../Framework/redux/OrdersActions';
import { getClientList } from '../../../Framework/redux/OrdersSideEffect';
import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories";

const ListadoOrdersFilterModal = ({
    filterModal,
    changeFilterModalApplied,
    setResponseRequest,
    setIsFilter
}) => {
    const orderStatus = [
        { value: "PENDING", label: "Pendiente de pago" },
        { value: "BILLED", label: "Facturado" },
    ];
    const filterSelect = [
        { value: "date.desc", label: "Más nuevo al más antiguo" },
        { value: "date.asc", label: "Más antiguo al más nuevo" },
        { value: "amount.desc", label: "Monto descendente" },
        { value: "amount.asc", label: "Monto ascendente" },
    ];

    const orderData = useSelector((state) => state.orderData);
    const [clients, setClients] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedClients, setSelectedClients] = useState([]);
    const [showClientSelector, setShowClientSelector] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(filterSelect[0]);

    const dispatch = useDispatch();

    useEffect(() => {
        const {
            startDate,
            endDate,
            selectedClients,
            selectedStatus,
            selectedOrder,
        } = orderData.filterOrders;
        setSelectedOrder(selectedOrder);
        setStartDate(startDate);
        setEndDate(endDate);
        setSelectedClients(selectedClients);
        setSelectedStatus(selectedStatus);
    }, [filterModal.isOpen]);

    useEffect(() => {
        getClients();
    }, []);

    const getClients = async () => {
        const clientId = localStorage.getItem("clientId")
        const countryCode = localStorage.getItem("country")

        const { payload } = await dispatch(
            getClientList({ clientId, countryCode })
        );
        setClients(payload.contacts);
    };

    const toggleStatus = (status) => {
        setSelectedStatus((prevSelected) =>
            prevSelected.some(item => item.value === status.value)
                ? prevSelected.filter((s) => s.value !== status.value)
                : [...prevSelected, status]
        );
    };

    const handleClientSelect = (clients) => {
        setSelectedClients(clients);
    };

    const clearAppliedFilters = async () => {
        setShowClientSelector(false)
        setFilter(false)
        clearFilters();
        dispatch(
            setFilter({
                startDate: "",
                endDate: "",
                selectedClients: [],
                selectedStatus: [],
                selectedOrder: filterSelect[0],
            })
        );

        changeFilterModalApplied(false);
        const clientId = localStorage.getItem("clientId")
        const countryCode = localStorage.getItem("country")

        const responseRequest =
            await Repository.PurchaseRepository.getPurchaseRequests(
                clientId,
                countryCode
            );

        setResponseRequest(responseRequest);
    };

    const clearFilters = () => {
        setStartDate("");
        setEndDate("");
        setSelectedClients([]);
        setSelectedStatus([]);
        setSelectedOrder(filterSelect[0]);
    };

    const handleApplyFilters = async () => {
        dispatch(
            setFilter({
                startDate: startDate,
                endDate: endDate,
                selectedClients: selectedClients,
                selectedStatus: selectedStatus,
                selectedOrder: selectedOrder,
            })
        );

        const querys = {
            limit: 10,
            offset: 0,
            sort_by: selectedOrder.value,
            ini_date: startDate.replace(/-/g, ''),
            end_date: endDate.replace(/-/g, ''),
            status: selectedStatus.length === 1 ? selectedStatus[0].value : "ALL",
            client_list: selectedClients.map(client => client.contactId),
        };

        const clientId = localStorage.getItem("clientId")
        const countryCode = localStorage.getItem("country")

        const responseRequest =
            await Repository.PurchaseRepository.getPurchaseRequests(
                clientId,
                countryCode,
                querys
            );

        setResponseRequest(responseRequest);

        clearFilters();
        changeFilterModalApplied(true);
    };

    return (
        <div
            className="modal fade"
            id="filterOrdersModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div
                style={{ width: "496px" }}
                className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-styles"
            >
                <div className="modal-content position-relative modal-content-styles p-4">
                    {!showClientSelector ? (
                        <div>
                            <div className="d-flex justify-content-between align-items-center">
                                <h1 className="m-0 s20">Filtrar</h1>
                                <div
                                    className="pointer"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <SVGClose />
                                </div>
                            </div>

                            <div>
                                <form>
                                    <div className="mt-3">
                                        <label className="form-label museo-sans-700 s14">
                                            Ordenar por
                                        </label>
                                        <CustomSelect
                                            onChange={(e) =>
                                                setSelectedOrder(e)
                                            }
                                            options={filterSelect}
                                            defaultValue={filterSelect[0]}
                                            value={selectedOrder}
                                            isDisabled={false}
                                            width={"100%"}
                                        />
                                    </div>

                                    <div className="d-flex gap-3 mt-3">
                                        <div className="mb-3 w-50">
                                            <label className="form-label museo-sans-700 s14">
                                                Fecha desde
                                            </label>
                                            <div className="date-input-wrapper">
                                                <input
                                                    type="date"
                                                    className="custom-date-input"
                                                    onChange={(e) =>
                                                        setStartDate(e.target.value)
                                                    }
                                                    value={startDate}
                                                    placeholder="Seleccionar"
                                                />
                                                <div
                                                    className="calendar-icon"
                                                    onClick={(e) => {
                                                        e.currentTarget.previousElementSibling.showPicker();
                                                    }}
                                                >
                                                    <SVGCalendar />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3 w-50">
                                            <label className="form-label museo-sans-700 s14">
                                                Fecha hasta
                                            </label>
                                            <div className="date-input-wrapper">
                                                <input
                                                    type="date"
                                                    className="custom-date-input"
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                    value={endDate}
                                                    placeholder="Seleccionar"
                                                />
                                                <div
                                                    className="calendar-icon"
                                                    onClick={(e) => {
                                                        e.currentTarget.previousElementSibling.showPicker();
                                                    }}
                                                >
                                                    <SVGCalendar />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="mb-3">
                                        <label className="form-label museo-sans-700 s14">
                                            Cliente/s
                                        </label>
                                        <div
                                            className="client-input-wrapper"
                                            onClick={() => setShowClientSelector(true)}
                                        >
                                            <input
                                                type="text"
                                                readOnly
                                                placeholder="Seleccionar"
                                                value={selectedClients.length === 0 ? "" :
                                                    selectedClients.length > 1 ? `Varios clientes` :
                                                        `${selectedClients[0]?.firstName} ${selectedClients[0]?.lastName}`}
                                            />
                                            <ArrowRight />
                                        </div>
                                    </div>

                                    <hr />

                                    <div className="mb-3">
                                        <label className="form-label museo-sans-700 s14">
                                            Estado del pedido
                                        </label>
                                        <div className="d-flex flex-wrap gap-2 mt-2 justify-content-between museo-sans-500 s12">
                                            {orderStatus.map((status) => (
                                                <button
                                                    key={status.value}
                                                    type="button"
                                                    className={`status-button ${selectedStatus.some(item => item.value === status.value) ? "active" : ""
                                                        }`}
                                                    onClick={() => toggleStatus(status)}
                                                >
                                                    {status.label}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="d-flex flex-column align-items-center gap-3 pt-1">
                                <button
                                    data-bs-dismiss="modal"
                                    className="filter-button-modal filter-button-primary museo-sans-700 s14"
                                    onClick={() => handleApplyFilters()}
                                >
                                    Aplicar
                                </button>
                                <button
                                    data-bs-dismiss="modal"
                                    className="filter-button-modal filter-button-secondary museo-sans-700 s14"
                                    onClick={() => {
                                        clearAppliedFilters();
                                    }}
                                >
                                    Limpiar filtros
                                </button>
                            </div>
                        </div>
                    ) : (
                        <ClientSelector
                            clients={clients || []}
                            selectedClients={selectedClients}
                            showModal={() => setShowClientSelector(false)}
                            onClientSelect={(clients) => {
                                handleClientSelect(clients);
                            }}
                            onBack={() => setShowClientSelector(false)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ListadoOrdersFilterModal;
