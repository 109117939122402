import { FormattedMessage } from "react-intl";
import { useRef } from "react";
import { useMediaPredicate } from "react-media-hook";
import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "../../../Share/ShareFunctionUseOnClickOutside";
import { ReactComponent as SVGPersonalOrder } from "../../../svgs/ic-people.svg";
import { ReactComponent as SVGClientOrder } from "../../../svgs/ic-people-group.svg";
import { ReactComponent as SVGEdbOrder } from "../../../svgs/ic-menu-edb.svg";
import { useDispatch, useSelector } from "react-redux";
import { orderType } from "../../../../Orders/Framework/redux/OrdersActions";

export default function HeaderNewOrderBtn({ toggler }) {
  const ref = useRef();
  useOnClickOutside(ref, () => toggler(false));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderData = useSelector((state) => state.orderData);

  const biggerThan1440 = useMediaPredicate("(min-width: 1440px)");
  const biggerThan1800 = useMediaPredicate("(min-width: 1800px)");

  const onPersonalOrder = () => {
    if (localStorage.getItem("personal-order-not-show-again") === "true") {
      onPersonalConfirmAdvance("personal")
      return;
      
    }
    const clickMe = document.getElementById("modal-first-personal-order-index");
    clickMe.click();
    return;
  };

  const onPersonalConfirmAdvance = (type) => { 
    dispatch(orderType(type));
    if (orderData?.hasKit) {
      navigate(`/dashboard/orders/catalogo-consulta`);
      return;
    }

    navigate(`/dashboard/orders/business-kit`);

  };

  const stylesByResolution = () => {
    if (biggerThan1800) {
      return { width: "288px", right: "80px" };
    } else if (biggerThan1440) {
      return { width: "288px", right: "55px" };
    } else {
      return { width: "288px", right: "35px" };
    }
  };

  return (
    <div ref={ref} id="header--model-plus" className="position-absolute">
      <ul
        style={stylesByResolution()}
        className="  shadow bg-white p-3 m-0 list-unstyled"
      >

        <>
          <li>
            <div className="p-0 model-plus--row pointer" onClick={() => onPersonalOrder()}>
              <div className=" svg-force-size ">
                <SVGPersonalOrder />
              </div>
              <p className=" s14 museo-sans-500 blackColor mb-0 ">
                <FormattedMessage
                  id="header.new.personal.order"
                  defaultMessage="Orden Personal"
                />
              </p>
            </div>
          </li>
          <hr></hr>
        </>

        <>
          <li>
            <div className="p-0 model-plus--row cursor-default">
              <div className=" svg-force-size ">
                <SVGClientOrder />
              </div>
              <p className="museo-sans-500 s14 blackColor mb-0 opacity-50">
                <FormattedMessage
                  id="header.new.client.order"
                  defaultMessage="Orden Cliente Individual"
                />
              </p>
            </div>
          </li>
          <hr></hr>
        </>

        <li>
          <div className="p-0 model-plus--row cursor-default">
            <div className=" svg-force-size ">
              <SVGEdbOrder />
            </div>
            <p className="s14 museo-sans-500 blackColor mb-0 opacity-50">
              <FormattedMessage
                id="header.new.edb.order"
                defaultMessage="Orden Cliente EDB"
              />
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}
