import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const fetchDesc = createAsyncThunk(
    'fetchDesc',
    async ({yearperiod}, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            if (yearperiod) {
                const response = (await customNetServAxios().get(`${location}/descendant-sales/${clientId}?yearPeriod=${yearperiod}&limit=10&offset=0&sort_by=billed.asc&filter_type=&orders=&weeks=&amount_min=0&amount_max=0`)).data;
                return response;
            } else {
                return null;
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const fetchDescIncorp = createAsyncThunk(
    'fetchDescIncorp',
    async ({root, offset}, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/descendants-tree/${clientId}/incorporated/root/${root}?offset=${offset ? offset : 0}&limit=10&search=`)).data;
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const fetchDescIncorpExtra = createAsyncThunk(
    'fetchDescIncorpExtra',
    async ({root, offSet}, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/descendants-tree/${clientId}/incorporated/root/${root}?offset=${offSet ? offSet : 0}&limit=10&search=`)).data;
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const fetchDescFirstGen = createAsyncThunk(
    'fetchDescFirstGen',
    async ({root, offset}, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/descendants-tree/${clientId}/first-generation-group/root/${root}?offset=${offset ? offset : 0}&limit=10&search=`)).data;
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const fetchDescFirstGenExtras = createAsyncThunk(
    'fetchDescFirstGenExtras',
    async ({root, offSet}, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/descendants-tree/${clientId}/first-generation-group/root/${root}?offset=${offSet ? offSet : 0}&limit=10&search=`)).data;
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const fetchDescPersonalGroup = createAsyncThunk(
    'fetchDescPersonalGroup',
    async ({root, offset}, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/descendants-tree/${clientId}/personal-group/root/${root}?limit=10&offset=${offset ? offset : 0}&search=`)).data;
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const fetchDescPersonalGroupExtra = createAsyncThunk(
    'fetchDescPersonalGroupExtra',
    async ({root, offSet}, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/descendants-tree/${clientId}/personal-group/root/${root}?limit=10&offset=${offSet ? offSet : 0}&search=`)).data;
           
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const fetchDesProfile = createAsyncThunk(
    "fetchDesProfile",
    async ({root}, { rejectWithValue }) => {
      try {
        let location = localStorage.getItem("country");
        let clientId = localStorage.getItem("clientId");
        const response = (await customNetServAxios().get(`${location}/profile/${root? root : clientId}`)).data;
        return response;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);

const fetchDesScore = createAsyncThunk(
    'fetchDesScore',
    async ({root} , {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/performance/${root ? root : clientId}/score`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
);

const fetchDesGroup = createAsyncThunk(
    'fetchDesGroup',
    async ({root} , {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/performance/${root ? root : clientId}/score/groups`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchDescSalesBills = createAsyncThunk(
    'fetchDescSalesBills',
    async ({root, yearPeriod}, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`/${location}/personal-sales/${root ? root : userId}/bills/weeks?yearPeriod=${yearPeriod}`)).data;
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export { fetchDescFirstGenExtras, fetchDescIncorpExtra, fetchDescPersonalGroupExtra, fetchDescSalesBills, fetchDesc, fetchDescPersonalGroup, fetchDescFirstGen, fetchDescIncorp, fetchDesProfile, fetchDesScore, fetchDesGroup}
