import OrderCartImageContainer from "./OrdersCartImageContainer"

export default function OrderCartKitHolder({cartKit, ifValueAPrice}) {

    console.log("cartKit", cartKit)
    
    return (
      <>
        {cartKit && (
          <div>
            <p className="mb-3 s20 museo-sans-600">Kit de Negocio</p>
            <OrderCartKitProduct
              ifValueAPrice={ifValueAPrice}
              cartKit={cartKit}
            />
          </div>
        )}
      </>
    );
}

export function OrderCartKitProduct({cartKit, ifValueAPrice}) {

    return(
        <div id={cartKit?.kitId} className="d-flex" >
            <OrderCartImageContainer imageUrl={cartKit?.imageURL} hasAmount={1} />
            <div style={{margin: "0px 0px 0px 12px", top: "6px"}} className=" position-relative d-flex justify-content-between w-100" >
                <div className="d-flex flex-column" >
                    <p className=" museo-sans-600 s16" >
                        {cartKit?.name}
                    </p>
                    <p className=" grayColor s14 museo-sans-400" >
                    {cartKit?.kitId}
                    </p>
                </div>
                <div className=" d-flex justify-content-end s16 dm-sans-700" >
                    <p>
                        {
                            cartKit?.discountPrice
                        }
                    </p>
                </div>
            </div>
        </div>
    )
}