import "../pages/dashboard.css";
import "../components/Modals/highlightOnBoarding/onBoarding.css";
import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories/index";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useMediaPredicate } from "react-media-hook";

import {
  getNoticeClients,
  getNoticeConsultant,
  getNoticeLiders,
  getOffersData,
  getPotentialComparisonLevel,
  getScore,
} from "../../Framework/redux/dashboardSideEffect";

import { fetchEarningsCardPotentialLeader } from "../../../Earning/redux/earningSideEffects";

import {
  getCMSDataOne,
  getDataConsultant,
  getDataFirstGeneration,
  getIncorporatedOnly,
  getRestOfGroupOnly,
} from "../../../FirstSteps/Framework/redux/FirstStepsAllSideEffect";

import {
  cleanFirstGenerationData,
} from "../../../FirstSteps/Framework/redux/FirstStepsAllActions";

import AccountModalShare from "../../../Account/Presentation/components/AccountModalShare";
import ModalClipboard from "../../../common/Share/ModalClipboard";
import DashboardShare from "../components/DashboardShare";
import DashboardModalContainer from "../components/DashboardModalContainer";
import DashboardTitle from "../components/DashboardTitle";
import DashboardUpdate from "../components/DashboardUpdate";
import DashboardInvitationsContainer from "../components/DashboardInvitationsContainer";
import DashboardQualificationContainer from "../components/DashboardQualificationContainer";
import DashboardGraphContainer from "../components/DashboardGraphContainer";
import DashboardFirstStepsContainer from "../components/DashboardFirstStepsContainer";
import DashboardTriperiodContainer from "../components/DashboardTriperiodContainer";
import DashboardOffersContainer from "../components/DashboardOffersContainer";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import OriginRoadDashboardShowcase from "../../../OriginRoad/Presentation/pages/OriginRoadDashboardShowcase";
import DashboardCatalog from "../components/DashboardCatalog";
import { fetchVirtualEcommerce, fetchVirtualEcommerceTopics } from "../../../Ecommerce/Framework/redux/EcommerceSideEffect";

export default function Dashboard({
  firstLoginListener,
  deactivateFirstLogin,
  activateFirstLogin
}) {
  const country = localStorage.getItem("country");
  const directorId = localStorage.getItem("directorId");
  const invitationToggler = localStorage.getItem("invitationToggle");

  const dispatch = useDispatch();

  const scoreData = useSelector((state) => state.scoreData);
  const noticeLider = useSelector((state) => state?.firstStepsData?.notiLider);
  const noticeConsultant = useSelector(
    (state) => state?.firstStepsData?.notiConsultant
  );
  const noticeClient = useSelector(
    (state) => state?.firstStepsData?.notiClient
  );
  const offerData = useSelector((state) => state?.firstStepsData?.offer);
  const periodGap = useSelector(
    (state) => state.headerData.period.periodProcessGap
  );
  const groupData = useSelector((state) => state.scoreData.groups);
  const sNumber = useSelector((state) => state.scoreData.stepNumber);
  const headerData = useSelector((state) => state?.headerData);
  const errorScoreData = useSelector(
    (state) => state.scoreData?.scoreDataError
  );
  const profileData = useSelector((state) => state.userInfo);
  const qualifiedInfo = useSelector(
    (state) => state.performanceData.qualifiedGroups
  );

  const [copyToggler, setCopyToggler] = useState(false);
  const [closeComplete, setCloseComplete] = useState(true);
  const [toggler, setToggler] = useState(false);
  const [offersModalInfo, setOffersModalInfo] = useState("client");
  const [offersInfoModal, setOffersInfoModal] = useState({});
  const [shareNoticeInfo, setShareNoticeInfo] = useState({});
  const [firstStepsLoader, setFirstStepsLoader] = useState(true);

  const biggerThan1025 = useMediaPredicate("(min-width: 1025px)");

  const modalRef = useRef(null);
  const divRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    const clientId = localStorage.getItem("clientId")
    Repository.PurchaseRepository.getKitData(clientId, 'ar').then((response) => {
      console.log('cosas raras response', response)

    }).catch((error) => {
      console.log('cosas raras error', error)
    })
  }, [])

  const changeModalClient = () => {
    setOffersModalInfo("client");
  };

  const changeModalConsult = () => {
    setOffersModalInfo("consult");
  };

  const togglerLider = () => {
    setOffersModalInfo("togglerLider");
  };

  const shareNotice = (elm, id) => {
    setShareNoticeInfo(elm);
    const activateNotiModalButton = document.getElementById(id);
    activateNotiModalButton.click();
  };

  const onOpenCopyModal = () => {
    setCopyToggler(true);
  };

  const onCloseCopyModal = () => {
    setCopyToggler(false);
  };

  const controllerModalOfertsToTop = () => {
    modalRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onStartUp = async () => {
    await dispatch(getPotentialComparisonLevel());
    await dispatch(fetchEarningsCardPotentialLeader());
  };

  const callServicesOffers = async () => {
    await dispatch(getNoticeLiders());
    await dispatch(getNoticeClients());
    await dispatch(getNoticeConsultant());
    await dispatch(getOffersData());
  };

  const callServiceFirstSteeps = async () => {
    setFirstStepsLoader(true);
    let resultPlanId = null;
    await dispatch(getIncorporatedOnly());

    if (profileData?.userInfo?.level > 2) {
      await dispatch(cleanFirstGenerationData());
      await dispatch(getDataFirstGeneration());
    }
    if (profileData?.userInfo?.level > 1) {
      await dispatch(getRestOfGroupOnly());
    }
    if (profileData?.userInfo?.level < 2) {
      resultPlanId = await dispatch(getDataConsultant());
      if (resultPlanId?.payload?.planId > 0) {
        callCMS(resultPlanId?.payload?.planId);
      }
    }
    setTimeout(() => {
      setFirstStepsLoader(false);
    }, 1000);
  };

  const callCMS = async (planId) => {
    await dispatch(getCMSDataOne(planId));
  };

  const onClickOne = () => {
    setToggler(false);
  };

  const onClickTwo = () => {
    setToggler(true);
  };

  const fetchScore = async () => {
    await dispatch(getScore());
  };

  useEffect(() => {
    if ((profileData?.userInfo?.level < 2 && scoreData?.consultantStatus?.potentialLeader) && !scoreData.potentialLeaderScore) {
      onStartUp()
    }
  }, [scoreData?.consultantStatus?.potentialLeader]);

  useEffect(() => {
    setTimeout(() => {
      const activateModalButton = document.getElementById("activateOnBoarding");
      if (firstLoginListener == true && sNumber == 0) {
        activateModalButton.click();
      }
    }, 1900);

    if (
      sNumber == 4 &&
      profileData?.userInfo?.level < 2 &&
      !scoreData?.consultantStatus?.potentialLeader
    ) {
      scrollToBottom();
    }

    if (sNumber == 20) {
      deactivateFirstLogin();
    }
  }, [sNumber]);

  return (
    <>
      <div ref={headerRef} className=" invisible"></div>

      <ShareModalStructure id={"modalShare"} maxWidth={"556px"} >
        <AccountModalShare
          socialArray={profileData.userInfo.socialNetwork}
          user={profileData.userInfo}
          open={onOpenCopyModal}
        />
      </ShareModalStructure>

      <section
        id="dashboard"
        className=" py-5 container--padding  "
        style={{ maxHeight: "100vh", overflowY: "auto" }}
      >
        <ModalClipboard show={copyToggler} close={onCloseCopyModal} />
        <DashboardModalContainer
          shareNoticeInfo={shareNoticeInfo}
          offersInfoModal={offersInfoModal}
          modalRef={modalRef}
          qualifiedInfo={qualifiedInfo}
          noticeLider={noticeLider}
          noticeClient={noticeClient}
          noticeConsultant={noticeConsultant}
          onOpenCopyModal={onOpenCopyModal}
          profileData={profileData}
          offersModalInfo={offersModalInfo}
          scoreData={scoreData}
          activateFirstLogin={activateFirstLogin}
        />

        <button
          id="activateOnBoarding"
          className="d-none"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#welcomeModal"
        >
          activar modals
        </button>

        <div className=" container--padding--card-holder">
          <div className="d-flex flex-column gap-5" >
            <div className=" w-100 d-flex justify-content-between ">
              <DashboardTitle
                biggerThan1025={biggerThan1025}
                profileData={profileData}
                profileLoader={profileData.loader}
              />
              <DashboardUpdate isDashboard={true} periodError={headerData?.periodError} />
            </div>
            <OriginRoadDashboardShowcase S1={biggerThan1025} scoreData={scoreData} />
            <DashboardFirstStepsContainer
              reloadMessage={callServiceFirstSteeps}
              profileData={profileData}
              country={country}
              loader={firstStepsLoader}
              callServiceFirstSteeps={callServiceFirstSteeps}
            />
            <DashboardInvitationsContainer
              invitationToggler={invitationToggler}
              directorId={directorId}
            />
            <DashboardQualificationContainer
              level={profileData.userInfo.level}
              profileData={profileData}
              groupData={groupData}
              scoreData={scoreData}
              periodGap={periodGap}
              setCloseComplete={setCloseComplete}
              closeComplete={closeComplete}
            />
            <DashboardGraphContainer
              scoreData={scoreData}
              errorScoreData={errorScoreData}
              profileData={profileData}
              toggler={toggler}
              onClickOne={onClickOne}
              onClickTwo={onClickTwo}
              periodGap={periodGap}
              fetchScore={fetchScore}
              reduceNumber={false}
            />
            <DashboardTriperiodContainer
              profileData={profileData} scoreData={scoreData} errorScoreData={errorScoreData} periodGap={periodGap} fetchScore={fetchScore}
            />
            <DashboardOffersContainer
              togglerConsult={changeModalConsult}
              togglerClient={changeModalClient}
              noticeLider={noticeLider}
              togglerLider={togglerLider}
              noticeConsultant={noticeConsultant}
              noticeClient={noticeClient}
              offerData={offerData}
              setOffersInfoModal={setOffersInfoModal}
              setShareNoticeInfo={shareNotice}
              onCopyOpen={onOpenCopyModal}
              controllerModalOfertsToTop={controllerModalOfertsToTop}
              callServicesOffers={callServicesOffers}
            />

            <DashboardCatalog phone={profileData?.userInfo?.phone} socialArray={profileData?.userInfo?.socialNetwork} />

            <div className=" w-100" ref={divRef}>
              <DashboardShare
                socialArray={profileData?.userInfo?.socialNetwork}
                user={!profileData}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
