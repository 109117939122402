import React from 'react';
import './summaryFirstSteps.css';

const SummaryFirstSteps = ({ data, title }) => {
  if (!data) return null
  const formatText = (text) => {
    return text.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  };
  return (
    <div style={{ paddingTop: '24px' }} >
      <div >
        <div className="modal-header museo-sans-600 s20" >
          {title && (<p>Primeros Pasos</p>)}
        </div>
        <div className="modal-body">
          <div className="productos museo-sans-400 s14 ">
            <div >
              {Object?.values(data)?.map((producto, index) => (
                <div key={index} className="producto-container" style={{ borderBottom: index < Object?.values(data)?.length - 1 ? '1px solid #DDDDDD' : '' }}>
                  <span>
                    <div
                      style={{
                        background: "#D18DEF",
                        width: "24px",
                        height: "24px",
                        marginLeft: '31px',
                        marginTop: '-31px'
                      }}
                      className=" s12 dm-sans-500 position-absolute text-white d-flex justify-content-center align-items-center rounded-circle"
                    >
                      x{producto.quantity}
                    </div>
                  </span>
                  <img src={producto.img} alt={producto.description} />
                  <div className="producto-info">
                    <p className=' museo-sans-600 s16'>
                      {formatText(producto.description)} <br />
                      <small className=' museo-sans-400 s14 color'>{producto.productId}</small>
                    </p>
                    <p style={{ textAlign: 'end' }}>
                      <strong className='dm-sans-700 s16'>{producto.price}</strong> <br />
                      <small className='dm-sans-medium s14 color'>PVP: {producto.pvpPrice}</small>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryFirstSteps;