import React, { useState } from 'react';
import { ReactComponent as SVGTruck } from "../../Icons/Truck.svg";
import ShippingAddressModal from './ShippingAddressModal';
import ShippingInfoModal from './ShippingInfoModal';
import SelectLocationModal from './SelectLocationModal';
import SelectPickupPointModal from './SelectPickupPointModal';
import SchedulesModal from './SchedulesModal';
import CustomSelect from '../../../common/Share/CustomSelect';

const ShippingAddress = () => {
    const [selectedOption, setSelectedOption] = useState('home');
    const [selectedCompany, setSelectedCompany] = useState('Elige una empresa');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const companySelect = [
        { value: 'Pickit', label: 'Pickit' },
        { value: 'HOP', label: 'HOP' },
    ];
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [showModalLocation, setShowModalLocation] = useState(false);
    const [showModalPickup, setShowModalPickup] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [addressData, setAddressData] = useState({
        street: '',
        floor: '',
        postalCode: '',
        province: ''
    })

    const country = localStorage.getItem('countryLong');

    const valueDefault = () => {
        return [{ value: selectedCompany, label: selectedCompany }]
    }

    const setAddress = (data) => {
        setIsFormComplete(true)
        setAddressData(data);
    }

    const handleSelectedPickup = (location) => {
        setSelectedAddress(location)
    }

    const handleLocation = (location) => {
        if (isEdit) {
            setSelectedAddress('')
            setIsEdit(false)
        }
        setSelectedLocation(location)
        setShowModalLocation(false)
    }

    return (
        <div className='mb-4'>
            <div className='d-flex align-items-center mb-4 museo-sans-700 gap-3'>
                <p className='s24' style={{ width: '211px', height: '32px' }}>Dirección de envío</p>
                {selectedOption === 'home' &&
                    <p className='skyColor s14 pointer'
                        data-bs-toggle="modal"
                        data-bs-target="#changeAddress"
                    >Modificar</p>}
            </div>
            <div className='container-card container-shipping' style={{ height: selectedOption === 'pickup' && '508px' }}>
                <div className="container-btn-toggle d-flex mb-4 p-1 museo-sans-700">
                    <button
                        className={`toggle-button w-50 ${selectedOption === 'home' ? 'active' : ''}`}
                        onClick={() => {
                            setSelectedOption('home');
                            setSelectedCompany('Elige una empresa');
                            setSelectedLocation('');
                            setSelectedAddress('');
                        }}
                    >
                        Entrega a domicilio
                    </button>
                    <button
                        className={`toggle-button w-50 ${selectedOption === 'pickup' ? 'active' : ''}`}
                        onClick={() => setSelectedOption('pickup')}
                    >
                        Punto de retiro
                    </button>
                </div>

                {selectedOption === 'home' ? (
                    <div className="address-info s14">
                        {isFormComplete && (
                            <>
                                <p className='museo-sans-500 blackColor text-capitalize'>{`${addressData.street}${addressData.floor && ", " + addressData.floor}`}</p>
                                <p className='dm-sans-medium text-uppercase'>{`${addressData.postalCode}, ${addressData.province}`}</p>
                                <p className='dm-sans-medium text-capitalize'>{country}</p>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="pickup-info">
                        <div>
                            <div className="mb-3">
                                <label className="form-label museo-sans-700 s14 blackColor">Empresa</label>
                                <CustomSelect onChange={(e) => setSelectedCompany(e.value)} options={companySelect} value={valueDefault()} defaultValue={[{ value: "", label: "Elige una empresa" }]} isDisabled={false} width={'100%'} />
                            </div>

                            <div className="kit-notification-container donutHeaderBg p-3 d-flex align-items-center justify-content-between mb-3">
                                <div style={{ marginRight: "0px" }}>
                                    <SVGTruck />
                                </div>
                                <p className='museo-sans-500 s14 blueColor'>
                                    ¡Conoce las ofertas y descuentos en tu envío!
                                </p>
                                <a data-bs-toggle="modal"
                                    data-bs-target="#shippingInfo"
                                    style={{ minWidth: "56px" }}
                                    className="museo-sans-700 s14 text-decoration-none skyColor pointer">Ver más</a>
                            </div>

                            <div className="mb-3">
                                <label className="museo-sans-700 blackColor s14 mb-2">Provincia</label>
                                <p className='museo-sans-500 blackColor s14'>Buenos Aires</p>
                            </div>

                            <div className="mb-3">
                                <div className='d-flex align-items-center mb-2 museo-sans-700 gap-2'>
                                    <label className="museo-sans-700 blackColor s14">Localidad</label>
                                    {selectedLocation !== '' &&
                                        <p className='skyColor s14 pointer'
                                            data-bs-toggle="modal"
                                            data-bs-target="#selectLocation"
                                            onClick={() => {
                                                setShowModalLocation(true)
                                                setIsEdit(true)
                                            }}
                                        >Modificar</p>}
                                </div>
                                <div>
                                    {selectedLocation === '' ?
                                        <button
                                            data-bs-toggle="modal"
                                            data-bs-target="#selectLocation"
                                            onClick={() => setShowModalLocation(true)}
                                            style={{ height: "40px" }}
                                            className="d-flex mb-4 w-100 s14 justify-content-center align-items-center museo-sans-700 skyColor bg-transparent skyBorder rounded"
                                        >
                                            Seleccionar una localidad
                                        </button>
                                        :
                                        <p className='museo-sans-500 blackColor s14'>{selectedLocation}</p>}
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className='d-flex align-items-center mb-2 museo-sans-700 gap-2'>
                                    <label className="museo-sans-700 blackColor s14">Domicilio</label>
                                    {selectedAddress !== '' &&
                                        <p className='skyColor s14 pointer'
                                            data-bs-toggle="modal"
                                            data-bs-target="#selectPickupPoint"
                                        >Modificar</p>}
                                </div>

                                <div>
                                    {selectedAddress === '' ?
                                        <button
                                            data-bs-toggle="modal"
                                            data-bs-target="#selectPickupPoint"
                                            onClick={() => setShowModalPickup(true)}
                                            disabled={!selectedLocation}
                                            style={{ height: "40px" }}
                                            className={`${!selectedLocation ? "brightGrayColor brightGrayBorder cursor-default" : 'skyColor skyBorder'} d-flex mb-4 w-100 s14 justify-content-center align-items-center museo-sans-700 bg-transparent rounded`}
                                        >
                                            Seleccionar punto de retiro
                                        </button>
                                        :
                                        <>
                                            <p className='museo-sans-500 blackColor s14 mb-2'>{selectedAddress}</p>
                                            <a
                                                data-bs-toggle="modal"
                                                data-bs-target="#selectSchedules"
                                                className='pointer museo-sans-700 skyColor s14'>Ver horarios del punto de entrega</a>
                                        </>
                                    }


                                </div>
                            </div>
                        </div>

                    </div>
                )}
            </div>
            <ShippingAddressModal setAddress={setAddress} />
            <ShippingInfoModal />
            <SelectLocationModal selectedLocation={selectedLocation} setSelectedLocation={handleLocation} showModal={showModalLocation} setShowModal={setShowModalLocation} />
            <SelectPickupPointModal selectedPickupPoints={selectedAddress} setSelectedPickupPoint={handleSelectedPickup} showModal={showModalPickup} setShowModal={setShowModalPickup} />
            <SchedulesModal />
        </div>
    );
}

export default ShippingAddress;
