import { useNavigate, useParams } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";
import ShareModalIndex from "../../../common/Share/ShareModalIndex";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import ProductCard from "../../../consultationCatalogue/presentation/components/cardProduct/cardProduct";
import OrdersFirstStepsModalInfo from "../components/modals/OrdersFirstStepsModalInfo";
import imageOne from "../../imgs/firststeps/firststep1.png";
import imageTwo from "../../imgs/firststeps/firststep2.png";
import imageThree from "../../imgs/firststeps/firststep3.png";
import imageThreeB from "../../imgs/firststeps/firststep3b.png";
import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories/index";
import { addToCartFromFirstSteps, removeFromCartFirstSteps } from "../../Framework/redux/OrdersActions";
import ProductCardSkeleton from "../../../consultationCatalogue/presentation/components/skeletoncardproduct/skeletoncardproduct";

export default function OrdersFirstSteps(params) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const stepNumber = useParams().step;
  const [products, setProducts] = useState([]);
  const [positionFixedWidth, setPositionFixedWidth] = useState(0);
  const biggerThan1440 = useMediaPredicate("(min-width: 1440px)");
  const cartContainerProducts = useSelector((state) => state.orderData.cart.cartContainer);
  const cartContainer = useSelector((state) => state.orderData.cart.cartFirstStepsProducts);

  const onContinue = () => {
    // need logic / service to determine to continue or stop moving through firstSteps
    let nextStep = parseInt(stepNumber) + 1;
    if (stepNumber == 4) {
      navigate(`/dashboard/orders/summary`);
      return;
    };
    if (stepNumber >= 3) {
      // Redirect to another page
      navigate(`/dashboard/orders/firststeps/${nextStep}/helpsales`);
      return;
    }
    navigate(`/dashboard/orders/firststeps/${nextStep}`);
  };

  const getProductsService = async () => {
    const params = {
      limit: 4,
      offset: 0,
      categoryId: "20",
      search: null,
      orderType: "personal",
    };

    try {
      const clientId = localStorage.getItem("clientId");
      const countryCode = localStorage.getItem("country");
      const response = await Repository.ProductCatalogRepository.getProducts(
        clientId,
        countryCode,
        params
      );
      if (response.products.length === 0) {
        setProducts([]);
      } else {
        setProducts((prevProducts) => [...prevProducts, ...response.products]);
        if (response.products.length < params.limit) setProducts([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const helpIcon = (
    <ShareModalIndex id="orders-first-modal-info">
      <div style={{ top: "-2px" }} className=" position-relative">
        <HelpIconNoTarget />
      </div>
    </ShareModalIndex>
  );

  const overdriveOnChange = (operation, product) => {
    if (operation == "add") {
      dispatch(addToCartFromFirstSteps(product));
    };
    if (operation == "remove") {
      dispatch(removeFromCartFirstSteps(product));
    };
  };

  const JSXHeadImage = {
    1: <img src={imageOne} alt="firstSteps1" />,
    2: <img src={imageTwo} alt="firstSteps1" />,
    3: <img src={imageThree} alt="firstSteps1" />,
  };

  const JSXHead = {
    1: (
      <div
        style={{ height: "160px" }}
        className="w-100 d-flex bg-white rounded-4 overflow-hidden cards-shadow "
      >
        <div className=" h-100 btnLoginWidth grayBg ">
          {JSXHeadImage[stepNumber]}
        </div>
        <div style={{ padding: "40px" }}>
          <div className="d-flex museo-sans-700 s24 gap-2">
            <p>
              <FormattedMessage id="first.steps.title" />
            </p>
            {helpIcon}
          </div>
          <div className=" museo-sans-500 s14">
            <p>
              {
                // Probablemente venga del servicio, no hace falta multilenguaje.
              }
              Por haber alcanzado el Logro 1 del programa de Primeros Pasos,
              podrás elegir productos de esta lista hasta un monto de $ 11.000
              PVP con el 50% de descuento
            </p>
          </div>
        </div>
      </div>
    ),
    2: (
      <div
        style={{ height: "160px" }}
        className="w-100 d-flex bg-white rounded-4 overflow-hidden cards-shadow "
      >
        <div className=" h-100 btnLoginWidth grayBg ">
          {JSXHeadImage[stepNumber]}
        </div>
        <div style={{ padding: "40px" }}>
          <div className="d-flex museo-sans-700 s24 gap-2">
            <p>
              <FormattedMessage id="first.steps.title" />
            </p>
            {helpIcon}
          </div>
          <div className=" museo-sans-500 s14">
            <p>
              {
                // Probablemente venga del servicio, no hace falta multilenguaje.
              }
              Por haber alcanzado el Logro 2 del programa de Primeros Pasos,
              podrás elegir productos de esta lista hasta un monto de $ 16.500
              PVP con el 75% de descuento
            </p>
          </div>
        </div>
      </div>
    ),
    3: (
      <div
        style={{ height: "266PX" }}
        className="w-100 d-flex bg-white rounded-4 overflow-hidden cards-shadow "
      >
        <div className=" h-100 btnLoginWidth grayBg ">
          {JSXHeadImage[stepNumber]}
        </div>
        <div className="" style={{ padding: "40px" }}>
          <div className="d-flex gap-2">
            <p className=" museo-sans-700 s24 ">
              <FormattedMessage id="orders.cart.steps.head.title.3" />
            </p>
          </div>
          <div className="w-100 d-flex pt-3">
            <div
              style={{
                border: "1px solid var(--Brand-Lilac-500, #CED2FF)",
                height: "122px",
              }}
              className=" d-flex  rounded-4 mt-3 "
            >
              <img src={imageThreeB} alt="firstSteps1" />
              <div className=" d-flex flex-column gap-2 w-100 s16 museo-sans-700 p-4 ">
                <div className="d-flex align-items-center gap-2 ">
                  <p>Bolso Just + Stickers</p>
                  <div
                    style={{
                      width: "81px",
                      height: "26px",
                      color: "#B57ACF",
                      background: "#FCF6FE",
                    }}
                    className=" d-flex justify-content-center align-items-center mx-1 rounded-4 s12"
                  >
                    <p className=" museo-sans-700 text-uppercase ">
                      ¡<FormattedMessage id="gift" />!
                    </p>
                  </div>
                </div>
                <div className=" s14 museo-sans-500">
                  <p>
                    Por haber cumplido el Logro 1 y realizado un Encuentro de
                    Bienestar, recibes un Bolso Just + 9 Stickers de regalo!
                  </p>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    ),
    4: (
      <p className=" museo-sans-700 s24">
        Productos de ayuda de ventas para sumar a tu pedido
      </p>
    ),
  };

  const JSXFooter = {
    1: (
      <OrdersFirstStepsFooterFixedContainer positionFixedWidth={positionFixedWidth} onContinue={onContinue} />
    ),
    2: (
      <OrdersFirstStepsFooterFixedContainer positionFixedWidth={positionFixedWidth} onContinue={onContinue} />
    ),
    3: (
      <div
        onClick={onContinue}
        className=" pointer  d-flex justify-content-center align-items-center mt-4 museo-sans-700  s14 rounded-3 btnLoginWidth skyBg text-white"
      >
        <FormattedMessage id="buttons.continue" />
      </div>
    ),
    4: (
      <div
        onClick={onContinue}
        className=" pointer  d-flex justify-content-center align-items-center mt-4 museo-sans-700 s14 rounded-3 btnLoginWidth skyBg text-white"
      >
        <FormattedMessage id="buttons.continue" />
      </div>
    ),
  };

  useEffect(() => {
    getProductsService();
  }, []);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setPositionFixedWidth(containerRef.current.getBoundingClientRect().width);
      }
    };

    const observer = new ResizeObserver(() => updateWidth());

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    updateWidth();

    return () => {
      observer.disconnect(); 
    };
  }, []);

  return (
    <div ref={containerRef} className=" position-relative w-100  ">
      <ShareModalStructure id="orders-first-modal-info" maxWidth="764px">
        <OrdersFirstStepsModalInfo />
      </ShareModalStructure>
      <div className="mt-3 pt-3">{JSXHead[stepNumber]}</div>
      {
        (products?.length > 0 ) ? (
          <OrdersFirstStepsTable cartContainerProducts={cartContainerProducts} biggerThan1440={biggerThan1440} products={products} cartContainer={cartContainer} overdriveOnChange={overdriveOnChange} stepNumber={stepNumber} />
        ) :
        (
          <>
          {
            stepNumber != 3 &&
          <div
        style={{ flexWrap: "wrap", gap: biggerThan1440 ? "" : "16px" }}
        className={` ${
          biggerThan1440 ? "justify-content-between" : "justify-content-evenly"
        } d-flex mt-4 pt-3`}
      >
            <ProductCardSkeleton/>
            <ProductCardSkeleton/>
            <ProductCardSkeleton/>
            <ProductCardSkeleton/>
          </div>

          }
          </>
        )
      }
    <div style={{height: "172px", bottom: "15%"}} className=" position-relative" >
      {JSXFooter[stepNumber]}

    </div>
    </div>
  );
}

export function OrdersFirstStepsFooterFixedContainer({ onContinue, positionFixedWidth }) {
  
  return(
    <div style={{width: positionFixedWidth, bottom: "10%", padding: "16px 20px"}} className=" position-fixed d-flex justify-content-between mt-5 bg-white rounded-4 museo-sans-500 shadow mb-4 ">
      <div className="  d-flex justify-content-between align-items-center w-100 align-items-center gap-3">
        <div className=" d-flex justify-content-between gap-3 w-75">
          <p style={{ minWidth: "120px" }}>
            <FormattedMessage id="orders.steps.footer.sales" />
          </p>
          <div className="d-flex align-items-center w-100  ">
            <ProgressBarParams completed={70} lightBlueBg={true} />
          </div>
          <p
            style={{ minWidth: "170px" }}
            className=" d-flex align-items-center dm-sans-medium"
          >
            $6000
            <span className=" grayColor">/ $11000 PVP</span>
          </p>
        </div>
        <div
        style={{ minWidth: "288px"}}
          onClick={onContinue}
          className=" pointer mx-3 me-0 d-flex justify-content-center align-items-center museo-sans-700 s14 rounded-3 btnLoginWidth skyBg text-white"
        >
          <FormattedMessage id="buttons.continue" />
        </div>
      </div>
    </div>
  )

}

export function OrdersFirstStepsTable({ products, cartContainer, overdriveOnChange, cartContainerProducts, stepNumber, biggerThan1440 }) {

  function getProductAmount(cartContainer, productId) {
    if (!cartContainer || !productId) {
        return 0; // Devuelve un valor predeterminado, como 0
    }
    return cartContainer[productId]?.quantity ?? 0; 
}
  
  const JSXTable = {
    1: (
      <div
        style={{ flexWrap: "wrap", gap: biggerThan1440 ? "56px 16px" : "56px 24px"}}
        className={` ${
          biggerThan1440 ? "justify-content-between" : "justify-content-evenly"
        } d-flex mt-4 pt-3`}
      >
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[0]?.productId)} overdriveOnChange={overdriveOnChange} product={products[0]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[1]?.productId)} overdriveOnChange={overdriveOnChange} product={products[1]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[2]?.productId)} overdriveOnChange={overdriveOnChange} product={products[2]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[3]?.productId)} overdriveOnChange={overdriveOnChange} product={products[3]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[0]?.productId)} overdriveOnChange={overdriveOnChange} product={products[0]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[1]?.productId)} overdriveOnChange={overdriveOnChange} product={products[1]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[2]?.productId)} overdriveOnChange={overdriveOnChange} product={products[2]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[3]?.productId)} overdriveOnChange={overdriveOnChange} product={products[3]} />
      </div>
    ),
    2: (
      <div
        style={{ flexWrap: "wrap", gap: biggerThan1440 ? "" : "16px" }}
        className={` ${
          biggerThan1440 ? "justify-content-between" : "justify-content-evenly"
        } d-flex mt-4 pt-3`}
      >
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[0]?.productId)} overdriveOnChange={overdriveOnChange} product={products[0]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[1]?.productId)} overdriveOnChange={overdriveOnChange} product={products[1]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[2]?.productId)} overdriveOnChange={overdriveOnChange} product={products[2]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainer, products[3]?.productId)} overdriveOnChange={overdriveOnChange} product={products[3]} />
      </div>
    ),
    3: <></>,
    4: (
      <div
        style={{ flexWrap: "wrap", gap: biggerThan1440 ? "" : "16px" }}
        className={` ${
          biggerThan1440 ? "justify-content-between" : "justify-content-evenly"
        } d-flex mt-4 pt-3`}
      >
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainerProducts, products[0]?.productId)} product={products[0]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainerProducts, products[1]?.productId)} product={products[1]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainerProducts, products[2]?.productId)} product={products[2]} />
        <ProductCard discount={50} markCounterByAmount={getProductAmount(cartContainerProducts, products[3]?.productId)} product={products[3]} />
      </div>
    ),
  };

  return(
    <>
    {
      JSXTable[stepNumber]
    }
    </>
  )

}