import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchKitData = createAsyncThunk(
    "fetchKitData",
    async ({ clientId, countryCode }, { rejectWithValue }) => {
        try {
            const response = await Repository.PurchaseRepository.getKitData(clientId, countryCode);
            const sanitizedKits = JSON.parse(JSON.stringify(response.kits));

            return { kits: sanitizedKits, billedKit: response.billedKit };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
export const fetchGetOrders = createAsyncThunk(
    "fetchGetOrders",
    async ({ clientId, countryCode }, { rejectWithValue }) => {
        try {
            const responseOrders = await Repository.PurchaseRepository.getPurchaseOrders(clientId, countryCode, 2502);
            const responseRequest = await Repository.PurchaseRepository.getPurchaseRequests(clientId, countryCode);
            return { requests: responseRequest, orders: responseOrders };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getClientList = createAsyncThunk(
    'getClientList',
    async ({ clientId, countryCode }, { rejectWithValue }) => {
        try {
            const response = await Repository.ContactsRepository.getClientList(countryCode, clientId);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)