import { createAsyncThunk } from "@reduxjs/toolkit";
import { _base64ToArrayBuffer } from "../../../common/redux/commonSideEffects";
import customNetServAxios from "../../../common/services/NetSrv";

const fetchVirtualEcommerce  = createAsyncThunk(
    'fetchVirtualEcommerce',
    async (number, {rejectWithValue}) => {
        try {
            let country = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios(false,false,false,false,false,false,true).get(`${country}/profile/${clientId}/store`);
            
            return response?.data ?? response
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
);

const updateVirtualEcommerce  = createAsyncThunk(
    'fetchVirtualEcommerce',
    async (body, {rejectWithValue}) => {
        try {
            let country = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            console.log("body: ", body);
            const response = await customNetServAxios("application/json;charset=UTF-8", null, [400]).put(`${country}/profile/${clientId}/store`, body);
            return response?.data;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
);

const fetchVirtualEcommerceTopics  = createAsyncThunk(
    'fetchVirtualEcommerceTopics',
    async (number, {rejectWithValue}) => {
        try {
            let country = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${country}/profile/${clientId}/store/topics`);
            return response?.data;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
);

const createEcommerce = createAsyncThunk(
    'createEcommerce',
    async (data, { rejectWithValue }) => {
        try {
            let country = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const method = data.isUpdate ? "put" : "post";
            const response = await customNetServAxios("application/json;charset=UTF-8", null, [400])[method](
                `${country}/profile/${clientId}/store`, 
                data.body
            );

            return response?.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const activeMP = createAsyncThunk(
    'activeMP',
    async (data, { rejectWithValue }) => {
        try {
            let country = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");

            const response = await customNetServAxios("application/json;charset=UTF-8", null, [400]).get(
                `${country}/profile/${clientId}/store/mercadopago`

            );
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
const activeTokenMP = createAsyncThunk(
    'activeTokenMP',
    async (data, { rejectWithValue }) => {
        try {
            let country = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");

            const response = await customNetServAxios("application/json;charset=UTF-8", null, [400]).post(
                `${country}/profile/${clientId}/store/mercadopago`,{"clientId": clientId,"code":data });
           
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
const updateStoreImage = createAsyncThunk(
    "updateStoreImage",
    async (data, { rejectWithValue }) => {
        try {
            let country = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const body = {
                content: (data),
                source: "store" 
            }

            const response = await customNetServAxios("application/json;charset=UTF-8", null, [400]).post(
                `${country}/profile/${clientId}/store/image`,
                body
            );

            return response?.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

export { fetchVirtualEcommerce, fetchVirtualEcommerceTopics, updateVirtualEcommerce, createEcommerce, activeMP, updateStoreImage,activeTokenMP };