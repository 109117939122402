export default function OrdersModalShare(params) {
  const JSXStatus = {
    warning: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
      >
        <path
          d="M50.732 42.347L31.5904 9.12108C31.2172 8.47376 30.6907 7.93835 30.0616 7.56667C29.4326 7.19499 28.7225 7 28.0001 7C27.2777 7 26.5676 7.19499 25.9386 7.56667C25.3095 7.93835 24.7829 8.47376 24.4097 9.12108L5.26912 42.347C4.88543 43.0125 4.67763 43.7727 4.66694 44.5499C4.65624 45.3271 4.84307 46.0935 5.20828 46.7703C5.57349 47.4471 6.10394 48.0102 6.74541 48.4019C7.38688 48.7936 8.1163 49.0002 8.85899 49H47.1421C47.8845 48.9996 48.6136 48.7929 49.2547 48.401C49.8959 48.0091 50.4261 47.446 50.7912 46.7694C51.1563 46.0928 51.3432 45.3268 51.3328 44.5499C51.3223 43.7729 51.115 43.0126 50.732 42.347ZM28.0105 20.0801C28.0584 20.0744 28.1063 20.0695 28.1543 20.0676C28.3917 20.0569 28.6288 20.0956 28.852 20.1808C29.0752 20.266 29.2801 20.3963 29.4552 20.5644C29.6302 20.7326 29.7719 20.9352 29.8721 21.1607C29.9723 21.3862 30.0291 21.6301 30.0393 21.8785C30.0393 25.3885 29.6449 30.434 29.6449 33.944C29.6449 34.8584 28.687 35.2419 28.0105 35.2419C27.106 35.2419 26.3471 34.8584 26.3471 33.944C26.3471 30.434 25.9528 25.3885 25.9528 21.8785C25.9573 20.7293 26.8591 20.0801 28.0105 20.0801ZM28.0394 41.8228H28.0295C27.4542 41.8214 26.903 41.5809 26.4969 41.1545C26.0908 40.728 25.8631 40.1504 25.8637 39.5483C25.8644 38.9462 26.0934 38.369 26.5004 37.9435C26.9074 37.518 27.4591 37.2788 28.0344 37.2788C28.6097 37.2788 29.1615 37.518 29.5685 37.9435C29.9755 38.369 30.2045 38.9462 30.2052 39.5483C30.2059 40.1504 29.9782 40.728 29.5721 41.1545C29.166 41.5809 28.6147 41.8214 28.0394 41.8228Z"
          fill="#F2D149"
        />
      </svg>
    ),
    confirm: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
      >
        <path
          d="M24.7332 32.2L19.7165 27.1833C19.2887 26.7555 18.7443 26.5416 18.0832 26.5416C17.4221 26.5416 16.8776 26.7555 16.4498 27.1833C16.0221 27.6111 15.8082 28.1555 15.8082 28.8166C15.8082 29.4777 16.0221 30.0222 16.4498 30.45L23.0998 37.1C23.5665 37.5666 24.1109 37.8 24.7332 37.8C25.3554 37.8 25.8998 37.5666 26.3665 37.1L39.5498 23.9166C39.9776 23.4888 40.1915 22.9444 40.1915 22.2833C40.1915 21.6222 39.9776 21.0777 39.5498 20.65C39.1221 20.2222 38.5776 20.0083 37.9165 20.0083C37.2554 20.0083 36.7109 20.2222 36.2832 20.65L24.7332 32.2ZM27.9998 51.3333C24.7721 51.3333 21.7387 50.7208 18.8998 49.4958C16.0609 48.2708 13.5915 46.6083 11.4915 44.5083C9.3915 42.4083 7.729 39.9388 6.504 37.1C5.279 34.2611 4.6665 31.2277 4.6665 28C4.6665 24.7722 5.279 21.7388 6.504 18.9C7.729 16.0611 9.3915 13.5916 11.4915 11.4916C13.5915 9.39163 16.0609 7.72913 18.8998 6.50413C21.7387 5.27913 24.7721 4.66663 27.9998 4.66663C31.2276 4.66663 34.2609 5.27913 37.0998 6.50413C39.9387 7.72913 42.4082 9.39163 44.5082 11.4916C46.6082 13.5916 48.2707 16.0611 49.4957 18.9C50.7207 21.7388 51.3332 24.7722 51.3332 28C51.3332 31.2277 50.7207 34.2611 49.4957 37.1C48.2707 39.9388 46.6082 42.4083 44.5082 44.5083C42.4082 46.6083 39.9387 48.2708 37.0998 49.4958C34.2609 50.7208 31.2276 51.3333 27.9998 51.3333Z"
          fill="#16BE7D"
        />
      </svg>
    ),
    error: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
      >
        <path
          d="M50.732 42.347L31.5904 9.12108C31.2172 8.47376 30.6907 7.93835 30.0616 7.56667C29.4326 7.19499 28.7225 7 28.0001 7C27.2777 7 26.5676 7.19499 25.9386 7.56667C25.3095 7.93835 24.7829 8.47376 24.4097 9.12108L5.26912 42.347C4.88543 43.0125 4.67763 43.7727 4.66694 44.5499C4.65624 45.3271 4.84307 46.0935 5.20828 46.7703C5.57349 47.4471 6.10394 48.0102 6.74541 48.4019C7.38688 48.7936 8.1163 49.0002 8.85899 49H47.1421C47.8845 48.9996 48.6136 48.7929 49.2547 48.401C49.8959 48.0091 50.4261 47.446 50.7912 46.7694C51.1563 46.0928 51.3432 45.3268 51.3328 44.5499C51.3223 43.7729 51.115 43.0126 50.732 42.347ZM28.0105 20.0801C28.0584 20.0744 28.1063 20.0695 28.1543 20.0676C28.3917 20.0569 28.6288 20.0956 28.852 20.1808C29.0752 20.266 29.2801 20.3963 29.4552 20.5644C29.6302 20.7326 29.7719 20.9352 29.8721 21.1607C29.9723 21.3862 30.0291 21.6301 30.0393 21.8785C30.0393 25.3885 29.6449 30.434 29.6449 33.944C29.6449 34.8584 28.687 35.2419 28.0105 35.2419C27.106 35.2419 26.3471 34.8584 26.3471 33.944C26.3471 30.434 25.9528 25.3885 25.9528 21.8785C25.9573 20.7293 26.8591 20.0801 28.0105 20.0801ZM28.0394 41.8228H28.0295C27.4542 41.8214 26.903 41.5809 26.4969 41.1545C26.0908 40.728 25.8631 40.1504 25.8637 39.5483C25.8644 38.9462 26.0934 38.369 26.5004 37.9435C26.9074 37.518 27.4591 37.2788 28.0344 37.2788C28.6097 37.2788 29.1615 37.518 29.5685 37.9435C29.9755 38.369 30.2045 38.9462 30.2052 39.5483C30.2059 40.1504 29.9782 40.728 29.5721 41.1545C29.166 41.5809 28.6147 41.8214 28.0394 41.8228Z"
          fill="#F06E69"
        />
      </svg>
    ),
  };

  const backgroundColor = {
    warning: "#FEFBEC",
    confirm: "#E7F5F0",
    error: "#FEF0F0",
  };

  const JSXButtonStyles = {
    skyBg: {
      background: "#5AAFF1",
      color: "#FFF",
    },
    skyBorder: {
      border: "1px solid #5AAFF1",
      color: "#5AAFF1",
    },
    redColor: {
      color: "#F06E69",
    },
  };

  return (
    <div style={{ padding: "32px" }} className=" position-relative">
      <div
        data-bs-dismiss="modal"
        aria-label="Close"
        style={{ right: "16px", top: "16px" }}
        className=" position-absolute pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.92861 4.9293C5.33662 4.52129 5.99813 4.52129 6.40614 4.9293L19.0707 17.5939C19.4788 18.0019 19.4788 18.6634 19.0707 19.0714C18.6627 19.4794 18.0012 19.4794 17.5932 19.0714L4.92861 6.40684C4.5206 5.99883 4.5206 5.33731 4.92861 4.9293Z"
            fill="#908F9A"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.92861 19.0707C4.5206 18.6627 4.5206 18.0012 4.92861 17.5932L17.5932 4.92856C18.0012 4.52055 18.6627 4.52055 19.0707 4.92856C19.4788 5.33657 19.4788 5.99809 19.0707 6.4061L6.40614 19.0707C5.99813 19.4787 5.33662 19.4787 4.92861 19.0707Z"
            fill="#908F9A"
          />
        </svg>
      </div>
      <div style={{ gap: "32px" }} className="d-flex flex-column">
        <div className="d-flex flex-column gap-3 align-items-center">
          <div
            style={{
              background: backgroundColor[params?.modalData?.modalStatus],
              height: "104px",
              width: "104px",
            }}
            className="d-flex justify-content-center align-items-center rounded-circle "
          >
            {JSXStatus[params?.modalData?.modalStatus]}
          </div>
          <div
            style={{ gap: "12px" }}
            className="d-flex flex-column justify-content-center align-items-center text-center"
          >
            <p className="s24 museo-sans-600">
              {params?.modalData?.modalTitle ?? "¿Cómo quieres continuar?"}
            </p>
            <p
              className="s16 grayColor museo-sans-400"
              style={{ marginTop: "12px" }}
            >
              {params?.modalData?.modalDescription ??
                "Puedes enviarla a Just individualmente o sumarla a un conjunto de órdenes y compartir los gastos de envío"}
            </p>
          </div>
        </div>
        <div className="d-flex flex-column gap-3">
          {params?.modalData?.modalButtons
            ? params?.modalData?.modalButtons?.map((button, index) => {
                return (
                  <div className="d-flex justify-content-center w-100">
                    <div
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={button?.onClick}
                      style={JSXButtonStyles[button?.style]}
                      className=" d-flex justify-content-center align-items-center s14 museo-sans-600 btnLoginWidth rounded-3 pointer "
                    >
                      {button?.text}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
}
