import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

import { getTerritoryByLevel } from "../../../ClientList/Framework/redux/ClientListSideEffect";
import { multiLangJSONParse } from "../../../common/Share/MultiLangJSONParse";
import ShareComponentInput from "../../../common/Share/ShareComponentInput";
import ShareLocalityController from "../../../common/Share/ShareLocalityController";
import { body } from "../../Framework/EDBsFormBody";
import { deactivateError } from "../../Framework/redux/EDBFormActions";
import { ErrorMessage } from "../pages/EDBsForm";

import ShareInputWithSelectableValue from "../../../common/Share/ShareInputWithSelectableValue";

export default function EDBsFormPresent({togglerSelectForm}) {
  // Force push by adding comment.
  const country = localStorage.getItem("country");
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const valueFound = useSelector(
    (state) => state.clientListData.clientFormTerritory.data
  );
  const errorToggler = useSelector(
    (state) => state.edbFormData.errorTogger.value
  );
  const [locationsByLevel, setLocationsByLevel] = useState({
    levelOne: [],
    levelTwo: [],
    levelThree: [],
    levelFour: [],
  });
  const [form, setForm] = useState(ShareLocalityController(country));
  const [fatherURL, setFatherURL] = useState({
    levelOne: "",
    levelTwo: "",
    levelThree: "",
    levelFour: "",
  });
  const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
  const [addressInfo, setAddressInfo] = useState(
    auxFormBody?.addressInfo || body.addressInfo
  );
  const levelArray = ["levelOne", "levelTwo", "levelThree", "levelFour"];
  const [formSelectToggler, setFormSelectToggler] = useState(
    ShareLocalityController(country)?.references?.filter((ref) => ref.isLevel !== -1).map((ref) => ({
      ...ref, 
      toggler: false,
    }))
  );

  const isReferenceOptional = () => {
    const objectReferenceExist = {
      mx: true,
      pe: true,
    }
    return objectReferenceExist[country] || false;
  };

  const onStartUp = async () => {
    setLocationsByLevel({
      levelOne: [],
      levelTwo: [],
      levelThree: [],
      levelFour: [],
    });
    loadByLevels(1);
  };

  const removeAccents = (str) => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  const compareStrings = (str1, str2) => {
    const normalizedStr1 = removeAccents(str1).replace("°","");
    const normalizedStr2 = removeAccents(str2).replace("°","");;
    return normalizedStr1 === normalizedStr2;
  };

  const loadByLevels = async (level, id = null) => {
    const result = await dispatch(
      getTerritoryByLevel({ level: level, value: id })
    );
    const found = form.references.find((ref) => ref.isLevel === level);
    if (result?.payload?.data?.locations && addressInfo[found?.name]) {
      const id = result?.payload?.data?.locations.find((loc) => {
        return compareStrings(loc.value, addressInfo[found?.name]);
      })?.id;
      if (id && level !== form.maxLevel) {
        loadByLevels(level + 1, id);
      }
    }
  };

  const onChangeAuxFormBody = (key, value) => {
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
    let updateAddressInfo = auxFormBody.addressInfo ?? body.addressInfo;
    updateAddressInfo = { ...updateAddressInfo, [key]: value };
    let completedForm = { ...auxFormBody, addressInfo: updateAddressInfo };
    localStorage.setItem("formBody", JSON.stringify(completedForm));
    return updateAddressInfo;
  };

  const onChangeFatherURL = (level, newUrl) => {
    const auxFatherUrl = { ...fatherURL };
    dispatch(deactivateError());

    if (level === 2) {
      setFatherURL({ ...auxFatherUrl, levelOne: newUrl });
    }
    if (level == 3) {
      setFatherURL({ ...auxFatherUrl, levelTwo: newUrl });
    }
    if (level == 4) {
      setFatherURL({ ...auxFatherUrl, levelThree: newUrl });
    }

    return newUrl;
  };

  const onChangeFatherURLRemoveObjectsValue = (level, resultAddressInfoData) => {
    const expectedValues = ShareLocalityController(country);
    let objectsWithSuperiorLevel = expectedValues.references.filter( (key) => key.isLevel > level);
    let copyAdressInfo = {...resultAddressInfoData};

    objectsWithSuperiorLevel.forEach((object) => {
      copyAdressInfo[object.name] = "";
    });

    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
    let updateAddressInfo = auxFormBody.addressInfo ?? body.addressInfo;
    updateAddressInfo =  copyAdressInfo;
    let completedForm = { ...auxFormBody, addressInfo: updateAddressInfo };
    localStorage.setItem("formBody", JSON.stringify(completedForm));

    setAddressInfo(updateAddressInfo);
  };

  const onChangeSelectedFather = async (level, data) => {
    if (level === 4) return "";

    const newLocations = {
        levelOne: locationsByLevel.levelOne,
        levelTwo: level >= 2 ? locationsByLevel.levelTwo : [],
        levelThree: level >= 3 ? locationsByLevel.levelThree : [],
        levelFour: level >= 4 ? locationsByLevel.levelFour : [],
    };

    setLocationsByLevel(newLocations);
    if (form?.maxLevel === level) {
      return;
    }
    if (data?.value) {
      await dispatch(
        getTerritoryByLevel({
          level: level + 1,
          value: onChangeFatherURL(level + 1, data.value),
        })
      );
      
    }
  };

  const onChangeAddressSelect = (item) => {
    if (!item?.label) {
      return "";
    }
    const resultAddressInfoData = onChangeAuxFormBody(item.key, item.label);
    onChangeSelectedFather(item.level, item);
    onChangeFatherURLRemoveObjectsValue(item.level, resultAddressInfoData);
  };

  const onChangeAddressInput = (key, empty, value) => {
    if (!key) {
      return "";
    }
    const resultAddressInfoData = onChangeAuxFormBody(key, value);
    onChangeFatherURLRemoveObjectsValue(10, resultAddressInfoData);
    dispatch(deactivateError());
  };

  const returnOptionalIf = (reference) => {
    if (reference?.name == "reference" && isReferenceOptional()) {
      return multiLangJSONParse()["selected.placeholder.optional"];
    }
    return "";
  };

  const noErrorOnOptional = (reference) => {
    if (reference?.name == "reference" && isReferenceOptional()) {
      return false;
    }
    if (reference?.name == "neighborhood" && country == "uy") {
      return false;
    }
    return errorToggler && !addressInfo[reference.name];
  };

  const onTogglerFormSelect = (name) => {
    const updatedFormSelectToggler = formSelectToggler.map((ref) => {
      if (ref.name === name) {
        return { ...ref, toggler: !ref.toggler };
      }
      if (ref.name !== name) {
        return { ...ref, toggler: false };
      }
      return ref;
    });
    setFormSelectToggler(updatedFormSelectToggler);
  };

  const onInputChangeFromSelect = (level, prevData) => {
    onChangeSelectedFather(level);
    onChangeFatherURLRemoveObjectsValue(level, prevData);
  };

  const renderAuxElement = (reference) => {
    if (reference.isLevel === -1) {
      return (
        <div className="d-flex flex-column gap-2">
          <ShareComponentInput
            errorToggler={noErrorOnOptional(reference)}
            key={reference.name}
            value={addressInfo[reference?.name] || ""}
            label={`${
              multiLangJSONParse()[`${reference.name}`]
            } ${returnOptionalIf(reference)}  `}
            name={reference.name}
            placeholder={multiLangJSONParse()[`selected.${reference.name}`]}
            onChange={onChangeAddressInput}
          />
          <ErrorMessage errorToggler={noErrorOnOptional(reference)} />
        </div>
      );
    } else {

      return (
        <div className="d-flex flex-column gap-2" key={reference.name}>
          <p>
            <FormattedMessage id={reference.name} />{" "}
          </p>
          <ShareInputWithSelectableValue
              key={reference.name}
              width={"100%"} 
              isError={ reference?.name == "neighborhood" && country == "uy" ? false : (errorToggler && !addressInfo[reference.name])} 
              list={locationsByLevel[levelArray[reference.isLevel - 1]] || null}
              IsDisabled={locationsByLevel[levelArray[reference.isLevel - 1]].length == 0}
              onChangeValue={onChangeAddressSelect}
              placeholder={multiLangJSONParse()[`selected.${reference.name}`]}
              inheritValue={addressInfo[reference?.name] || ""}
              name={reference.name}
              togglerSelect={formSelectToggler.find((ref) => ref.name == reference.name).toggler}
              setTogglerSelect={onTogglerFormSelect}
              level={reference.isLevel}
              onLostSavedValue={onInputChangeFromSelect}
              />
          {
            <>
            {
              (reference.name == "neighborhood" && country == "uy") ?
              <>
              </>
              :
              <ErrorMessage
                errorToggler={errorToggler && !addressInfo[reference.name]}
              />

            }
            
            </>
          }
        </div>
      );
    }
  };

  useEffect(() => {
    if (!valueFound) return;

    const { levelOne, levelTwo, levelThree, levelFour } = valueFound;

    const updateLevel = (level, levelKey) => {
      if (!level || !level.locations) return;

      const updatedLocations = level.locations.map((location) => ({
        value: location.id,
        label: location.value,
      }));

      setLocationsByLevel((prevState) => ({
        ...prevState,
        [levelKey]: updatedLocations,
      }));
    };

    updateLevel(levelOne, "levelOne");
    updateLevel(levelTwo, "levelTwo");
    updateLevel(levelThree, "levelThree");
    updateLevel(levelFour, "levelFour");
  }, [valueFound]);

  useEffect(() => {
    onStartUp();
  }, []);

  useEffect(() => {
    onTogglerFormSelect("none")
  }, [togglerSelectForm])

  return (
    <div ref={formRef} className="edb-form-grid gap-3">
      {form?.references && addressInfo && (
        <>
          {form.references.map((reference) => {
            return renderAuxElement(reference);
          })}
        </>
      )}
    </div>
  );
}
