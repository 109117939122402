export default function ShareModalStructureCloseIcon(params) {
    
    return(
        <div
        data-bs-dismiss="modal"
        aria-label="Close"
        style={{ right: params?.right ?? params?.top, top: params?.top }}
        className=" position-absolute pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.92861 4.9293C5.33662 4.52129 5.99813 4.52129 6.40614 4.9293L19.0707 17.5939C19.4788 18.0019 19.4788 18.6634 19.0707 19.0714C18.6627 19.4794 18.0012 19.4794 17.5932 19.0714L4.92861 6.40684C4.5206 5.99883 4.5206 5.33731 4.92861 4.9293Z"
            fill="#908F9A"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.92861 19.0707C4.5206 18.6627 4.5206 18.0012 4.92861 17.5932L17.5932 4.92856C18.0012 4.52055 18.6627 4.52055 19.0707 4.92856C19.4788 5.33657 19.4788 5.99809 19.0707 6.4061L6.40614 19.0707C5.99813 19.4787 5.33662 19.4787 4.92861 19.0707Z"
            fill="#908F9A"
          />
        </svg>
      </div>
    )
}