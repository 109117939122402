import { Navigate, Outlet, useLocation } from "react-router-dom";
import PublicLayout from "../UI/PublicLayout";
import PrivateLayout from "../UI/PrivateLayout";
import { createContext, useState } from "react";
import ShareModalClipboard from "../Share/ShareModalClipboard";
import PoliticsModal from "../termsAndConditions/PoliticsModal";
import EthicsCodeContent from "../termsAndConditions/EthicsCodeContent";
import { FormattedMessage } from "react-intl";
import Loader from "../Share/Loader";
import EcommerceDashboardModalProfileStartNav from "../../Ecommerce/Presentation/components/modals/EcommerceDashboardModalProfileStartNav";

const ModalClipboardContext = createContext();

export function PrivateRoute() {
  const token = localStorage.getItem("token");
  return token ? <PrivateLayout /> : <Navigate replace to="/" />;
}
export function PublicRoute() {
  const location = useLocation();

  const [modalClipboardData, setModalClipboardData] = useState({
    title: "",
    text: "",
    status: "confirm",
    show: false,
    bottom: 0,
  });

  const toggleModalClipboard = () => {
    setModalClipboardData({
      ...modalClipboardData,
      show: !modalClipboardData.show,
    });
  };

  const setModalTexts = (params) => {
    setModalClipboardData({
      bottom: params.bottom,
      title: params.title,
      text: params.text,
      status: params.status,
      height: params.height,
      show: true,
    });
  };

  return (
    <ModalClipboardContext.Provider value={{ setModalTexts }}>
      <ShareModalClipboard
        data={modalClipboardData}
        close={toggleModalClipboard}
      />
      <div
      
      style={{right: "100px", bottom: "100px", zIndex: 500}}
      data-bs-toggle="modal"
          data-bs-target="#edb-virtual-builded"
          id="edb-virtual-builded-target" 
          className=" invisible position-absolute bg-danger" >
              Show me what I want
      </div>
      <PublicModalScroller />
      <EcommerceDashboardModalProfileStartNav />
      {location?.pathname?.indexOf("/dashboard") === -1 ? (
        <>
          <PoliticsModal
            id="ethicsCode"
            title={<FormattedMessage id="ethics.code.title" />}
          >
            <EthicsCodeContent />
          </PoliticsModal>
          <PublicLayout />
        </>
      ) : (
        <Outlet />
      )}
    </ModalClipboardContext.Provider>
  );
}
export function PublicModalScroller(params) {

  const [scroller, setScroller] = useState(false)
  
  return(
    
    <>
    {
      false &&
      <div style={{zIndex: 999999}} className="  position-absolute d-flex justify-content-center align-items-center  w-100 h-100 " >
      <div style={{background: "black", opacity: 0.4}} className=" position-absolute  w-100 h-100 " >
        a
        <div className=" position-absolute invisible" >
            .
        </div>
      </div>
      <div style={{width: "150px", height: "150px"}} className=" skyColor position-relative  " >
        <Loader loading={true} />
      </div>
    </div>
    }
    </>
  )
}

export { ModalClipboardContext };
