import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const getScoreComparison = createAsyncThunk(
    'getScoreComparison',
    async ({level} , {rejectWithValue}) => {
        try {
            const location = localStorage.getItem("country");
            const clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/performance/${clientId}/score?comparisonLevel=${level}`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getQualifiedGroup = createAsyncThunk(
    'getQualifiedGroup',
    async ({level} , {rejectWithValue}) => {
        try {
            const location = localStorage.getItem("country");
            const clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/performance/${clientId}/score/qualified-groups?comparisonLevel=${level}`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getBussinessPlan = createAsyncThunk(
    'getBussinessPlan',
    async ({level} , {rejectWithValue}) => {
        try {
            const location = localStorage.getItem("country");
            const clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/business-parameters/${clientId}/business-plan/level/${level}`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

export {  getScoreComparison , getBussinessPlan, getQualifiedGroup }
