import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { passwordChanged, checkedChanged, activeProfileError } from "./loginAction";

import {
  fetchAuthLogin,
  fetchAssociateSms,
  fetchPassRecovery,
  fetchUserProfile,
  fetchAuthRegister,
  fetchAuthenticator,
  resolveChangePassword,
  fetchDirectorProfile,
  fetchclientWithDirectorId
} from "./loginSideEffects";

const initialState = {
  userInfo: {},
  directorInfo: {
    data: null,
    error: false,
    loader: false,
  },
  userProfile: {},
  authenticatorCode: "",
  pin: "",
  message: "",
  changingPassword: false,
  loader: false,
  profileError: false,
};

const loginReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(fetchclientWithDirectorId.fulfilled, (state, action) => {
      state.userInfo = action.payload;
      state.loader = false;
    } )

    .addCase(fetchDirectorProfile.fulfilled, (state, action) => {
      state.directorInfo.data = action.payload;
      if (action?.payload?.accessToken) {
        action.payload.accessToken && localStorage.setItem("token", `Bearer ${action.payload.accessToken}`);
        localStorage.setItem(
          "refresh",
          `Bearer ${action.payload.refreshToken}`
        );
        localStorage.setItem("token_time", new Date().getTime() + action.payload.accessTokenExpires * 1000)
      }
      state.directorInfo.error = false;
      state.directorInfo.loader = false;
    })

    .addCase(fetchDirectorProfile.pending, (state, action) => {
      state.directorInfo.error = false;
      state.directorInfo.loader = true;
    })

    .addCase(fetchDirectorProfile.rejected, (state, action) => {
      state.directorInfo.error = true;
      state.directorInfo.loader = false;
    })

    .addCase(resolveChangePassword.fulfilled, (state, action) => {
      state.loader = false;
    })

    .addCase(checkedChanged, (state, action) => {
      state.changingPassword = false
      state.loader = false;
    })

    .addCase(passwordChanged, (state, action) => {
      state.changingPassword = true
      state.loader = false;
    })

    .addCase(fetchAuthLogin.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.accessToken) {
        state.userInfo = action.payload;
        action.payload.accessToken && localStorage.setItem("token", `Bearer ${action.payload.accessToken}`);
        localStorage.setItem(
          "refresh",
          `Bearer ${action.payload.refreshToken}`
        );
        localStorage.setItem("token_time", new Date().getTime() + action.payload.accessTokenExpires * 1000)
      }
    })

    .addCase(fetchAuthRegister.fulfilled, (state, action) => {
      state.loader = false;
      state.userInfo = action.payload;
      if (action.payload.authenticatorToken) {
        localStorage.setItem(
          "token",
          `Bearer ${action.payload.authenticatorToken}`
        );
        
      }
      localStorage.setItem("token_time", new Date().getTime() + 3600 * 1000);
    })

    .addCase(fetchAuthenticator.fulfilled, (state, action) => {
      state.loader = false;
      if (action.payload.accessToken) {
        localStorage.setItem(
          "token",
          `Bearer ${action.payload.accessToken}`
        );
        
      }
    }) 

    .addCase(fetchPassRecovery.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.message) {
        state.message = action.payload.message;
        if (action.payload.authenticatorToken) {
          localStorage.setItem(
            "token",
            `Bearer ${action.payload.authenticatorToken}`
          );
          
        }
      }
    })

    .addCase(fetchAssociateSms.fulfilled, (state, action) => {
      state.userProfile = action.payload;
      state.loader = false;
    })

    .addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.userInfo = action.payload;
      state.loader = false;
      state.profileError = false;
    })

    .addCase(fetchUserProfile.rejected, (state, action) => {
      state.loader = false;
      state.profileError = true;
    })

    .addCase(activeProfileError, (state, action) => {
      state.profileError = true;
    })


    .addMatcher(
      isAnyOf(
        fetchAuthLogin.pending,
        fetchAssociateSms.pending,
        fetchPassRecovery.pending,
        fetchUserProfile.pending,
        fetchAuthRegister.pending,
        resolveChangePassword.pending,
        fetchAuthenticator.pending,
        fetchclientWithDirectorId.pending
      ),
      (state, action) => {
        state.loader = true;
      }
    )
    .addMatcher(
      isAnyOf(
        fetchAuthLogin.rejected,
        fetchAssociateSms.rejected,
        fetchPassRecovery.rejected,
        fetchAuthRegister.rejected,
        resolveChangePassword.rejected,
        fetchAuthenticator.rejected,
        fetchclientWithDirectorId.rejected
      ),
      (state, action) => {
        state.loader = false;
      }
    );
});

export default loginReducer;
