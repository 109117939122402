import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import ShareModalStructure from "../../../../common/Share/ShareModalStructure";
import OrdersModalShare from "../modals/OrdersModalShare";
import OrderCartKitHolder from "../OrdersCartKitHolder";
import OrdersCartOfertsFeature from "../OrdersCartOfertsFeature";
import OrdersCartProduct from "../OrdersCartProduct";
import OrderCartModalOfertsList from "../modals/OrderCartModalOfertsList";
import SummaryOrdersList from "../summaryOrdersList/summaryOrdersList";
import ArrowRightIcon from "../../../imgs/Arrow-Right.png";
import ModalAllProducts from "../modals/modalAllProducts/modalAllProducts";
import OrdersPromotion from "../ordersPromotion/ordersPromotion";
import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories";
import {
  OrderCartEmpty,
  OrderCartSummary,
  OrderCartButtonContainer,
} from "../../pages/OrdersCart";
import OrderCartFree from "../ordersFree/ordersFree";
export default function SummaryOrders() {
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    onStartUp();
  }, []);
  const cartContainer = useSelector(
    (state) => state.orderData.cart.cartContainer
  );
  const cartKit = useSelector((state) => state.orderData.cart.cartKit);
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const frirstSteps = useSelector(
    (state) => state.orderData.cart.cartFirstStepsProducts
  );

  const orderDataCount = useSelector((state) => state.orderData.ordersSelectCount);
  const productsSelect = useSelector((state) => state.orderData.productsSelect);
  const onStartUp = () => {
    onCallService();
  };

  const cartCounter = useSelector((state) => state.orderData.cart.cartCounter);
  const [cartBody, setCartBody] = useState({ cart: {}, isLoading: true });
  const [hasChanged, setHasChanged] = useState(false);
  const [forcedChange, setForcedChange] = useState(false);

  const ifValueAPrice = (amount) => {
    return formatAmount(
      amount,
      cultureInfo?.languageCode,
      cultureInfo?.defaultCurrencyCode
    );
  };
  const aChangeHasOccured = () => {
    setHasChanged(true);
  };

  const onCallService = async (cartBody) => {
    const arrayProducts = Object.values(cartContainer);

    if (arrayProducts?.length < 1) {
      setCartBody({
        cart: {},
        isLoading: false,
      });
      return;
    };

    let cartLocalStorage = localStorage.getItem("cart");
    let cartLocalJSON = JSON.parse(cartLocalStorage);

    const requestCart = {
      id: cartLocalJSON?.id || null,
      orderType: "personal",
      contactId: null,
      edbData: null,
      orderProducts: arrayProducts,
    };

    let countryCode = localStorage.getItem("country");
    let clientId = localStorage.getItem("clientId");

    let response = await Repository.CartRepository.updateCart(
      clientId,
      countryCode,
      cartBody?.cart ?? requestCart
    );

    const orderProductsFull = response?.orderProducts.filter(
      (element) => element.quantity > 0
    );

    const updatedResponse = {
      ...response,
      orderProducts: orderProductsFull,
    };

    let addIdToLocalStorage = {
      ...cartLocalJSON,
      id: response.id,
    };

    localStorage.setItem("cart", JSON.stringify(addIdToLocalStorage));

    setCartBody({
      cart: updatedResponse,
      isLoading: false,
    });

    return "OK";
  };

  const onListProductsUpdate = (product, operation) => { };
  const onPromotionsChange = (product, operation) => { };
  const aChangeHasFalsed = () => {
    setForcedChange(true);
    setHasChanged(false);
  };
  const onUpdateCommand = async () => {
    setHasChanged(true);
  };

  const JSXButtonsAndFunctionsForModals = {
    sendOrder: {
      modalId: "modal-send-order",
      modalTitle: "¿Cómo quieres continuar?",
      modalDescription:
        "Puedes enviarla a Just individualmente o sumarla a un conjunto de órdenes y compartir los gastos de envío",
      modalStatus: "warning",
      modalButtons: [
        {
          text: "Enviar esta orden únicamente",
          style: "skyBorder",
          onClick: () => {
            console.log("Enviar a Just");
          },
        },
        {
          text: "Seleccionar más órdenes a enviar",
          style: "skyBorder",
          onClick: () => {
            console.log("Sumar a un conjunto de órdenes");
          },
        },
        {
          text: "Cancelar",
          style: "redColor",
          onClick: () => {
            console.log("Sumar a un conjunto de órdenes");
          },
        },
      ],
    },
    endAndSaveOrder: {
      modalId: "modal-end-and-save-order",
      modalTitle: "¡Orden guardada con éxito!",
      modalDescription:
        "Recuerda que puedes enviarla a Just individualmente o sumarla a un conjunto de órdenes y compartir los gastos de envío",
      modalStatus: "confirm",
      modalButtons: [
        {
          text: "Crear otra orden",
          style: "skyBg",
          onClick: () => {
            console.log("Enviar a Just");
          },
        },
        {
          text: "Ver mis órdenes",
          style: "skyBorder",
          onClick: () => {
            console.log("Sumar a un conjunto de órdenes");
          },
        },
      ],
    },
    endOrder: {
      modalId: "modal-end-order",
      modalTitle: "Salir del pedido",
      modalDescription:
        "¿Estás seguro de que quieres salir? Si sales ahora, el pedido se desconsolidará y tendrás que volver a seleccionar las órdenes que desees enviar.",
      modalStatus: "error",
      modalButtons: [
        {
          text: "Volver al pedido",
          style: "skyBorder",
          onClick: () => {
            console.log("Enviar a Just");
          },
        },
        {
          text: "Salir sin guardar",
          style: "redColor",
          onClick: () => {
            goBack();
          },
        },
      ],
    },
  };

  const goBack = () => {
    navigate(`/dashboard/orders/listado-kit`, { state: { isOrders: true } });
  }

  return (
    <>
      <ShareModalStructure maxWidth={"496px"} id={"modal-end-order"}>
        <OrdersModalShare
          modalData={JSXButtonsAndFunctionsForModals["endOrder"]}
          status={"error"}
        />
      </ShareModalStructure>
      <div
        style={{ marginTop: "48px", marginBottom: "52px" }}
        className="d-flex flex-column museo-sans-400 "
      >
        <p className=" museo-sans-600 s24 mb-4">Resumen de pedido</p>
        <div
          style={{ padding: "40px" }}
          className="w-100 bg-white  box-shadow rounded-3"
        >
          <div className="mb-4">
            <div className="d-flex justify-content-between w-100">
              <p className=" s20 museo-sans-600">
                Órdenes seleccionadas{" "}
                <span
                  className="s16 museo-sans-600 pointer"
                  style={{ marginLeft: "6px", color: "#5AAFF1" }}
                  onClick={goBack}
                >
                  Modificar
                </span>
              </p>
            </div>
            <div
              className="d-flex justify-content-between w-100"
              style={{ marginTop: "8px", cursor: "pointer" }}
             onClick={openModal} 
            >
              <p className="s16 museo-sans-600" style={{ color: "#5AAFF1" }}>
                Ver todos los productos ({orderDataCount}){" "}
                <span>
                  <img src={ArrowRightIcon} alt="" />
                </span>
              </p>
            </div>
          </div>
          <SummaryOrdersList></SummaryOrdersList>
          {!hasChanged && (
            <>
              {cartBody?.cart?.spontaneousProducts?.length > 0 && (
                <OrdersPromotion data={cartBody?.cart?.promotionsProducts} />
              )}
              <OrderCartFree products={cartBody?.cart?.spontaneousProducts} />
              <OrderCartSummary
                cartSummary={cartBody?.cart?.cartSummary}
                isSummary={true}
              />
            </>
          )}
        </div>
        <OrderCartButtonContainer
          hasChanged={hasChanged}
          aChangeHasFalsed={aChangeHasFalsed}
          isSummary={true}
          navigate={navigate}
        />
      </div>
      <ModalAllProducts
        isOpen={isModalOpen}
        onClose={closeModal}
        data={{productsSelect,orderDataCount}}
      />
    </>
  );
}
