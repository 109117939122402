import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import "./cardProduct.css";
import changIcon from "../../../../common/img/consultationCatalogue/icon.png";
import addIcon from "../../../../common/img/consultationCatalogue/_Asset_Icon_Add.png";
import substractIcon from "../../../../common/img/consultationCatalogue/_Asset_Icon_Substract.png";
import trashIcon from "../../../../common/img/consultationCatalogue/_Asset_Icon_Trash.png";
import trashIcon2 from "../../../../common/img/consultationCatalogue/_Asset_Icon_Trash 2.png";
import addIcon2 from "../../../../common/img/consultationCatalogue/Vector.png";
import substractIcon2 from "../../../../common/img/consultationCatalogue/Vector.svg";
import {
  addToCart,
  removeFromCart,
} from "../../../../Orders/Framework/redux/OrdersActions";
import { formatAmount } from "../../../../common/Share/FormatCurrency";

export default function ProductCard({
  product,
  overdriveOnChange,
  discount,
  markCounterByAmount,
  isLoading,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredplus, setIsHoveredplus] = useState(false);
  const [isHoveredminus, setIsHoveredminus] = useState(false);
  const [isHoveredminustrash, setIsHoveredminustrash] = useState(false);
  const [count, setCount] = useState(0);
  const cartContainer = useSelector(
    (state) => state.orderData.cart.cartContainer
  );
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const dispatch = useDispatch();

  const plusCount = () => {
    if (overdriveOnChange) {
      overdriveOnChange("add", product);
      return;
    }
    dispatch(addToCart(product));
  };
  const minusCount = () => {
    if (overdriveOnChange) {
      overdriveOnChange("remove", product);
      return;
    }
    dispatch(removeFromCart(product));
  };

  useEffect(() => {
    if (markCounterByAmount > -1) {
      setCount(markCounterByAmount);
      return;
    }
    if (cartContainer[product?.productId]) {
      setCount(cartContainer[product?.productId].quantity);
      setIsHoveredminus(false);
      setIsHoveredminustrash(false)
    }
    if (!cartContainer[product?.productId]) {
      setCount(0);
      setIsHoveredminus(true);
    }
  }, [cartContainer, markCounterByAmount]);

  return (
    <div
      className="product-card"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="product-image position-relative ">
        {discount && (
          <div style={{ left: "0px" }} className=" position-absolute">
            <div
              style={{ background: "#ECF0FA", width: "67px", top: "12px" }}
              className=" d-flex justify-content-center align-items-center blueColor museo-sans-700 s12 text-uppercase mx-2 rounded-3 position-relative "
            >
              {discount ? `${discount}% OFF` : "50% OFF"}
            </div>
          </div>
        )}
        <img src={product?.img} alt={product?.name} />
      </div>
      <div className="product-details">
        <div>
          <div style={{ height: "124px", padding: "16px 20px 0px 20px" }}>
            <h2 className="product-title">{product?.name}</h2>
            <p className="product-description">{product?.description}</p>
          </div>
          <OrderProductCardPrice
            discount={discount}
            product={product}
            cultureInfo={cultureInfo}
          />
        </div>
        <div style={{ padding: "16px", borderTop: "1px solid #DDDDDD" }}>
          <>
            {count < 1 ? (
              <button
                className="product-button"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={plusCount}
              >
                {isHovered ? (
                  <span className="chang-text">Agregar al carrito</span>
                ) : (
                  <img
                    style={{ width: "27px", height: "24px" }}
                    src={changIcon}
                    alt="changIcon"
                    className="chang-icon"
                  />
                )}
              </button>
            ) : (
              <div
                className="product-button2 dm-sans-medium"
                style={{ justifyContent: "space-between" }}
              >
                <button
                  className="buttonTrashAndMinus"
                  onMouseEnter={() => setIsHoveredminustrash(true)}
                  onMouseLeave={() =>setIsHoveredminustrash(false)}
                  onClick={minusCount}
                >
                  {count === 1 ? (
                    <>
                      <img
                        src={!isHoveredminustrash ? trashIcon : trashIcon2}
                        alt="trashIcon"
                        className="trash-icon"
                      />
                    </>
                  ) : (
                    <>
                      {isHoveredminus ? (
                        <img
                          src={substractIcon2}
                          alt="substractIcon"
                          className="substract-icon"
                        />
                      ) : (
                        <img src={substractIcon} alt="substractIcon" />
                      )}
                    </>
                  )}
                </button>
                {count}
                <button
                  className="buttonPlus"
                  style={{ backgroundColor: isHoveredplus ? "#5AAFF1" : "" }}
                  onMouseEnter={() => setIsHoveredplus(true)}
                  onMouseLeave={() => setIsHoveredplus(false)}
                  onClick={plusCount}
                >
                  <>
                    {!isHoveredplus ? (
                      <img src={addIcon} alt="plusIcon" className="plus-icon" />
                    ) : (
                      <img
                        src={addIcon2}
                        alt="plusIcon2"
                        className="plus-icon2"
                      />
                    )}
                  </>
                </button>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
}

export function OrderProductCardPrice(params) {

  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const measureDiscount = () => {
    const texto = "$ 25.242";
    const numeroEntero = parseInt(texto.replace(/[^0-9]/g, ""), 10);
    const discountedNumber = numeroEntero - numeroEntero * 0.5;
    return formatAmount(
      discountedNumber,
      cultureInfo?.languageCode,
      cultureInfo?.defaultCurrencyCode
    );
  };

  return (
    <div className="d-flex">
      <p className="product-price position-relative dm-sans-bold ">
        {params.discount ? measureDiscount() : params?.product?.price}
      </p>
      {params?.discount && (
        <p
          style={{ left: "-8px" }}
          className="text-decoration-line-through s16 dm-sans-medium position-relative "
        >
          {params?.product?.price}
        </p>
      )}
    </div>
  );
}
