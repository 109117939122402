import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as SVGHelp } from "../../Icons/Help.svg";
import { ReactComponent as SVGCopy } from "../../../common/svgs/ic-copy-md.svg";
import ImgMercadoPago from "../../Imgs/mercado-pago-method.png";
import ImgCash from "../../Imgs/cash-method.png";
import BarCode from "../../Imgs/bar-code.png"
import { useSelector } from 'react-redux';
import { payments, formatNumber } from '../../common';

function PaymentMethodModal({ selectedPayment, setSelectedPayment, open, showModal, setShowModal, isBalanceEnough, partialAmount, selectedMethods, totalBalance, selectedJustCoins, hasJustCoins }) {
    const [tempSelected, setTempSelected] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const orderSummary = useSelector(
        (state) => state.orderData.orderSummary.cartSummary
    );

    useEffect(() => {
        setTempSelected(selectedPayment)
    }, [selectedPayment]);

    useEffect(() => {
        setTempSelected(selectedMethods.find(method => method === "mercado-pago" || method === "cash"))
    }, [isBalanceEnough]);

    const handleSelection = () => {
        setSelectedPayment(tempSelected);
        setShowModal(false);
    };

    const handleHelpClick = () => {
        setShowHelp(true);
    };

    const handleBackClick = () => {
        setShowHelp(false);
        setTempSelected(selectedPayment);
    };

    const handleClose = () => {
        setShowModal(false);
        setTempSelected(selectedPayment)
    };

    const getAmount = (method) => {
        if (method === "mercado-pago" || method === "cash") {
            return formatNumber(partialAmount)
        } else if (method === "balance") {
            return formatNumber(totalBalance)
        } else {
            return selectedJustCoins !== '' ? selectedJustCoins : "-"
        }
    }

    return (
        <div
            className={`modal fade ${showModal ? 'show' : ''}`}
            id="paymentMethod"
            aria-hidden={!showModal}
            tabIndex="-1"
            style={{ display: showModal ? 'block' : 'none' }}
            onClick={() => handleClose()}
        >
            <div
                className="modal-dialog modal-dialog-centered"
                style={{ maxWidth: "498px" }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="modal-content modal-radius">
                    <div className="modal-body p-0">
                        {showHelp ? (
                            <CashPayment handleBackClick={handleBackClick} open={open} setShowHelp={setShowHelp} setShowModal={setShowModal} />
                        ) : (
                            <div style={{ padding: "32px" }}>
                                {!isBalanceEnough && !totalBalance.includes("-") ?
                                    <p className='museo-sans-700 blackColor s20' style={{ lineHeight: "26px" }}>Selecciona una de las siguientes opciones para completar tu pago</p> :
                                    <p className='museo-sans-700 blackColor s20' style={{ lineHeight: "26px" }}>Elegir método de pago</p>}


                                <div className='d-flex flex-column align-items-center my-4'>
                                    <label
                                        htmlFor='mercado-pago'
                                        style={{ border: `2px solid ${tempSelected === 'mercado-pago' ? '#DBDBF4' : 'transparent'}` }}
                                        className='container-card input-card d-flex align-items-center gap-2 mb-2 pointer'
                                    >
                                        <input
                                            className="custom-radio"
                                            type='radio'
                                            name='payment'
                                            id='mercado-pago'
                                            value='mercado-pago'
                                            checked={tempSelected === 'mercado-pago'}
                                            onChange={(e) => setTempSelected(e.target.value)}
                                        />
                                        <img src={ImgMercadoPago} width={48} height={32} alt='Pagar con Mercado Pago' />
                                        <p className='museo-sans-500 blackColor s14'>Mercado Pago</p>
                                    </label>

                                    <label
                                        htmlFor='cash'
                                        style={{ border: `2px solid ${tempSelected === 'cash' ? '#DBDBF4' : 'transparent'}` }} className='container-card input-card d-flex align-items-center pointer'>
                                        <div className='d-flex justify-content-between w-100'>
                                            <div className='d-flex align-items-center gap-2'>
                                                <input
                                                    className="custom-radio"
                                                    type='radio'
                                                    name='payment'
                                                    id='cash'
                                                    value='cash'
                                                    checked={tempSelected === 'cash'}
                                                    onChange={(e) => setTempSelected(e.target.value)} />
                                                <img src={ImgCash} width={48} height={32} alt='Pagar con Efectivo' />
                                                <div className='d-flex flex-column'>
                                                    <p className='museo-sans-500 blackColor s14'>Pago en efectivo</p>
                                                    <p className='museo-sans-500 grayColor s12'>a través de Pago Fácil</p>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <SVGHelp onClick={handleHelpClick} />
                                            </div>
                                        </div>
                                    </label>
                                </div>

                                {!isBalanceEnough && !totalBalance.includes("-") && hasJustCoins ?
                                    selectedMethods.map(method => (
                                        <div className='d-flex justify-content-between blackColor s14'>
                                            <div className='museo-sans-500'>{payments[method].title}</div>
                                            <div className='dm-sans-500'>{getAmount(method)}</div>
                                        </div>
                                    ))

                                    : null
                                }

                                < hr />

                                <div className='d-flex justify-content-between blackColor s16'>
                                    <p className='museo-sans-500'>Total a pagar</p>
                                    <p className='dm-sans-500'>{orderSummary?.totalAmount}</p>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <button data-bs-dismiss="modal" aria-label="Close" onClick={handleSelection} className='payment-btn skyBg whiteColor skyBorder museo-sans-700 s14 mt-5'>Seleccionar</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const CashPayment = ({ handleBackClick, open, setShowHelp, setShowModal }) => {
    const modalRef = useRef();
    const numberCode = "71701000628646"
    const doThis = async () => {
        try {
            await navigator.clipboard.writeText(numberCode);
            open();

        } catch (error) {
            unsecuredCopyToClipboard(numberCode);
        }
    }
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false)
            setShowHelp(false)
        }
    };
    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);
    const unsecuredCopyToClipboard = async (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            open();
        } catch (err) {
            console.error('Unable to copy to clipboard', err);
        }
        document.body.removeChild(textArea);
    }

    return (
        <div style={{ padding: "52px 44px 44px" }} ref={modalRef} onClick={(e) => e.stopPropagation()}>
            <div className='d-flex gap-2 ' style={{ marginBottom: "12px" }}>
                <SVGHelp />
                <h1 className=' museo-sans-700 m-0 s24' style={{ lineHeight: "32px" }}>Pago en efectivo</h1>
            </div>

            <p className='s14 museo-sans-500 blackColor mb-4'>Puedes pagar tus órdenes en cualquier red de cobranza con tu código obtenido en el momento de tu alta en Just:</p>

            <div className='d-flex flex-column align-items-center mb-4'>
                <div className='mb-2 dm-sans-medium s20 d-flex align-items-center gap-2'>
                    <p>{numberCode}</p>
                    <div
                        onClick={doThis}
                        className="pointer pb-1"
                    >
                        <SVGCopy />
                    </div>
                </div>
                <img src={BarCode} alt='codigo de barras' />
            </div>

            <div className='d-flex flex-column align-items-center gap-3' >
                <button
                    style={{
                        width: "288px",
                        height: "40px",
                        borderRadius: "8px",
                    }}
                    className='museo-sans-700 s14 skyBg text-white border-0 mt-4'
                    onClick={handleBackClick}>Entendido</button>
                <button
                    style={{ height: "40px", width: "288px" }}
                    className="d-flex s14 justify-content-center align-items-center museo-sans-700 skyColor bg-transparent skyBorder rounded">
                    Ver sucursales cercanas
                </button>

            </div>
        </div>
    )
}

export default PaymentMethodModal;