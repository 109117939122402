import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import { ReactComponent as RightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg";
import customNetServAxios from "../../../common/services/NetSrv";
import "../../../Performance/Presentation/pages/performance.css";
import PerformanceSocialIcons from "../../../Performance/Presentation/components/PerformanceSocialIcons";
import { ReactComponent as Red } from "../../../common/svgs/ic-alert-circle-red.svg";
import { ReactComponent as Yellow } from "../../../common/svgs/ic-alert-circle-yellow.svg";
import { ReactComponent as Green } from "../../../common/svgs/ic-check-circle-sm.svg";
import { ReactComponent as Purple } from "../../../common/svgs/ic-req-red.svg";
import { ReactComponent as Grey } from "../../../common/svgs/ic-alert-circle-grey.svg";
import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGTriangle } from "../../../common/svgs/ic-triangle-risk-lg.svg";
import {
  changePersonalGroupName,
  clearInsideArray,
  defineModalUserSalesTab,
  pushInsideArray,
} from "../../Framework/redux/DescendantsAction";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";

export default function DescendantsConsultantInfoModal() {
  const dataFirstGen = useSelector((state) => state.descendantData.dataFirstGen);
  const dataForModal = useSelector((state) => state.descendantData.modalUserInfo);
  const modalSalesTab = useSelector((state) => state.descendantData.modalUserSalesTab);
  const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);
  const [perfData, setPerfData] = useState(undefined);
  const dispatch = useDispatch();
  let clientId = localStorage.getItem("clientId");
  let keyWarning = ((dataForModal?.triperiodicSalesStatus?.toLowerCase() === "warning" ||
    dataForModal?.triperiodicSalesStatus?.toLowerCase() === "risk") &&
    dataForModal?.triperiodicSalesPeriods?.every(period => period?.state?.toLowerCase() !== "inactive") &&
    dataForModal?.level < 2);

  function displayIcons(social) {
    if (social) {
      return <PerformanceSocialIcons socialArray={social} />;
    }
  }

  async function getCheckMarkStatus() {
    if (dataForModal.id) {
      let location = localStorage.getItem("country");
      const respuesta = (await customNetServAxios().get(
        `${location}/performance/${dataForModal.id}/score`
      )).data;
      setPerfData(respuesta);
      return;
    } else {

    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getCheckMarkStatus();
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dataFirstGen, dataForModal]);

  function statusColor(status) {
    const statusColor = {
      normal: "blackColor",
      warning: "darkYellowColor",
      completed: "lightGreenColor",
      risk: "lightRedColor",
    };

    return statusColor[status];
    
  }

  function travelAndClose() {
    dispatch(
      changePersonalGroupName({
        name: dataForModal.name,
        clientId: dataForModal.id,
      })
    );
    const closeModal = document.getElementById("closeButton");
    closeModal.click();
  }

  function travelAndCloseAsc() {
    dispatch(clearInsideArray());
    dispatch(
      changePersonalGroupName({
        name: dataForModal.name,
        clientId: dataForModal.id,
      })
    );
    dispatch(
      pushInsideArray({
        clientId: dataForModal.uplineId,
        name: dataForModal.uplineName,
      })
    );
    const closeModal = document.getElementById("closeButton");
    closeModal.click();
  }

  const addProgressBar = (actual, total, level, status) => {
    let aux = [];

    let auxActual = parseInt(actual);
    let auxTotal = parseInt(total);

    for (let index = 0; index < auxTotal; index++) {
      if (auxActual >= auxTotal) {
        aux.push(
          <ProgressBarParams
          height={12}
            completed={100}
            incompleted={false}
            level={level}
            status={status}
            gap={status === "gap"}
          />
        );
      } else if (index + 1 > auxActual || auxActual == 0) {
        aux.push(
          <ProgressBarParams
          height={12}
            completed={0}
            incompleted={true}
            level={level}
            status={status}
            gap={status === "gap"}
          />
        );
      } else {
        aux.push(
          <ProgressBarParams
          height={12}
            completed={100}
            incompleted={true}
            level={level}
            status={status}
            gap={status === "gap"}
          />
        );
      }
    }

    return aux;
  };

  const showInfoSales = () => {
    if (dataForModal.level < 2) {
      return (
        <div className=" pt-4 position-relative bronceBg modal-radius setMaxHeight">
          <div className="d-flex flex-column pb-3">
            <div className=" s12 museo-sans-500">
              <div className="d-flex justify-content-between ">
                <p className="col-md-6 mb-2 museo-sans-500 s14">
                  <FormattedMessage id="descendants.leader.modal.showSales.invoicedOrders" />
                </p>
                <p
                  className={`col-md-3 text-end dm-sans-medium s14 ${statusColor(
                    dataForModal.billedStatus
                  )}`}
                >
                  {formatAmount(dataForModal.billedAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
              </div>
              <div className="d-flex justify-content-between ">
                <p className="col-md-6 museo-sans-500 s14">
                  <FormattedMessage id="descendants.leader.modal.showSales.balance" />
                </p>
                <p
                  className={`col-md-3 text-end dm-sans-medium s14 ${statusColor(
                    dataForModal.balanceStatus
                  )}`}
                >
                  {" "}
                  {formatAmount(dataForModal.balanceAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <hr
                  className="brightGrayColor d-flex align-self-center opacity-50"
                  style={{ width: "416px" }}
                />
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="col-md-6 museo-sans-500 s14">
                  <FormattedMessage id="descendants.leader.modal.showSales.ordersToBeInvoiced" />
                </p>
                <p
                  className={`col-md-3 text-end dm-sans-medium s14 ${statusColor(
                    dataForModal.toInvoiceStatus
                  )}`}
                >
                  {" "}
                  {formatAmount(dataForModal.toInvoiceAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
              </div>
              <div className="d-flex justify-content-between ">
                <p className="col-md-6 museo-sans-500 s14">
                  <FormattedMessage id="descendants.leader.modal.showSales.UnshippedOrders" />
                </p>
                <p
                  className={`col-md-3 text-end dm-sans-medium s14 ${statusColor(
                    dataForModal.notSentStatus
                  )}`}
                >
                  {" "}
                  {formatAmount(dataForModal.notSentAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (dataForModal.level > 1) {
      return (
        <div className=" pt-4 position-relative bronceBg modal-radius setMaxHeight">
          <div className="d-flex flex-column pb-3">
            <div className=" s12 museo-sans-500">
              <div className="d-flex justify-content-between">
                <p className="col-md-6 museo-sans-500 s14">
                  <FormattedMessage id="descendants.leader.modal.showSales.groupPersonalSales" />
                </p>
                <p
                  className={`col-md-3 text-end dm-sans-medium s14 ${statusColor(
                    dataForModal.groupSalesStatus
                  )}`}
                >
                  {" "}
                  {formatAmount(dataForModal.groupSalesActual, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
              </div>

              <div className="d-flex justify-content-between">
                <hr
                  className="brightGrayColor d-flex align-self-center opacity-50"
                  style={{ width: "416px" }}
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between">
                  <p className="col-md-6 museo-sans-500 s14">
                    <FormattedMessage id="descendants.leader.modal.showSales.invoicedOrders" />
                  </p>
                  <p
                    className={`col-md-3 text-end dm-sans-medium s14 ${statusColor(
                      dataForModal.billedStatus
                    )}`}
                  >
                    {" "}
                    {formatAmount(dataForModal.billedAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="col-md-6 museo-sans-500 s14">
                    <FormattedMessage id="descendants.leader.modal.showSales.balance" />
                  </p>
                  <p
                    className={`col-md-3 text-end dm-sans-medium s14 ${statusColor(
                      dataForModal.balanceStatus
                    )}`}
                  >
                    {" "}
                    {formatAmount(dataForModal.balanceAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <hr
                  className="brightGrayColor d-flex align-self-center opacity-50"
                  style={{ width: "416px" }}
                />
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex justify-content-between">
                  <p className="col-md-6 museo-sans-500 s14">
                    <FormattedMessage id="descendants.leader.modal.showSales.ordersToBeInvoiced" />
                  </p>
                  <p
                    className={`col-md-3 text-end dm-sans-medium s14 ${statusColor(
                      dataForModal.toInvoiceStatus
                    )}`}
                  >
                    {" "}
                    {formatAmount(dataForModal.toInvoiceAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="col-md-6 museo-sans-500 s14">
                    <FormattedMessage id="descendants.leader.modal.showSales.UnshippedOrders" />
                  </p>
                  <p
                    className={`col-md-3 text-end dm-sans-medium s14 ${statusColor(
                      dataForModal.notSentStatus
                    )}`}
                  >
                    {" "}
                    {formatAmount(dataForModal.notSentAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const showInfoQualification = () => {
    if (dataForModal.level < 2) {
      return (
        <div className="pt-4 pb-3 position-relative bronceBg modal-radius setMaxHeight">
          <div className="d-flex justify-content-between flex-column">
            <div className="d-flex s12 museo-sans-500 grayColor flex-column gap-2">
              <div className="d-flex justify-content-between">
                <div className="col-md-4 col-lg-4 museo-sans-500 s14 blackColor">
                  <FormattedMessage
                    id="descendants.consultant.modal.keyMaintenance.threePeriodSale"
                    defaultMessage="Venta triperiodica"
                  />
                </div>
                <div className="d-flex gap-1 align-items-baseline">
                  <p className="dm-sans-medium s14 blackColor">
                    {" "}
                    {formatAmount(dataForModal.triperiodicSalesActual, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                  <p className="dm-sans-medium s14 grayColor">/</p>
                  <p className={`dm-sans-medium s14 grayColor`}>
                    {" "}
                    {formatAmount(dataForModal.triperiodicSalesTotal, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
              </div>
              <div>
                <ProgressBarParams
                height={12}
                  level={dataForModal.level}
                  status={dataForModal.triperiodicSalesStatus}
                  incompleted={
                    dataForModal.triperiodicSalesActual >=
                      dataForModal.triperiodicSalesTotal
                      ? false
                      : true
                  }
                  gap={
                    dataForModal.triperiodicSalesStatus === "gap"
                  }
                  completed={
                    (dataForModal.triperiodicSalesActual * 100) /
                    dataForModal.triperiodicSalesTotal
                  }
                />
              </div>
            </div>

            {(keyWarning) &&
              <div className=" pt-3">
                <div
                  style={{ background: "#FEF0F0" , color: "#62221F" }}
                  className=" rounded-2 p-3 gap-3 d-flex "
                >
                  <div>
                    <SVGTriangle />
                  </div>
                  <p className=" s14 museo-sans-500">
                    <FormattedMessage id="descendants.consultant.modal.keyMaintenance.inactiveWarning" />
                  </p>
                </div>
              </div>}

            <hr
              className="brightGrayColor d-flex align-self-center mt-4 mb-4 opacity-50"
              style={{ width: "416px" }}
            />
            <div className="d-flex flex-column gap-2">
              {dataForModal.triperiodicSalesPeriods.map(
                (element, index, array) => {
                  return (
                    <div className="d-flex justify-content-between">
                      <p className={element.state !== "inactive" ? "museo-sans-500 s14 blackColor" : "museo-sans-500 s14 grayColor"}>
                      {element?.title}
                      </p>
                      <p className={element.state !== "inactive" ? "dm-sans-medium s14 blackColor" : "dm-sans-medium s14 grayColor"}>
                        {" "}
                        {formatAmount(element.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                      </p>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      );
    }
    if (dataForModal.level > 1) {
      return (
        <div className="pt-4 pb-3 position-relative bronceBg modal-radius setMaxHeight">
          <div className="d-flex justify-content-between flex-column">
            <div className="d-flex pb-3 s12 museo-sans-500 grayColor flex-column row-height gap-2">
              <div className="d-flex justify-content-between">
                <div className="col-md-4 col-lg-4 museo-sans-500 s14 blackColor">
                  <FormattedMessage
                    id="descendants.leader.modal.showInfoQualification.personalSales"
                    defaultMessage="Venta personal"
                  />
                </div>
                <div className="d-flex gap-1 align-items-baseline">
                  <p className="dm-sans-medium s14 blackColor">
                    {formatAmount(dataForModal.personalSalesActual, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                  <p className="dm-sans-medium s14 grayColor">/</p>
                  <p className={`dm-sans-medium s14 grayColor`}>
                    {" "}
                    {formatAmount(dataForModal.personalSalesTotal, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
              </div>
              <div>
                <ProgressBarParams
                height={12}
                  level={dataForModal.level}
                  status={dataForModal.personalSalesStatus}
                  incompleted={
                    dataForModal.personalSalesActual >=
                      dataForModal.personalSalesTotal
                      ? false
                      : true
                  }
                  gap={
                    dataForModal.personalSalesStatus === "gap"
                  }
                  completed={
                    (dataForModal.personalSalesActual * 100) /
                    dataForModal.personalSalesTotal
                  }
                />
              </div>
            </div>
            <div className="d-flex s12 museo-sans-500 grayColor flex-column row-height  gap-2">
              <div className="d-flex justify-content-between">
                <div className="col-md-7 col-lg-7 museo-sans-500 s14 blackColor">
                  <FormattedMessage
                    id="descendants.leader.modal.showInfoQualification.incomingPersonalSales"
                    defaultMessage="Incorporados personales con ventas"
                  />
                </div>
                <div className="d-flex gap-1 align-items-baseline">
                  <p className="dm-sans-medium s14 blackColor">
                    {dataForModal.incorporatedSalesActual}
                  </p>
                  <p className="dm-sans-medium s14 grayColor">/</p>
                  <div className="d-flex gap-1 " >
                    <p className={`dm-sans-medium s14 ${dataForModal.incorporatedSalesTotal < 4 ? "lightPurpleColor" : "grayColor"} `}>
                      {dataForModal.incorporatedSalesTotal}
                    </p>
                    {
                      dataForModal.incorporatedSalesTotal < 4 &&
                      <div style={{ width: "16px", height: "16px" }} className=" svg-force-size " >
                        <Purple />
                      </div>

                    }
                  </div>
                </div>
              </div>
              <div className="d-flex gap-1">
                {addProgressBar(
                  dataForModal.incorporatedSalesActual,
                  dataForModal.incorporatedSalesTotal,
                  dataForModal.level,
                  dataForModal.incorporatedSalesStatus
                )}
              </div>
            </div>
            <div className="d-flex s12 museo-sans-500 grayColor flex-column row-height  gap-2">
              <div className="d-flex justify-content-between">
                <div className="col-md-6 col-lg-6 museo-sans-500 s14 blackColor">
                  <FormattedMessage
                    id="descendants.leader.modal.showInfoQualification.groupSale"
                    defaultMessage="Venta grupal"
                  />
                </div>

                <div className="d-flex gap-1 align-items-baseline">
                  <p className="dm-sans-medium s14 blackColor">
                    {formatAmount(dataForModal.groupSalesActual, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                  <p className="dm-sans-medium s14 grayColor">/</p>
                  <div className="d-flex gap-1" >
                    <p className={`dm-sans-medium s14 ${dataForModal.incorporatedSalesTotal < 4 ? "lightPurpleColor" : "grayColor"} `}>
                      {formatAmount(dataForModal.groupSalesTotal, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                    </p>
                    {
                      dataForModal.incorporatedSalesTotal < 4 &&
                      <div style={{ width: "16px", height: "16px" }} className=" svg-force-size " >
                        <Purple />
                      </div>

                    }
                  </div>
                </div>
              </div>
              <div>
                <ProgressBarParams
                height={12}
                  level={dataForModal.level}
                  status={dataForModal.groupSalesStatus}
                  incompleted={
                    dataForModal.groupSalesActual <
                      dataForModal.groupSalesTotal
                  }
                  gap={dataForModal.groupSalesStatus === "gap"}
                  completed={
                    (dataForModal.groupSalesActual * 100) /
                    dataForModal.groupSalesTotal
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };


  return (
    <div
      className="modal fade"
      id="descendantConsultantInfo"
      aria-hidden="true"
      aria-labelledby="descendantConsultantInfo"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div
          className="modal-content modal-radius desc-info-modal overflow-hidden"
          style={{ width: "496px", height: keyWarning ? "666px" : "576px" }}
        >
          <div
            className="modal-body p-0"
            style={{ width: "496px", height: "576px" }}
          >
            <div
              className="justify-content-center d-flex flex-column align-items-center donutHeaderBg pb-3 position-relative"
              style={{
                paddingTop: "32px",
                paddingLeft: "32px",
                paddingRight: "32px",
                borderBottom: "1px solid #DDDDDD",
              }}
            >
              <div
                id="closeButton"
                className="pointer position-absolute"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ right: "16px", top: "16px" }}
              >
                <SVGClose />
              </div>
              <ImageCircleHandler
                width={"88px"}
                url={dataForModal.picture}
                name={dataForModal?.name}
                firstName={dataForModal.firstName}
                lastName={dataForModal.lastName}
                size={"32px"}
              />
              <div className="d-flex gap-2 mt-1">
                <p className="s16 museo-sans-700 performance-toltip-inline-container">
                  {dataForModal.name}
                </p>
                <div className="performance-svg-size performance-toltip-inline-container">
                  {perfData?.consultantStatus?.checkMarkStatus ===
                    "reduced" && <Purple />}
                  {perfData?.consultantStatus?.checkMarkStatus === "risk" && (
                    <Red />
                  )}
                  {perfData?.consultantStatus?.checkMarkStatus ===
                    "warning" && <Yellow />}
                  {perfData?.consultantStatus?.checkMarkStatus ===
                    "completed" && <Green />}
                  {perfData?.consultantStatus?.checkMarkStatus === "gap" && (
                    <Grey />
                  )}
                </div>
              </div>



              <p className="s14 museo-sans-500 mt-1">
                <ReformatLevelByGender gender={dataForModal?.gender} level={dataForModal?.level} roleDescriptionDefault={dataForModal?.roleDescription} />
              </p>
              <div className="mt-3 text-center mt-2">
                <p className="museo-sans-500 s14 grayColor performance-toltip-inline-container">
                  <FormattedMessage
                    id="descendants.user.info.modal.ascendant.name"
                    defaultMessage="Ascendente"
                  />
                  :{" "}
                </p>
                {dataForModal.uplineId == 1 ? (
                  
                    <p className=" performance-toltip-inline-container pe-1 grayColor s14 museo-sans-700">
                      <FormattedMessage id="no.asc" />
                    </p>
                ) : (
                  
                    <Link
                      onClick={() => travelAndCloseAsc()}
                      to={`/dashboard/descendants/profile/asc/${dataForModal.uplineId}`}
                      className="museo-sans-700 s14 skyColor performance-toltip-inline-container button-hover-light-text"
                    >
                      {dataForModal.uplineName}
                    </Link>
                )}
              </div>
            </div>

            <div>
              <div
                className="donutHeaderBg pt-2"
                style={{ paddingLeft: "32px", paddingRight: "32px" }}
              >
                <div className="d-flex justify-content-center gap-2 donutHeaderBg">
                  <div className="d-flex gap-2">
                    {displayIcons(dataForModal.socialNetwork)}
                  </div>
                  {clientId == dataForModal.id ? (
                    <Link
                      onClick={() => travelAndClose()}
                      className="museo-sans-700 s14 skyColor d-flex align-items-center gap-2 button-hover-light-text button-hover-svg-light-stroke"
                      to={`/dashboard/account`}
                    >
                      <FormattedMessage
                        id="descendants.user.info.modal.view.profile"
                        defaultMessage="Ver perfil"
                      />{" "}
                      <RightArrow />
                    </Link>
                  ) : (
                    <Link
                      onClick={() => travelAndClose()}
                      className="museo-sans-700 s14 skyColor d-flex align-items-center gap-2 button-hover-light-text button-hover-svg-light-stroke"
                      to={`/dashboard/descendants/profile/${dataForModal.id}`}
                    >
                      <FormattedMessage
                        id="descendants.user.info.modal.view.profile"
                        defaultMessage="Ver perfil"
                      />{" "}
                      <RightArrow />
                    </Link>
                  )}
                  {dataForModal.level > 1 ? (
                    <Link
                      onClick={() => travelAndClose()}
                      className="museo-sans-700 s14 skyColor d-flex align-items-center gap-2 button-hover-light-text button-hover-svg-light-stroke"
                      to={`/dashboard/otherUserSales/${dataForModal.id}/personalSale`}
                    >
                      {clientId == dataForModal.id ? (
                        <FormattedMessage
                          id="descendants.user.info.modal.view.own.sales"
                          defaultMessage="Ver tus ventas"
                        />
                      ) : (
                        <FormattedMessage
                          id="descendants.user.info.modal.view.descendant.sales"
                          defaultMessage="Ver sus ventas"
                        />
                      )}{" "}
                      <RightArrow />
                    </Link>
                  ) : null}
                </div>
                <div className={`d-flex align-items-start mt-3  donutHeaderBg`}>
                  <button
                    style={{ width: "184px" }}
                    onClick={() => dispatch(defineModalUserSalesTab(true))}
                    id="personalSales"
                    type="button"
                    className={`   ps-5 pe-5 s14 performance-flap-button museo-sans-500 s14 ${modalSalesTab 
                      ? "bronceBg blackColor"
                      : "lightGreyBg grayColor"
                      }`}
                  >
                    {dataForModal.level > 1 ? (
                      <FormattedMessage
                        id="descendants.user.info.modal.qualifications.flap"
                        defaultMessage="Calificaciones"
                      />
                    ) : (
                      <FormattedMessage
                        id="descendants.user.info.modal.key.maintenance.flap"
                        defaultMessage="Mantenimiento de clave"
                      />
                    )}
                  </button>
                  <button
                    style={{ width: "184px" }}
                    onClick={() => dispatch(defineModalUserSalesTab(false))}
                    id="groupSales"
                    type="button"
                    className={` menu-shadow justify-content-center ps-5 pe-5 s14 performance-flap-button museo-sans-500 s14 ${!modalSalesTab
                      ? "bronceBg blackColor"
                      : "lightGreyBg grayColor"
                      }`}
                  >
                    <FormattedMessage
                      id="descendants.consultant.modal.sales.title"
                      defaultMessage="Ventas"
                    />
                  </button>
                </div>
              </div>

              <div
                style={{
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  paddingBottom: "32px",
                }}
              >
                {modalSalesTab
                  ? showInfoQualification()
                  : showInfoSales()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
