import React from 'react'
import "./OrdersCard.css"
import { FormattedMessage } from 'react-intl';
import { ReactComponent as SVGClose } from "../../Icons/Actions/Close.svg";
import { ReactComponent as SVGCheck } from "../../Icons/Notification/Check-Circle.svg";

export default function OrdersKitDetailModal({ kit, kitSelected, handleKit }) {

    return (
        <div className="modal fade" id="kitDetailModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div style={{ width: "872px" }} className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-styles">
                <div className="modal-content position-relative p-4" style={{
                    borderRadius: "15px",
                    height: "480px"
                }}>

                    <div className="d-flex gap-5 m-2 mb-0">
                        <div className="kit-image-section">
                            <img
                                src={kit?.imageURL}
                                alt={kit?.name}
                            />
                        </div>

                        <div className="kit-details-section">
                            <div className="pointer btn-close-modal" data-bs-dismiss="modal" aria-label="Close">
                                <SVGClose />
                            </div>
                            <h3 className="museo-sans-700 s24 mb-2">{kit?.name}</h3>
                            <p className="kit-description-modal museo-sans-500 s14 mb-4">
                                {kit?.description}
                            </p>
                            <hr className='mb-4' />
                            <div className="mb-4">
                                <h5 className='kit-includes-title museo-sans-700 s14'>¿Qué incluye?</h5>
                                <div className='kit-includes hidden-y-scroll'>
                                    {kit?.includedProducts?.map((product) =>
                                        <p className='museo-sans-500 s14'>{product}</p>
                                    )}
                                </div>
                            </div>
                            <div className="kit-prices d-flex gap-4">
                                <p className="kit-price-modal d-flex flex-column">
                                    <span className='grayColor s12 museo-sans-500'>PRECIO POR KIT </span>
                                    <p className='s24 blackColor dm-sans-700'>{kit?.discountPrice}</p>
                                </p>
                                <p className="kit-regular-price-modal d-flex flex-column grayColor museo-sans-500">
                                    <span className='s12'>PRECIO REGULAR</span>
                                    <p className='s24 text-decoration-line-through dm-sans-500'>{kit?.regularPrice}</p>
                                </p>
                            </div>
                            <div data-bs-dismiss="modal" aria-label="Close">
                                <button onClick={() => handleKit(kit)} className={`${kitSelected === kit?.kitId ? 'kit-selection-detail--selected' : ''} skyBg bronceColor kit-select-button museo-sans-500 s14 btnCustom`} >
                                    {kitSelected === kit?.kitId ?
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <FormattedMessage
                                                id="business.kit.selected"
                                                defaultMessage="Seleccionado"
                                            />
                                            <SVGCheck className='ms-1' />
                                        </div>
                                        :
                                        <FormattedMessage
                                            id="business.kit.selection"
                                            defaultMessage="Seleccionar"
                                        />
                                    }</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}
