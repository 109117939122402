import React from "react";
import { FormattedMessage } from "react-intl";
import "./OrdersCard.css";

const OrdersBusinessKit = ({
  image,
  title,
  description,
  buttonLabel,
  onButtonClick,
  icon,
}) => {
  return (
    <div className="kit-card width-container-card">
      <div className="d-flex g-0">
        <div>
          <img src={image} alt="Kit de Negocio" className="kit-image" />
        </div>
        <div className="card-container-body d-flex align-items-center">
          <div className="card-body">
            <div className="d-flex gap-2 align-items-center">
              <div className="d-flex">{icon}</div>
              <h5 className="kit-title museo-sans-700">
                <FormattedMessage id={title} />
              </h5>
            </div>
            <p className="kit-description mt-2 museo-sans-500">
              <FormattedMessage id={description} />
            </p>
          </div>
          <div>
            <button
              type="button"
              className=" position-relative museo-sans-700 s14 skyBg whiteColor skyBorder button-bootstrap-radius button-padding"
              style={{ width: "288px", height: "40px" }}
              onClick={onButtonClick}
            >
              <FormattedMessage id={buttonLabel} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersBusinessKit;
