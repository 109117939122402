import { createAction } from '@reduxjs/toolkit';

const kitData = createAction('getKitData');
const orders = createAction('getOrders');
const requests = createAction('getRequests');
const isOrder = createAction('IsOrder');
const orderType = createAction('orderType');
const cleanOrderData = createAction("cleanOrderData");
const ordersSelect = createAction('ordersSelect');
const setFilter = createAction("setFilter")
const setOrder = createAction("setOrder")

// === CART ACTIONS ===

const addToCart = createAction('addToCart');
const removeFromCart = createAction('removeFromCart');
const clearCart = createAction('clearCart');
const loadCart = createAction('loadCart');
const loadOrderSummary = createAction('loadOrderSummary');
const addKittoCart = createAction('addKittoCart');
const addToCartFromFirstSteps = createAction('addToCartFromFirstSteps');
const removeFromCartFirstSteps = createAction('removeFromCartFirstSteps');
const saveCartBody = createAction('saveCartBody')
const saveNewTotalAmount = createAction('saveNewTotalAmount')

export { kitData, orders, requests, isOrder, orderType, cleanOrderData, addToCart, removeFromCart, clearCart, loadCart, loadOrderSummary, addKittoCart, addToCartFromFirstSteps, removeFromCartFirstSteps, ordersSelect, saveCartBody, setFilter, saveNewTotalAmount, setOrder }; 
