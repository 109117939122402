import { useMediaPredicate } from "react-media-hook";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import getTimeZoneByCountry from "../../../common/Share/formatDateMoment";
import { OfertsCardHolderHeader } from "../../../Oferts&Features/Presentation/components/OfertsCardHolderHeader";
import { OfertCardDates } from "../../../Oferts&Features/Presentation/components/dates/OfertsCardDates";
import { useSelector } from "react-redux";
export default function DashboardOffertsNews({
  noticeLider,
  controllerModalOfertsToTop,
  noticeConsultant,
  noticeClient,
  offerData,
  setOffersInfoModal,
  onCopyOpen,
  callServicesOffers
}) {
  const history = useNavigate();
  const [notiArray, setNotiArray] = useState([]);
  const [oferts, setOferts] = useState([]);
  const [ofertsNoFeatures, setOfertsNoFeatures] = useState([]);
  const [skeleton, setSkeleton] = useState(true)

  const profileData = useSelector((state) => state.userInfo);
  const errorControlNotis = useSelector((state) => state?.firstStepsData?.notiErrors);
  const errorControlOferts = useSelector((state) => state?.firstStepsData?.offertsErrors);

  const biggerThan1000 = useMediaPredicate("(min-width: 1281px)");

  useEffect(() => {
    callServicesOffers();
  }, [])

  useEffect(() => {
    let aux = [];

    let auxOffer = [];
    let auxLeader = [];
    let auxClient = [];
    let auxConsultant = [];

    let auxOfferNotFeatured = [];

    offerData?.data?.length > 0 &&
      offerData?.data.map((elm) => {
        if (elm.status == "published" && elm?.isAvailable && elm?.featured) {
          if (
            OfertCardDates(
              elm?.dateStartOfPublication,
              elm?.dateEndOfPublication,
              true
            )
          ) {
            auxOffer.push(elm);
          }
        }
        if ((!elm?.isAvailable || !elm?.featured) && OfertCardDates(
          elm?.dateStartOfPublication,
          elm?.dateEndOfPublication,
          true
        ) ) {
          auxOfferNotFeatured.push(elm);
        }
      });

    if (auxOfferNotFeatured?.length > 0) {
      auxOffer.push("MORE");
    }

    setOfertsNoFeatures(auxOfferNotFeatured);

    setOferts({ data: auxOffer });

    if (profileData.userInfo.level > 1) {
      noticeLider?.data?.length > 0 &&
        noticeLider?.data.map((elm) => {
          if (
            OfertCardDates(
              elm?.dateStartOfPublication,
              elm?.dateEndOfPublication,
              true
            )
          ) {
            if (elm.status == "published") {
              let tempProps = JSON.parse(JSON.stringify(elm));
              tempProps.applyTo = "Lideres";
              Object.preventExtensions(tempProps);

              if (auxLeader.length > 0) {
                if (
                  OfertCardDates(
                    elm?.dateStartOfPublication,
                    elm?.dateEndOfPublication,
                    false,
                    false,
                    auxLeader[0]
                  )
                ) {
                  auxLeader = [];
                  auxLeader.push(tempProps);
                }
              }
              if (auxLeader.length == 0) {
                auxLeader.push(tempProps);
              }
            }
          }
        });
    }

    noticeClient?.data?.length > 0 &&
      noticeClient?.data.map((elm) => {
        if (
          
          OfertCardDates(
            elm?.dateStartOfPublication,
            elm?.dateEndOfPublication,
            true
          )
        ) {
          if (elm.status == "published") {
            let tempProps = JSON.parse(JSON.stringify(elm));
            tempProps.applyTo = "Clientes";
            Object.preventExtensions(tempProps);

            if (auxClient.length > 0) {
              if (
                OfertCardDates(
                  elm?.dateStartOfPublication,
                  elm?.dateEndOfPublication,
                  false,
                  false,
                  auxClient[0]
                )
              ) {
                auxClient = [];
                auxClient.push(tempProps);
              }
            }
            if (auxClient.length == 0) {
              auxClient.push(tempProps);
            }
          }
        }
      });
    noticeConsultant?.data?.length > 0 &&
      noticeConsultant?.data.map((elm) => {
        if (
          OfertCardDates(
            elm?.dateStartOfPublication,
            elm?.dateEndOfPublication,
            true
          )
        ) {
          if (elm.status == "published") {
            let tempProps = JSON.parse(JSON.stringify(elm));
            tempProps.applyTo = "Consultoras";
            Object.preventExtensions(tempProps);

            if (auxConsultant.length > 0) {
              if (
                OfertCardDates(
                  elm?.dateStartOfPublication,
                  elm?.dateEndOfPublication,
                  false,
                  false,
                  auxConsultant[0]
                )
              ) {
                auxConsultant = [];
                auxConsultant.push(tempProps);
              }
            }
            if (auxConsultant.length == 0) {
              auxConsultant.push(tempProps);
            }
          }
        }
      });

    auxLeader.length > 0 && aux.push(auxLeader[0]);

    auxClient.length > 0 && aux.push(auxClient[0]);

    auxConsultant.length > 0 && aux.push(auxConsultant[0]);

    setNotiArray(aux);
    setSkeleton(false);
  }, [noticeClient, noticeConsultant, noticeLider, offerData]);

  const isNotiVoid = () => {
    if (notiArray.length > 0) {
      return false;
    }
    return true;
  };

  const toggleSkeleton = () => {
    setSkeleton(true)
  };

  const historyControl = (string) => {
    history(string);
  };

  return (
    <>
      {!skeleton ? (
        <>
            
              { ((ofertsNoFeatures?.length > 0) || (oferts?.data?.length > 0)) ? (
              <div
              className={
                !biggerThan1000
                  ? "museo-sans-500 d-flex gap-3 w-100 "
                  : "museo-sans-500 d-flex gap-4 w-100 "
              }
            >
              
              <OfertsCardHolderHeader
                historyControl={historyControl}
                offerData={oferts}
                allOffers={offerData}
                setOffersInfoModal={setOffersInfoModal}
                controllerModalOfertsToTop={controllerModalOfertsToTop}
                ofertsNoFeatures={ofertsNoFeatures}
                errorControl={errorControlOferts}
                toggleSkeleton={toggleSkeleton}
              />
              </div>
              ) : null}
              {!isNotiVoid() ? (
                <div
                className={
                  !biggerThan1000
                    ? "museo-sans-500 d-flex gap-3 w-100 "
                    : "museo-sans-500 d-flex gap-4 w-100 "
                }
              >
                <OfertsCardHolderHeader
                  historyControl={historyControl}
                  offerData={notiArray}
                  setOffersInfoModal={setOffersInfoModal}
                  applyToAux={notiArray.applyTo}
                  notis={true}
                  onCopyOpen={onCopyOpen}
                  errorControl={errorControlNotis}
                  toggleSkeleton={toggleSkeleton}
                />
                </div>
              ) : null}

        </>
      ) : (
          <div
            style={{ marginTop: "56px", marginBottom: "54px" }}
            className={
              !biggerThan1000
                ? "museo-sans-500 d-flex gap-5 w-100 "
                : "museo-sans-500 d-flex gap-4 w-100 "
            }
          >
            <EskeletonOfferCard></EskeletonOfferCard>
            <EskeletonOfferCard></EskeletonOfferCard>
          </div>
      )}
    </>
  );
}

export function dateFormat(dateStart, dateEnd, index) {
  const country = localStorage.getItem("country");
  const timeZone = getTimeZoneByCountry(country);

  const fechaActual = new Date().toLocaleString("en-US", { timeZone });

  let idElm;

  const fechaInicio = new Date(dateStart).toLocaleString("en-US", { timeZone });
  const fechaFin = new Date(dateEnd).toLocaleString("en-US", { timeZone });
  const fechaActualFormateada = new Date().toLocaleString("en-US", {
    timeZone,
  });

  const estaEnRango = isWithinRange(
    fechaActualFormateada,
    fechaInicio,
    fechaFin
  );

  if (estaEnRango) {
    idElm = index;
    return { estaEnRango, idElm };
  }

  return { estaEnRango, idElm };

  function isWithinRange(date, startDate, endDate) {
    const dateObj = new Date(date);
    const startObj = new Date(startDate);
    const endObj = new Date(endDate);

    return dateObj >= startObj && dateObj <= endObj;
  }
}

export function EskeletonOfferCard() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="d-flex flex-column justify-content-between gap-2">
      <div
        className="d-flex justify-content-between d-flex  align-items-center museo-sans-500 "
        style={{
          height: "24px",
          width: windowWidth == 1280 ? "256px" : "288px",
        }}
      >
        <div className=" w-50 brightGrayBg h-100 rounded-3  "></div>
        <div className=" w-25 brightGrayBg h-100 rounded-3"></div>
      </div>
      <div
        className="cards-Offers cards-shadow edb-card-radius d-flex flex-column bronceBg h-100 "
        style={{ width: "288px" }}
      >
        <div
          style={{ height: "156px", borderRadius: "16px 16px 0px 0px" }}
          className=" w-100 brightGrayBg  "
        ></div>
        <div className="p-3" style={{ height: "211px" }}>
          <div
            style={{ height: "12px" }}
            className=" w-100 mt-3 rounded-3 brightGrayBg  "
          ></div>

          <div className=" mt-4 pt-3 d-flex flex-column gap-2">
            <div
              style={{ height: "8px" }}
              className=" w-100 rounded-3 brightGrayBg  "
            ></div>
            <div
              style={{ height: "8px" }}
              className=" w-100 rounded-3 brightGrayBg  "
            ></div>
            <div
              style={{ height: "8px" }}
              className=" w-50   rounded-3 brightGrayBg  "
            ></div>
          </div>
          <div className=" mt-4 d-flex flex-column gap-2">
            <div
              style={{ height: "40px", width: "244px" }}
              className=" w-100 rounded-3 brightGrayBg  "
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
