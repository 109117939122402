import React, { useEffect, useState } from 'react';
import { ReactComponent as SVGWarning } from "../../Icons/Notification/Warning.svg";
import { ReactComponent as SVGError } from "../../Icons/Notification/Delete-One.svg";
import { useSelector, useDispatch } from 'react-redux';
import OrdersSelectKit from '../components/OrdersSelectKit';
import OrdersNotification from '../components/OrdersNotification';
import { useNavigate } from 'react-router-dom';
import { fetchKitData } from '../../Framework/redux/OrdersSideEffect';
import { addKittoCart } from '../../Framework/redux/OrdersActions';

const DashboardBusinessKit = () => {
    const orderData = useSelector((state) => state.orderData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [kitSelected, setKitSelected] = useState(null);
    const [kitDetail, setKitDetail] = useState(null);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        getKitData();
    }, []);

    const getKitData = async () => {
        const clientId = localStorage.getItem("clientId")
        const countryCode = localStorage.getItem("country")

        await dispatch(
            fetchKitData({ clientId, countryCode })
        );
    };

    const goToCatalogue = () => {
        if (kitSelected)
            navigate(`/dashboard/orders/catalogo-consulta`);
        else
            setShowError(true);
    };

    const handleKit = (kit) => {
        setKitSelected(kit.kitId);
        setShowError(false);
        dispatch(addKittoCart(kit));
    };

    const handleKitDetail = (kit) => {
        setKitDetail(kit)
    };

    return (
        <div className='mt-5' style={{ width: "808px" }}>
            <h2 className='s24 w600 museo-sans-700'>Selecciona tu Kit de Negocio</h2>
            <div className='mt-4'>
                <OrdersNotification
                    icon={<SVGWarning />}
                    text="Al ser tu primer pedido, es obligatorio que selecciones un Kit de Negocio para activarte como Consultor/a Independiente Just."
                />
            </div>
            <p className='museo-sans-500 s14 mt-3 mb-5'>
                El Kit de Negocio es clave para que empieces a familiarizarte con los productos Just, y hagas tus primeras ventas conquistando a tus clientes cuando los demuestres en persona.
            </p>
            {orderData?.kitData.map(kit =>
                <OrdersSelectKit key={kit.kitId} kit={kit} handleKit={handleKit} handleKitDetail={handleKitDetail} kitSelected={kitSelected} kitDetail={kitDetail} />
            )}

            {showError && <div className='my-4'>
                <OrdersNotification
                    icon={<SVGError />}
                    text="Debes seleccionar un kit para poder avanzar"
                    type="error"
                />
            </div>}

            <button onClick={() => goToCatalogue()} className='btnLoginWidth s14 museo-sans-700  py-2 text-white btnCustom skyBg mb-5'>
                Continuar
            </button>
        </div>
    )
}

export default DashboardBusinessKit;
