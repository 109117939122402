import { FormattedMessage } from "react-intl";
import EcommerceFormInput from "./EcommerceFormInput";

export default function EcommerceFormSocialHolder(params) {
  const onChange = (label, value) => {
    params?.onChange(`socialNetwork.${label}`, value);
  };

  const checkErrorArray = (label) => {
    if (params?.errorArray?.length === 0) {
      return false;
    }
    return params?.errorArray[label] && params?.formData?.errorToggler;
  }

  return (
    <div className="d-flex flex-column gap-4 museo-sans-400">
      <div className="d-flex flex-column gap-2">
        <p className=" d-flex align-items-end gap-1 s20">
          <FormattedMessage id="ecommerce.form.social.array.title" />
          <span className=" s16">
            (<FormattedMessage id="optional" />)
          </span>
        </p>
        <p className="s14 grayColor">
          <FormattedMessage id="ecommerce.form.social.array.description" />
        </p>
      </div>
      <div className=" d-flex flex-column gap-4 w-100">
        <div className="d-flex gap-3 w-100">
          <EcommerceFormInput
            checkErrorType={params?.validatePerRequest}
            isError={
                checkErrorArray("facebook")
            }
            onChange={onChange}
            value={params?.formData?.body?.socialNetwork?.facebook}
            label={"facebook"}
          />
          <EcommerceFormInput
            checkErrorType={params?.validatePerRequest}
            isError={
              checkErrorArray("instagram")
            }
            onChange={onChange}
            value={params?.formData?.body?.socialNetwork?.instagram}
            label={"instagram"}
          />
        </div>
        <div className="d-flex gap-3 w-100">
          <EcommerceFormInput
            checkErrorType={params?.validatePerRequest}
            isError={
                checkErrorArray("youtube")
            }
            onChange={onChange}
            value={params?.formData?.body?.socialNetwork?.youtube}
            label={"youtube"}
          />
          <EcommerceFormInput
            checkErrorType={params?.validatePerRequest}
            isError={
                checkErrorArray("linkedin")
            }
            onChange={onChange}
            value={params?.formData?.body?.socialNetwork?.linkedin}
            label={"linkedin"}
          />
        </div>
      </div>
    </div>
  );
}
