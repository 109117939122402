import ShareModalStructureCloseIcon from "../../../../common/Share/ShareModalStructureCloseIcon";
import ShareVimeoHolster from "../../../../common/Share/ShareVimeoHolster";
import { FormattedMessage } from "react-intl";
import { useState } from "react";

export default function OrderDashboardModalFirstPersonal(params) {

  const [toggler, setToggler] = useState(false);

  const onToggleNotShowAgain = () => {
    localStorage.setItem("personal-order-not-show-again", !toggler);
    setToggler(!toggler);
  };

  return (
      <div
        style={{ padding: "44px 44px 44px 44px" }}
        className=" museo-sans-500 position-relative"
      >
        <div
          style={{ right: "0px", top: "0px" }}
          className=" position-absolute"
        >
          <ShareModalStructureCloseIcon top={"16px"} />
        </div>
        <div>
          <p className=" museo-sans-700 s24">
            < FormattedMessage id="orders.dashboard.modal.first.order.title" />
          </p>
          <p className=" pt-1 mt-2">
          < FormattedMessage id="orders.dashboard.modal.first.order.p" />
          </p>
          <div className="pt-1 mt-3">
            <ShareVimeoHolster url={"https://vimeo.com/660669339/201a3349aa "} description="Beneficios de identificar a tus clientes" />
          </div>

          <div style={{marginTop: "22px"}} className="d-flex gap-2 w-100 justify-content-center" >
            <div onClick={onToggleNotShowAgain} style={{width:"20px", height: "20px"}} className={` ${toggler ? " skyBg " : "" } rounded-2 border-2 skyBorder pointer  `} >
            <i style={{color: "white", top: "-2px", width: "24px"}} className=" position-relative bi bi-check"></i>


            </div>
            <p className="s14 museo-sans-500" >
            No volver a mostrar este mensaje
            </p>
          </div>
        </div>
        <div
          style={{ marginTop: "44px" }}
          className="d-flex flex-column gap-3 w-100 align-items-center "
        >
          {params?.buttons?.map((button, index) => (
            <ButtonRow
              key={index}
              onClick={button.onClick}
              text={button.text}
              isDisabled={button.isDisabled}
            />
          ))}
        </div>
      </div>
  );
}

export function ButtonRow(params) {
  return (
    <div
      data-bs-dismiss={`${params?.isDisabled ? "" : "modal" }`}
      aria-label="Close"
      onClick={params?.onClick}
      className={` btnLoginWidth ${params?.isDisabled ? "grayColor grayBorderShare opacity-50 " : "skyColor skyBorder pointer"} s14 museo-sans-700 border-1  rounded-3 d-flex justify-content-center align-items-center`}
    >
      {params.text ? params.text : "Crear Orden de Cliente"}
    </div>
  );
}
