export default function ImageCircleHandler({
    width,
    height,
    url,
    firstName,
    size,
    lastName,
    top,
    styles,
    name,
    fontFamily,
  }) {
    const handleFullName = (name) => {
      if (!name) {
        return "NA";
      }
      let aux = name.split(" ");
      let first = aux[0].charAt(0);
      let second = aux[aux.length - 1].charAt(0);
      let whole = `${first}${second}`;
      return whole;
    };
  
    const styleImage = {
      width: width,
      fontSize: size ? size : "16px",
      height: height ? height : width,
      top: top,
      ...styles,
    };
  
    return !url || url == "" ? (
        <div
          style={styleImage}
          className="rounded-circle align-items-center grayColor brightGrayBg d-flex gap-0 justify-content-center text-center text-uppercase "
        >
          {
            <span className={`${fontFamily ? fontFamily : "museo-sans-700"}`} >
              {firstName != "" ? (
                <>
                  {lastName && lastName?.charAt(0)}
                  {firstName && firstName?.charAt(0)}
                </>
              ) : (
                <>{handleFullName(name)}</>
              )}
            </span>
          }
        </div>
    ) : (
        <img
          style={styleImage}
          src={url}
          alt="imagen-perfil-propio"
          className="rounded-circle"
        />
    );
  }
  
