export const OrdersKitSkeleton = () => {
    return (
      <>
        <div
          className="title-orders museo-sans-500 d-flex align-items-center rounded-3 brightGrayBg w-50 lightui1-shimmer"
          style={{ height: "24px" }}
        ></div>
  
        <div className="container-orderkit">
          <div style={{ display: "flex" }}>
            <div
              className="titleTable orden museo-sans-500 s12 rounded-3 brightGrayBg w-100 lightui1-shimmer"
              style={{ height: "150px"}}
            ></div>
          </div>
        </div>
      </>
    );
  };