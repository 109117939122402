import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

export default function EcommerceRegisterPath({current, max}) {

    const location = useLocation();

    return (
        <div className="d-flex flex-column gap-1 museo-sans-400" >
            {
                location?.pathname?.includes("/new") &&

            <p className=" grayColor s12" >
                <FormattedMessage id="ecommerce.register.steps" values={{current: current, max: max}} />
                </p>
            }
            <p className="s14" >
                <FormattedMessage id="ecommerce.configure.title" />
            </p>
        </div>
    );
}