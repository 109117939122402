import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";
import ShareLocalityController from "../../../common/Share/ShareLocalityController";
import SharePhoneLibrary from "../../../common/Share/SharePhoneLibrary";

const numbers = {
  ar: "+549",
  cl: "+56",
  co: "+57",
  cr: "+506",
  mx: "+52",
  pa: "+507",
  pe: "+51",
  uy: "+598",
};

const fetchTopics = createAsyncThunk(
  "fetchTopics",
  async (number, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      const response = (await customNetServAxios().get(
        `/${location}/edbs/${userId}/appointment/topics`
      )).data;
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchEDBDetailsForFormBody = createAsyncThunk(
  "fetchEDBDetailsForFormBody",
  async (edbId, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      const response = (await customNetServAxios().get(
        `/${location}/edbs/${userId}/appointment/${edbId}`
      )).data;
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const removeCountryCode = (phoneNumber) => {
  // Recorremos los prefijos del objeto numbers
  // debugger
  for (const code in numbers) {
    const countryCode = numbers[code];
    // Si el número empieza con el prefijo (sin el '+')
    const countryCodeWithoutPlus = countryCode.slice(1); // Eliminamos el '+' del prefijo
    if (phoneNumber.startsWith(countryCodeWithoutPlus)) {
      // Quitamos el prefijo sin el '+' y retornamos el número restante
      return phoneNumber.slice(countryCodeWithoutPlus.length);
    }
  }
  // Si no coincide con ningún prefijo, retornamos el número original
  return phoneNumber;
}

const checkFormValues = async (form, country) => {

  const isReferenceOptional = () => {
    const objectReferenceExist = {
      mx: true,
      pe: true,
    }
    return objectReferenceExist[country] || false;
  };

  if (!form?.date) {
    return true;
  }
  if (!form?.time || form?.time == "time") {
    return true;
  }
  if (!form?.topicId) {
    return true;
  }
  if (!form?.edbType) {
    return true;
  }
  if (form?.edbType == "virtual") {
    form.addressInfo = null;

    const regex =
      /^(https:\/\/|http:\/\/)(www\.)?([\w-]+(\.[\w-]+)+)(\/[\w- ;,./?%&=-]*)?$/i;

    if (form.conference.url == "" || !regex.test(form.conference.url)) {
      return true;
    }
    if (
      form?.streaming?.url?.trim() != "" &&
      !regex.test(form?.streaming?.url)
    ) {
      return true;
    }
  }
  if (form?.edbType == "presencial") {
    form.streaming = null;
    form.conference = null;
    form.public = false;
    let isError = false;

    const references = ShareLocalityController(country)?.references;

    references.forEach((reference) => {
      if (reference.name == "neighborhood" && country == "uy") {
      } else if (reference.name == "reference" && isReferenceOptional()) {
      } else if (form.addressInfo[reference.name] == "") {
        isError = true;
      }
    });
    if (isError) {
      return true;
    }
  }

  if (!form?.hostData?.hostId) {
    return true;
  }

  if (!form?.contactEdbPhone?.phone) {
    return false;
  }

  // debugger

  let phone = removeCountryCode(form?.contactEdbPhone?.phone);

  let isPhoneError = await SharePhoneLibrary(phone);
  if (isPhoneError) {
    let responde = {
      msg: "El teléfono ingresado no es válido",
      status: isPhoneError,
    };
    return responde;
  }

  return false;
};

const postNewEdb = createAsyncThunk(
  "postNewForm",
  async (form, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      let isError = await checkFormValues(form, location);
      if (isError) {
        if (isError.status) {
          throw new Error(isError.msg);
        }
        throw new Error("Faltan datos en el formulario");
      }
      if (form.contactEdbPhone.phone) {
        let valueCountry = numbers[location].concat(
          removeCountryCode(form?.contactEdbPhone.phone)
        );
        form.contactEdbPhone.phone = valueCountry;
      }
      const response = await customNetServAxios(
        "application/json;charset=UTF-8",
        false,
        [400, 500]
      ).post(`/${location}/edbs/${userId}/appointment`, form);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const putEdb = createAsyncThunk("putEdb", async (data, { rejectWithValue }) => {
  try {
    let location = localStorage.getItem("country");
    let userId = localStorage.getItem("clientId");
    let isError = await checkFormValues(data.form, location);

    if (isError) {
      if (isError.status) {
        throw new Error(isError.msg);
      }
      throw new Error("Faltan datos en el formulario");
    }

    if (data?.form?.contactEdbPhone?.phone) {
      let valueCountry = numbers[location].concat(
        removeCountryCode(data?.form?.contactEdbPhone?.phone)
      );
      data.form.contactEdbPhone.phone = valueCountry;
    }
    const response = await customNetServAxios(
      "application/json;charset=UTF-8",
      false,
      [400]
    ).put(`/${location}/edbs/${userId}/appointment/${data?.id}`, data?.form);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const deleteEdb = createAsyncThunk(
  "deleteEdb",
  async (id, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      const response = await customNetServAxios(
        "application/json;charset=UTF-8",
        false,
        [400]
      ).delete(`/${location}/edbs/${userId}/appointment/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const deleteInvitee = createAsyncThunk(
  "deleteInvitee",
  async (data, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      const response = await customNetServAxios().delete(
        `/${location}/edbs/${userId}/appointment/${data.edbId}/assistants/${data.inviteeId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const addInvitee = createAsyncThunk(
  "addInvitee",
  async (data, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      const response = await customNetServAxios(null, null, [400]).post(
        `/${location}/edbs/${userId}/appointment/${data.edbId}/assistants/${data.attendeeId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const getEDBCards = createAsyncThunk(
  "getEDBCards",
  async (data, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let userId = localStorage.getItem("clientId");
      const response = await customNetServAxios(null, null, [400]).get(
        `/${location}/edbs/${userId}/appointment/${data.edbId}/assistants/${data.attendeeId}/cards`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export {
  fetchTopics,
  postNewEdb,
  deleteEdb,
  putEdb,
  fetchEDBDetailsForFormBody,
  addInvitee,
  deleteInvitee,
  getEDBCards,
};
