import React, { useState } from 'react';
import { useEffect } from 'react';
import './SelectPickupPointModal.css';
const SelectPickupPointModal = ({ setSelectedPickupPoint, selectedPickupPoints, showModal, setShowModal }) => {
    const [pickupPoints, setPickupPoints] = useState([
        "Virrey del Pino 2500",
        "Virrey del Pino 2501",
        "Virrey del Pino 2502",
        "Virrey del Pino 2503",
        "Virrey del Pino 2504",
        "Virrey del Pino 2505",
        "Virrey del Pino 2506",
        "Virrey del Pino 2507",
        "Virrey del Pino 2508",
        "Virrey del Pino 2509",
        "Virrey del Pino 2510",
        "Virrey del Pino 2511",
    ]);
    const [tempSelected, setTempSelected] = useState('');

    useEffect(() => {
        setTempSelected(selectedPickupPoints)
    }, [selectedPickupPoints]);

    const handleSelection = () => {
        setSelectedPickupPoint(tempSelected)
        setTempSelected("");
        setShowModal(false);
    }

    const changeSelection = (e) => {
        setTempSelected(e.target.value)
    }

    const handleClose = () => {
        setShowModal(false);
        setTempSelected(selectedPickupPoints)
    };

    return (
        <div
            id="selectPickupPoint"
            className={`modal fade ${showModal ? 'show' : ''}`}
            aria-hidden={!showModal}
            tabIndex="-1"
            style={{ display: showModal ? 'block' : 'none' }}
            onClick={() => handleClose()}
        >
            <div
                className="modal-dialog modal-dialog-centered"
                style={{ maxWidth: "496px" }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="modal-content modal-radius">
                    <div className="modal-body" style={{ padding: "32px",height:'550px',width:'496px' }}>
                        <div className='mb-3'>
                            <div className='d-flex  gap-2 ' style={{ marginBottom: "34px" }}>
                                <h1 className=' museo-sans-700 m-0 s20'>Seleccionar punto de retiro</h1>
                            </div>
                        </div>

                        <div style={{  maxHeight: "364px" }}
                            className="scrollLocation d-flex flex-column my-4 mx-4">
                            {pickupPoints?.map((location, index) => (
                                <div key={index} style={{marginRight:'16px'}}>
                                    <div className="d-flex gap-2 align-items-center ms-3">
                                        <input
                                            className="custom-radio"
                                            type="radio"
                                            name="pickupPoint"
                                            id={location}
                                            value={location}
                                            checked={tempSelected === location}
                                            onChange={changeSelection}
                                        />
                                        <label className="form-check-label museo-sans-500 s14" htmlFor={location}>
                                            {location}
                                        </label>
                                    </div>
                                    {index < pickupPoints.length - 1 && <hr />}
                                </div>
                            ))}
                        </div>

                        <div className='d-flex justify-content-center' style={{marginTop:'32px'}}>
                            <button
                                disabled={tempSelected === ''}
                                onClick={() => handleSelection()}
                                data-bs-dismiss="modal" aria-label="Close"
                                type="button"
                                className={`${tempSelected === '' && "cursor-default opacity-50"} payment-btn skyBg whiteColor skyBorder museo-sans-700 s14 `}
                            >
                                Seleccionar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SelectPickupPointModal;
