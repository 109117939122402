import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import ShareModalStructure from "../../../../common/Share/ShareModalStructure";
import OrdersModalShare from "../modals/OrdersModalShare";
import OrderCartKitHolder from "../OrdersCartKitHolder";
import OrdersPromotion from "../ordersPromotion/ordersPromotion";
import { Repository } from "@huenei/oficina-virtual-data-manager/src/repositories";
import {
  OrderCartSummary,
  OrderCartButtonContainer,
} from "../../pages/OrdersCart";
import profileIcon from "../../../imgs/Profile.png";
import OrderCartFree from "../ordersFree/ordersFree";
import SummaryFirstSteps from "../summaryFirstSteps/summaryFirstSteps";
import { saveCartBody } from "../../../Framework/redux/OrdersActions";

export default function SummaryRequested() {



  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartContainer = useSelector(
    (state) => state.orderData.cart.cartContainer
  );
  const cartKit = useSelector((state) => state.orderData.cart.cartKit);
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const frirstSteps = useSelector(
    (state) => state.orderData.cart.cartFirstStepsProducts
  );
  const orderSummary = useSelector(
    (state) => state.orderData.orderSummary
  );


  const cartCounter = useSelector((state) => state.orderData.cart.cartCounter);
  const [cartBody, setCartBody] = useState({ cart: {}, isLoading: true });
  const [hasChanged, setHasChanged] = useState(false);
  const [forcedChange, setForcedChange] = useState(false);

  const ifValueAPrice = (amount) => {
    return formatAmount(
      amount,
      cultureInfo?.languageCode,
      cultureInfo?.defaultCurrencyCode
    );
  };

  const aChangeHasOccured = () => {
    setHasChanged(true);
  };



  const onListProductsUpdate = (product, operation) => {
  };
  const onPromotionsChange = (product, operation) => {


  };
  const aChangeHasFalsed = () => {
    setForcedChange(true);
    setHasChanged(false);
  };
  const onUpdateCommand = async () => {
    setHasChanged(true);
  };
  const JSXButtonsAndFunctionsForModals = {
    sendOrder: {
      modalId: "modal-send-order",
      modalTitle: "¿Cómo quieres continuar?",
      modalDescription:
        "Puedes enviarla a Just individualmente o sumarla a un conjunto de órdenes y compartir los gastos de envío",
      modalStatus: "warning",
      modalButtons: [
        {
          text: "Enviar esta orden únicamente",
          style: "skyBorder",
          onClick: () => {
            console.log("Enviar a Just");
          },
        },
        {
          text: "Seleccionar más órdenes a enviar",
          style: "skyBorder",
          onClick: () => {
            console.log("Sumar a un conjunto de órdenes");
          },
        },
        {
          text: "Cancelar",
          style: "redColor",
          onClick: () => {
            console.log("Sumar a un conjunto de órdenes");
          },
        },
      ],
    },
    endAndSaveOrder: {
      modalId: "modal-end-and-save-order",
      modalTitle: "¡Orden guardada con éxito!",
      modalDescription:
        "Recuerda que puedes enviarla a Just individualmente o sumarla a un conjunto de órdenes y compartir los gastos de envío",
      modalStatus: "confirm",
      modalButtons: [
        {
          text: "Crear otra orden",
          style: "skyBg",
          onClick: () => {
            console.log("Enviar a Just");
          },
        },
        {
          text: "Ver mis órdenes",
          style: "skyBorder",
          onClick: () => {
            console.log("Sumar a un conjunto de órdenes");
          },
        },
      ],
    },
    endOrder: {
      modalId: "modal-end-order",
      modalTitle: "Salir del pedido",
      modalDescription:
        "¿Estás seguro de que quieres salir? Si sales ahora, el pedido se desconsolidará y tendrás que volver a seleccionar las órdenes que desees enviar.",
      modalStatus: "error",
      modalButtons: [
        {
          text: "Volver al pedido",
          style: "skyBorder",
          onClick: () => {
            console.log("Enviar a Just");
          },
        },
        {
          text: "Salir sin guardar",
          style: "redColor",
          onClick: () => {
            goBack();
          },
        },
      ],
    },
  };

  const goBack = () => {
    navigate(`/dashboard/orders/listado-kit`, { state: { isOrders: true } });
  }

  return (
    <>
      <ShareModalStructure maxWidth={"496px"} id={"modal-end-order"}>
        <OrdersModalShare
          modalData={JSXButtonsAndFunctionsForModals["endOrder"]}
          status={"error"}
        />
      </ShareModalStructure>
      <div
        style={{ marginTop: "48px", marginBottom: "52px" }}
        className="d-flex flex-column museo-sans-400 "
      >
        <p className=" museo-sans-600 s24 mb-4">Resumen de pedido</p>
        <div
          style={{ padding: "40px", boxShadow: '3px 3px 15px 0px rgba(136, 137, 219, 0.1)' }}
          className="w-100 bg-white  box-shadow rounded-3"
        >
          <div >
            <div className="d-flex justify-content-between w-100" style={{ borderBottom: '1px solid #DDDDDD', height: '44px' }}>
              <p className=" s12 museo-sans-600" style={{ color: "#908F9A" }}>
                <span style={{ marginRight: "6px" }}>
                  <img src={profileIcon} alt="" />
                </span>
                ORDEN PERSONAL
              </p>
            </div>
          </div>
          <SummaryFirstSteps data={orderSummary.orderProducts} title={false} />
          {!hasChanged && (
            <>
              {orderSummary?.promotionsProducts?.length > 0 && (
                <OrdersPromotion data={orderSummary?.promotionsProducts} />
              )}
              {orderSummary?.spontaneousProducts?.length > 0 && (
                <OrderCartFree products={orderSummary?.spontaneousProducts} />
              )}
              {frirstSteps && Object.keys(frirstSteps).length !== 0 && (
                <SummaryFirstSteps data={frirstSteps} title={true} />
              )}
              {orderSummary?.kitProducts?.length > 0 && (
                <OrderCartKitHolder
                  ifValueAPrice={ifValueAPrice}
                  cartKit={orderSummary?.kitProducts}
                />
              )}

              <OrderCartSummary
                cartSummary={orderSummary.cartSummary}
                isSummary={true}
              />
            </>
          )}
        </div>
        <OrderCartButtonContainer
          hasChanged={hasChanged}
          aChangeHasFalsed={aChangeHasFalsed}
          isSummary={true}
          navigate={navigate}
        />
      </div>
    </>
  );
}
