import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

// http://api-backend-test.aws.swissjust.com

const getDataLiderAndDirector  = createAsyncThunk(
    'getDataLiderAndDirector',
    async (data, {rejectWithValue} ) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            // despues cambiar 698083 por userId.
            if (data) {
                const response = await customNetServAxios().get(`/${location}/first-steps/${userId}/consultants-group?filterType=all&sort_by=${data.ordernarPor}.${data.orden}`);
                return response.data
            }else{
                const response = await customNetServAxios().get(`/${location}/first-steps/${userId}/consultants-group?filterType=all`);
                return response.data;
            }
        } catch(error) {
            return error 
        }
    }
)

const getDataConsultant  = createAsyncThunk(
    'getDataConsultant',
    async ( ) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/first-steps/${userId}/personal-steps`);
            return response.data;
        } catch(error) {
            return error
        }
    }
)
const getDataFirstGeneration  = createAsyncThunk(
    'getDataFirstGeneration',
    async (data, {rejectWithValue}  ) => {
        try {
            
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            if (data) {
                const response = await customNetServAxios().get(`/${location}/first-steps/${userId}/first-generation?limit=10&offset=${data.auxOffSet}&sort_by=${data.ordernarPor}.${data.orden}`);
                return response.data;
            }else{
                const response = await customNetServAxios().get(`/${location}/first-steps/${userId}/first-generation?limit=10&offset=0`);
                return response.data;
            }            
        } catch(error) {
            return error
        }
    }
)
const getDataFirstGenerationLenght  = createAsyncThunk(
    'getDataFirstGenerationLenght',
    async (data, {rejectWithValue}  ) => {
        try {
            
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            if (data) {
                const response = await customNetServAxios().get(`/${location}/first-steps/${userId}/first-generation?limit=10&offset=${data.auxOffSet}&sort_by=${data.ordernarPor}.${data.orden}`);
                return response.data
            }else{
                const response = await customNetServAxios().get(`/${location}/first-steps/${userId}/first-generation?limit=10&offset=0`);
                return response.data
            }            
        } catch(error) {
            return error
        }
    }
)
const getCMSDataOne = createAsyncThunk(
    'getCMSDataOne',
    async (data, {rejectWithValue} ) => {
        try {
            let location = localStorage.getItem("country");
            const response = await customNetServAxios(false, true).get(`/${location}_pp_plan/${data ?? 1}?fields=*.*,stepId.image.*`);
            return response.data;
        } catch(error) {
            return error
        }
    }
)

const getCMSDataTwo = createAsyncThunk(
    'getCMSDataTwo',
    async (data, {rejectWithValue} ) => {
        try {
            let location = localStorage.getItem("country");
            const response = await customNetServAxios(false, true).get(`/${location}_pp_plan/${data ?? 1}?fields=*.*,stepId.image.*`);
            return response.data;
        } catch(error) {
            return error
        }
    }
)

const getIncorporatedOnly = createAsyncThunk(
    'getIncorporatedOnly',
    async (  ) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/first-steps/${userId}/consultants-group?filter_type=incorporated`);
            return response.data
        } catch(error) {
            return error
        }
    }
)
const getRestOfGroupOnly = createAsyncThunk(
    'getRestOfGroupOnly',
    async ( ) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/first-steps/${userId}/consultants-group?filter_type=restOfGroup`);
            return response.data
        } catch(error) {
            return error
        }
    }
)
export {getDataLiderAndDirector,getDataConsultant,getDataFirstGeneration, getCMSDataOne, getCMSDataTwo,getIncorporatedOnly,getRestOfGroupOnly,getDataFirstGenerationLenght}