import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../services/NetSrv";

const fetchExtLinks = createAsyncThunk(
    'fetchExtLinks',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/external-links/${clientId}/links`)).data;
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const getPeriod  = createAsyncThunk(
    'getPeriod',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            const response = (await customNetServAxios().get(`${location}/calendar/period-data`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getNotifications  = createAsyncThunk(
    'getNotifications',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/notifications/${clientId}`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getBadgeCounter = createAsyncThunk(
    'getBadgeCounter',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/notifications/badge-counter/${clientId}`)).data;
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

const patchNotfication = createAsyncThunk(
    'patchNotfication',
    async ({notificationId, newStatus}, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            let urlencoded = new URLSearchParams();
            urlencoded.append("notificationId", parseInt(notificationId));
            urlencoded.append("newStatus", newStatus);
            const response = await customNetServAxios().post(`${location}/notifications/${clientId}`, urlencoded);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)


export { getPeriod, fetchExtLinks, getNotifications, getBadgeCounter, patchNotfication }
