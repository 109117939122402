import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const fetchAccountStatusDataTableMyAccount = createAsyncThunk(
  "fetchAccountStatusDataTableMyAccount",
  async (
    {
      accountType,
      filterType,
      iniDate,
      endDate,
      iniPeriod,
      endPeriod,
      limit,
      offSet,
      defaultIniDate,
      defaultEndDate,
    },
    { rejectWithValue }
  ) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");

      // Seperate services according to Filters.

      if (
        filterType != "lastWeek" &&
        filterType != "lastPeriod" &&
        filterType != "lastYear" &&
        filterType
      ) {
        if (filterType == "dateFilter") {
          return (await customNetServAxios().get(
            `${location}/account-status/${clientId}/summary?account_type=${
              accountType ? accountType : "currentAccount"
            }&filter_type=dateFilter&ini_date=${iniDate}&end_date=${endDate}&limit=${
              limit ? limit : 20
            }&offset=${offSet ? offSet : "0"}`
          )).data;

          // 2021-03-18
        }
        if (filterType == "periodFilter") {
          return (await customNetServAxios().get(
            `${location}/account-status/${clientId}/summary?account_type=${
              accountType ? accountType : "currentAccount"
            }&filter_type=periodFilter&ini_period=${iniPeriod}&end_period=${endPeriod}&limit=${
              limit ? limit : 20
            }&offset=${offSet ? offSet : "0"}`
          )).data;
        }
      } else {
        if (filterType == null) {
          let arrayOrigin = defaultIniDate.split("-");

          let year = arrayOrigin[2];
          let month = arrayOrigin[1];
          let day = arrayOrigin[0];

          let finalOrigin = `${year}-${month}-${day}`;

          let arrayTarget = defaultEndDate.split("-");

          year = arrayTarget[2];
          month = arrayTarget[1];
          day = arrayTarget[0];

          let finalTarget = `${year}-${month}-${day}`;

          return (await customNetServAxios().get(
            `${location}/account-status/${clientId}/summary?account_type=${
              accountType ? accountType : "currentAccount"
            }&filter_type=${
              filterType ? filterType : "dateFilter"
            }&ini_date=${finalOrigin}&end_date=${finalTarget}&limit=20&offset=${
              offSet ? offSet : "0"
            }`
          )).data;
        }

        if (filterType == "lastYear") {
          filterType = "currentYear";
        };

        return (await customNetServAxios().get(
          `${location}/account-status/${clientId}/summary?account_type=${
            accountType ? accountType : "currentAccount"
          }&filter_type=${
            filterType ? filterType : "lastPeriod"
          }&limit=20&offset=${offSet ? offSet : "0"}`
        )).data;
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
  // /account-status/{clientId}/summary?account_type=currentAccount
);


const fetchAccountStatusDescDataBalance = createAsyncThunk(
  "fetchAccountStatusDescDataBalance",
  async ({ descendantId }, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");

      return (await customNetServAxios().get(
        `${location}/account-status/${clientId}/descendant/${descendantId}/balance`
      )).data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchAccountStatusDescData = createAsyncThunk(
  "fetchAccountStatusDescData",
  async ({ descendantId }, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");

      return (await customNetServAxios().get(`${location}/profile/${descendantId}`)).data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchAccountStatusDocumentSummaryById = createAsyncThunk(
  "fetchAccountStatusDocumentSummaryById",
  async (params) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");
      return (await customNetServAxios().get(
        `${location}/account-status/${clientId}/summary/${params}`
      )).data;
    } catch (error) {
      return error.message;
    }
  }
);
const fetchAccountStatusDocumentSummaryDescById = createAsyncThunk(
  "fetchAccountStatusDocumentSummaryById",
  async (params) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");
      return (await customNetServAxios().get(
        `${location}/account-status/${clientId}/descendant/${params.descId}/summary/${params.id}/`
      )).data;
    } catch (error) {
      return error.message;
    }
  }
);

const fetchAccountStatusDataBalance = createAsyncThunk(
  "fetchAccountStatusDataBalance",
  async (params, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");

      return (await customNetServAxios().get(`${location}/account-status/${clientId}/balance`)).data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchAccountStatusDescendentData = createAsyncThunk(
  "fetchAccountStatusDescendentData",
  async (
    {
      descendantId,
      accountType,
      filterType,
      iniDate,
      endDate,
      iniPeriod,
      endPeriod,
      limit,
      offSet,
      defaultIniDate,
      defaultEndDate,
    },
    { rejectWithValue }
  ) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");

      if (
        filterType != "lastWeek" &&
        filterType != "lastPeriod" &&
        filterType != "lastYear" &&
        filterType
      ) {
        if (filterType == "dateFilter") {
          return (await customNetServAxios().get(
            `${location}/account-status/${clientId}/descendant/${
              descendantId ? descendantId : clientId
            }/summary?account_type=${
              accountType ? accountType : "currentAccount"
            }&filter_type=dateFilter&ini_date=${iniDate}&end_date=${endDate}&limit=${
              limit ? limit : 20
            }&offset=${offSet ? offSet : "0"}`
          )).data;

          // 2021-03-18
        }
        if (filterType == "periodFilter") {
          return (await customNetServAxios().get(
            `${location}/account-status/${clientId}/descendant/${
              descendantId ? descendantId : clientId
            }/summary?account_type=${
              accountType ? accountType : "currentAccount"
            }&filter_type=periodFilter&ini_period=${iniPeriod}&end_period=${endPeriod}&limit=${
              limit ? limit : 20
            }&offset=${offSet ? offSet : "0"}`
          )).data;
        }
      } else {
        if (filterType == null) {
          let arrayOrigin = defaultIniDate.split("-");

          let year = arrayOrigin[2];
          let month = arrayOrigin[1];
          let day = arrayOrigin[0];

          let finalOrigin = `${year}-${month}-${day}`;

          let arrayTarget = defaultEndDate.split("-");

          year = arrayTarget[0];
          month = arrayTarget[1];
          day = arrayTarget[2];

          let finalTarget = `${year}-${month}-${day}`;

          return (await customNetServAxios().get(
            `${location}/account-status/${clientId}/descendant/${
              descendantId ? descendantId : clientId
            }/summary?account_type=${
              accountType ? accountType : "currentAccount"
            }&filter_type=${"dateFilter"}&ini_date=${finalOrigin}&end_date=${finalTarget}&limit=20&offset=${
              offSet ? offSet : "0"
            }`
          )).data;
        };

        if (filterType == "lastYear") {
          filterType = "currentYear";
        };

        return (await customNetServAxios().get(
          `${location}/account-status/${clientId}/descendant/${
            descendantId ? descendantId : clientId
          }/summary?account_type=${
            accountType ? accountType : "currentAccount"
          }&filter_type=${filterType ? filterType : "lastPeriod"}&limit=${
            limit ? limit : 20
          }&offset=${offSet ? offSet : "0"}`
        )).data;
      }
      // …/descendant/{descendantId}/summary?account_type=currentAccount&filter_type=dateFilter&ini_date=2021-03-18&end_date=2021-03-23
      // …/descendant/{descendantId}/summary?account_type=currentAccount&filter_type=periodFilter&ini_period=2301&end_period=2304
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export {
  fetchAccountStatusDataBalance,
  fetchAccountStatusDescendentData,
  fetchAccountStatusDataTableMyAccount,
  fetchAccountStatusDocumentSummaryById,
  fetchAccountStatusDescData,
  fetchAccountStatusDescDataBalance,
  fetchAccountStatusDocumentSummaryDescById,
};
