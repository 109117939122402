import * as yup from "yup";
import SharePhoneLibrary from "../../../../common/Share/SharePhoneLibrary";

export const mexicoSchema = yup
  .object({
    firstName: yup
      .string()
      .trim()
      .required("nombre"),
    lastName: yup
      .string()
      .trim()
      .required("apellido"),
    comments: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9.@ áéíóúÁÉÍÓÚñÑüÜ_-]*$/, "comentarios"),
    street: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9.@ áéíóúÁÉÍÓÚñÑüÜ_-]*$/, "calle"),
    signs: yup
      .string()
      .trim()
      .default("")
      .matches(/^[a-zA-Z0-9.@ áéíóúÁÉÍÓÚñÑüÜ_-]*$/, "Señas"),
    reference: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9.@ áéíóúÁÉÍÓÚñÑüÜ_-]*$/, "Referencia"),
    externalNumber: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9.@ áéíóúÁÉÍÓÚñÑüÜ_-]*$/, "Número exterior"),
    internalNumber: yup
      .string()
      .trim()
      .matches(/^[a-zA-Z0-9.@ áéíóúÁÉÍÓÚñÑüÜ_-]*$/, "Número interior"),
    email: yup
      .string()
      .email("email")
      .test("is-valid-email", "email", (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        if (value === "" || value === null || value === undefined) return true;
        return emailRegex.test(value);
      }),
    cellNumber: yup
      .string("telefono")
      .test("phone-validation", "teléfono", async (value, context) => {
        let isPhoneFailedValidation = await SharePhoneLibrary(value);
        return !isPhoneFailedValidation;
      })
      .required("teléfono"),
  })
  .required();
