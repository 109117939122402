import PerformanceSocialIcons from "../../../Performance/Presentation/components/PerformanceSocialIcons";
import InvitationsListTableRowProgress from "./InvitationsListTableRowProgress";
import { useDispatch } from "react-redux";
import { newInvitationCopy } from "../../Framework/redux/InvitationsAction";
import InvitationsModalShareLink from "./modals/InvitationsModalShareLink";
import InvitationsDetailsErrorMessage from "./InvitationsDetailsErrorMessage";
import InvitationsDetailsButtonMessage from "./InvitationsDetailsButtonMessage";
import InvitationsListTableModal from "./modals/InvitationsListTableModal";
import { FormattedMessage } from "react-intl";
import { capitalizeString } from "../../../common/utils/stringUtilities";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import InvitationsModalIncorporatedPopUp from "./modals/InvitationsModalIncorporatedPopUp";

export default function InvitationsListTableRow({ data, auxIndex, lastData }) {
  const dispatch = useDispatch();

  const onShowModalCopy = () => {
    dispatch(newInvitationCopy());
  };

  const configExist = (array, auxId) => {
    let resolve = 0;
    if (array) {
      resolve = array.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  return (
    <>
      <ShareModalStructure id={`incorporated`} idNumber={data?.id}>
        <InvitationsModalIncorporatedPopUp
          body={data?.stages[2]?.stageDetail?.tooltipMessage}
        />
      </ShareModalStructure>
      <InvitationsListTableModal id={data?.id} />
      <InvitationsModalShareLink
        firstName={data?.inviteeInfo?.firstName}
        lastName={data?.inviteeInfo?.lastName}
        reInvite={true}
        open={onShowModalCopy}
        id={data?.id}
        data={data?.inviteeInfo?.invitationLink}
        phone={
          data.inviteeInfo?.contactInfo[
            configExist(data.inviteeInfo?.contactInfo, "whatsapp")
          ]?.value
        }
        email={
          data.inviteeInfo?.contactInfo[
            configExist(data.inviteeInfo?.contactInfo, "email")
          ]?.value
        }
      />
      <div className="d-flex justify-content-between  w-100 gap-5 px-3 ">
        <div className="d-flex flex-column w-100">
          <p className=" s26 museo-sans-700">
          {capitalizeString(data?.inviteeInfo?.firstName)} {capitalizeString(data.inviteeInfo?.lastName)}
          </p>
          <div className="d-flex flex-column gap-2 mt-1">
            {data.inviteeInfo?.clientProfile?.isReincorporated && (
              <p className=" text-uppercase  w-min-content px-3 blueBubbleColors museo-sans-700 s12 rounded-16 text-center">
                <FormattedMessage id="invitation.reincoporated" />
              </p>
            )}
            {data.inviteeInfo?.reference && (
              <p className=" museo-sans-500 grayColor s14">
                {
                  //capFirstLetter(data.inviteeInfo?.reference)
                }
              </p>
            )}
          </div>
          <div className=" mt-4 d-flex gap-4">
            {data.inviteeInfo?.contactInfo?.length > 0 && (
              <div className="d-flex gap-2">
                <PerformanceSocialIcons
                  socialArray={data.inviteeInfo?.contactInfo}
                />
              </div>
            )}
            <InvitationsDetailsButtonMessage
              stages={data.stages}
              id={data?.id}
              data={data}
            />
          </div>
        </div>
        <div className="h-100 w-100 d-flex align-items-start justify-content-end">
          <InvitationsListTableRowProgress
            restOfData={data}
            data={data.stages}
          />
        </div>
      </div>
      <div className=" mt-2">
        <InvitationsDetailsErrorMessage stages={data?.stages} />
      </div>
      {lastData !== auxIndex + 1 ? <hr /> : <div className="my-3"></div>}
    </>
  );
}
