import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";


const getMeetings  = createAsyncThunk(
    'getMeetings',
    async (data , {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/edbs/${clientId}/meetings?offset=0&limit=10`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getScore = createAsyncThunk( 
    'getScore',
    async (data , {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId"); // Error Potential Leader
            const response = (await customNetServAxios().get(`${location}/performance/${clientId}/score`)).data; // ${location}/performance/${clientId}/score
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getGroup = createAsyncThunk(
    'getGroup',
    async (data , {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId"); // Error Potential Leader
            const response = (await customNetServAxios().get(`${location}/performance/${data ? data.id : clientId}/score/groups`)).data; // ${location}/performance/${data ? data.id : clientId}/score/groups
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getGroupQualified = createAsyncThunk(
    'getGroup',
    async (data , {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/performance/${clientId}/score/qualified-groups`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getPotentialComparisonLevel = createAsyncThunk(
    'getPotentialComparisonLevel',
    async (data , {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/performance/${clientId}/score?comparisonLevel=${2}`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const getOffersData = createAsyncThunk(
    'getOffersData',
    async ( ) => {
        try {
            let location = localStorage.getItem("country");
            const response = (await customNetServAxios(false, true).get(`${location}_ofertas?fields=*.*`)).data;
            
            return response;
        } catch(error) {
            return error;
        }
    }
)

const getNoticeLiders = createAsyncThunk(
    'getNoticeLiders',
    async ( ) => {
        try {
            let location = localStorage.getItem("country");
            const response = (await customNetServAxios(false, true).get(`${location}_notilideres?fields=*.*`)).data;
           

            return response;
        } catch(error) {
            return error;
        }
    }
)
const getNoticeConsultant = createAsyncThunk(
    'getNoticeConsultant',
    async ( ) => {
        try {
            let location = localStorage.getItem("country");
            const response = (await customNetServAxios(false, true).get(`${location}_noticonsultores?fields=*.*`)).data;
            

            return response;
        } catch(error) {
            return error;
        }
    }
)
const getNoticeClients = createAsyncThunk(
    'getNoticeClients',
    async ( ) => {
        try {
            let location = localStorage.getItem("country");
            const response = (await customNetServAxios(false, true).get(`${location}_noticlientes?fields=*.*`)).data;
            

            return response;
        } catch(error) {
            return error;
        }
    }
)

export { getMeetings , getScore , getGroup, getGroupQualified, getPotentialComparisonLevel,getOffersData, getNoticeLiders,getNoticeConsultant,getNoticeClients }
