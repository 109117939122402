import { useSelector } from "react-redux";
import HeaderNewBtn from "../HeaderNewBtn";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import Loader from "../../../../Share/Loader";
import { useLocation } from "react-router-dom";
import HeaderNewOrderBtn from "../HeaderNewOrderBtn";
import HeaderNotifications from "../HeaderNotifications";
import { ReactComponent as SVGBell } from "../../../../svgs/ic-bell-lg.svg";
import OrdersHeaderCartIcon from "../../../../../Orders/Presentation/components/OrdersHeaderCartIcon";

export default function HeaderColumnThree({
  toggleNotfi,
  onHeaderClick,
  togglePlusModal,
  headerTogglerBtn,
  setHeaderTogglerBtn,
  headerData,
  invitationToggler,
  headerTogglerNotfi,
  setHeaderTogglerNotfi,
  directorId,
}) {
  const location = useLocation();
  const ecommerceData = useSelector((state) => state.ecommerceData);
  const [loaderLocation, setLoaderLocation] = useState("none");

  const { isOrder } = useSelector((state) => state.orderData);

  const onLocationChangeButton = () => {
    const pathsToCheck = [
      "/dashboard/edbs/form/type",
      "/dashboard/ecommerce/edit",
      "/dashboard/clientlist/form/update",
      "/dashboard/clientlist/form/add",
      "/dashboard/edbs/form/client/add",
      "/dashboard/edbs/form/client/update",
    ];

    if (
      location.pathname.includes("dashboard/edbs/details/") &&
      location.pathname.includes("/client/form")
    ) {
      return true;
    }

    return pathsToCheck.some((path) => location.pathname.includes(path));
  };

  const onChangeButton = () => {
    const routeMappings = [
      {
        paths: [
          "/dashboard/clientlist/form/update/",
          "/dashboard/edbs/form/client/update",
          "dashboard/ecommerce/edit",
          "/dashboard/ecommerce/edit",
        ],
        result: "save",
      },
      {
        paths: ["/dashboard/orders"],
        result: "button.new",
      },
      {
        paths: ["/dashboard/edbs/form/type"],
        condition: (path) =>
          path.includes("present/") || path.includes("virtual/"),
        trueResult: "edb.form.save.encounter",
        falseResult: "edb.form.create.encounter",
      },
      {
        paths: [
          "/dashboard/edbs/details/",
          "/client/form",
          "/dashboard/clientlist/form/add",
          "/dashboard/edbs/form/client/add",
        ],
        result: "create",
      },
    ];

    for (const mapping of routeMappings) {
      if (mapping.paths.some((path) => location.pathname.includes(path))) {
        if (mapping.condition) {
          return mapping.condition(location.pathname)
            ? mapping.trueResult
            : mapping.falseResult;
        }
        return mapping.result;
      }
    }

    return "id";
  };

  const onLocationNeedInvisible = () => {
    const pathsToCheck = ["/dashboard/ecommerce/new","/dashboard/ecommerce/mod"];
    return pathsToCheck.some((path) => location.pathname.includes(path));
  };

  const onBellInvisibleWhen = () => {
    const paths = ["/dashboard/orders"];

    return !paths.some((path) => location.pathname.includes(path));
  };

  const onCartVisibleWhen = () => {
    const paths = [
      "/dashboard/orders/catalogo-consulta",
      "/dashboard/orders/cart",
    ];

    return paths.some((path) => location.pathname.includes(path));
  };

  const onButtonInvisibleWhen = () => {
    const paths = ["/dashboard/orders/"];
    return !paths.some((path) => location.pathname.includes(path));
  };

  const onNewButtonVisibleWhen = () => {
    console.log(isOrder);
    const paths = ["/dashboard/orders/listado-kit"];

    return paths.some((path) => location.pathname.includes(path)) && isOrder;
  };

  const buttonNewOrder = () => {
    return (
      <>
        <button
          id="header--plusBtn"
          onClick={togglePlusModal}
          className={` position-relative w-100 border-0 rounded-3 skyBg text-white d-flex justify-content-center  py-2 px-3 s14`}
        >
          {JSXLoader[loaderLocation]}
          {" "}
          <FormattedMessage id={onChangeButton()} />
        </button>
        <HeaderNewOrderBtn
          toggle={headerTogglerBtn}
          toggler={setHeaderTogglerBtn}
        />
      </>
    );
  };

  const JSXLoader = {
    ecommerce: <Loader loading={ecommerceData?.isLoading} />,
    none: <></>,
  };

  useEffect(() => {
    if (location?.pathname?.includes("/dashboard/ecommerce")) {
      setLoaderLocation("ecommerce");
    }
  }, [location?.pathname]);

  return (
    <>
      {directorId ? (
        <div className=" h-100 d-flex w-100 py-2 justify-content-end">
          <div className=" px-5 museo-sans-700 d-flex flex-column justify-content-center align-items-center goldWarning-container rounded-16  ">
            <p>
              <FormattedMessage id="sale.director.header.message.one" />
            </p>
            <p>
              <FormattedMessage id="sale.director.header.message.two" />
            </p>
          </div>
        </div>
      ) : (
        <div
          id="header--btn-holder"
          className={` ${
            onLocationNeedInvisible() ? "invisible" : ""
          } position-relative container--padding-right position-relative `}
        >
          {onButtonInvisibleWhen() && (
            <>
              {onLocationChangeButton() ? (
                <>
                  <div
                    onClick={onHeaderClick}
                    style={{ width: "184px" }}
                    className="header-btn-plus-button-width museo-sans-700 s14 d-flex justify-content-center"
                  >
                    <button
                      id=""
                      onClick={togglePlusModal}
                      className={` w-100 border-0 rounded-3 skyBg text-white d-flex justify-content-center  py-2 px-3 s14`}
                    >
                      {" "}
                      <FormattedMessage id={onChangeButton()} />
                    </button>
                  </div>
                </>
              ) : (
                <div className="header-btn-plus-button-width museo-sans-700 s14 d-flex justify-content-center">
                  {location.pathname.includes("/dashboard/orders") ? (
                    buttonNewOrder()
                  ) : (
                    <>
                      <button
                        id="header--plusBtn"
                        onClick={togglePlusModal}
                        className={` w-100 border-0 rounded-3 blueBg text-white d-flex justify-content-center  py-2 px-3 s14`}
                      >
                        {" "}
                        <p id="header--rotateCross">
                          <i className="bi bi-plus-lg"></i>
                        </p>{" "}
                        <p className=" invisible">{"a"}</p>{" "}
                        <FormattedMessage
                          id="buttons.new"
                          defaultMessage="New"
                        />
                      </button>
                      <HeaderNewBtn
                        toggle={headerTogglerBtn}
                        toggler={setHeaderTogglerBtn}
                        headerData={headerData}
                        invitationToggler={invitationToggler}
                      />
                    </>
                  )}
                </div>
              )}
            </>
          )}

          {onBellInvisibleWhen() && (
            <div id="header--bell" className=" d-flex align-items-center">
              <HeaderNotifications
                toggle={headerTogglerNotfi}
                toggler={setHeaderTogglerNotfi}
              />
              {headerData?.badgeCounter?.badges > 0 && (
                <div
                  className=" position-absolute dm-sans-medium s14 text-white text-center"
                  id="hover-counter"
                  style={{ background: "#f06e69" }}
                >
                  {headerData?.badgeCounter?.badges > 99
                    ? "99+"
                    : headerData?.badgeCounter?.badges}
                </div>
              )}
              <div
                style={{ cursor: "pointer" }}
                className=" pointer"
                onClick={toggleNotfi}
              >
                <SVGBell />
              </div>
            </div>
          )}

          {onCartVisibleWhen() && (
            <div style={{ top: "-4px" }} className=" position-relative">
              <OrdersHeaderCartIcon />
            </div>
          )}

          {onNewButtonVisibleWhen() && (
            <div className="header-btn-plus-button-width museo-sans-700 s14 d-flex justify-content-center">
              {buttonNewOrder()}
            </div>
          )}
        </div>
      )}
    </>
  );
}
