import React, { useState } from "react";
import "./ModalDifferentCashPayments.css";
import ForWindows from "./components/forWindows/forWindows";
import MouthComponent from "./components/mouth/MouthComponent";
import ATMs from "./components/ATMs/ATMs";
import closeIcon from "../../../../Imgs/Vector.png";
const ModalDifferentCashPayments = ({ isOpen, close }) => {
  const [selectedOption, setSelectedOption] = useState("windows");
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-diferentPayment " style={{ width: '496px', height: selectedOption === "windows" ? '666px' : '524px', paddingTop: '16px' }}>
        <div className="container-close">
          <img onClick={() => close()} src={closeIcon} alt="closeIcon" />
        </div>
        <div className="container-check-blue">
          <div
            className="container-btn-toggle d-flex mb-4 p-1 museo-sans-700"
            style={{ width: "392px" }}
          >
            <button
              className={`toggle-button w-50 ${selectedOption === "windows" ? "active" : ""
                }`}
              onClick={() => {
                setSelectedOption("windows");
              }}
            >
              Por ventanilla
            </button>
            <button
              className={`toggle-button w-50 ${selectedOption === "mouth" ? "active" : ""
                }`}
              onClick={() => setSelectedOption("mouth")}
            >
              Por bocas
            </button>
            <button
              className={`toggle-button w-50 ${selectedOption === "ATMs" ? "active" : ""
                }`}
              onClick={() => setSelectedOption("ATMs")}
            >
              Por cajeros
            </button>
          </div>
          {selectedOption === "windows" && <ForWindows />}
          {selectedOption === "mouth" && <MouthComponent />}
          {selectedOption === "ATMs" && <ATMs />}
          <div style={{ borderTop: "1px solid #DDDDDD", margin: "22px 22px 0" }}>
            <p className="museo-sans-400 container-img text-payment-modal  s12" style={{ width: '408px' }}>
              SwissJust no se hace responsable por pagos realizados fuera de los
              medios indicados en este informe. Todos los medios emiten
              comprobante de pago, CONSERVALO. El mismo NO debe ser enviado a la
              empresa salvo pedido expreso. Si tenés crédito de compañía, te
              recordamos que el vencimiento de tu compra es a 7 DÍAS FECHA
              FACTURA y la obligación de pago es personal e intransferible.{" "}
              <br />
              Ante cualquier duda o dificultad comunicate con tu Líder Regional.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDifferentCashPayments;
