import { createReducer } from "@reduxjs/toolkit";
import {
  isOrder,
  orders,
  requests,
  orderType,
  cleanOrderData,
  addToCart,
  removeFromCart,
  loadCart,
  addKittoCart,
  addToCartFromFirstSteps,
  removeFromCartFirstSteps,
  ordersSelect,
  saveCartBody,
  setFilter,
  saveNewTotalAmount,
  clearCart,
  setOrder,
  loadOrderSummary,
} from "./OrdersActions";
import { fetchKitData, fetchGetOrders, getClientList } from "./OrdersSideEffect";

const initialState = {
  hasKit: false,
  cart: {
    cartCounter: 0,
    cartContainer: {},
    cartFirstStepsProducts: {},
    cartKit: null,
    total: 0,
    cartBody: {}
  },
  kitData: [
    {
      kitId: null,
      name: null,
      description: null,
      imageURL: null,
      regularPrice: null,
      discountPrice: null,
      mostChosen: null,
      includedProducts: [],
      info: null,
      freeKitAvailable: null,
      freeKitMinAmount: null,
    },
  ],
  loading: false,
  orders: [],
  requests: [],
  clients: [],
  isOrder: false,
  orderType: "",
  ordersSelect: [],
  ordersSelectCount: 0,
  productsSelect: [],
  filterOrders: {
    startDate: "",
    endDate: "",
    selectedClients: [],
    selectedStatus: [],
    selectedOrder: { value: "date.desc", label: "Más nuevo al más antiguo" },
  },
  orderSummary: {}
};

function saveCartToLocalStorage(cart) {
  localStorage.setItem("cart", JSON.stringify(cart));
}

function saveOrderSummaryToLocalStorage(summary) {
  localStorage.setItem("orderSummary", JSON.stringify(summary));
}

function loadCartFromLocalStorage() {
  const cart = localStorage.getItem("cart");
  return cart ? JSON.parse(cart) : { cartCounter: 0, cartContainer: {} };
}

function loadOrderSummaryFromLocalStorage() {
  const order = localStorage.getItem("orderSummary");
  return order ? JSON.parse(order) : {};
}

const ordersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addKittoCart, (state, action) => {
      const kit = action.payload;
      if (!state.cart.cartKit) {
        state.cart.cartCounter += 1;
      }
      state.cart.cartKit = kit;
      saveCartToLocalStorage(state.cart);
    })
    .addCase(addToCartFromFirstSteps, (state, action) => {
      const product = action.payload;
      const productId = product.productId;

      if (!state.cart) {
        state.cart = {
          cartFirstStepsProducts: {},
          cartCounter: 0,
        };
      }

      if (!state.cart.cartFirstStepsProducts) {
        state.cart.cartFirstStepsProducts = {};
      }

      if (state.cart.cartFirstStepsProducts[productId]) {
        state.cart.cartFirstStepsProducts[productId].quantity += 1;
        state.cart.cartCounter += 1;
        saveCartToLocalStorage(state.cart);
        return;
      }

      state.cart.cartFirstStepsProducts[productId] = {
        ...product,
        quantity: 1,
      };
      state.cart.cartCounter += 1;

      saveCartToLocalStorage(state.cart);
    })
    .addCase(removeFromCartFirstSteps, (state, action) => {
      const productId = action.payload.productId;

      if (!state.cart || !state.cart.cartFirstStepsProducts) {
        return;
      }

      if (!state.cart.cartFirstStepsProducts[productId]) {
        return;
      }

      if (state.cart.cartFirstStepsProducts[productId].quantity === 1) {
        delete state.cart.cartFirstStepsProducts[productId];
        state.cart.cartCounter -= 1;
        saveCartToLocalStorage(state.cart);
        return;
      }

      if (state.cart.cartFirstStepsProducts[productId].quantity > 1) {
        state.cart.cartFirstStepsProducts[productId].quantity -= 1;
        state.cart.cartCounter -= 1;
        saveCartToLocalStorage(state.cart);
      }
    })
    .addCase(addToCart, (state, action) => {
      const product = action.payload;
      const productId = product.productId;

      if (state.cart.cartContainer[productId]) {
        state.cart.cartContainer[productId].quantity += 1;
        state.cart.cartCounter += 1;
        saveCartToLocalStorage(state.cart);
        return;
      }
      state.cart.cartContainer[productId] = {
        ...product,
        quantity: 1,
      };
      state.cart.cartCounter += 1;

      saveCartToLocalStorage(state.cart);
    })
    .addCase(removeFromCart, (state, action) => {
      const productId = action.payload.productId;

      if (!state.cart.cartContainer[productId]) {
        console.warn(`El producto con ID ${productId} no existe en el carrito.`);
        return;
      }

      if (state.cart.cartContainer[productId].quantity == 1) {
        delete state.cart.cartContainer[productId];
        state.cart.cartCounter -= 1;
        saveCartToLocalStorage(state.cart);
        return;
      }
      if (state.cart.cartContainer[productId].quantity > 1) {

        state.cart.cartContainer[productId].quantity -= 1;
      }

      state.cart.cartCounter -= 1;

      saveCartToLocalStorage(state.cart);
    })
    .addCase(loadCart, (state, action) => {
      const cart = loadCartFromLocalStorage();
      state.cart = cart;
    })
    .addCase(loadOrderSummary, (state, action) => {
      const order = loadOrderSummaryFromLocalStorage();
      state.orderSummary = order;
    })
    .addCase(saveCartBody, (state, action) => {
      state.cart.cartBody = action.payload;
      saveCartToLocalStorage(state.cart);
    })
    .addCase(saveNewTotalAmount, (state, action) => {
      state.orderSummary.cartSummary.newTotalAmount = action.payload;
      saveOrderSummaryToLocalStorage(state.orderSummary);
    })
    .addCase(clearCart, (state, action) => {
      state.cart = {
        cartCounter: 0,
        cartContainer: {},
        cartFirstStepsProducts: {},
        cartKit: null,
        total: 0,
        cartBody: {}
      };
    })
    .addCase(fetchKitData.fulfilled, (state, action) => {
      const { kits, billedKit } = action.payload;

      state.kitData = kits;
      state.hasKit = billedKit;
      state.loading = false;
    })
    .addCase(fetchKitData.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(fetchKitData.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchGetOrders.fulfilled, (state, action) => {
      const { orders, requests } = action.payload;
      state.orders = orders;
      state.requests = requests;
      state.loading = false;
    })
    .addCase(fetchGetOrders.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(fetchGetOrders.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getClientList.fulfilled, (state, action) => {
      state.clients = action.payload.contacts;
    })
    .addCase(orders, (state, action) => {
      state.orders = action.payload;
    })
    .addCase(requests, (state, action) => {
      state.requests = action.payload;
    })
    .addCase(ordersSelect, (state, action) => {
      const { orderSelect, count, products } = action.payload;
      state.ordersSelect = orderSelect;
      state.ordersSelectCount = count;
      state.productsSelect = products;
    })
    .addCase(isOrder, (state, action) => {
      state.isOrder = action.payload;
    })
    .addCase(orderType, (state, action) => {
      state.orderType = action.payload;
    })
    .addCase(cleanOrderData, (state, action) => {
      return { ...initialState };
    })
    .addCase(setFilter, (state, action) => {
      state.filterOrders = action.payload;
    })
    .addCase(setOrder, (state, action) => {
      state.orderSummary = action.payload;
    });
});

export default ordersReducer;
