import { useRef } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import OfertsModalImageCardContentGift from "./OfertsModalImageCardContentGift";
import OfertsModalImageCardContentJustcoins from "./OfertsModalImageCardContentJustcoins";

export default function OfertsModalImageCardContent({
  closeModalAll,
  modalRef,
  element,
  dataSharingController,
  replaceElementsURL,
  newScrollbarRef,
}) {
  let auxLength = element?.description?.length;
  const scrollbarRef = useRef(null);
  const dataSharing = useSelector((state) => state.ofertsData.dataSharing);
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const switchOfertTarget = (key) => {
    switch (key) {
      case "consultora":
        return (
          <div
            style={{
              background: "#5AAFF1",
              borderRadius: "40px",
              width: "110px",
            }}
            className=" d-flex justify-content-center text-white s12 "
          >
            <p>
              <FormattedMessage id="for" />{" "}
              <span className="text-capitalize">
                {switchPerValue(dataSharing?.data?.applyTo)}
              </span>
            </p>
          </div>
        );

      default:
        return (
          <div
            style={{
              background: "#456ECE",
              borderRadius: "40px",
              width: "110px",
            }}
            className=" d-flex justify-content-center text-white skyBg s12 "
          >
            <p>
              <FormattedMessage id="for" />{" "}
              <span className="text-capitalize">
                {switchPerValue(dataSharing?.data?.applyTo)}
              </span>
            </p>
          </div>
        );
    }
  };

  const switchPerValue = (type) => {
    if (type == "cliente") {
      return "Clientes";
    }
    if (type == "consultora") {
      return "Consultores";
    }

    return "";
  };

  return (
    <div
      ref={newScrollbarRef}
      style={{ height: "350px" }}
      className="d-flex flex-column museo-sans-500 hidden-y-scroll  "
    >
      <div ref={modalRef} className=""></div>
      {switchOfertTarget(dataSharing?.data?.applyTo)}
      <p className=" d-flex flex-column pt-2 mt-1 s12">
        <span className=" grayColor text-uppercase pe-1 ">
          {dataSharing?.data?.type}
        </span>
        <span className=" text-uppercase">
          {" "}
          {formatDateRange(
            dataSharing?.data?.dateStartOfPublication,
            dataSharing?.data?.dateEndOfPublication
          )}
        </span>
      </p>
      <div>
        <p className=" pt-4 s20 museo-sans-700 ">{dataSharing?.data?.title}</p>
      </div>
      {dataSharing?.data?.badgeType?.indexOf("Oferta") != -1 && (
        <div className="mt-1">
          <p>{dataSharing?.data?.productName}</p>
          <div className=" mt-1 d-flex gap-2 align-items-center ">
            <p className=" museo-sans-700 s20 ">
              {dataSharing?.data?.discountPrice && (
                <>
                  {formatAmount(
                    +dataSharing?.data?.discountPrice,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </>
              )}
            </p>
            <p className=" grayColor s14 text-decoration-line-through">
              {dataSharing?.data?.regularPrice && (
                <>
                  {formatAmount(
                    +dataSharing?.data?.regularPrice,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </>
              )}
            </p>
          </div>
        </div>
      )}
      {dataSharing?.data?.badgeType?.indexOf("Descuento") != -1 && (
        <div className="mt-1">
          <p>{dataSharing?.data?.productName}</p>
          <div className=" mt-1 d-flex gap-2 align-items-center ">
            <p className=" museo-sans-700 s20 ">
              {dataSharing?.data?.discountPrice && (
                <>
                  {formatAmount(
                    +dataSharing?.data?.discountPrice,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </>
              )}
            </p>
            <p className=" grayColor s14 text-decoration-line-through">
              {dataSharing?.data?.regularPrice && (
                <>
                  {formatAmount(
                    +dataSharing?.data?.regularPrice,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </>
              )}
            </p>
          </div>
        </div>
      )}
      {dataSharing?.data?.badgeType == "Justcoins" && (
        <div className="mt-1">
          <p>{dataSharing?.data?.productName}</p>
          <div className=" mt-1 d-flex gap-2 align-items-center ">
            <p className=" museo-sans-700 s20 ">
              {dataSharing?.data?.discountPrice && (
                <>
                  {formatAmount(
                    +dataSharing?.data?.discountPrice,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </>
              )}
            </p>
            <p className=" grayColor s14 text-decoration-line-through">
              {dataSharing?.data?.regularPrice && (
                <>
                  {formatAmount(
                    +dataSharing?.data?.regularPrice,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </>
              )}
            </p>
          </div>
        </div>
      )}
      {dataSharing?.data?.badgeType == "Descuento + Justcoins" && (
        <div>
          <OfertsModalImageCardContentJustcoins
            data={dataSharing?.data}
            replaceElementsURL={replaceElementsURL}
          />
        </div>
      )}
      {dataSharing?.data?.badgeType == "Justcoins" && (
        <div>
          <OfertsModalImageCardContentJustcoins
            data={dataSharing?.data}
            replaceElementsURL={replaceElementsURL}
          />
        </div>
      )}
      {dataSharing?.data?.badgeType?.indexOf("Regalo") != -1 && (
        <div>
          <OfertsModalImageCardContentGift
            data={dataSharing?.data}
            replaceElementsURL={replaceElementsURL}
          />
        </div>
      )}
      <div className=" pt-3">
        <p className=" museo-sans-500 s16 ">{dataSharing?.data?.description}</p>
      </div>

      <pre
        style={{
          fontSize: "16px",
          whiteSpace: "pre-wrap",
          overfloWrap: "break-word",
          wordWrap: "break-word,",
          maxHeight: "180px",
        }}
        ref={scrollbarRef}
        className="museo-sans-500 hidden-y-scroll"
      >
        {element?.description}
      </pre>
      {auxLength > 289 && (
        <ModalWhiteShadow
          left={"0%"}
          width={"90%"}
          scrollbarRef={scrollbarRef}
        />
      )}
      <div className=" grayColor museo-sans-500 s12 pt-4">
        <p>{dataSharing?.data?.legals}</p>
      </div>
      <div className="invisible" style={{ height: "15px", background: "red" }}>
        <p>.</p>
      </div>
    </div>
  );
}

export function formatDateRange(start, end) {
  const months = [
    "ENERO",
    "FEBRERO",
    "MARZO",
    "ABRIL",
    "MAYO",
    "JUNIO",
    "JULIO",
    "AGOSTO",
    "SEPTIEMBRE",
    "OCTUBRE",
    "NOVIEMBRE",
    "DICIEMBRE",
  ];

  const startDate = new Date(start?.split("T")[0]);
  const endDate = new Date(end?.split("T")[0]);

  const formattedStart = `${startDate.getUTCDate()} DE ${
    months[startDate.getUTCMonth()]
  }`;
  const formattedEnd = `${endDate.getUTCDate()} DE ${
    months[endDate.getUTCMonth()]
  }`;

  return `DEL ${formattedStart} AL ${formattedEnd}`;
}
