import React, { useState } from 'react';
import { ReactComponent as SVGWarning } from "../../Icons/Warning.svg";
import { normalizedStr, formatNumber } from '../../common';

function JustCoinsModal({ showModal, setShowModal, availableCoins, maxAmount, setSelectedJustCoins, isBalanceEnough }) {
    const [amount, setAmount] = useState('');
    const [useMaxAmount, setUseMaxAmount] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    };

    const handleAmountChange = (value) => {
        if (value.startsWith('0')) {
            value = value.replace(/^0+/, '');
        }

        let newValue = formatNumber(value, true)

        let numericNewValue = normalizedStr(newValue)
        let numericMaxAmount = normalizedStr(maxAmount)

        if (numericNewValue >= numericMaxAmount) {
            newValue = formatNumber(maxAmount);
            setUseMaxAmount(true)
        } else {
            setUseMaxAmount(false);
        }
        setAmount(newValue);
    };

    const handleUseMaxToggle = () => {
        setUseMaxAmount(!useMaxAmount);
        if (!useMaxAmount) {
            setAmount(formatNumber(maxAmount));
        } else {
            setAmount('');
        }
    };

    const handleSelectedJustCoins = () => {
        setSelectedJustCoins(amount)
    }

    return (
        <div
            className={`modal fade ${showModal ? 'show' : ''}`}
            id="paymentJustCoins"
            aria-hidden={!showModal}
            tabIndex="-1"
            style={{ display: showModal ? 'block' : 'none' }}
            onClick={() => handleClose()}
        >
            <div
                className="modal-dialog modal-dialog-centered"
                style={{ width: "498px" }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="modal-content" style={{ borderRadius: "16px" }} >
                    <div style={{ padding: "32px" }} >
                        <p className='museo-sans-700 blackColor s20' style={{ lineHeight: "26px" }}>Just Coins</p>

                        <div className=" d-flex flex-column align-items-center mt-4">
                            <label className="museo-sans-700 blackColor s16 ">Ingresar monto</label>
                            <div className="amount-input-wrapper">
                                <input
                                    type="text"
                                    value={amount}
                                    onChange={(e) => handleAmountChange(e.target.value)}
                                    className="amount-input dm-sans-500"
                                    placeholder="0"
                                />
                            </div>
                            <p className="dm-sans-500 s14 grayColor my-3" style={{ lineHeight: "20px" }}>
                                Just Coins disponibles: {formatNumber(availableCoins)}
                            </p>
                        </div>

                        <div className="d-flex justify-content-between align-items-center" style={{ marginTop: "20px" }}>
                            <div className="d-flex align-items-center gap-2">
                                <div className="form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={useMaxAmount}
                                        onChange={handleUseMaxToggle}
                                    />
                                </div>
                                <span className="museo-sans-500 s14">Usar monto máximo</span>
                            </div>

                            <span className="dm-sans-500 s14">
                                {formatNumber(maxAmount)}
                            </span>
                        </div>

                        {maxAmount !== availableCoins && <div style={{
                            background: "#FEFBEC"
                        }}
                            className="kit-notification-container p-3 d-flex align-items-center mt-3">
                            <div style={{ marginRight: "0px" }}>
                                <SVGWarning />
                            </div>
                            <p className='museo-sans-500 s12 blackColor'>
                                Solo puedes pagar el 90% del total del pedido con Just Coins
                            </p>
                        </div>}


                        <div className='d-flex justify-content-center'>
                            <button
                                data-bs-dismiss="modal"
                                aria-label="Close"

                                data-bs-toggle={!isBalanceEnough && "modal"}
                                data-bs-target={!isBalanceEnough && "#paymentMethod"}
                                data-amount={amount}
                                style={{ marginTop: "32px" }}
                                onClick={() => handleSelectedJustCoins()} className='payment-btn skyBg whiteColor skyBorder museo-sans-700 s14'>Utilizar este monto</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JustCoinsModal;