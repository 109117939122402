import { createReducer } from "@reduxjs/toolkit";
import { fetchBalance, getTerritoryByLevel } from "./PaymentsSideEffect";

const initialState = {
    balance: null,
    loader: false,
    locations: {}
};

const paymentsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fetchBalance.fulfilled, (state, action) => {
            state.balance = action.payload;
            state.loader = false;
        })
        .addCase(fetchBalance.pending, (state) => {
            state.loader = true;
        })
        .addCase(fetchBalance.rejected, (state) => {
            state.loader = false;
        })
        .addCase(getTerritoryByLevel.fulfilled, (state, action) => {
            state.locations = action.payload;
        })
});

export default paymentsReducer;