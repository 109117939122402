import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const fetchImportant = createAsyncThunk(
    'fetchImportant',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${location}/great-practices/${clientId}/important-cards`);
            return response.data;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchEcommerce = createAsyncThunk(
    'fetchEcommerce',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${location}/great-practices/${clientId}/ecommerce-cards`);
            return response.data;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchPersonalSalesBond = createAsyncThunk(
    'fetchPersonalSalesBond',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`${location}/great-practices/${clientId}/personal-sales-bond?filter_type=${data?.filterType ?? ""}&limit=20&offset=${data?.offset ?? 0}&sort_by=${data?.billedOrder ? data?.billedOrder : "billed.desc" }`);
            return response.data;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

export { fetchImportant, fetchEcommerce, fetchPersonalSalesBond  }