import React, { useEffect } from 'react';
import ShareFeaturedChildren from "../../../common/Share/ShareFeaturedChildren";
import ShareCenterControlPage from '../../../common/Share/ShareCenterControlPage';
import { fetchKitData, fetchGetOrders } from '../../Framework/redux/OrdersSideEffect';
import { Outlet } from 'react-router-dom';
import { loadCart, loadOrderSummary, orders } from "../../../Orders/Framework/redux/OrdersActions";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
const OrdersOutlet = () => {
    const dispatch = useDispatch();
    const orderData = useSelector((state) => state.orderData);
    const location = useLocation();
    useEffect(() => {
        getKitData();
        onForcedReaload();
    }, [])

    useEffect(() => {
        if (orderData?.hasKit) {
            const clientId = localStorage.getItem("clientId");
            const countryCode = localStorage.getItem("country");
            getOrders(clientId, countryCode);
        }
    }, [orderData?.hasKit]);
    const isOrdersKit = () => {
        const paths = [
            "/dashboard/orders/listado-kit",
        ]

        return paths.some(path => location.pathname.includes(path));
    };
    const onForcedReaload = () => {
        dispatch(loadCart());
        dispatch(loadOrderSummary())
    };
    const getKitData = async () => {
        const clientId = localStorage.getItem("clientId")
        const countryCode = localStorage.getItem("country")

        await dispatch(
            fetchKitData({ clientId, countryCode })
        );
    };
    const getOrders = async (clientId, countryCode) => {
        if (clientId != "5726") {
            await dispatch(fetchGetOrders({ clientId, countryCode }));
        } else {
            dispatch(orders([]));
        }
    };

    return (
        <ShareCenterControlPage pageBackground={isOrdersKit() ? '#FFF' : false} >
            <Outlet />
        </ShareCenterControlPage>

    );
}

export default OrdersOutlet;
