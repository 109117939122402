import { useState } from "react";
import { useSelector } from "react-redux";
import CategoriesSearch from "../components/categoriesSearch/categoriesSearch";
import ProductsCategories from "../components/products/products";
import "./consultationCatalogue.css";
export default function ConsultationCatalogue() {

  const [selectedCategory, setSelectedCategory] = useState({
    id: 0,
    description: "",
  });
  const orderType = useSelector((state) => state.orderData).orderType;
  const [searchText, setSearchText] = useState("");

  return (
    <>
      <div className="categories-container">

        <CategoriesSearch
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
          setSearchText={setSearchText}
          searchText={searchText}
          ordertype={orderType}
        />

        <ProductsCategories
          titleCategory={selectedCategory}
          searchText={searchText}
          ordertype={orderType}
        ></ProductsCategories>
      </div>
    </>
  );
}
