import React from 'react';
import './modalAllProducts.css';

const ModalAllProducts = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" >
      <div className="modal-contentProducts ">
        <div className="modal-header museo-sans-600 s20" style={{marginBottom:'24px'}}>
          <p>Productos del pedido ({data.orderDataCount})</p>
        </div>
        <div className="modal-body">
          <div className="productos museo-sans-400 s14 ">
            <div className='overflow' style={{paddingTop:'0px'}}>
            {Object.values(data.productsSelect).map((producto, index) => (
              <div key={index} className="producto" style={{ position: 'relative' }}>
              <span>
                <div
                  style={{
                    background: "#D18DEF",
                    width: "24px",
                    height: "24px",
                    marginLeft: '31px',
                    marginTop: '-31px'
                  }}
                  className="s12 dm-sans-500 position-absolute text-white d-flex justify-content-center align-items-center rounded-circle"
                >
                  x{producto.totalItems}
                </div>
              </span>
              <img src={producto.img} alt={producto.title} />
              <div className="producto-info">
                <p className='museo-sans-600 s16'>
                  {producto.title} <br />
                  <small className='museo-sans-400 s14 color'>{producto.id}</small>
                </p>
                <p style={{ textAlign: 'end', width: '113px' }}>
                  <strong className='museo-sans-700 s16'>{producto.price}</strong> <br />
                  <small className='museo-sans-500 s14 color'>PVP: {producto.pvpPrice}</small>
                </p>
              </div>
            </div>
            ))}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className='container-button' onClick={onClose}>Entendido</button>
        </div>
      </div>
    </div>
  );
};

export default ModalAllProducts;
