import React, { useState } from 'react';
import './SchedulesModal.css';
const SchedulesModal = () => {
    const [schedules, setSchedules] = useState([
        "Lunes de 08:00 a 21:00",
        "Martes de 08:00 a 21:00",
        "Miércoles de 08:00 a 21:00",
        "Jueves de 08:00 a 21:00",
        "Viernes de 08:00 a 21:00",
        "Sabado de 08:00 a 21:00"
    ]);

    return (
        <div
            className="modal fade"
            id="selectSchedules"
            aria-hidden="true"
            tabIndex="-1"
        >
            <div
                className="modal-dialog modal-dialog-centered"
                style={{ maxWidth: "496px" }}
            >
                <div className="modal-content modal-radius">
                    <div className="modal-body container-modal-Schedules" style={{ padding: "32px" }}>
                        <div className='mb-4'>
                            <div className='d-flex mb-4 gap-2 '>
                                <h1 className=' museo-sans-700 m-0 s20'>Horarios</h1>
                            </div>
                        </div>

                        <div style={{  maxHeight: "220px"}}
                            className="hidden-y-scroll d-flex flex-column mt-4">
                            {schedules?.map((sch, index) => (
                                <div key={index}>
                                    <div className="d-flex gap-2 align-items-center container-time">
                                        <p className='museo-sans-500 s14 blackColor'>{sch}</p>
                                    </div>
                                    {index < schedules.length - 1}
                                </div>
                            ))}
                        </div>

                        <div className='d-flex justify-content-center container-button-Schedules'>
                            <button
                                data-bs-dismiss="modal" aria-label="Close"
                                type="button"
                                className="payment-btn skyBg whiteColor skyBorder museo-sans-700 s14 "
                            >
                                Entendido
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SchedulesModal;
