import { ReactComponent as SVGInfo } from "../icons/Info.svg";
import { ReactComponent as SVGChevArrowDown } from "../../../common/svgs/ic-chevron-down-md.svg";
import { FormattedMessage } from "react-intl";

export default function EcommerceFormCustomHolder(params) {
  return (
    <div
      style={{ width: "600px" }}
      className="d-flex flex-column gap-4 museo-sans-400"
    >
      <div className=" museo-sans-400 s20">
        <FormattedMessage id="ecommerce.form.custom.title" />
      </div>
      {params?.formData?.body?.history?.value?.length == 0 && (
        <div className="s14 bgWarningHelpMessage museo-sans-400 p-3">
          <FormattedMessage id="ecommerce.form.custom.description" />
        </div>
      )}
      <div className=" museo-sans-400 s20">
        <FormattedMessage id="your.history" />
      </div>
      <div className="d-flex flex-column gap-2">
        <p className="s14 museo-sans-600">
          <FormattedMessage id="ecommerce.form.phrase.title" />
        </p>
        <div className="d-flex flex-column gap-1">
          <div
            data-bs-toggle="modal"
            data-bs-target="#form-select-phrase"
            className={` ${
              params?.formData?.body?.phrase?.value?.title ||
              params?.ecommerceData?.data?.phrase
                ? ""
                : "grayColor"
            } pointer w-100 s14 d-flex justify-content-between rounded-3 input-border px-3 py-2`}
          >
            <p>
              {params?.formData?.body?.phrase?.value?.title
                ? params?.formData?.body?.phrase?.value?.title
                : params?.ecommerceData?.data?.phrase || "Seleccionar frase"}
            </p>
            <div>
              <SVGChevArrowDown />
            </div>
          </div>
          <div className="d-flex gap-1 s12 museo-sans-400">
            <div>
              <SVGInfo />
            </div>
            <p>
              <FormattedMessage id="ecommerce.form.phrase.description" />
            </p>
          </div>
        </div>
      </div>
      <div className=" d-flex flex-column w-100">
        <div className="d-flex justify-content-between s14 mb-2">
          <p>
            <FormattedMessage id="ecommerce.form.history.title" />
          </p>
          <div className=" dm-sans-medium ">
            <p>{params?.formData?.body?.history?.value?.length ?? 0}/480</p>
          </div>
        </div>
        <textarea
          onChange={(evt) =>
            params?.onChange("history.value", evt.target.value)
          }
          value={params?.formData?.body?.history?.value}
          maxLength={480}
          style={{
            padding: "16px 12px 16px 12px",
            height: "144px",
            resize: "none",
            outline: "none",
          }}
          className={` ${
            params?.errorArray?.history ? "input-error-border" : "input-border"
          } rounded-3 museo-sans-400 w-100 s14 museo-sans-400 `}
          placeholder="Ej.: Placeholder"
        />
      </div>
      {params?.errorArray?.history && (
        <div
          style={{ top: "-12px" }}
          className=" position-relative d-flex gap-1 s14 lightRedColor  "
        >
          <div style={{ top: "-2px" }} className=" position-relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M7.665 8.5981L9.59785 10.5309C9.72004 10.6531 9.87556 10.7142 10.0644 10.7142C10.2532 10.7142 10.4088 10.6531 10.5309 10.5309C10.6531 10.4088 10.7142 10.2532 10.7142 10.0644C10.7142 9.87556 10.6531 9.72004 10.5309 9.59785L8.5981 7.665L10.5309 5.73215C10.6531 5.60996 10.7142 5.45444 10.7142 5.2656C10.7142 5.07676 10.6531 4.92124 10.5309 4.79905C10.4088 4.67686 10.2532 4.61576 10.0644 4.61576C9.87556 4.61576 9.72004 4.67686 9.59785 4.79905L7.665 6.7319L5.73215 4.79905C5.60996 4.67686 5.45444 4.61576 5.2656 4.61576C5.07676 4.61576 4.92124 4.67686 4.79905 4.79905C4.67686 4.92124 4.61576 5.07676 4.61576 5.2656C4.61576 5.45444 4.67686 5.60996 4.79905 5.73215L6.7319 7.665L4.79905 9.59785C4.67686 9.72004 4.61576 9.87556 4.61576 10.0644C4.61576 10.2532 4.67686 10.4088 4.79905 10.5309C4.92124 10.6531 5.07676 10.7142 5.2656 10.7142C5.45444 10.7142 5.60996 10.6531 5.73215 10.5309L7.665 8.5981ZM7.665 14.33C6.74301 14.33 5.87656 14.155 5.06565 13.8051C4.25474 13.4552 3.54936 12.9803 2.94951 12.3805C2.34966 11.7806 1.87478 11.0753 1.52487 10.2643C1.17496 9.45344 1 8.58699 1 7.665C1 6.74301 1.17496 5.87656 1.52487 5.06565C1.87478 4.25474 2.34966 3.54936 2.94951 2.94951C3.54936 2.34966 4.25474 1.87478 5.06565 1.52487C5.87656 1.17496 6.74301 1 7.665 1C8.58699 1 9.45344 1.17496 10.2643 1.52487C11.0753 1.87478 11.7806 2.34966 12.3805 2.94951C12.9803 3.54936 13.4552 4.25474 13.8051 5.06565C14.155 5.87656 14.33 6.74301 14.33 7.665C14.33 8.58699 14.155 9.45344 13.8051 10.2643C13.4552 11.0753 12.9803 11.7806 12.3805 12.3805C11.7806 12.9803 11.0753 13.4552 10.2643 13.8051C9.45344 14.155 8.58699 14.33 7.665 14.33Z"
                fill="#F06E69"
              />
            </svg>
          </div>
          <p>
            <FormattedMessage id="input.requeried" />
          </p>
        </div>
      )}
    </div>
  );
}
