import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { removeXAmountArray } from "../../../../Descendants/Framework/redux/DescendantsAction";
import EcommerceStatus from "../../../../Ecommerce/Presentation/components/EcommerceStatus";
import HelpIcon from "../../../Share/HelpIcon";
import HelpIconNoTarget from "../../../Share/HelpIconNoTarget";
import { ReactComponent as SVGChevronRight } from "../../../svgs/c-chevron-right-sm.svg";

export default function HeaderTracker() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  let auxAscBreadcrumb = 0;
  let auxAscArray = 0;

  const [toggler, setToggler] = useState(false);
const isOrders = useSelector((state) => state.orderData.isOrder);
  const edbTitle = useSelector((state) => state.edbDetailsData.data);
  const otherConsultantData = useSelector(
    (state) => state.performanceData.otherConsultantData
  );
  const groupName = useSelector(
    (state) => state.descendantData.dataPersonalGroupName
  );
  const idorderSummary = useSelector(
    (state) => state.orderData.orderSummary.id
  );
  const arrayAsc = useSelector((state) => state.descendantData.dataArrayAsc);
  const profileData = useSelector((state) => state.userInfo);
  const clientId = localStorage.getItem("clientId");
  const profileDescData = useSelector(
    (state) => state.descendantData.descendantProfilaData
  );
  const clientDetails = useSelector(
    (state) => state.clientListData.clientDetails
  );

  let edbFormRouteType = localStorage.getItem("formBodyType");

  const routeMappings = new Map([
    ["/dashboard/earning/period", 1],
    ["/dashboard/profile", 2],
    ["/dashboard/ratings", 3],
    ["/dashboard/calendar", 4],
    ["/dashboard/goodpractice", 5],
    ["/dashboard/edbs", 6],
    ["/dashboard/edbs/details/invitation", 32],
    ["/dashboard/edbs/details", 7],
    ["/dashboard/edbs/list", 10],
    ["/dashboard/edbs/list/" + params.state + "/", 18],
    ["/dashboard/businessplan", 8],
    ["/dashboard/account", 9],
    ["/dashboard/performance", 11],
    ["/detailsSales", 12],
    ["/descendants/personal-group", 13],
    ["/descendants/dashboard", 14],
    ["/descendants/profile/asc", 17],
    ["/descendants/profile", 16],
    ["/dashboard/otherUserSales", 15],
    ["/dashboard/weeklydeals", 19],
    ["/dashboard/firstSteps", 20],
    ["/dashboard/account/status/details", 22],
    ["/dashboard/account/status", 21],
    ["/dashboard/clientlist/details/", 24],
    ["/dashboard/clientlist/form/update", 26],
    ["/dashboard/clientlist/form/add", 25],
    ["/dashboard/clientlist", 23],
    ["/dashboard/invitations/add", 28],
    ["/dashboard/invitations/list", 27],
    ["/dashboard/edbs/form/type/virtual/", 33],
    ["/dashboard/edbs/form/type/present/", 34],
    ["/dashboard/edbs/form/client/list", 31],
    ["/dashboard/edbs/form/client", 30],
    ["/dashboard/edbs/form/", 29],
    ["invitee/details/", 35],
    ["/dashboard/goodpractice/bonds", 37],
    ["/dashboard/ecommerce", 38],
    ["/dashboard/orders/business-kit", 40],
    ["/dashboard/orders/catalogo-consulta", 41],
    ["/dashboard/orders/cart", 42],
    ["/dashboard/orders", 39],
    ["/dashboard/orders/firststeps/", 43],
    [`/dashboard/orders/firststeps/${params?.step}/helpsales`, 44],
    ["/dashboard/orders/summary", 45],
    ["/dashboard/orders/payment", 46],
    ["/dashboard/orders/listado-kit", 47],
  ]);

  const getTrackerValue = () => {
    const getLongestRouteId = (arrayValues) => {
      const longestRoute = arrayValues.reduce((longest, current) => {
        return current.route.length > longest.route.length ? current : longest;
      });
      return longestRoute.id;
    };

    if (
      location?.pathname?.includes("/client/list") &&
      location?.pathname?.includes("/dashboard/edbs/details/")
    ) {
      return 36;
    };

    let arrayValues = [];
    for (const [route, value] of routeMappings) {
      if (location?.pathname?.includes(route)) {
        arrayValues.push({ id: value, route: route });
      }
    }

    return getLongestRouteId(arrayValues) ?? 0;
  };

  let tracker = getTrackerValue();

  function onAscHeader(index, path) {
    dispatch(removeXAmountArray(index));
    onBreadcrumb(path);
  };

  function onBreadcrumb(path) {
    navigate(path);
  };

  function manageArray() {
    const auxArray = [];
    for (let index = 0; index < arrayAsc.length - 1; index++) {
      auxArray.push(arrayAsc[index]);
    }
    return auxArray;
  }
  const getId=()=>{
   
    return `Pedido ${idorderSummary}`
  }
  function trackingRoute(param) {
    switch (param) {
      case 38:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/account")}
              label="my.profile"
              defaultMessage="my.profile"
            />
            <BreadcrumbSeparator />
            <p className=" text-capitalize s16 museo-sans-700">
              Tu Tienda Virtual
            </p>
            <div style={{top:"-4px"}} className="d-flex position-relative mx-1 s10" >
              {
                location?.pathname?.includes("/dashboard/ecommerce/edit") &&
              <EcommerceStatus height="22px" />
              }

            </div>
          </>
        )
      case 47:
        return (
          <>
            <BreadcrumbLink label='Tus órdenes y pedidos' />
            <BreadcrumbSeparator/>
            <p className=" s16 museo-sans-500">{isOrders?'Tus órdenes':'Tus pedidos'}</p>
          </>
        );
      case 46:
        return (
          <>
            <BreadcrumbLink label='pedido' />
            <BreadcrumbSeparator />
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/orders/summary")}
              label="Resumen de pedido"
            />
            <BreadcrumbSeparator />
            <p className=" s16 museo-sans-500">Pago del pedido</p>
          </>
        );
      case 45:
        return (
          <>
            <BreadcrumbLink label='pedido' />
            <BreadcrumbSeparator/>
            <p className=" s16 museo-sans-500">Resumen de pedido</p>
          </>
        );
      case 44:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/orders/cart")}
              label="Pedido 3000"
            />
            <BreadcrumbSeparator />
            <BreadcrumbLink label="Ofertas y promociones" />
            <BreadcrumbSeparator />
            <p className=" text-capitalize s16 museo-sans-700">
              <FormattedMessage id="orders.cart.first.steps.help.sales.title" />
            </p>
          </>
        );
      case 43:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/orders/cart")}
              label="Pedido 3000"
            />
            <BreadcrumbSeparator />
            <BreadcrumbLink label="Ofertas y promociones" />
            <BreadcrumbSeparator />
            <p className=" text-capitalize s16 museo-sans-700">
              <FormattedMessage id="first.steps.title" />
            </p>
          </>
        );
      case 42:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/orders")}
              label="dashboard.orders"
              defaultMessage={"Tus órdenes y pedidos"}
            />
            <BreadcrumbSeparator />
            <p className=" s16 museo-sans-500">
              <FormattedMessage
                id="dashboard.personal.order"
                defaultMessage={"Nueva orden personal"}
              />
            </p>
            <BreadcrumbSeparator />
            <BreadcrumbLink
              onClick={() =>
                onBreadcrumb("/dashboard/orders/catalogo-consulta")
              }
              label="dashboard.catalogue"
              defaultMessage={"Tus órdenes y pedidos"}
            />
            <BreadcrumbSeparator />
            <p className="s16 museo-sans-700">Carrito</p>
          </>
        );
      case 41:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/orders")}
              label="dashboard.orders"
              defaultMessage={"Tus órdenes y pedidos"}
            />
            <BreadcrumbSeparator />
            <p className=" s16 museo-sans-500">
              <FormattedMessage
                id="dashboard.personal.order"
                defaultMessage={"Nueva orden personal"}
              />
            </p>
            <BreadcrumbSeparator />
            <p className="s16 museo-sans-700">
              <FormattedMessage
                id="dashboard.catalogue"
                defaultMessage={"Seleccionar productos"}
              />
            </p>
          </>
        );
      case 40:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/orders")}
              label="dashboard.orders"
              defaultMessage={"Tus órdenes y pedidos"}
            />
            <BreadcrumbSeparator />
            <p className=" s16 museo-sans-700">
              <FormattedMessage
                id="dashboard.business.kit"
                defaultMessage={"Kit de Negocio"}
              />
            </p>
          </>
        );
      case 39:
        return (
          <>
            <FormattedMessage
              id="dashboard.orders"
              defaultMessage={"Tus órdenes y pedidos"}
            />
            {/*  <HelpIcon size="sm" /> */}
            <HelpIconNoTarget size="sm" />
          </>
        );
      case 37:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/goodpractice")}
              label="good.practice"
              defaultMessage="encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            <p className=" text-capitalize s16 museo-sans-700">
              <FormattedMessage id="descendants.personal.group.tracker.yours" />
            </p>
          </>
        );
      case 36:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/edbs")}
              label="edb.header.title"
              defaultMessage="encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            <div
              onClick={() => onBreadcrumb(-1)}
              className=" pointer museo-sans-500"
            >
              {edbTitle?.title}
            </div>
            <BreadcrumbSeparator />
            <p className=" museo-sans-700 pointer s16">
              <FormattedMessage id="client.list.header.title" />
            </p>{" "}
          </>
        );
      case 35:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/edbs")}
              label="good.practice"
              defaultMessage="encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            <div
              onClick={() => onBreadcrumb(-1)}
              className=" pointer museo-sans-500"
            >
              {edbTitle?.title}
            </div>
            <BreadcrumbSeparator />
            <p className=" text-capitalize s16 museo-sans-700">
              {clientDetails?.data?.firstName} {clientDetails?.data?.lastName}
            </p>
          </>
        );
      case 1:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard")}
              label="nav.label.one.index"
            />
            <BreadcrumbSeparator />
            <FormattedMessage
              id="dashboard.prev.earning.modal.info.title"
              defaultMessage="Tus Ganancias de Períodos Anteriores"
            />
            <HelpIconNoTarget />
          </>
        );
      case 2:
        return (
          <>
            <FormattedMessage id="my.profile" />
            <HelpIconNoTarget />
          </>
        );
      case 3:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard")}
              label="nav.label.one.index"
            />
            <BreadcrumbSeparator />
            <FormattedMessage id="dashboard.score" />
          </>
        );
      case 4:
        return <FormattedMessage id="nav.label.seven.index" />;
      case 5:
        return (
          <>
            <FormattedMessage
              id="good.practice"
              defaultMessage="Buenas Practicas"
            />
            <HelpIcon size="sm" />
          </>
        );
      case 6:
        return (
          <>
            <FormattedMessage
              id="edb.header.title"
              defaultMessage="encuentros de bienestar"
            />
            <HelpIcon size="sm" />
          </>
        );
      case 7:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/edbs")}
              label="edb.header.title"
              defaultMessage="encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            {edbTitle?.title}
          </>
        );
      case 32:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/edbs")}
              label="edb.header.title"
              defaultMessage="encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            <div
              onClick={() => onBreadcrumb(-1)}
              className=" pointer museo-sans-500"
            >
              {edbTitle?.title}
            </div>
            <BreadcrumbSeparator />
            <p className=" text-capitalize s16 museo-sans-700">
              <FormattedMessage id="invite.assitant" />
            </p>
          </>
        );
      case 8:
        return (
          <>
            <FormattedMessage id="business.plan" />
            <HelpIcon size="sm" />
          </>
        );
      case 9:
        return <FormattedMessage id="nav.label.five.index" />;
      case 10:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/edbs")}
              label="edb.header.title.two"
              defaultMessage="Tus encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            <FormattedMessage id="all.encounters" />
          </>
        );
      case 11:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard")}
              label="nav.label.one.index"
            />
            <BreadcrumbSeparator />
            {profileData.userInfo.level > 1 ? (
              <FormattedMessage id="dashboard.modal.qualification.title" />
            ) : (
              <FormattedMessage id="settings.notifications.keyManteinance.two" />
            )}
          </>
        );
      case 12:
        return (
          <h5 className=" museo-sans-700 m-0  s16">
            {" "}
            <span
              onClick={() => onBreadcrumb("/dashboard")}
              className=" pointer museo-sans-500  pe-2"
            >
              <FormattedMessage
                id="nav.label.one.index"
                defaultMessage={"Desempeño"}
              />
            </span>
            <BreadcrumbSeparator />
            <FormattedMessage id="request.detail" />{" "}
          </h5>
        );
      case 15:
        return (
          <h5 className=" museo-sans-700 m-0  s16">
            {" "}
            <BreadcrumbLink
              onClick={() =>
                onBreadcrumb(
                  profileData.userInfo.level > 1
                    ? "/dashboard/descendants/dashboard"
                    : `/dashboard/descendants/personal-group/${clientId}`
                )
              }
              label="descendants.header.title"
              defaultMessage="encuentros de bienestar"
            />
            {profileData.userInfo.level > 1 && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbLink
                  onClick={() =>
                    onBreadcrumb(
                      `/dashboard/descendants/personal-group/${clientId}`
                    )
                  }
                  label="descendants.personal.group.tracker.yours"
                />
              </>
            )}
            <BreadcrumbSeparator />
            {otherConsultantData?.firstName
              ? `Venta de ${otherConsultantData?.firstName}`
              : null}
          </h5>
        );
      case 13:
        return (
          <>
            {profileData.userInfo.level < 2 ? (
              <div style={{ alignItems: "center" }} className=" d-flex ">
                <h5 className=" museo-sans-700 m-0 s16">
                  {" "}
                  <span className=" d-flex gap-2">
                    {" "}
                    <p className=" s16">
                      <FormattedMessage
                        id="descendants.header.title"
                        defaultMessage="Tu Descendencia"
                      />
                    </p>{" "}
                    <HelpIcon size={"sm"} />
                  </span>{" "}
                </h5>
              </div>
            ) : (
              <div style={{ alignItems: "center" }} className=" d-flex ">
                <h5 className=" museo-sans-500 m-0 s16">
                  {" "}
                  <span className=" d-flex gap-2">
                    {" "}
                    <p
                      className="pointer s16"
                      onClick={() =>
                        onBreadcrumb("/dashboard/descendants/dashboard")
                      }
                    >
                      <FormattedMessage
                        id="descendants.header.title"
                        defaultMessage={"descendencia"}
                      />
                    </p>{" "}
                    <HelpIcon size={"sm"} />
                    <BreadcrumbSeparator />
                    {params.root == clientId ? (
                      <p className=" museo-sans-700 ">
                        <FormattedMessage id="descendants.personal.group.tracker.yours" />
                      </p>
                    ) : (
                      <p className=" museo-sans-700 ">
                        <FormattedMessage id="descendants.personal.group.tracker" />{" "}
                        {groupName?.name}
                      </p>
                    )}
                  </span>{" "}
                </h5>
              </div>
            )}
          </>
        );
      case 14:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex ">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex gap-2">
                {" "}
                <p
                  className="pointer s16"
                  onClick={() =>
                    onBreadcrumb("/dashboard/descendants/dashboard")
                  }
                >
                  {profileData?.userInfo?.level > 1 ? (
                    <FormattedMessage
                      id="descendants.header.title"
                      defaultMessage="Tu Descendencia"
                    />
                  ) : (
                    <FormattedMessage
                      id="descendants.header.title.two"
                      defaultMessage="Tu Descendencia"
                    />
                  )}
                </p>{" "}
                <HelpIcon size={"sm"} />
              </span>{" "}
            </h5>
          </div>
        );
      case 16:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex ">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex">
                {" "}
                <BreadcrumbLink
                  onClick={() =>
                    onBreadcrumb(
                      profileData.userInfo.level > 1
                        ? "/dashboard/descendants/dashboard"
                        : `/dashboard/descendants/personal-group/${clientId}`
                    )
                  }
                  label="descendants.header.title"
                  defaultMessage="encuentros de bienestar"
                />
                <BreadcrumbSeparator />
                {profileData.userInfo.level > 1 && (
                  <>
                    <BreadcrumbLink
                      onClick={() =>
                        onBreadcrumb(
                          `/dashboard/descendants/personal-group/${clientId}`
                        )
                      }
                      label="descendants.personal.group.tracker.yours"
                      defaultMessage="encuentros de bienestar"
                    />
                    <BreadcrumbSeparator />
                  </>
                )}
                <p className=" museo-sans-700 ">
                  <>
                    {groupName?.name == "" ? (
                      <>{profileDescData?.name}</>
                    ) : (
                      <>{groupName?.name}</>
                    )}
                  </>
                </p>
              </span>{" "}
            </h5>
          </div>
        );
      case 17:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex ">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex">
                {" "}
                <BreadcrumbLink
                  onClick={() =>
                    onBreadcrumb(
                      profileData.userInfo.level > 1
                        ? "/dashboard/descendants/dashboard"
                        : `/dashboard/descendants/personal-group/${clientId}`
                    )
                  }
                  label="descendants.header.title"
                  defaultMessage="encuentros de bienestar"
                />
                <BreadcrumbSeparator />
                {arrayAsc.length < 2 && (
                  <>
                    {profileData.userInfo.level > 2 && (
                      <>
                        <BreadcrumbLink
                          onClick={() =>
                            onBreadcrumb(
                              `/dashboard/descendants/personal-group/${clientId}`
                            )
                          }
                          label="descendants.personal.group.tracker.yours"
                          defaultMessage="encuentros de bienestar"
                        />
                        <BreadcrumbSeparator />
                      </>
                    )}
                    <p
                      className=" pointer"
                      onClick={() =>
                        onBreadcrumb(
                          `/dashboard/descendants/profile/${groupName?.clientId}`
                        )
                      }
                    >
                      {groupName?.name}
                    </p>
                  </>
                )}
                {arrayAsc.length > 1 && (
                  <div className=" position-relative ">
                    {toggler && (
                      <div
                        style={{ width: "288px", left: "0px", top: "22px" }}
                        className=" position-absolute bg-white museo-sans-500 s14 rounded-2  "
                      >
                        {profileData.userInfo.level > 1 && (
                          <>
                            <p
                              onClick={() =>
                                onBreadcrumb(
                                  `/dashboard/descendants/personal-group/${clientId}`
                                )
                              }
                              className=" pointer px-3 my-1 py-2 d-flex align-items-center"
                              style={{ height: "40px" }}
                            >
                              <FormattedMessage id="descendants.personal.group.tracker.yours" />{" "}
                            </p>
                            <hr className=" m-0 p-0" />
                          </>
                        )}
                        <p
                          onClick={() =>
                            onBreadcrumb(
                              `/dashboard/descendants/profile/${groupName.clientId}`
                            )
                          }
                          className=" pointer px-3 my-1 py-2 d-flex align-items-center"
                          style={{ height: "40px" }}
                        >
                          {groupName?.name}
                        </p>

                        {manageArray().map((elm) => {
                          auxAscArray++;
                          auxAscBreadcrumb--;
                          return (
                            <>
                              <hr className=" m-0 p-0" />
                              <p
                                onClick={() =>
                                  onAscHeader(auxAscArray, auxAscBreadcrumb)
                                }
                                className=" pointer px-3 my-1 py-2 d-flex align-items-center"
                                style={{ height: "40px" }}
                              >
                                {elm.name}
                              </p>
                            </>
                          );
                        })}
                      </div>
                    )}
                    <p
                      onClick={() => setToggler(!toggler)}
                      className=" pointer skyColor"
                    >
                      ...
                    </p>
                  </div>
                )}
                <BreadcrumbSeparator />
                {arrayAsc.length < 1 ? (
                  <p
                    onClick={() =>
                      onBreadcrumb(`/dashboard/descendants/dashboard`)
                    }
                  >
                    a
                  </p>
                ) : (
                  <p className=" museo-sans-700 pointer">
                    {arrayAsc[arrayAsc.length - 1].name}
                  </p>
                )}
              </span>{" "}
            </h5>
          </div>
        );
      case 18:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/edbs")}
                >
                  <FormattedMessage
                    id="edb.header.title"
                    defaultMessage={"encuentros de bienestar"}
                  />
                </p>{" "}
                <HelpIcon size={"sm"} />
              </span>{" "}
            </h5>
            <BreadcrumbSeparator />
            <span
              onClick={() =>
                onBreadcrumb("/dashboard/edbs/list/" + params.state)
              }
              className=" pointer svg-stroke-gray museo-sans-500 pe-2 "
            >
              {params.state == "closed" ? (
                <FormattedMessage id="edb.list.table.col.closed" />
              ) : (
                <>
                  {params.state == "dated" ? (
                    <FormattedMessage id="edb.list.table.col.dated" />
                  ) : (
                    <FormattedMessage id="edb.list.table.col.openned" />
                  )}
                </>
              )}
            </span>{" "}
            <BreadcrumbSeparator />
            <span className="  museo-sans-700 s16">
              {edbTitle && edbTitle.title}
            </span>
          </div>
        );

      case 19:
        return (
          <h5 className=" museo-sans-700 m-0 s16">
            {" "}
            <FormattedMessage
              id="dashboard.weekly.deals"
              defaultMessage={"Tus Ganancias de Períodos Anteriores"}
            />{" "}
            <i
              className=" pointer "
              data-bs-toggle="modal"
              data-bs-target="#modalInfo"
            ></i>{" "}
          </h5>
        );
      case 20:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p className=" museo-sans-500 s16">
                  {profileData.userInfo.level < 2 ? (
                    <FormattedMessage
                      id="dashboard.steps.1"
                      defaultMessage={"encuentros de bienestar"}
                    />
                  ) : (
                    <FormattedMessage
                      id="dashboard.steps"
                      defaultMessage={"encuentros de bienestar"}
                    />
                  )}
                </p>{" "}
                <div
                  data-bs-toggle="modal"
                  data-bs-target="#modalFirstOne"
                  className=" pointer d-flex aling-items-center justify-content-center"
                >
                  <HelpIconNoTarget size={"sm"} />
                </div>
              </span>{" "}
            </h5>
          </div>
        );
      case 21:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/account")}
                >
                  <FormattedMessage
                    id="nav.label.five.index"
                    defaultMessage={"encuentros de bienestar"}
                  />
                </p>{" "}
              </span>{" "}
            </h5>
            <BreadcrumbSeparator />
            <p
              onClick={() => onBreadcrumb("/dashboard/account/status")}
              className=" museo-sans-500 pointer s16"
            >
              <FormattedMessage
                id="nav.label.thirty.index"
                defaultMessage={"Tus Ganancias de Períodos Anteriores"}
              />
            </p>
          </div>
        );
      case 22:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/account")}
                >
                  <FormattedMessage
                    id="nav.label.five.index"
                    defaultMessage={"encuentros de bienestar"}
                  />
                </p>{" "}
              </span>{" "}
            </h5>
            <span className=" svg-stroke-gray museo-sans-500 pe-2 ">
              <SVGChevronRight />{" "}
            </span>{" "}
            <p
              onClick={() => onBreadcrumb("/dashboard/account/status")}
              className=" museo-sans-500 pointer s16"
            >
              <FormattedMessage
                id="nav.label.thirty.index"
                defaultMessage={"Tus Ganancias de Períodos Anteriores"}
              />
            </p>
            <BreadcrumbSeparator />
            <p className=" museo-sans-500 pointer s16">
              <FormattedMessage
                id="nav.label.fourty.index"
                defaultMessage={"Tus Ganancias de Períodos Anteriores"}
              />
            </p>
          </div>
        );
      case 23:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p className=" museo-sans-700 s16">
                  <FormattedMessage id="client.list.header.title" />
                </p>{" "}
              </span>{" "}
            </h5>
          </div>
        );
      case 24:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/clientlist")}
                >
                  <FormattedMessage id="client.list.header.title" />
                </p>{" "}
                <BreadcrumbSeparator />
                <p className=" museo-sans-700 pointer s16">
                  {clientDetails?.data?.firstName}{" "}
                  {clientDetails?.data?.lastName}
                </p>
              </span>{" "}
            </h5>
          </div>
        );
      case 25:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/clientlist")}
                >
                  <FormattedMessage id="client.list.header.title" />
                </p>{" "}
                <BreadcrumbSeparator />
                <p className=" museo-sans-700 pointer s16">
                  <FormattedMessage id="other.header.tracker.new.client" />
                </p>
              </span>{" "}
            </h5>
          </div>
        );
      case 26:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/clientlist")}
                >
                  <FormattedMessage id="client.list.header.title" />
                </p>{" "}
                <BreadcrumbSeparator />
                <p
                  onClick={() =>
                    onBreadcrumb(
                      `/dashboard/clientlist/details/${clientDetails?.data?.contactId}`
                    )
                  }
                  className=" museo-sans-500 pointer s16"
                >
                  {clientDetails?.data?.firstName}{" "}
                  {clientDetails?.data?.lastName}
                </p>
                <BreadcrumbSeparator />
                <p className=" museo-sans-700 pointer s16">
                  <FormattedMessage id="other.header.tracker.edit.personal.data" />
                </p>
              </span>{" "}
            </h5>
          </div>
        );
      case 27:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p className=" museo-sans-700 pointer s16">
                  <FormattedMessage id="other.header.tracker.invitations" />
                </p>{" "}
              </span>{" "}
            </h5>
          </div>
        );
      case 28:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p className=" museo-sans-700 pointer s16">
                  <FormattedMessage id="other.header.tracker.invitations.new" />
                </p>{" "}
              </span>{" "}
            </h5>
          </div>
        );
      case 29:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex gap-2">
                {" "}
                <p
                  className="pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/edbs")}
                >
                  <FormattedMessage
                    id="edb.header.title"
                    defaultMessage={"Tus encuentros de bienestar"}
                  />
                </p>{" "}
              </span>{" "}
            </h5>
            <BreadcrumbSeparator />
            <span className=" museo-sans-700 s16">
              <FormattedMessage id="edb.form.header.tracker.new.edb" />
            </span>
          </div>
        );
      case 30:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex gap-2">
                {" "}
                <p
                  className="pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/edbs")}
                >
                  <FormattedMessage
                    id="edb.header.title.two"
                    defaultMessage={"Tus encuentros de bienestar"}
                  />
                </p>{" "}
              </span>{" "}
            </h5>
            <BreadcrumbSeparator />
            <span
              onClick={() =>
                onBreadcrumb(`/dashboard/edbs/form/type/${edbFormRouteType}`)
              }
              className=" pointer museo-sans-500 s16"
            >
              <FormattedMessage id="edb.form.header.tracker.new.edb" />
            </span>
            <BreadcrumbSeparator />
            <span className=" museo-sans-700 s16">
              <FormattedMessage id="edb.form.header.tracker.new.client" />
            </span>
          </div>
        );
      case 31:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex  gap-2">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex gap-2">
                {" "}
                <p
                  className="pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/edbs")}
                >
                  <FormattedMessage
                    id="edb.header.title.two"
                    defaultMessage={"Tus encuentros de bienestar"}
                  />
                </p>{" "}
              </span>{" "}
            </h5>
            <span className=" svg-stroke-gray museo-sans-500">
              <SVGChevronRight />
            </span>
            <span
              onClick={() =>
                onBreadcrumb(`/dashboard/edbs/form/type/${edbFormRouteType}`)
              }
              className=" pointer museo-sans-500 s16"
            >
              <FormattedMessage id="edb.form.header.tracker.new.edb" />
            </span>
            <BreadcrumbSeparator />
            <span className=" museo-sans-700 s16">
              <FormattedMessage id="edb.form.header.tracker.search.host" />
            </span>
          </div>
        );
      case 33:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2">
            <BreadcrumbLink
              defaultMessage={"any"}
              label={"edb.header.title"}
              onClick={() => onBreadcrumb("/dashboard/edbs")}
            />
            <BreadcrumbSeparator />
            <span className=" museo-sans-700 s16">
              <FormattedMessage id="header.tracker.edb.form.update.virtual" />
            </span>
          </div>
        );
      case 34:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2">
            <BreadcrumbLink
              defaultMessage={"any"}
              label={"edb.header.title"}
              onClick={() => onBreadcrumb("/dashboard/edbs")}
            />
            <BreadcrumbSeparator />
            <span className=" museo-sans-700 s16">
              <FormattedMessage id="header.tracker.edb.form.update.present" />
            </span>
          </div>
        );
      default:
        return (
          <h5 className="">
            {" "}
            <span className="s16" style={{ color: "gray" }}>
              No existe
            </span>{" "}
          </h5>
        );
    }
  };

  const BreadcrumbLink = ({ onClick, label, defaultMessage }) => (
    <span onClick={onClick} className="pointer museo-sans-500 pe-2">
      <FormattedMessage id={label} defaultMessage={defaultMessage} />
    </span>
  );
  const BreadcrumbLinkGray = ({ onClick, label, defaultMessage }) => (
    <span onClick={onClick} className="pointer museo-sans-500 pe-2" style={{color:'#908F9A'}}> 
      <FormattedMessage id={label} defaultMessage={defaultMessage} />
    </span>
  );

  const BreadcrumbSeparator = () => (
    <span className="svg-stroke-gray museo-sans-500 pe-2">
      <SVGChevronRight />
    </span>
  );
  const BreadcrumbSeparatorBlue = () => (
    <span className="svg-stroke-blue museo-sans-500 pe-2" style={{height:'24px',width:'24px'}}>
      <SVGChevronRight />
    </span>
  );
  return (
    <h5 className="museo-sans-700 m-0 s16 d-flex gap-2 align-items-center">
      {trackingRoute(tracker)}
    </h5>
  );
}
