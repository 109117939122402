import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import ShareModalIndex from "../../../../common/Share/ShareModalIndex";
import ShareModalStructureCloseIcon from "../../../../common/Share/ShareModalStructureCloseIcon";

export default function OrdersFirstStepsModalInfo(params) {

  const JSXElements = [
    {
      index: 1,
      text: "Los productos adquiridos en el programa Primeros Pasos no tienen puntos, ni valor negocio."
    },
    {
      index: 2,
      text: "La oportunidad de pedir los productos con descuento ganados por cumplir con Logro 1 o Logro 2 vencen en la semana 8 siguiente a la adquisición del Kit."
    },
    {
      index: 3,
      text: "De no haber completado tu Logro 1, podes ganar el Logro 2 llegando a la venta de $ 80.000 PVP hasta tu cuarta semana."
    },
    {
      index: 4,
      text: "Para ganar el Paso 1 Encuentro, la venta deberá estar registrada con Ordenes de EDB que tengan fechado confirmado."
    },
    {
      index: 5,
      text: "Los productos se solicitan al consolidar el pedido y enviarlo a compañía. Los premios se asignan espontáneos al consolidar el pedido y enviarlo a compañía."
    },
    {
      index: 6,
      text: "Los productos en promoción podrán ser modificados de acuerdo a stock. Las incorporaciones anteriores a período 03, con programa vigente, mantienen requisitos a lograr anteriores a esta actualización."
    },
    {
      index: 7,
      text: "Los productos en promoción podrán ser modificados de acuerdo a stock. Las incorporaciones anteriores a período 03, con programa vigente, mantienen requisitos a lograr anteriores a esta actualización."
    },
    {
      index: 8,
      text: "Los productos en promoción podrán ser modificados de acuerdo a stock. Las incorporaciones anteriores a período 03, con programa vigente, mantienen requisitos a lograr anteriores a esta actualización."
    },
    {
      index: 9,
      text: "Los productos en promoción podrán ser modificados de acuerdo a stock. Las incorporaciones anteriores a período 03, con programa vigente, mantienen requisitos a lograr anteriores a esta actualización."
    },
    {
      index: 10,
      text: "Los productos en promoción podrán ser modificados de acuerdo a stock. Las incorporaciones anteriores a período 03, con programa vigente, mantienen requisitos a lograr anteriores a esta actualización."
    },
  ]

  return (
    <div
      style={{ padding: "44px 44px 44px 44px" }}
      className=" position-relative"
    >
      <div style={{right: "0px", top: "0px"}} className=" position-absolute">
        <ShareModalStructureCloseIcon top={"16px"} />
      </div>
      <div className="d-flex gap-2">
        <ShareModalIndex >
        <div className=" position-relative">
          <HelpIconNoTarget />
        </div>

        </ShareModalIndex>
        <p className=" museo-sans-700 s24" ><FormattedMessage id="orders.cart.oferts.importnat.modal.title" />:</p>
      </div>
      <div style={{marginTop: "12px"}} className=" d-flex flex-column gap-1 s14 museo-sans-500" >
        {
          JSXElements.map((element, index) => {
            return(
              <RowCounter
              key={index}
              index={element.index}
              text={element.text}
              />
            )
          })
        }
      </div>
      <div style={{marginTop: "44px"}} className=" d-flex justify-content-center align-items-center w-100" >
      <div
      data-bs-dismiss="modal"
      aria-label="Close" 
      className=" d-flex justify-content-center align-items-center btnLoginWidth skyBg text-white rounded-3 museo-sans-700 s14 pointer ">
        <FormattedMessage id="buttons.understood" />
      </div>

      </div>
    </div>
  );
  
}

export function RowCounter(params) {
  
  return(
    <div className="d-flex gap-1 position-relative " >
          <p style={{left: params?.index > 9 ? "-7px" : ""}} className="position-absolute" >
            {params?.index}.
          </p>
          <p className="mx-3">
            {params?.text}
          </p>
        </div>
  )
}