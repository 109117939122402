import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SummaryRequested from "../../components/summaryRequested/summaryRequested";
import SummaryOrders from "../../components/summaryOrders/summaryOrders";

export default function Summary() {
    const cartContainer = useSelector(
        (state) => state.orderData.cart.cartContainer
      );
    const isRequested=true;
  return (
    <>
      <SummaryRequested></SummaryRequested>
    </>
  );
}




