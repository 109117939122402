import React from 'react';
import "./skeletoncardproduct.css";
const ProductCardSkeleton = () => {
  return (
    <div className="product-card-skeleton">
      <div className="product-image-skeleton skeleton-box"></div>
      <div className="product-details-skeleton">
        <div style={{ height: "108px", padding: "16px 20px 0px 20px" }}>
          <div className="product-title-skeleton skeleton-box"></div>
          <div className="product-description-skeleton skeleton-box"></div>
        </div>
        <div className="product-price-skeleton skeleton-box"></div>
        <div style={{ padding: "16px", borderTop: "1px solid #DDDDDD" }}>
          <div className="product-button-skeleton skeleton-box"></div>
        </div>
      </div>
    </div>
  );
};

export default ProductCardSkeleton;
