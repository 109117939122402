import { useSelector } from "react-redux/es";
import { useLocation, useNavigate } from "react-router";

export default function OrdersHeaderCartIcon(params) {
  const location = useLocation();
  const navigate = useNavigate();

  const onClickCart = () => {
    if (location.pathname.includes("/dashboard/orders/cart")) {
      return "";
    }
    navigate("/dashboard/orders/cart");
  };

  return (
    <div onClick={onClickCart} className=" pointer position-relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M25.339 21.6609L27.4281 12.4415C27.4635 12.2852 27.4634 12.1229 27.4276 11.9667C27.3917 11.8105 27.3212 11.6644 27.2213 11.5394C27.1213 11.4143 26.9945 11.3135 26.8502 11.2445C26.706 11.1755 26.5481 11.1401 26.3883 11.1408H9.03573L8.63005 9.15517C8.58123 8.91501 8.45119 8.69914 8.26193 8.5441C8.07267 8.38905 7.83582 8.30436 7.59146 8.30435H3.06119C2.92189 8.30419 2.78392 8.33158 2.65518 8.38494C2.52644 8.4383 2.40944 8.51659 2.31089 8.61534C2.21233 8.71408 2.13415 8.83135 2.0808 8.96042C2.02746 9.0895 2 9.22785 2 9.36758V10.0764C2 10.2161 2.02746 10.3545 2.0808 10.4836C2.13415 10.6126 2.21233 10.7299 2.31089 10.8286C2.40944 10.9274 2.52644 11.0057 2.65518 11.059C2.78392 11.1124 2.92189 11.1398 3.06119 11.1396H6.15434L9.257 26.3649C8.80728 26.6236 8.44993 27.0175 8.23541 27.491C8.02089 27.9644 7.96004 28.4934 8.06145 29.0034C8.16286 29.5134 8.4214 29.9786 8.80058 30.3332C9.17976 30.6879 9.66042 30.9141 10.1747 30.98C10.689 31.0459 11.2109 30.9481 11.6668 30.7004C12.1226 30.4527 12.4894 30.0676 12.7154 29.5996C12.9413 29.1315 13.015 28.6041 12.926 28.0918C12.8369 27.5795 12.5898 27.1082 12.2193 26.7444H21.4845C21.109 27.1136 20.8605 27.5931 20.7751 28.1135C20.6896 28.6339 20.7717 29.1681 21.0093 29.6385C21.2469 30.109 21.6278 30.4913 22.0966 30.7301C22.5655 30.9688 23.0979 31.0515 23.6168 30.9662C24.1357 30.881 24.614 30.6321 24.9824 30.2558C25.3508 29.8795 25.5901 29.3952 25.6656 28.8733C25.7411 28.3514 25.6489 27.8189 25.4024 27.3531C25.1558 26.8872 24.7678 26.5123 24.2945 26.2826L24.5324 25.2086C24.5676 25.0529 24.5673 24.8912 24.5318 24.7356C24.4962 24.58 24.4262 24.4344 24.3269 24.3096C24.2276 24.1848 24.1016 24.084 23.9582 24.0147C23.8148 23.9454 23.6577 23.9093 23.4986 23.9091H11.6364L11.3473 22.4903H24.304C24.5448 22.4905 24.7784 22.4085 24.9666 22.2577C25.1547 22.107 25.286 21.8965 25.339 21.6609Z"
          fill="#5AAFF1"
        />
      </svg>
      <OrdersHeaderCartIconCounter />
    </div>
  );
}

export function OrdersHeaderCartIconCounter(params) {
  const counter = useSelector((state) => state.orderData.cart.cartCounter);
  const cartContainer = useSelector(
    (state) => state.orderData.cart.cartContainer
  );

  const controlCounter = (value) => {
    if (value > 9) {
      return "+9";
    }

    return value;
  };

  return (
    <>
      {counter != 0 && (
        <div
          style={{ top: "2px", right: "0px" }}
          className=" position-absolute"
        >
          <div
            style={{ width: "18px", height: "18px", border: "1px solid white" }}
            className=" position-relative d-flex justify-content-center align-items-center lightRedBg text-white rounded-circle s12  "
          >
            <p
              style={{ top: "-0.5px", left: counter < 10 ? "" : "-1px" }}
              className="position-relative "
            >
              {counter ? controlCounter(counter) : 0}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
