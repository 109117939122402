import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SVGCloseGreen } from "../svgs/ic-x-sm-green.svg";
import { ReactComponent as SVGCloseRed } from "../svgs/ic-x-sm.svg";

export default function ShareModalClipboard({ data, close }) {
  const history = useNavigate();
  const [onView, setOnView] = useState(false);

  const styles = {
    1: { width: "392px", color: "#62221F", backgroundColor: "#FEF0F0" },
    2: { width: "392px", color: "#456ECE", backgroundColor: "#ECF0FA" },
    3: { width: "392px" },
  };

  const stylesStatusIndex = {
    confirm: 3,
    error: 1,
    info: 2,
  };

  const onWebMovement = () => {
    const closeModalClick = document.getElementById("modal--clipboard--close");
    if (onView && closeModalClick) {
      closeModalClick.click();
      setOnView(false);
    }
    if (!onView && closeModalClick) {
      setOnView(true);
    }
  };

  useEffect(() => {
    onWebMovement();
  }, [history])

  useEffect(() => {
    setOnView(true);
  }, [data]);


  return (
    data.show && (
      <div className="">
        <div
          style={{
            bottom: data.bottom ? data.bottom : 0,
            left: data.left ? data.left : 0,
            zIndex: 1000,
          }}
          className=" p-3 spacing--modal greenColor position-fixed"
        >
          <div
            style={styles[stylesStatusIndex[data.status]]}
            className=" shadow  py-3 "
            id="modal--clipboard"
          >
            {data?.status == "error" ? (
              <div style={{justifySelf: "center"}} >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8 8.84L9.74 10.58C9.85 10.69 9.99 10.745 10.16 10.745C10.33 10.745 10.47 10.69 10.58 10.58C10.69 10.47 10.745 10.33 10.745 10.16C10.745 9.99 10.69 9.85 10.58 9.74L8.84 8L10.58 6.26C10.69 6.15 10.745 6.01 10.745 5.84C10.745 5.67 10.69 5.53 10.58 5.42C10.47 5.31 10.33 5.255 10.16 5.255C9.99 5.255 9.85 5.31 9.74 5.42L8 7.16L6.26 5.42C6.15 5.31 6.01 5.255 5.84 5.255C5.67 5.255 5.53 5.31 5.42 5.42C5.31 5.53 5.255 5.67 5.255 5.84C5.255 6.01 5.31 6.15 5.42 6.26L7.16 8L5.42 9.74C5.31 9.85 5.255 9.99 5.255 10.16C5.255 10.33 5.31 10.47 5.42 10.58C5.53 10.69 5.67 10.745 5.84 10.745C6.01 10.745 6.15 10.69 6.26 10.58L8 8.84Z"
                    fill="#62221F"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.89772 12.9766C6.54936 13.2578 7.24809 13.4 8 13.4C8.75191 13.4 9.45064 13.2578 10.1023 12.9766C10.7656 12.6904 11.3366 12.3049 11.8207 11.8207C12.3049 11.3366 12.6904 10.7656 12.9766 10.1023C13.2578 9.45064 13.4 8.75191 13.4 8C13.4 7.24809 13.2578 6.54936 12.9766 5.89772C12.6904 5.23443 12.3049 4.66345 11.8207 4.17926C11.3366 3.69508 10.7656 3.30961 10.1023 3.0234C9.45064 2.74221 8.75191 2.6 8 2.6C7.24809 2.6 6.54936 2.74221 5.89772 3.0234C5.23443 3.30961 4.66345 3.69508 4.17926 4.17926C3.69508 4.66345 3.30961 5.23443 3.0234 5.89772C2.74221 6.54936 2.6 7.24809 2.6 8C2.6 8.75191 2.74221 9.45064 3.0234 10.1023C3.30961 10.7656 3.69508 11.3366 4.17926 11.8207C4.66345 12.3049 5.23443 12.6904 5.89772 12.9766ZM5.66 13.5275C6.39 13.8425 7.17 14 8 14C8.83 14 9.61 13.8425 10.34 13.5275C11.07 13.2125 11.705 12.785 12.245 12.245C12.785 11.705 13.2125 11.07 13.5275 10.34C13.8425 9.61 14 8.83 14 8C14 7.17 13.8425 6.39 13.5275 5.66C13.2125 4.93 12.785 4.295 12.245 3.755C11.705 3.215 11.07 2.7875 10.34 2.4725C9.61 2.1575 8.83 2 8 2C7.17 2 6.39 2.1575 5.66 2.4725C4.93 2.7875 4.295 3.215 3.755 3.755C3.215 4.295 2.7875 4.93 2.4725 5.66C2.1575 6.39 2 7.17 2 8C2 8.83 2.1575 9.61 2.4725 10.34C2.7875 11.07 3.215 11.705 3.755 12.245C4.295 12.785 4.93 13.2125 5.66 13.5275Z"
                    fill="#62221F"
                  />
                </svg>
              </div>
            ) : (
              <i
                style={{ justifySelf: "center" }}
                className="  bi bi-check-circle"
              ></i>
            )}
            <div>
              <p className=" museo-sans-700 s14 ">
                {data.title ?? "Datos copiados"}
              </p>
              <p className=" museo-sans-500 s14 ">
                {data.text ?? "Se han copiado los datos en el portapapeles."}
              </p>
            </div>
            <div
              id="modal--clipboard--close"
              onClick={close}
              style={{ justifySelf: "center " }}
              className="pointer"
            >
              {data?.status == "info" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.46406 2.46465C2.66806 2.26065 2.99882 2.26065 3.20283 2.46465L9.53513 8.79695C9.73913 9.00096 9.73913 9.33171 9.53513 9.53572C9.33112 9.73972 9.00036 9.73972 8.79636 9.53572L2.46406 3.20342C2.26005 2.99941 2.26005 2.66866 2.46406 2.46465Z"
                    fill="#456ECE"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.46406 9.53535C2.26005 9.33134 2.26005 9.00059 2.46406 8.79658L8.79636 2.46428C9.00036 2.26028 9.33112 2.26028 9.53513 2.46428C9.73913 2.66829 9.73913 2.99904 9.53513 3.20305L3.20283 9.53535C2.99882 9.73935 2.66806 9.73935 2.46406 9.53535Z"
                    fill="#456ECE"
                  />
                </svg>
              )}
              {data?.status === "confirm" && <SVGCloseGreen />}
              {data?.status == "error" && <SVGCloseRed />}
            </div>
          </div>
        </div>
      </div>
    )
  );
}
