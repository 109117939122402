import React, { useState } from 'react';
import "./ATMs.css";
import logos2 from "../../../../../../Imgs/logos2.png";
const ATMs = () => {


  return (
    <div>
      <p className="museo-sans-600 container-img   s20">
        Pago por cajero automático
      </p>
      <p className="museo-sans-400 container-img margin-top-payment   s14" style={{ color: "#908F9A", padding: '0px 24px', textAlign: 'center' }}>
        Podés realizar el pago con tu tarjeta personalizada para cajeros automáticos emitida
        por el banco Credicoop, en cualquier cajero automático dela Red Link.
        Si todavía no tenés tu tarjeta comunicate con tu Líder Regional.
      </p>
      <div className='container-logos2'>
        <img src={logos2} alt="logos2" />
      </div>
    </div>
  );
};

export default ATMs;