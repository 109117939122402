import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import OrdersCardContainer from "../components/OrdersCardContainer";
import OrdersBusinessKit from "../components/OrdersBusinessKit";
import kitImage from "../../imgs/kit-negocio.png";
import { ReactComponent as SVGWarning } from "../../../common/svgs/ic-triangle-warning-new.svg";
import OrdersKit from "../components/ordersKit/ordersKit";
import { orderType } from "../../Framework/redux/OrdersActions";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import OrderDashboardModalFirstPersonal from "../components/modals/OrderDashboardModalFirstPersonal";
import ShareModalIndex from "../../../common/Share/ShareModalIndex";

const DashboardOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderData = useSelector((state) => state.orderData);

  const selectBusinessKit = () => {
    dispatch(orderType("personal"));
    navigate(`/dashboard/orders/business-kit`);
  };

  const listPreview = (list) => {
    if (list.length < 4) return list;
    return list.slice(0, 4);
  };

  const onOrderCardContainerClick = (card) => {
    const activeOnType = (type) => {
      if (type == "personal") {
        if (localStorage.getItem("personal-order-not-show-again") === "true") {
          onPersonalConfirmAdvance("personal")
          return;
          
        }
        const clickMe = document.getElementById("modal-first-personal-order-index");
        clickMe.click();
        return;
      };
      if (type == "client") {

      };
      if (type == "edb") {

      };
    };
    activeOnType(card?.type);
  };

  const onPersonalConfirmAdvance = (type) => {
    dispatch(orderType(type));
    if (orderData?.hasKit) {
      navigate(`/dashboard/orders/catalogo-consulta`);
      return;
    }

    navigate(`/dashboard/orders/business-kit`);

  };

  const onVoid = () => {

  };

  const ObjectButtonsModal = [
    {
      text: "Crear Orden de Cliente",
      onClick: () => onVoid(),
      isDisabled: true,
    },
    {
      text: "Continuar con Orden Personal",
      onClick: () => onPersonalConfirmAdvance("personal"),
      isDisabled: false,
    },
  ];

  return (
    <div className="container p-0">
      <ShareModalStructure maxWidth={"496px"} id="modal-first-personal-order">
        <OrderDashboardModalFirstPersonal buttons={ObjectButtonsModal} />
      </ShareModalStructure>
      <ShareModalIndex id="modal-first-personal-order" />
      <OrdersCardContainer orderData={orderData} onCardClick={onOrderCardContainerClick} />
      {orderData.loading ? (
        <div
          style={{ height: "168px" }}
          className="rounded-3 brightGrayBg lightui1-shimmer mt-5  width-container-card"
        ></div>
      ) : !orderData?.hasKit ? (
        <div className="mt-5">
          <OrdersBusinessKit
            image={kitImage}
            title="dashboard.orders.kit.title"
            description="dashboard.orders.kit.description"
            buttonLabel="buttons.orders.kit"
            onButtonClick={() => selectBusinessKit()}
            icon={<SVGWarning />}
          />
        </div>
      ) : (
        <>
          <div style={{ marginBottom: "51px" }}>
            <OrdersKit
              title={"Tus Órdenes"}
              subTitle={"Ver todas las órdenes"}
              subtitleNot={
                "Verás tus órdenes aquí en cuanto hayas creado alguna."
              }
              titleNot={"Aún no tienes órdenes"}
              list={listPreview(orderData.orders)}
              isOrders={true}
              titleTable={"ORDEN"}
            />
            <OrdersKit
              title={"Tus Pedidos"}
              subTitle={"Ver todos los pedidos"}
              subtitleNot={
                "Verás tus pedidos aquí en cuanto tengas pedidos activos."
              }
              titleNot={"Aún no tienes pedidos"}
              titleTable={"PEDIDO"}
              list={listPreview(orderData.requests)}
              isOrders={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardOrders;
