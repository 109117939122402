import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../common/services/NetSrv";

const fetchEarningsCard  = createAsyncThunk(
    'fetchEarningsCard',
    async (number, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId")
            const response = (await customNetServAxios().get(`${location}/period-earnings/${clientId}/earnings`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchEarningsCardPotentialLeader  = createAsyncThunk(
    'fetchEarningsCardPotentialLeader',
    async (number, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId")
            const response = (await customNetServAxios().get(`${location}/period-earnings/${clientId}/earnings?comparisonLevel=2`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchHistoryEarning  = createAsyncThunk(
    'fetchHistoryEarning',
    async ({currentYearPeriod}, {rejectWithValue}) => { 
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/period-earnings/${clientId}/history/earnings?yearPeriod=${currentYearPeriod}`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchHistoryEarningByPeriod  = createAsyncThunk(
    'fetchHistoryEarningByPeriod',
    async ({yearPeriod}, {rejectWithValue}) => { 
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            const response = (await customNetServAxios().get(`${location}/period-earnings/${clientId}/history/earnings?yearPeriod=${yearPeriod}`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchHistoryPeriods  = createAsyncThunk(
    'fetchHistoryPeriods',
    async (number, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId")
            const response = (await customNetServAxios().get(`${location}/period-earnings/${clientId}/history/periods?historicPeriods=12`)).data;
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

export { fetchEarningsCard, fetchHistoryEarning, fetchHistoryPeriods, fetchHistoryEarningByPeriod, fetchEarningsCardPotentialLeader }